<template>
    <div class="main flex-col-center-center">
        <img src="@/assets/icons/gou.png" />
        <div class="title">{{ $t('feed.tit2') }}</div>
        <div class="desc">{{ $t('feed.tip6') }}</div>
        <div class="btn" @click="handleSkip('/task')">{{ $t('feed.know') }}
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{}
    },methods:{
        //跳转
        handleSkip(url){
            this.$router.push(url)
        }
    }
}

</script>
<style lang="scss" scoped>
.main {
    width: 13.2rem;
    height: 4.6rem;
    background: #FFFFFF;
    border-radius: 0.03rem;
    margin: 0.92rem auto 0;

    img {
        width: 0.8rem;
    }

    .title {

        color: #363636;
        margin-top: 0.3rem;
        font-size: 0.24rem;

    }

    .desc {
        margin-top: 0.2rem;
        color: #363636;
        font-size: 0.16rem;

    }

    .btn {
        width: 2.35rem;
        height: 0.58rem;
        text-align: center;
        line-height: 0.58rem;
        margin-top: 0.6rem;
        cursor: pointer;
        background-color: #02428C;
        color: #ffffff;
        font-size: 0.16rem;
        border-radius: 0.06rem;


    }

}
</style>