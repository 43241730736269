<!-- 任务详情 -->
<template>
    <div class="task-detail">
        <div class="floor1 flex-row-center-between">
            <div class="floor1-left flex-row-center">
                <span class="txt">{{ $t('task.task_manage') }}</span>
                <img src="@/assets/icons/san.png" />
                <span class="txt" v-if="item.user && userId == item.user.id">{{ $t('task.publish') }}</span>
                <span class="txt" v-else-if="item.user && userId != item.user.id && item.has_join == 1">{{ $t('task.accept')
                }}</span>
                <span class="txt" v-else>{{ item.title }}</span>
                <img src="@/assets/icons/san.png" />
                <span class="txt diff"> {{ $t('task.task_detail') }}</span>
            </div>
            <div class="btn" v-if="item.user && userId == item.user.id" @click="handleCancel(item.id)">{{
                $t('task.cancel_task') }}</div>
        </div>
        <!-- 任务状态 -->
        <div class="floor2">
            <div class="tit">{{ $t('task.task_status') }}</div>
            <div class="desc flex-row-center" v-if="item.user && userId == item.user.id">{{ $t('task.tip4') }}，
                <span v-if="item.status == -1">{{ $t('task.status7') }}</span>
                <span v-if="item.status == 0">{{ $t('task.status0') }}</span>
                <span v-if="item.status == 1">{{ $t('task.status6') }}</span>
                <span v-if="item.status == 2">{{ $t('task.status2') }}</span>
                <span v-if="item.status == 3">{{ $t('task.status3') }}</span>
                <span v-if="item.status == 4">{{ $t('index.completed') }}</span>
                <span v-if="item.status == 5">{{ $t('index.completed') }}</span>
                ，

                {{ $t('task.tip5') }}

            </div>
            <div class="desc" v-else>{{ $t('task.success') }}， {{ $t('task.service_ing') }}</div>
            <!-- <div class="desc">经平台确认后，无法为您提供服务，原因是目前没有对应的专家机构。</div> -->
            <!-- item.user && userId == item.user.id && -->
            <ul class="flex-row-center-center" v-if="item.status != -1 && accept == 0">
                <li v-for="(item, index) in steps" :key="index" class="flex-row">
                    <div class="block flex-col-center-center">
                        <img :src="item.time ? item.iconed : item.icon" />
                        <div class="tit" :class="{ 'active-color': item.time }">{{ item.title }}</div>
                        <div class="time" :class="{ 'active-color': item.time }" v-if="item.time">{{ item.time | dateFormat
                        }}</div>
                        <div class="time" v-else></div>
                    </div>
                    <div class="line" v-if="index != 4"></div>
                </li>
            </ul>
            <ul class="flex-row-center-center"
                v-else-if="item.user && item.user.id != userId && accept == 1 && item.status != -1">
                <li v-for="(item, index) in steps2" :key="index" class="flex-row">
                    <div class="block flex-col-center-center">
                        <img :src="item.time ? item.iconed : item.icon" />
                        <div class="tit" :class="{ 'active-color': item.time }">{{ item.title }}</div>
                        <div class="time" :class="{ 'active-color': item.time }" v-if="item.time">{{ item.time }}</div>
                        <div class="time" v-else></div>
                    </div>
                    <div class="line" v-if="index != 3"></div>
                </li>
            </ul>
            <div class="flex-row-center-center" v-else>
                <img src="@/assets/icons/jj.png" style="margin:1rem auto" />
            </div>
        </div>
        <!-- 任务信息 -->
        <div class="floor3">
            <div class="tit"> {{ $t('task.task_info') }}</div>
            <div class="floor3-main">
                <div class="task-info">
                    <div class="title">{{ item.title }}</div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task1.png" />
                        <div class="label-right">{{ $t('task.content') }}：</div>
                    </div>
                    <div class="desc">
                        {{ item.content }}</div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task2.png" />
                        <div class="label-right">{{ $t('task.deliverable') }}：</div>
                    </div>
                    <div class="desc">
                        {{ item.deliverable }}
                    </div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task3.png" />
                        <div class="label-right">{{ $t('task.category') }}：</div>
                        <div class="label-main">{{ item.category && item.category.name }}</div>
                        <img src="@/assets/icons/task4.png" />
                        <div class="label-right">{{ $t('task.budget') }}：</div>
                        <div class="label-main">{{ item.budget }}</div>
                        <img src="@/assets/icons/task5.png" />
                        <div class="label-right">{{ $t('task.due_time') }}：</div>
                        <div class="label-main">{{ item.due_time }}{{ $t('task.front') }}</div>
                    </div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task6.png" />
                        <div class="label-right">{{ $t('task.country') }}：</div>
                        <div class="label-main" v-if="item.country && item.country.length">
                            <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.country" :key="i">{{ itemt
                            }}</span>
                        </div>
                        <div class="label-main" v-else>
                            {{ $t('index.no_limit') }}
                        </div>
                    </div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task7.png" />
                        <div class="label-right">{{ $t('task.city') }}：</div>
                        <div class="label-main" v-if="item.city && item.city.length"> <span style="margin-right: 0.1rem;"
                                v-for="(itemt, i) in item.city" :key="i">{{ itemt }}</span>
                        </div>
                        <div class="label-main" v-else>
                            {{ $t('index.no_limit') }}
                        </div>
                    </div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task8.png" />
                        <div class="label-right">{{ $t('task.industry') }}：</div>
                        <div class="label-main" v-if="item.industry && item.industry.length">
                            <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.industry" :key="i">{{
                                itemt.name }}</span>
                        </div>
                        <div class="label-main" v-else>
                            {{ $t('index.no_limit') }}
                        </div>
                    </div>
                </div>
                <div class="task-operate flex-row-center-between">
                    <div class="time">{{ $t('task.end_time') }}：{{ item.end_time }}</div>
                    <div class="flex-row-center">

                        <div class="btn btn1" v-if="item.has_join == 0 && item.status >= 1 && item.status < 4"
                            @click.stop="handleApply(item.id)" v-loading="loading"
                            element-loading-spinner="el-icon-loading">{{ $t('task.apply') }}</div>
                        <div class="btn btn3" v-if="item.has_join != 1 && item.extra_join == 1"
                            @click.stop="handleApply(item.id, 1)">{{ $t('task.apply2') }}</div>

                        <div class="btn" @click="handleskip(`/feed/publishTask?id=${item.id}`)"
                            v-if="item.user && item.user.id == userId && item.status != 4">
                            {{ $t('task.edit_task') }}
                        </div>
                        <div class="btn" @click="handleCollect(item.has_collect, item.id)">

                            {{ item.has_collect ? $t('task.collected') : $t('task.collect_task') }}
                        </div>
                        <div class="btn" @click="handleConfirm(item.id)" v-if="item.status==2&&item.user.id==userId">
                            {{ $t('task.confirm_finish') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 服务商信息 -->
        <div class="floor4">
            <div class="tit"> {{ $t('task.servicer_info') }}</div>
            <div class="floor4-main" v-if="item.task_user && item.task_user.length">
                <ul class="users flex-row-wrap flex-row-center-between">
                    <li v-for="(itemt, i) in item.task_user" class="flex-row-center"
                        @click="handleSkip(`/personal?id=${itemt.user.id}`)">
                        <Avatar size="medium3" :user="itemt.user" :showName="true" />
                    </li>
                </ul>
            </div>
            <div class="floor4-main flex-col-center-center" v-else>
                <img src="@/assets/icons/noData.png" />
                <div class="tip">{{ $t('task.tip7') }}</div>
            </div>
        </div>
        <!-- 相似服务供应商推荐 -->
        <div class="floor5" v-if="users.length > 0">
            <div class="tit"> {{ $t('task.tit1') }}</div>
            <ul class="flex-row-wrap flex-row-center-between">
                <li v-for="(item, index) in users" :key="index" class="flex-col"
                    @click="handleSkip(`/personal?id=${item.user.id}`)">
                    <Avatar size="medium3" :showName="true" :user="item.user" />
                    <!-- <div class="floor4-item">服务定价: ¥75000</div>  -->
                    <div class="floor4-item-label flex-row-center-between">
                        <div class="label">{{ $t('task.service_time') }}: {{ item.created_at }}</div>
                        <div class="look">{{ $t('task.view') }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="flex-row-center-center">
            <div class="bot-btn" @click="show2 = true">{{ $t('task.contact') }}</div>
        </div>
        <!-- 申请接单 -->
        <ApplyOrder :show="show" @handleClose="show = false" @sub="handleJoin" />
        <!-- 申请接单成功 -->
        <CustomerService :show="show2" @handleClose="show2 = false" />
        <!-- 未认证弹窗 -->
        <ApplyService :show="show3" @handleClose="show3 = false" :tip="$t('task.tip1')" />
    </div>
</template>
<script>
import CustomerService from '@/views/personal/components/CustomerService'
import ApplyService from '@/components/ApplyService.vue'
import ApplyOrder from '@/components/ApplyOrder'
import Avatar from '@/components/Avatar.vue'
import { MessageBox } from 'element-ui';
export default {
    components: { Avatar, ApplyOrder, CustomerService, ApplyService },
    data() {
        return {
            loading: false,
            userId: this.$storage.getData("userInfo")
                ? this.$storage.getData("userInfo").id
                : null,
            status: 0,
            item: {},
            users: [],
            //步骤
            steps: [
                {
                    icon: require('@/assets/icons/step1.png'),
                    iconed: require('@/assets/icons/step1.png'),
                    title: this.$t('task.step1'),
                    time: ''
                },
                {
                    icon: require('@/assets/icons/step2.png'),
                    iconed: require('@/assets/icons/steped2.png'),
                    title: this.$t('task.step2'),
                    time: ''
                },
                {
                    icon: require('@/assets/icons/step3.png'),
                    iconed: require('@/assets/icons/steped3.png'),
                    title: this.$t('task.step3'),
                    time: ''
                },
                {
                    icon: require('@/assets/icons/step4.png'),
                    iconed: require('@/assets/icons/steped4.png'),
                    title: this.$t('task.step4'),
                    time: ''
                },
                {
                    icon: require('@/assets/icons/step5.png'),
                    iconed: require('@/assets/icons/steped5.png'),
                    title: this.$t('task.step5'),
                    time: ''
                }
            ],
            steps2: [
                {
                    icon: require('@/assets/icons/step1.png'),
                    iconed: require('@/assets/icons/step1.png'),
                    title: this.$t('task.step6'),
                    time: ''
                },
                {
                    icon: require('@/assets/icons/step2.png'),
                    iconed: require('@/assets/icons/steped2.png'),
                    title: this.$t('task.step7'),
                    time: ''
                },
                {
                    icon: require('@/assets/icons/step3.png'),
                    iconed: require('@/assets/icons/steped3.png'),
                    title: this.$t('task.step8'),
                    time: ''
                },
                {
                    icon: require('@/assets/icons/step4.png'),
                    iconed: require('@/assets/icons/steped4.png'),
                    title: this.$t('task.step5'),
                    time: ''
                }
            ],
            accept: 0,
            user: this.$storage.getData("userInfo"),
            show: false,//控制申请弹窗的显示和隐藏
            show2: false,
            show3: false,
            form: {
                id: '',
                resource_ids: []
            },
        }
    },
    created() {
        if (this.$route.query.accept) {
            this.accept = this.$route.query.accept
        }

        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getDetail()
        }
    },
    methods: {
        //确认完成
        handleConfirm(id) {
            MessageBox.confirm(this.$t('task.tip8'), this.$t('task.tit2'), {
                confirmButtonText: this.$t('task.confirm'),
                cancelButtonText: this.$t('task.cancel'),
                type: 'warning'
            }).then(() => {
                this.$api.get(`api/task-complete/${id}`).then(res=>{
                    this.item = res.data.data
                    this.status = this.item.status
                    this.steps[0].time = this.item.created_at
                    this.steps[1].time = this.item.created_at
                    this.steps[2].time = this.item.examine_at
                    this.steps[3].time = this.item.start_at
                    this.steps[4].time = this.item.fee_complete_at
                    this.$message.success(this.$t('auth.operate_success'))
                })
            }).catch(() => {
            });

        },
        //获取详情
        getDetail() {
            this.getUsers()
            this.$api.get(`/api/task/${this.id}`).then(res => {
                this.item = res.data.data
                this.status = this.item.status
                this.steps[0].time = this.item.created_at
                this.steps[1].time = this.item.created_at
                this.steps[2].time = this.item.examine_at
                this.steps[3].time = this.item.start_at
                this.steps[4].time = this.item.fee_complete_at
            })
            if (this.$route.query.id2) {
                this.accept = 1
                this.$api.get(`/api/task-join/${this.$route.query.id2}`).then(res => {
                    const obj = res.data.data
                    this.steps2[0].time = obj.created_at
                    this.steps2[1].time = obj.examine_at
                    this.steps2[2].time = obj.complete_at
                    this.steps2[3].time = obj.fee_complete_at
                })

            }

        },
        //申请接单

        handleApply(id, is_extra) {
            // if (is_extra) {
            //     this.$set(this.form, 'is_extra', is_extra)
            // }
            // this.form.id = id;
            // this.show = true
            if (this.user && this.user.certificate) {
            	if (is_extra) {
            		this.$set(this.form, 'is_extra', is_extra)
            	}
            	this.form.id = id;
            	this.show = true
            } else {
            	this.$api.get(`/api/user`).then(res => {
            		this.user = res.data
            		this.$storage.userInfo();
            		if (this.user && this.user.certificate) {
            			if (is_extra) {
            				this.$set(this.form, 'is_extra', is_extra)
            			}
            			this.form.id = id;
            			this.show = true
            		} else {
            			this.show3 = true
            		}
            	})

            }
        },
        //接单
        handleJoin(resource_ids) {
            this.form.resource_ids = resource_ids
            this.loading = true
            this.$api.post(`/api/task-join/${this.form.id}`, this.form).then(() => {
                this.$message.success(this.$t('task.success'))
                this.getDetail()
                this.loading = false
                this.show = false
                this.show2 = true
            }).catch(() => {
                this.loading = false
                this.show = false
                this.$message.error(this.$t('task.fail'))
            })
        },
        //相似服务商推荐
        getUsers() {
            this.$api.get('/api/users', { params: { task_id: this.id } }).then(res => {
                this.users = res.data.data
            })

        },
        //收藏 取消收藏
        handleCollect(state, id) {
            if (state == 0) {
                // 收藏
                this.item.has_collect = 1
                this.$api.post(`/api/collect/task/${id}`)
            } else {
                //取消点赞
                this.item.has_collect = 0
                this.$api.delete(`/api/collect/task/${id}`)
            }
        },
        //跳转
        handleskip(url) {
            this.$router.push(url)
        },
        //撤销任务
        handleCancel(id) {
            this.$api.post('/api/task/revoke', { task_id: id }).then(() => {
                this.$message.success(this.$t('task.cancel_success'))
            }).catch(() => {
                this.$message.error(this.$t('task.cancel_fail'))
            })

        }
    }
}
</script>
<style lang="scss" scoped>
.task-detail {
    margin: 0.92rem auto;
    width: 13.2rem;

    .floor1 {
        height: 0.89rem;
        background: #FFFFFF;
        border-radius: 0.03rem;
        padding: 0 0.3rem;
        box-sizing: border-box;

        .floor1-left {
            .txt {
                font-size: 0.17rem;
                color: #848484;
            }

            .diff {
                color: #2B2B2B;
            }

            img {
                width: 0.07rem;
                margin: 0 0.09rem;
            }

        }

        .btn {
            width: 1.73rem;
            height: 0.5rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #979797;
            text-align: center;
            line-height: 0.5rem;
            cursor: pointer;
            color: #8B8B8B;
            font-size: 0.16rem;
            margin-left: 0.2rem;
            background-color: #ffffff;
        }



    }

    .tit {
        font-size: 0.2rem;
        color: #262423;
    }

    .floor2 {
        margin-top: 0.3rem;
        padding: 0.3rem 0.3rem 0.6rem;
        box-sizing: border-box;
        background-color: #ffffff;

        .desc {
            font-size: 0.16rem;
            color: #999999;
            margin-top: 0.08rem;
        }

        ul {
            margin: 0.5rem auto 0;

            li {
                .block {
                    width: 1.5rem;

                    img {
                        width: 0.84rem;
                        height: 0.84rem;
                    }

                    .tit {
                        font-size: 0.16rem;
                        color: #BCBCBC;
                        margin-top: 0.2rem;
                        text-align: center;
                    }

                    .time {
                        font-size: 0.16rem;
                        color: #BCBCBC;
                        line-height: 0.24rem;
                        height: 0.48rem;
                        text-align: center;
                    }

                    .active-color {
                        color: #02428C;
                    }
                }

                .line {
                    width: 1.12rem;
                    height: 0.12rem;
                    background: #F7F7F7;
                    margin-top: 0.36rem;
                    margin-left: -0.3rem;
                }

                .active-line {
                    background-color: #DBE3F6;
                }


            }

            li:nth-child(2),
            li:nth-child(3),
            li:nth-child(4),
            li:nth-child(5) {
                .block {
                    margin-left: -0.35rem;

                }
            }
        }





    }

    .floor3 {
        padding: 0.3rem;
        margin-top: 0.3rem;
        background-color: #ffffff;

        .floor3-main {
            height: auto;
            border-radius: 0.04rem;
            border: 0.01rem solid #D8D8D8;
            margin-top: 0.3rem;


            .task-info {
                width: 100%;
                padding: 0.3rem;
                box-sizing: border-box;
                border-bottom: 1px solid #EAEAEA;

                .title {
                    font-size: 0.21rem;

                    color: #202020;

                }

                .label {
                    margin-top: 0.1rem;

                    img {
                        width: 0.18rem;

                        margin-right: 0.1rem;

                    }

                    .label-right {
                        font-size: 0.18rem;
                        color: #02428C;

                    }

                    .label-main {
                        font-size: 0.18rem;
                        color: #828282;
                        margin-right: 0.37rem;
                    }

                }

                .desc {
                    font-size: 0.18rem;
                    color: #828282;
                    line-height: 0.29rem;
                    margin-top: 0.1rem;

                }


            }

            .task-operate {
                padding-left: 0.26rem;
                padding-right: 0.26rem;
                margin-top: 0.3rem;
                padding-bottom: 0.3rem;


                .time {
                    width: 3.57rem;
                    height: 0.49rem;
                    background: #E6ECFB;
                    border-radius: 0.04rem;
                    text-align: center;
                    line-height: 0.49rem;
                    font-size: 0.17rem;
                    color: #02428C;
                }


                .btn {
                    width: 1.73rem;
                    height: 0.5rem;
                    border-radius: 0.04rem;
                    border: 0.01rem solid #02428C;
                    text-align: center;
                    line-height: 0.5rem;
                    cursor: pointer;
                    font-size: 0.17rem;
                    color: #02428C;
                    margin-left: 0.2rem;

                }

                .btn1 {

                    background-color: #02428C;
                    color: #ffffff;
                }

                .btn2 {
                    border: 0.01rem solid #979797;
                    color: #979797;
                    background-color: #ffffff;
                }

                .btn3 {

                    border: 0.01rem solid #71B260;
                    background-color: #71B260;
                    color: #ffffff;
                }



            }


        }

    }

    .floor4 {
        padding: 0.3rem;
        margin-top: 0.3rem;
        background-color: #ffffff;
        height: auto;
        border-radius: 0.03rem;

        .floor4-main {
            margin-top: 0.8rem;

            .users {
                padding: 0 0.3rem;

                li {
                    width: 48%;
                    height: 0.98rem;
                    background: #FAFAFA;
                    border-radius: 0.09rem;
                    padding: 0.13rem;
                    box-sizing: border-box;
                    margin-bottom: 0.2rem;


                }

            }

            img {
                width: 2.02rem;

            }

            .tip {
                font-size: 0.2rem;
                color: #949494;
                margin-top: 0.3rem;

            }

        }

    }

    .floor5 {
        padding: 0.3rem;
        margin-top: 0.3rem;
        background-color: #ffffff;
        border-radius: 0.03rem;

        ul {
            li {
                margin-top: 0.2rem;
                width: 49%;
                height: auto;
                background: #FAFAFA;
                border-radius: 0.09rem;
                padding: 0.16rem;
                box-sizing: border-box;

                .floor4-item {
                    font-size: 0.16rem;
                    color: #5077D5;
                    line-height: 0.29rem;
                    margin-top: 0.1rem;

                }

                .floor4-item-label {
                    font-size: 0.16rem;
                    color: #5077D5;
                    line-height: 0.29rem;

                    .label {
                        font-size: 0.16rem;
                    }

                }

                .look {
                    width: 1.44rem;
                    height: 0.42rem;
                    border-radius: 0.04rem;
                    border: 0.01rem solid #2380D8;
                    cursor: pointer;
                    text-align: center;
                    line-height: 0.42rem;
                    font-size: 0.16rem;

                }



            }
        }


    }

    .bot-btn {
        width: 2.35rem;
        height: 0.58rem;
        background: #02428C;
        border-radius: 0.04rem;
        text-align: center;
        line-height: 0.58rem;
        cursor: pointer;
        font-size: 0.16rem;
        color: #ffffff;
        margin: 0.6rem auto;

    }


}
</style>
