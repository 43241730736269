<!-- 编辑个人资料 -->
<template>
  <div class="release">
    <section class="main">
      <div class="flex-row-center-between">
        <div class="title">{{ $t("personal.edit_user") }}</div>
        <div class="vertify" @click="handleSkip('/authentication')">
          {{ $t("personal.certificate") }}
        </div>
      </div>

      <div class="release-form">
        <el-form ref="form" :model="form" label-position="top">
          <div class="avatar-wrap" @click="handleUpload">
            <el-image
              class="avatar"
              :src="form.avatar"
              v-if="form.avatar && form.avatar.length"
            />
            <img class="avatar" src="@/assets/icons/default.png" v-else />
            <img class="avatar-icon" src="@/assets/icons/avatar.png" />
          </div>
          <div class="modify" @click="handleUpload">
            {{ $t("personal.modify_avatar") }}
          </div>
          <div class="form-item">
            <div class="form-label">{{ $t("auth.nick_name") }}</div>
            <div class="form-input">
              <el-input
                :placeholder="$t('auth.placeholder5')"
                v-model="form.name"
                :maxlength="16"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="form-label">{{ $t("personal.introduction") }}</div>
            <div class="form-input">
              <el-input
                type="textarea"
                :placeholder="$t('auth.placeholder7')"
                v-model="form.introduction"
                :maxlength="200"
              />
            </div>
          </div>
          <el-form-item :label="$t('personal.country')">
            <el-col
              class="sec"
              :span="7"
              style="position: relative"
              v-for="(v, i) in chooseCountry"
              :key="i"
            >
              <el-select
                filterable
                :placeholder="$t('feed.placeholder5')"
                style="width: 100%"
                v-model="v.id"
              >
                <el-option
                  :label="itemt.name"
                  :value="itemt.id"
                  v-for="(itemt, i) in country"
                  :key="i"
                ></el-option>
              </el-select>
              <div class="del" @click="handleDel('country', i)">
                {{ $t("task.del") }}
              </div>
            </el-col>
            <el-col class="line add" :span="5">
              <div @click="handleAdd('country')">
                +{{ $t("feed.add_country2") }}
              </div>
            </el-col>
          </el-form-item>
          <el-form-item :label="$t('personal.city')">
            <el-col
              class="sec"
              :span="7"
              style="position: relative"
              v-for="(v, i) in chooseCity"
              :key="i"
            >
              <ViewProvinceCity
                @getSelectedOptions="getSelectedOptions($event, i)"
                :value="v.name"
              />
              <div class="del" @click="handleDel('city', i)">
                {{ $t("task.del") }}
              </div>
            </el-col>
            <el-col class="line add" :span="5">
              <div @click="handleAdd('city')">+{{ $t("feed.add_city") }}</div>
            </el-col>
          </el-form-item>
          <el-form-item :label="$t('personal.industry')">
            <el-col
              class="sec"
              :span="7"
              style="position: relative"
              v-for="(v, i) in chooseIndustry"
              :key="i"
            >
              <el-cascader
                style="width: 100%"
                :props="{
                  label: 'name',
                  value: 'id',
                  children: 'childrens',
                  checkStrictly: true,
                }"
                :placeholder="$t('feed.placeholder28')"
                v-model="v.ids"
                :options="industry"
                filterable
              ></el-cascader>

              <div class="del" @click="handleDel('industry', i)">
                {{ $t("task.del") }}
              </div>
            </el-col>
            <el-col class="line add" :span="5">
              <div @click="handleAdd('industry')">
                +{{ $t("feed.add_industry") }}
              </div>
            </el-col>
          </el-form-item>
          <el-form-item :label="$t('personal.label')" prop="label">
            <el-input
              v-model="form.label"
              :placeholder="$t('personal.placeholder2')"
            ></el-input>
          </el-form-item>
          <div
            class="release-form-btn"
            @click="handleSubmit()"
            style="cursor: pointer"
          >
            {{ $t("feed.confirm_edit") }}
          </div>
        </el-form>
      </div>
    </section>
    <input
      type="file"
      style="display: none"
      ref="inputRef"
      @change="change"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
    />
    <!-- 剪裁组件弹窗 -->
    <el-dialog
      title="编辑头像"
      :visible.sync="cropperModel"
      width="950px"
      center
    >
      <cropper-image
        :Name="cropperName"
        @uploadImgSuccess="handleUploadSuccess"
        ref="child"
        width="150"
        height="150"
      >
      </cropper-image>
    </el-dialog>
  </div>
</template>
<script>
import ViewProvinceCity from "@/components/ViewProvinceCity2";
import CropperImage from "@/components/cropperImage.vue";
import uploadFile from "@/utils/upload-file";
export default {
  components: { ViewProvinceCity, CropperImage },
  data() {
    return {
      cropperModel: false,
      cropperName: "",
      form: {
        name: "", //昵称
        label: "", //标签
        introduction: "", //简介
        avatar: "", //头像
        country_ids: [], //国家
        city_ids: [], //城市
        industry_ids: [], //优势行业
      },
      country: [], //国家
      industry: [], //行业
      chooseCountry: [{ id: "" }], //选择的国家
      chooseCity: [{ id: "", name: "" }], //选择的城市
      chooseIndustry: [{ ids: [] }], //选择的行业
      user: {},
      loading: false,
    };
  },
  created() {
    //获取用户信息
    this.getUser();
    //获取数据
    this.fetchData();
  },
  methods: {
    //获取用户信息
    getUser() {
      this.$api.get("/api/user").then((res) => {
        const user = res.data;
        this.form = {
          name: user.name, //昵称
          label: user.label, //标签
          introduction: user.introduction, //简介
          avatar: user.avatar, //头像
          country_ids:
            user.country && user.country.length
              ? user.country.map((item) => item.id)
              : [], //国家
          city_ids:
            user.city && user.city.length
              ? user.city.map((item) => item.id + "")
              : [], //城市
          industry_ids:
            user.industry && user.industry.length
              ? user.industry.map((item) => item.id)
              : [], //优势行业
        };
        if (this.form.country_ids && this.form.country_ids.length) {
          this.chooseCountry = this.form.country_ids.map((id) => {
            return { id };
          });
        }
        if (user.city && user.city.length) {
          this.chooseCity = user.city.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
        }
        if (this.form.industry_ids && this.form.industry_ids.length) {
          this.chooseIndustry = this.form.industry_ids.map((ids) => {
            return { ids };
          });
        }
      });
    },
    //打开上传文件弹窗
    handleUpload() {
      this.cropperModel = true;
      // this.$refs.inputRef.click()
    },
    //文件改变
    async change(e) {
      let file = e.target.files[0];
      let { data } = await uploadFile(file);
      this.form.avatar = data.path;
    },
    //上传成功
    handleUploadSuccess(path) {
      this.form.avatar = path;
      this.cropperModel = false;
    },
    //获取数据
    fetchData() {
      this.$api.get("/api/industry", { paginate: 0 }).then((res) => {
        this.industry = res.data;
      });
      this.$api.get("/api/country", { paginate: 0 }).then((res) => {
        this.country = res.data;
      });
    },
    //新增
    handleAdd(state) {
      console.log(state);
      switch (state) {
        //新增国家
        case "country":
          this.chooseCountry.push({ id: "" });
          break;
        //新增城市
        case "city":
          this.chooseCity.push({ id: "", name: "" });
          break;
        //新增行业
        case "industry":
          this.chooseIndustry.push({ ids: [] });
          break;
      }
    },
    //删除
    handleDel(state, index) {
      switch (state) {
        case "country":
          this.chooseCountry.splice(index, 1);
          break;
        case "city":
          this.chooseCity.splice(index, 1);
          break;
        case "industry":
          this.chooseIndustry.splice(index, 1);
          break;
      }
    },
    //城市
    getSelectedOptions(event, i) {
      this.chooseCity[i].id = event[event.length - 1];
    },
    //跳转
    handleSkip(url) {
      this.$router.push(url);
    },
    //提交
    handleSubmit() {
      if (!this.form.name.length) {
        this.$message.warning(this.$t("auth.tip10"));
        return;
      }
      if (
        this.chooseCountry &&
        this.chooseCountry.length &&
        this.chooseCountry[0].id != ""
      ) {
        this.form.country_ids = this.chooseCountry.map((item) => item.id);
      }
      if (
        this.chooseCity &&
        this.chooseCity.length &&
        this.chooseCity[0].id != ""
      ) {
        this.form.city_ids = this.chooseCity.map((item) => item.id);
      }
      if (this.chooseIndustry.length && this.chooseIndustry[0].ids.length) {
        let arr3 = [];
        this.chooseIndustry.forEach((item) => {
          arr3.push(item.ids[item.ids.length - 1]);
        });
        this.form.industry_ids = arr3;
      }
      this.loading = true;
      this.$api
        .post("/api/user", this.form)
        .then(() => {
          //获取用户信息
          this.$storage.userInfo();

          this.$message.success({
            message: this.$t("auth.operate_success"),
            duration: 1000,
          });
          this.$router.push("/personal");
        })
        .catch(() => {
          this.$message.error(this.$t("auth.operate_fail"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.form-item {
  width: 100%;

  .form-label {
    margin: 0.2rem 0 0.2rem;
    font-size: 0.18rem;

    color: #828282;
  }

  .form-input {
    ::v-deep .el-input__inner {
      height: 0.6rem;
      background: #fbfbfb;
      border-radius: 0.04rem;
      width: 100%;
      outline: none;
      border: none;
      font-size: 0.18rem;
      padding-left: 0.26rem;
      box-sizing: border-box;
      line-height: 0.6rem;
    }

    ::v-deep .el-textarea__inner {
      width: 100%;
      height: 2.12rem;
      background: #fbfbfb;
      border-radius: 0.04rem;
      padding: 0.2rem 0.26rem;
      box-sizing: border-box;
      font-size: 0.18rem;
      border: none;
      outline: none;
    }
  }
}

//主体
.main {
  margin: 0.92rem auto;
  display: flex;
  flex-direction: column;
  width: 13.2rem;
  background-color: #ffffff;
  border-radius: 0.03rem;
  padding: 0.5rem 1rem 0.75rem;
  box-sizing: border-box;

  .title {
    font-size: 0.24rem;
    color: #262423;
  }
}

.release-form {
  height: auto;
  background: #ffffff;
  margin-top: 0.2rem;
  padding: 0 2rem;

  .avatar-wrap {
    position: relative;
    width: 1.1rem;
    height: 1.1rem;
    margin: 0 auto 0.26rem;
    cursor: pointer;

    .avatar {
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      background-color: #efefef;
    }

    .avatar-icon {
      position: absolute;
      width: 0.49rem;
      height: 0.49rem;
      border-radius: 50%;
      bottom: 0;
      right: 0;
      background-color: #efefef;
    }
  }

  .modify {
    color: #828282;
    text-align: center;
    cursor: pointer;
  }

  .line {
    text-align: center;
  }

  p {
    font-size: 0.17rem;

    color: #bcbcbc;
  }

  .tip {
    font-size: 0.14rem;

    color: #b0b0b0;
  }

  .add {
    font-size: 0.16rem;
    color: #02428c;
    // line-height: 0.29rem;
    // margin-top: 0.23rem;
    cursor: pointer;
  }

  .release-form-btn {
    cursor: pointer;
    width: 2.35rem;
    height: 0.58rem;
    background: #02428c;
    border-radius: 0.04rem;
    text-align: center;
    line-height: 0.58rem;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-size: 0.17rem;
    color: #ffffff;
    margin: 0 auto;
  }
}

.del {
  font-size: 0.16rem;
  color: #587dd7;
  position: absolute;
  right: 0;
  top: -0.5rem;
  cursor: pointer;
}

.vertify {
  min-width: 1.58rem;
  padding: 0 0.1rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #02428c;
  text-align: center;
  line-height: 0.4rem;
  cursor: pointer;
  color: #02428c;
  font-size: 0.16rem;
}

.sec {
  margin-right: 0.1rem;
  margin-bottom: 0.5rem;
}
</style>
