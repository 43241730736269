<template>
    <el-dialog title="" :visible.sync="visible" width="7rem" :before-close="handleClose" class="my-popup">
        <div class="popup">
            <div class="popup-top flex-col">
                <div class="popup-title">{{ $t('personal.rank') }}</div>
                <div class="select-wrap flex-row-center-between">
                    <el-select filterable v-model="params.country_id" :placeholder="$t('personal.placeholder19')"  @change="changeCountry" clearable>
                        <el-option v-for="item in country" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                    <el-select v-model="params.province_id" :placeholder="$t('feed.placeholder6')" @change="changeProvince" clearable>
                        <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                    <el-select v-model="params.city_id" :placeholder="$t('feed.placeholder7')" @change="changeCity" clearable>
                        <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                    <el-cascader 
                        :props="{ label: 'name', value: 'id', children: 'childrens', checkStrictly: true }"
                        :placeholder="$t('feed.placeholder28')" v-model="industry_ids" :options="industry" clearable @change="changeIndustry"></el-cascader>
                </div>
                <ul class="user-wrap flex-row-center-between" v-if="arr1 && arr1.length && arr1.length == 3">
                    <li style="cursor: pointer;" v-for="(item, index) in arr1" :key="index" class="flex-col-center" @click="handleSkip(`/personal?id=${item.user.id}`)">
                        <div class="avatar-wrap">
                            <img class="avatar" v-if="item.user && item.user.avatar" :src="item.user.avatar" />
                            <img class="avatar" src="@/assets/icon/default.png" v-else />
                            <img :src="item.icon" class="icon" />
                        </div>
                        <div class="name x-ell-1">{{ item.user && item.user.name || $t('auth.nullName') }}</div>
                        <div class="score">{{ item.points }}{{ $t('circle.point') }}</div>
                    </li>
                </ul>
                <ul class="user-wrap flex-row-center-between" style="width: 66%;" v-if="arr1 && arr1.length && arr1.length == 2">
                    <li  v-for="(item, index) in arr1" :key="index" class="flex-col-center" style="width: 50%;cursor: pointer;" @click="handleSkip(`/personal?id=${item.user.id}`)">
                        <div class="avatar-wrap">
                            <img class="avatar" v-if="item.user && item.user.avatar" :src="item.user.avatar" />
                            <img class="avatar" src="@/assets/icon/default.png" v-else />
                            <img :src="item.icon" class="icon" />
                        </div>
                        <div class="name x-ell-1">{{ item.user && item.user.name || $t('auth.nullName') }}</div>
                        <div class="score">{{ item.points }}{{ $t('circle.point') }}</div>
                    </li>
                </ul>
                <ul class="user-wrap flex-row-center-center" v-if="arr1 && arr1.length && arr1.length == 1">
                    <li style="cursor: pointer;" v-for="(item, index) in arr1" :key="index" class="flex-col-center" @click="handleSkip(`/personal?id=${item.user.id}`)">
                        <div class="avatar-wrap">
                            <img class="avatar" v-if="item.user && item.user.avatar" :src="item.user.avatar" />
                            <img class="avatar" src="@/assets/icon/default.png" v-else />
                            <img :src="item.icon" class="icon" />
                        </div>
                        <div class="name x-ell-1">{{ item.user && item.user.name || $t('auth.nullName') }}</div>
                        <div class="score">{{ item.points }}{{ $t('circle.point') }}</div>
                    </li>
                </ul>

                <img class="bg" src="@/assets/icons/bg.png" />

            </div>
            <div class="popup-bot">
                <ul>
                    <li style="cursor: pointer;" class="flex-row-center-between" v-for="(item, index) in arr2" :key="index" @click="handleSkip(`/personal?id=${item.user.id}`)">
                        <div class="num">{{ index + 4 }}</div>
                        <div class="info flex-row-center">
                            <img class="img" v-if="item.user && item.user.avatar" :src="item.user.avatar" />
                            <img class="img" src="@/assets/icon/default.png" v-else />
                            <div class="info-wrap">
                                <div class="info-name">{{ item.user && item.user.name || $t('auth.nullName') }}</div>
                                <div class="info-desc x-ell-1">{{ item.user&&item.user.introduction || $t('auth.nulldesc') }}</div>
                            </div>
                        </div>
                        <div class="scores">{{ item.points }}{{ $t('circle.point') }}</div>
                    </li>
                </ul>
                <div class="border"></div>
                <div class="flex-row-center-between" style="cursor: pointer;" v-if="user&&userInfo.rank" @click="handleSkip(`/personal?id=${user.id}`)">
                    <div class="num">{{ userInfo && userInfo.rank }}</div>
                    <div class="info flex-row-center">
                        <img class="img" :src="user.avatar"  />
                        <div class="info-wrap">
                            <div class="info-name">{{ $t('personal.my_rank') }}</div>
                        </div>
                    </div>
                    <div class="scores">{{ userInfo && userInfo.points }}{{ $t('circle.point') }}</div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible','groupId'],
    data() {
        return {
            arr: [],
            arr1: [],
            arr2: [],
            params: {
                country_id: '',
                province_id: '',
                city_id: '',
                industry_id: ''
            },
            industry_ids: [],
            country: [],//国家
            province: [],//省份
            city: [],//城市
            industry: [],//行业
            userInfo: {
                rank: '',
                points: ''
            },
            user: this.$storage.getData("userInfo"),

        }
    },
    created() {
        if(this.groupId){
            this.$set(this.params,'group_id',this.groupId)
        }
        //获取积分排行榜
        this.getPointsRank()
        //获取数据
        this.fetchData()

    },
    methods: {
      
        handleClose() {
            this.$emit('handleClose')
        },
        //选择国家改变
        changeCountry(id) {
            this.params.province_id = ''
            this.params.city_id = ''
            this.$api.get(`/api/state/${id}`).then(res => {
                this.province = res.data
            })
            this.getPointsRank()

        },
        //选择省份改变
        changeProvince(id) {
            this.params.province_id = id
            this.params.city_id = ''
            this.$api.get(`/api/city?pid=${id}`).then(res => {
                this.city = res.data
            })
            this.getPointsRank()

        },
        //城市改变
        changeCity() {
            this.getPointsRank()
        },
        //积分天梯榜
        getPointsRank() {
            this.$api.get('/api/points-rank', { params: this.params }).then(res => {
                if (res && res.data) {
                    this.arr = res.data.list
                    if (this.arr.length == 0) {
                        this.arr1 = []
                        this.arr2 = []
                    }
                    if (this.arr.length == 1) {
                        this.arr1 = this.arr.map(item => {
                            return {
                                ...item,
                                icon: require('@/assets/icons/rank2.png')
                            }
                        })
                        this.arr2 = []
                    }
                    if (this.arr.length > 1 && this.arr.length <= 3) {
                        this.arr1 = this.swapArr(this.arr, 0, 1)
                        this.arr1 = this.arr1.map((item, index) => {
                            return {
                                ...item,
                                icon: require(`@/assets/icons/rank${index + 1}.png`)
                            }
                        })
                    }
                    if (this.arr.length > 3) {
                        this.arr1 = this.arr.slice(0, 3)
                        this.arr1 = this.swapArr(this.arr1, 0, 1)
                        this.arr1 = this.arr1.map((item, index) => {
                            return {
                                ...item,
                                icon: require(`@/assets/icons/rank${index + 1}.png`)
                            }
                        })
                        this.arr2 = this.arr.splice(3, this.arr.length)
                    }
                    if (res && res.data && res.data.user_rank && res.data.user_rank.length) {
                        this.userInfo = res.data.user_rank[0]
                    }

                }

            })
        },
        //获取数据
        fetchData() {
            this.$api
                .get("/api/industry", { paginate: 0 })
                .then((res) => {
                    this.industry = res.data
                });
            this.$api
                .get("/api/country", { paginate: 0 })
                .then((res) => {
                    this.country = res.data
                });
        },
        //两个元素互换位置
        swapArr(arr, index1, index2) {
            arr[index1] = arr.splice(index2, 1, arr[index1])[0];
            return arr;
        },
        //行业改变
        changeIndustry(val){
           this.params.industry_id = val[val.length-1]
           this.getPointsRank()
        },
        handleSkip(url){
            this.handleClose()
            this.$router.push(url)
        }
    }
}

</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
    padding: 0;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
}

::v-deep .el-dialog__body {
    padding: 0;
}

::v-deep .el-dialog__footer {
    padding: 0;
}

::v-deep .el-input__inner {
    width: 1.44rem;
    height: 0.42rem;
    background: #A8C1FC;
    border-radius: 0.04rem;
    line-height: 0.42rem;
    border: none;
    color: #4461A4;


}


.my-popup {
    .popup {
        width: 100%;

        .popup-top {
            width: 100%;
            height: auto;
            background: linear-gradient(to bottom, #5077D5, #ACCAFF);
            padding: 0.25rem 0.5rem 0;
            box-sizing: border-box;

            .popup-title {
                font-size: 0.24rem;
                color: #CCD8F6;
                text-align: center;
                margin-bottom: 0.3rem;
            }
            ::v-deep .el-input__suffix{
                color: #4461A4;
            }

            ::v-deep input::-webkit-input-placeholder {
                color: #4461A4;
            }

            ::v-deep input::-moz-input-placeholder {
                color: #4461A4;
            }

            ::v-deep input::-ms-input-placeholder {
                color: #4461A4;
            }

            ::v-deep .el-select .el-input .el-select__caret {
                color: #4461A4;
            }

            .user-wrap {
                margin-top: 0.03rem;

                li {
                    width: 33%;
                   
                    .avatar-wrap {
                        width: 0.8rem;
                        height: 0.8rem;
                        position: relative;

                        .avatar {
                            width: 0.8rem;
                            height: 0.8rem;
                            border-radius: 50%;
                            background-color: #4461A4;
                            overflow: hidden;
                            border: none;
                            border: 3px solid #bdbdbd
                        }

                        .icon {
                            position: absolute;
                            top: -0.24rem;
                            left: 0;
                        }

                    }

                    .name {
                        font-size: 0.18rem;
                        color: #ffffff;
                        margin-top: 0.1rem;
                    }

                    .score {
                        font-size: 0.15rem;
                        color: #ffffff;
                        margin-top: 0.1rem;
                    }
                }

                li:nth-child(1) {
                    padding-top: 0.8rem;

                }

                li:nth-child(3) {
                    padding-top: 1rem;

                    .avatar-wrap {
                        .avatar {
                            border: 3px solid #e1ccbb
                        }

                    }


                }

                li:nth-child(2) {
                    .avatar-wrap {
                        width: 1rem;
                        height: 1rem;
                        position: relative;

                        .avatar {
                            width: 1rem;
                            height: 1rem;
                            border-radius: 50%;
                            background-color: #4461A4;
                            overflow: hidden;
                            border: none;
                            border: 3px solid #F6d657
                        }

                        .icon {
                            position: absolute;
                            top: -0.2rem;
                            left: 0;
                        }

                    }

                }



            }

            .bg {
                margin: -0.4rem 0 0;
            }

        }

        .popup-bot {
            position: relative;
            width: 100%;
            height: auto;
            background-color: #ffffff;
            padding: 0.2rem 0.5rem;
            box-sizing: border-box;
            border-radius: 0.39rem 0.29rem 0.03rem 0.03rem;
            margin-top: -0.3rem;

            .border {
                height: 1px;
                background-color: #EAEAEA;
                margin-bottom: 0.2rem;
            }

            ul {
                li {
                    margin-bottom: 0.2rem;
                }
            }

            .num {
                font-size: 0.24rem;
                color: #9BA0A5;
                margin-right: 0.1rem;
                width: 0.4rem;

            }

            .info {
                flex: 1;

                .info-wrap {
                    margin-left: 0.15rem;
                }
            }



            .scores {
                color: #8BA5E3;
                font-size: 0.18rem;
                margin-left: 0.12rem;
                width: 1rem;
            }

            .img {
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
                background-color: #efefef;

            }

            .info-name {
                color: #202020;
                font-size: 0.18rem;
            }

            .info-desc {
                margin-top: 0.09rem;
                color: #8A8A8A;
                font-size: 0.15rem;

            }

        }

    }
}
</style>