/**
 * 格式化时间
 * @author jsonleex <jsonlseex@163.com>
 * @param  {Object} date
 * @param  {String} fmt
 * @return {String}
 */
export default {
    formatDate(date, fmt = 'yyyy/MM/dd hh:mm') {
        const timeOffset = new Date().getTimezoneOffset() * 60 * 1000
        if (typeof date === 'string') {
            date = date.replace(/-/g, '/') // for safari
            // match 2018/10/17 01:48:52"
            if (date.match(/^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/)) {
                // 如果匹配到服务器返回的时间是非标准格式的祖鲁时间，需要进行本地化
                date = +new Date(date) - timeOffset
            }
        }
        date = new Date(date)
        const o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds(),
            'q+': Math.floor((date.getMonth() + 3) / 3),
            S: date.getMilliseconds(),
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                (date.getFullYear() + '').substr(4 - RegExp.$1.length),
            )
        }
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
                )
            }
        }
        return fmt
    },
    rTime(date) {
        var json_date = new Date(date).toJSON();
        return new Date(+new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    getTimer(stringTime) {
        let minute = 1000 * 60;
        let hour = minute * 60;
        let day = hour * 24;
        let week = day * 7;
        let month = day * 30;
        let year = month * 12;
        let time1 = new Date().getTime(); //当前的时间戳
        let time2 = Date.parse(new Date(stringTime)); //指定时间的时间戳
        let time = time1 - time2;
        let result = null;
        if (time < 0) {
            // 时间不能早于当前时间
        } else if (time / year >= 1) {
            result = parseInt(time / year) + "年前";
        } else if (time / month >= 1) {
            result = parseInt(time / month) + "月前";
        } else if (time / week >= 1) {
            result = parseInt(time / week) + "周前";
        } else if (time / day >= 1) {
            result = parseInt(time / day) + "天前";
        } else if (time / hour >= 1) {
            result = parseInt(time / hour) + "小时前";
        } else if (time / minute >= 1) {
            result = parseInt(time / minute) + "分钟前";
        } else {
            result = "刚刚发布";
        }
        return result;
    }

}
