import { render, staticRenderFns } from "./improveInfo.vue?vue&type=template&id=5d923afe&scoped=true&"
import script from "./improveInfo.vue?vue&type=script&lang=js&"
export * from "./improveInfo.vue?vue&type=script&lang=js&"
import style0 from "./improveInfo.vue?vue&type=style&index=0&id=5d923afe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d923afe",
  null
  
)

export default component.exports