// 右侧侧边栏
<template>
  <div class="sidebar">
    <!-- 热门圈子 -->
    <div class="circle">
      <div class="create-circle" @click="skip">+{{ $t('circle.create_circle') }}</div>
      <ul>
        <li v-for="(item, index) in hotCircle" :key="index" @click="handleSkip(`/circleDetail?id=${item.id}`)"
          style="cursor: pointer" class="flex-row-center-between">
          <div class="li-left">
            <img :src="item.icon" />
          </div>
          <div class="li-rig">
            <div class="li-rig-top x-ell-1">{{ item.name }}</div>
            <div class="li-rig-bot">{{ item.user_count }}{{ $t('personal.tit2') }}</div>
          </div>
          <div class="enter" v-if="item.has_join == 0" @click.stop="handleJoin(item.id)">{{ $t('activity.status0') }}</div>
          <div class="enter different" v-if="item.has_join == 1">{{ $t('auth.joined') }}</div>
          <div class="enter different" v-if="item.has_join == -2"> {{ $t('circle.apply_join3') }}</div>


          
        </li>
      </ul>
    </div>
    <div class="circle">
      <div class="circle-tit">{{ $t('con.hot_resource') }}</div>
      <ul>
        <li @click="handleSkip(`/sourceDetail?id=${item.id}`)" v-for="(item, index) in sourceList" :key="index"
          style="cursor: pointer" class="flex-row">
          <div class="li-left">
            <img :src="item.image && item.image[0]" />
          </div>
          <div class="li-rig flex-col">
            <div class="li-rig-top x-ell-1">{{ item.title || $t('con.no_tit') }}</div>
            <div class="li-rig-bottom">
              <img src="@/assets/icons/star.png" v-for="(itemt) in item.level" :key="itemt" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 排行榜 -->
    <div style="margin-bottom: 0.17rem">
      <RankBox />
    </div>
    <div class="foot flex-row-center flex-row-wrap">
      <span class="label" @click="handleSkip(`/agreement?type=about`)"> {{ $t('con.tab1') }}</span>
      <span class="dot">·</span>
      <span class="label" @click="handleSkip(`/agreement?type=business_partner`)"> {{ $t('con.tab2') }}</span>
      <span class="dot">·</span>
      <span class="label" @click="handleSkip(`/agreement?type=xikedao_service_agreement`)"> {{ $t('con.tab3') }}</span>
      <span class="dot">·</span>
      <span class="label" @click="handleSkip(`/agreement?type=privacy_agreement`)"> {{ $t('con.tab4') }}</span>
      <span class="dot">·</span>
      <span class="label" @click="handleSkip(`/agreement?type=case`)"> {{ $t('con.tab5') }}</span>
      <span class="dot">·</span>
      <span class="label" @click="handleSkip(`/agreement?type='cooperate`)"> {{ $t('con.tab6') }}</span>
      <span class="dot">·</span>
      <span class="label" @click="handleSkip(`/agreement?type=media_coverage`)"> {{ $t('con.tab7') }}</span>
      <span class="dot">·</span>
      <span class="label" @click="handleSkip(`/agreement?type=advertising_cooperation`)"> {{ $t('con.tab8') }}</span>
    </div>
    <!-- 加圈申请 -->
    <ApplyJoin :show="visible" @handleClose="visible = false" @handleConfirm="handleConfirm" />
  </div>
</template>
<script>
import RankBox from "@/components/RankBox";
import ApplyJoin from '@/components/ApplyJoin.vue'
export default {
  components: { RankBox, ApplyJoin },
  props: ["show"],
  data() {
    return {
      user: this.$storage.getData("userInfo"),
      hotCircle: [],//热门圈子
      visible: false,//控制加圈申请弹窗的显示和隐藏
      form: {
        id: '',
        comment: ''
      },
      sourceList: [],//资源列表
    };
  },
  created() {
    //获取热门圈子列表
    this.getHotCircle()
    //获取热门资源列表
    this.getHotSource()
    //获取热门用户
    this.getHotUsers()
  },
  methods: {
    skip() {
      if (!this.user) {
        this.handleSkip('/login')
        return
      }

      this.handleSkip('/createCircle')

    },
    //获取热门圈子
    getHotCircle() {
      this.$api.get('/api/group', { params: { type: 'hot', page: 1 } }).then(res => {
        let result = res.data.data
        this.hotCircle = result.slice(0, 5)
      })
    },
    //获取热门资源列表
    getHotSource() {
      this.$api.get('/api/resource', { params: { type: 'hot', page: 1 } }).then(res => {
        let result = res.data.data
        this.sourceList = result.slice(0, 5)
      })
    },
    //加入圈子
    handleJoin(id) {
      this.form.id = id;
      this.visible = true
    },
    //确认加入圈子
    handleConfirm(comment) {
      this.form.comment = comment
      this.$api.post(`/api/join-group/${this.form.id}`, this.form).then(() => {
        this.$message.success(this.$t('auth.operate_success'))
        this.visible = false
        this.getHotCircle()
      }).catch(() => {
        this.$message.error(this.$t('auth.operate_fail'))
      })
    },
    //跳转
    handleSkip(url) {
      this.$router.push(url)
    },
    //获取热门用户
    getHotUsers() {
      this.$api.get('/api/users', { params: { hot: 1, paginate: 10 } }).then(res => {
        console.log(res)
      })
    }
  },
};
</script>
<style scoped lang="scss">
.sidebar {
  width: 3.01rem;
}



// 公共部分
.common {
  width: 100%;
  height: 0.59rem;
  color: #519ee8;
  background: #ededed;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  line-height: 0.59rem;
  align-items: center;
  padding: 0 0.2rem;
  box-sizing: border-box;

  span:nth-child(1) {
    font-size: 0.2rem;
    font-family: PingFang SC;

    line-height: 0.36rem;
    color: #519ee8;
  }

  span:nth-child(2) {
    font-size: 0.16rem;
    font-family: PingFang SC;

    cursor: pointer;
  }
}

//达人推荐
.floor3 {
  width: 3.01rem;
  background: #ffffff;


  ul {
    display: flex;
    padding: 0rem 0.24rem;
    flex-direction: column;


    li {

      display: flex;
      flex-direction: row;
      margin-bottom: 0.19rem;
      text-align: center;
      width: 100%;
      cursor: pointer;

      img {
        width: 0.46rem;
        height: 0.46rem;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        margin-right: 0.14rem;
      }

      .overplus {
        font-size: 0.17rem;


        color: #252525;
        line-height: 0.24rem;
        text-align: left;
      }

      .consult {
        font-size: 0.14rem;


        color: #737373;
        line-height: 0.2rem;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

}

//排行榜
.floor4 {
  width: 2.7rem;
  height: 5.19rem;
  background: #ffffff;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0 0.2rem;

    li {
      display: flex;
      flex-direction: row;
      margin-top: 0.25rem;
      height: 0.6rem;
      align-items: center;
      cursor: pointer;

      span {
        width: 0.2rem;
        height: 0.2rem;
        background: #e6e6e6;
        opacity: 1;
        border-radius: 0.05rem;
        color: #ffffff;
        text-align: center;
        line-height: 0.2rem;
      }

      img {
        margin-left: 0.18rem;
        width: 0.6rem;
        height: 0.6rem;

        border-radius: 50%;
      }

      .user {
        margin-left: 0.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .nickName {
          font-size: 0.14rem;
          font-family: PingFang SC;

          line-height: 0.2rem;
          color: #333333;
        }

        .score {
          font-size: 0.14rem;
          font-family: PingFang SC;

          line-height: 0.2rem;
          color: #b2b2b2;
          opacity: 1;
          margin-top: 0.1rem;
        }
      }
    }

    li:nth-child(1) span,
    li:nth-child(2) span,
    li:nth-child(3) span {
      background: #519ee8;
    }
  }
}



.tit {
  width: 100%;
  height: 0.68rem;
  background: #ffffff;
  line-height: 0.68rem;
  padding-left: 0.22rem;
  box-sizing: border-box;
  font-size: 0.2rem;


  color: #202020;


}

.more {
  cursor: pointer;
  width: 100%;
  height: 0.75rem;
  line-height: 0.75rem;
  text-align: center;
  font-size: 0.16rem;


  color: #5889FF;

}

//热门圈子
.circle {
  background: #ffffff;
  margin-bottom: 0.14rem;
  padding: 0.2rem 0.2rem;
  box-sizing: border-box;
  border-radius: 0.03rem;

  .circle-tit {
    font-size: 0.18rem;
  }

  .create-circle {
    width: 2.65rem;
    height: 0.43rem;
    border-radius: 0.03rem;
    border: 1px solid #02428C;
    text-align: center;
    line-height: 0.43rem;
    font-size: 0.16rem;
    color: #02428C;
    cursor: pointer;
  }

  ul {
    li {
      width: 100%;
      margin-top: 0.14rem;

      .li-left {
        img {
          width: 0.52rem;
          height: 0.52rem;
          border-radius: 0.06rem;
          background-color: #abcdef;
          object-fit: cover;

        }
      }

      .li-rig {
        margin-left: 0.07rem;
        flex: 1;

        .li-rig-top {
          font-size: 0.16rem;
          color: #202020;
          line-height: 0.2rem;


        }

        .li-rig-bot {
          font-size: 0.14rem;
          color: #909090;
          line-height: 0.23rem;
        }

        .li-rig-bottom {
          margin-top: 0.05rem;

          img {
            width: 0.14rem;
            margin-right: 0.06rem;
          }
        }

      }

      .enter {
        width: 0.63rem;
        height: 0.29rem;
        border-radius: 0.03rem;
        border: 1px solid #02428C;
        text-align: center;
        line-height: 0.29rem;
        font-size: 0.14rem;


        color: #02428C;


      }

      .different {
        color: #909090;
        border: 1px solid #909090;
      }

    }

    li:last-child {
      padding-bottom: 0;
    }
  }
}

//热门问答
.wenda {
  margin-bottom: 0.17rem;
  background: #ffffff;

  ul {
    padding: 0 0.26rem;

    li {
      margin-bottom: 0.14rem;

      .wenda-tit {
        .mark {
          width: 0.23rem;
          height: 0.23rem;
          background: #FF463C;
          text-align: center;
          line-height: 0.23rem;
          color: #ffffff;
          border-radius: 0.03rem;

        }

        .wenda-title {
          width: 2.25rem;
          font-size: 0.17rem;


          color: #202020;
          line-height: 0.24rem;
          margin-left: 0.08rem;
        }


      }

      .desc {
        font-size: 0.17rem;


        color: #737373;
        line-height: 0.24rem;
        margin-left: 0.29rem;
      }

    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}

//热门活动
.activity {
  margin-bottom: 0.17rem;
  background: #ffffff;

  ul {
    padding: 0 0.26rem;

    li {
      margin-bottom: 0.14rem;

      .activity-tit {
        font-size: 0.17rem;


        color: #202020;
        line-height: 0.24rem;
      }

      .state {
        width: 0.82rem;
        height: 0.29rem;
        line-height: 0.29rem;
        background: #6CBC73;
        border-radius: 0.14rem;
        margin-top: 0.09rem;
        color: #ffffff;
        text-align: center;
        font-size: 0.17rem;
      }

    }

    li:last-child {
      margin-bottom: 0;
    }
  }

}

.foot {
  font-size: 0.16rem;
  margin-top: 0.2rem;
  color: #979DA4;
  line-height: 0.3rem;
  text-align: left;
  padding: 0 0.1rem;
  cursor: pointer;
  .label{
    margin: 0 0.1rem;
  }

}
</style>
