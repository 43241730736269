import axios from "axios";
import store from './store.js'
import { Message } from "element-ui";
export function uploadFile(file, allow) {
  if (allow) {
    let surfix = file.name.substring(file.name.lastIndexOf(".") + 1);
    if (!allow.includes(surfix)) {
      Message.error(`只允许上传${allow.join("、")}文件`);
      return;
    }
  }
  return new Promise((resolve, reject) => {
    let fd = new FormData();
    fd.append("file", file.raw || file); //传给后台接收的名字 file
    axios
      .post(`${process.env.VUE_APP_BASE_API}/api/upload`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${store.getData('access_token')}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        reject(false);
      });
  });
}
export default uploadFile;
