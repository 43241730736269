import axios from 'axios'
import http from 'axios'
import {
  Message
} from 'element-ui';
import store from '@/utils/store.js'
import router from '../router'
let cancel
const pending = {}
let baseURL = process.env.VUE_APP_BASE_API
let token = ''
if(store){
  token = store.getData('access_token')
}

const instance = axios.create({
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})
instance.isCancel = http.isCancel.bind(http)
instance.CancelToken = http.CancelToken.bind(http)
// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 发起请求时，取消掉当前正在进行的相同请求
    if (pending[config.url]) {
      pending[config.url] = cancel
    } else {
      pending[config.url] = cancel
    }
    const TOKEN = store.getData('access_token')
    if (TOKEN) {
      config.headers.Authorization = 'Bearer ' + TOKEN
    }
    let language = localStorage.getItem('languageSet')
    if(language){
      config.data = Object.assign({ 'lang': language }, config.data)
      config.params =  Object.assign( {'lang':language}, config.params)
    }else{
      config.data = Object.assign({ 'lang': 'zh' }, config.data)
      config.params =  Object.assign( {'lang':language}, config.params)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)
// 创建一个新实例.
const createInstance = (options) => {
  if (typeof options === 'string') {
    options = {
      baseURL: options
    }
  } else {
    options = options || {}
  }
  options.headers = {
    Authorization: `Bearer ${token}`,
    ...options.headers || {}
  }
  const instance = http.create(options)
  // 拦截响应器
  instance.interceptors.response.use(
    response => (
      Promise.resolve(response)
    ),
    // 响应错误处理
    error => {
      const {
        response: {
          status
        }
      } = error
      switch (status) { }
      return Promise.reject(error)
    }
  )
  instance.isCancel = http.isCancel.bind(http)
  instance.CancelToken = http.CancelToken.bind(http)
  return instance
}
export const newApi = createInstance(baseURL + '/api/v2')
// 响应拦截器即异常处理
instance.interceptors.response.use(
  res => res,
  err => {
    if (!axios.isCancel(err)) {
      if (err && err.response) {
        switch (err.response.status) {
          case 401:
            router.push({
              path: '/login'
            })
            break
          case 422:
            Message({
              message: err.response.data.message,
              duration: 1000,
              type: 'error',
            });
            break
          case 403:
            Message({
              message: err.response.data.message,
              duration: 1000,
              type: 'error',
            });
            break
        }
      } else { }
    }
    return Promise.reject(err)
  },
)
export default instance
