<!-- 圈子详情 -->
<template>
  <div class="content">
    <div class="main">
      <div class="sec flex-row-center-between" :style="{
        'background-image': 'url(' + info.cover + ')',
      }">
      <div class="bg flex-row-center-between">
        <div class="sec-left">
          <el-image class="sec-left-logo" fit="cover" :src="info.icon" />
          <div class="sec-left-name">{{ info.name }} </div>
          <div class="sec-left-desc x-ell-1">{{ info.description }} </div>
          <div class="sec-left-desc flex-row-center ">
            <div class="username x-ell-1" v-if="info && info.user" @click="handleSkip(`/personal?id=${info.user.id}`)">{{
              $t('circle.circle_creater')
            }} ： {{ info.user && info.user.name }}</div>
            <div v-if="info && info.category" style="margin-left:1rem"
              @click="handleSkip(`/circle?id=${info.category.id}`)"> {{ $t('circle.circle_type') }} ：{{ info.category &&
                info.category.name }}</div>
          </div>
          <div class="sec-left-desc" @click="handleLabel(info.label)">{{ $t('circle.label') }}：{{ info.label }}</div>
          <div class="sec-left-desc">{{ $t('circle.created_at') }}：{{ info.created_at }}</div>
        </div>
        <div class="sec-right">
          <div v-if="info.user && user && info.user.id == user.id" class="camera flex-row-center-between"
            @click="handleSkip(`/createCircle?id=${info.id}`)">
            <img src="@/assets/icons/camera.png" class="icon" />
            <div class="camera-name"> {{ $t('circle.edit_bg') }}</div>
          </div>
          <div class="sec-right-operate flex-row-center">
            <!--   -->
            <div v-if="info.user && user && info.user.id == user.id" class="share-icon-wrap flex-row-center" @click="share"
             >
              <img src="@/assets/icons/share.png" class="icon" />
              <div class="share-name">{{ $t('circle.share') }}</div>
            </div>
            <!--  -->
            <div v-if="info.user && user && info.user.id == user.id"  class="edit-circle"
              @click="handleSkip(`/createCircle?id=${info.id}`)">{{ $t('circle.edit_circle') }}
            </div>
            <!--  -->
            <div v-if="info.has_join == -2" class="button" @click="handleJoin(info.id)" style="cursor: pointer" >
              <p> {{ $t('circle.apply_join') }}</p>
            </div>
            <!-- -->
            <div  v-if="info.has_join == 1" class="button different" style="cursor: pointer" >
              <p> {{ $t('auth.joined') }}</p>
            </div>
            <!--  -->
            <div v-if="info.has_join == 0" class="button different" style="cursor: pointer" >
              <p> {{ $t('activity.status0') }}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="flex-row main-cen">
        <!-- 左侧 -->
        <div class="left">
          <!-- 主体左侧分享最近动态 -->
          <div class="flex-row-center-between share-feed">
            <img class="avatar" :src="user.avatar" v-if="user && user.avatar" />
            <img class="avatar" src="@/assets/icons/default.png" v-else />
            <div class="comment" v-if="user" @click="visible3 = true">{{ $t('circle.tip1') }}</div>
            <div class="comment" v-else>{{ $t('index.tip') }}</div>
          </div>
          <div class="floor2">
            <!-- 主体左侧导航 -->
            <div class="flex-row-center-between main-left-navs">
              <ul class="flex-row-center">
                <li @click="changeNav(index)" v-for="(item, index) in navs" :key="index"
                  :class="index == currentIndex ? 'activeNav' : ''">
                  {{ item.title }}
                </li>
              </ul>
            </div>
            <!-- 动态列表开始 -->
            <ul v-if="currentIndex == 0" class="main-left-list" ref="homeUl" v-loading="loading"
              element-loading-spinner="el-icon-loading">
              <li v-for="(item, i) in list" :key="i">
                <FeedsCard :card="item" size="medium" />
              </li>
            </ul>
            <!-- 动态列表结束 -->
            <!-- 资源列表开始 -->
            <div v-else>
              <SourceItem :list="sourceList" :id="id" :manageId="manageId" />
            </div>
            <!-- 资源列表结束 -->
          </div>
        </div>
        <!-- 右侧 -->
        <div class="right">
          <!-- 圈子成员 -->
          <div class="floor5">
            <div class="common">
              {{ $t('circle.circle_member') }}({{ user_count }})
            </div>
            <ul>
              <li class="flex-row-center-between" v-for="(item, index) in members" :key="index"
                @click="handleSkip(`/personal?id=${item.id}`)">
                <img v-if="item.avatar" :src="item.avatar" />
                <img src="@/assets/icon/default.png" v-else />
                <div class="user">
                  <div class="nickName x-ell-1">{{ item.name }}</div>
                  <div class="score x-ell-1">{{ item.introduction || $t('auth.nulldesc') }}</div>
                </div>
                <div class="timer">{{ item.join_time }}{{ $t('circle.enter') }}</div>
              </li>
            </ul>
            <div class="more" @click="handleLookMember">{{ $t('task.lookAll') }}</div>
          </div>
          <!-- 圈子积分榜 -->
          <div class="floor4">
            <div class="common" style="padding-left: 0.22rem;" @click="onClick('/rankList')">
              <img src="@/assets/icons/rank.png" />
              <span>{{ $t('circle.circle_score') }}</span>
            </div>
            <ul>
              <li v-for="(item, index) in arr" :key="index" @click="handleSkip(`/personal?id=${item.user.id}`)">
                <img class="biao" src="@/assets/icons/rank2.png" v-if="index == 0" />
                <img class="biao" src="@/assets/icons/rank1.png" v-else-if="index == 1" />
                <img class="biao" src="@/assets/icons/rank3.png" v-else-if="index == 2" />
                <span v-else>{{ index + 1 }}</span>
                <img class="avatar" v-if="item.user && item.user.avatar && item.user.avatar.length"
                  :src="item.user && item.user.avatar" />
                <img class="avatar" src="@/assets/icon/default.png" v-else />
                <div class="user">
                  <div class="nickName x-ell-1">{{ item.user && item.user.name || $t('auth.nullName') }}</div>
                  <div class="score">{{ item.points }}{{ $t('circle.point') }}</div>
                </div>
              </li>
              <div class="line"></div>
              <li style="margin-top:0.2rem" v-if="userInfo.rank">
                <span>{{ userInfo.rank }}</span>
                <img class="avatar" :src="user.avatar" v-if="user" />
                <div class="user">
                  <div class="nickName">{{ $t('circle.my') }}
                  </div>
                  <div class="score">{{ userInfo.points }}{{ $t('circle.point') }}</div>
                </div>
              </li>
            </ul>
            <div class="mores" @click="visible = true">
              {{ $t('task.lookAll') }}
            </div>
          </div>
          <div class="tui" v-if="user && info && info.has_join == 1" @click="handleOut(info.id)">{{
            $t('circle.del_circle') }}
          </div>
        </div>
      </div>
    </div>
    <!-- 圈子成员 -->
    <CircleMember :show="show" @handleClose="show = false; getMember(id)" :group_id="id" :manage_id="manageId" />
    <!-- 积分天梯榜 -->
    <PopupIntegral :visible="visible" @handleClose="visible = false" :groupId="id" />
    <!-- 分享弹窗 -->
    <SharePopup :show="visible2" @handleClose="visible2 = false" />
    <!-- 发布动态弹窗 -->
    <PostFeed :show="visible3" @handleClose="visible3 = false; getList(1)" />
    <!-- 加圈申请 -->
    <ApplyJoin :show="show2" @handleClose="show2 = false" @handleConfirm="handleConfirm" />
  </div>
</template>
<script>
import PopupIntegral from '@/views/personal/components/PopupIntegral'
import CircleMember from "@/components/CircleMember"
import ApplyJoin from '@/components/ApplyJoin.vue'
import SourceItem from './components/SourceItem'
import SharePopup from '@/components/SharePopup'
import FeedsCard from "@/components/FeedsCard";
import PostFeed from "@/components/PostFeed"
export default {
  components: { FeedsCard, CircleMember, SourceItem, PopupIntegral, SharePopup, PostFeed, ApplyJoin },
  data() {
    return {
      manageId: '',//管理员ID
      user: this.$storage.getData("userInfo"),
      id: null,//圈子ID
    
      show: false,//控制圈子成员弹窗的显示和隐藏
      members: [], //圈子成员
      total: 0,//圈子人数
      info: {},//详情
      arr: [],//圈子积分榜
      visible: false,
      visible2: false,
      visible3: false,
      navs: [
        {
          id: 1,
          title: this.$t('circle.feed_list')
        },
        {
          id: 2,
          title: this.$t('circle.source')
        },
      ],
      currentIndex: 0, //当前选中导航
      listQuery: {//动态查询条件
        page: 1,
        sort: 'desc',//时间降序
        group_id: ''
      },
      list: [], //动态列表
      lastPage: 0,
      listQuery2: {//资源查询条件
        page: 1,
        group_id: '',
        sort: 'desc',//时间降序
      },
      sourceList: [],//资源列表
      sourceCount: 0,//资源数量
      loading: false,
      lastPage2: 0,
      userInfo: {
        rank: null,
        points: null
      },
      avatar: '',
      user_count: 0,
      form: {
        id: '',
        comment: ''
      },
      show2: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    //监听如果页面发生滚动时
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    //接收传递过来的圈子id
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.listQuery.group_id = this.id
      this.listQuery2.group_id = this.id
      //获取圈子资源用户列表
      this.getUsers()
      //查询圈子详情
      this.getDetail(this.id)
      //查询圈子帖子列表
      this.getList();
      //查看圈子成员
      this.getMember(this.id)
      //获取积分排行榜
      this.getPointsRank()
    }
  },
  methods: {
    //标签点击事件
    handleLabel(label) {
      const txt = label.replace(/#/g, ",");
      this.$router.push(`/search?search=${txt}&current=3&flag=1`)

    },
    //加入圈子
    handleJoin(id) {
      if(!this.user){
        this.$router.push('/login')
        return
      }
      this.form.id = id;
      this.show2 = true
    },
    //确认加入圈子
    handleConfirm(comment) {
      this.form.comment = comment
      this.$api.post(`/api/join-group/${this.form.id}`, this.form).then(() => {
        this.$message.success(this.$t('auth.operate_success'))
        this.getList(1)
        this.show2 = false
      }).catch(() => {
        this.$message.error(this.$t('auth.operate_fail'))
      })
    },
    //获取圈子资源用户列表
    getUsers(page) {
      if (page) this.listQuery2.page = page
      this.$api.get('/api/group-resource-user', { params: this.listQuery2 }).then(res => {
        let result = res.data.data
        result.forEach(item => {
          this.$api.get(`/api/resource`, { params: { page: 1, user_id: item.id } }).then(result => {
            this.$set(item, 'resource', result.data.data)
          })
        })
        if (this.listQuery.page == 1) {
          this.sourceList = result
        } else {
          this.sourceList = [...this.sourceList, ...result]
        }
        this.lastPage2 = res.data.meta.last_page
      })
    },
    //积分天梯榜
    getPointsRank() {
      this.$api.get('/api/points-rank', { params: { group_id: this.id } }).then(res => {
        this.arr = res.data.list
        if (res.data.user_rank && res.data.user_rank.length) {
          this.userInfo = res.data.user_rank[0]
        }
      })
    },
    //导航切换
    changeNav(index) {
      this.currentIndex = index
    },
    //查询圈子详情
    getDetail(id) {
      this.$api.get(`/api/group/${id}`).then((res) => {
        this.info = res.data;
        this.manageId = this.info.user.id
        if (this.info.post_count != 0) {
          this.navs[0].title = `${this.$t('circle.feed_list')}(${this.info.post_count})`
        }
        if (this.info.resource_count != 0) {
          this.navs[1].title = `${this.$t('circle.source')}(${this.info.resource_count})`
        }
        this.user_count = this.info.user_count
      });
    },
    //获取动态列表
    getList(page) {
      if (page) this.listQuery.page = page
      this.loading = true
      this.$api.get('/api/post', { params: this.listQuery }).then((res) => {
        if (this.listQuery.page == 1) {
          this.list = res.data.data
        } else {
          this.list = [...this.list, ...res.data.data]
        }
        this.lastPage = res.data.meta.last_page
        this.loading = false
      })
    },
    //分享
    share() {
      this.visible2 = true
    },
    //查看圈子成员
    getMember(id) {
      this.$api.get(`/api/group/users/${id}`, { paginate: 10 }).then(res => {
        if (res.data.data && res.data.data.length) {
          this.members = res.data.data.slice(0, 10)
          this.total = res.data.meta.total
        }
      })
    },
    //圈子剔出成员
    handleDel(index) {
      this.members.splice(index)
    },
    //查看全部成员
    handleLookMember() {
      if (this.members.length == 0) {
        this.$message.warning(this.$t('circle.tip2'))
      } else {
        this.show = true
      }
    },
    //跳转
    handleSkip(url) {
      this.$router.push(url)
    },
    //退出圈子
    handleOut(id) {
      this.$api.delete('/api/out-group-self', { params: { group_id: id } }).then(() => {
        this.$message.success(this.$t('auth.operate_success'))
        this.getDetail(this.id)
      }).catch(() => {
        this.$message.error(this.$t('auth.operate_fail'))
      })

    },
    //页面滑到底部调用的方法
    handleScroll() {
      //下面这部分兼容手机端和PC端
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeitht =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
        if (this.listQuery.page <= this.lastPage) {
          this.listQuery.page++;
          this.getList()
        }
        if (this.listQuery2.page <= this.lastPage2) {
          this.listQuery2.page++
          this.getUsers()
        }
      }
    }
  },
};
</script>
<style scoped lang="scss">
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

}

.main {
  width: 13.2rem;
  margin-top: 0.72rem;
  height: auto;

  .sec {
    width: 13.2rem;
    height: 4.42rem;
    background: #232323;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0.3rem 0 0.6rem;
    box-sizing: border-box;
    position: relative;
    .bg{
      position: absolute;
      padding: 0 0.3rem 0 0.6rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 4.42rem;
      background: rgba(0,0,0,0.5);
      box-sizing: border-box;

    }

   

  }
  .sec-left {
      padding-top: 0.3rem;

      .sec-left-logo {
        width: 1.63rem;
        height: 1.63rem;
        background: #D8D8D8;
        border-radius: 0.07rem;
        border: 0.03rem solid #FFFFFF;

      }

      .sec-left-name {
        font-size: 0.22rem;


        color: #ffffff;
        line-height: 0.4rem;
        margin-top: 0.22rem;

      }

      .sec-left-desc {
        font-size: 0.16rem;
        cursor: pointer;

        color: #ffffff;
        line-height: 0.29rem;
        margin-top: 0.07rem;

      }

    }

    .sec-right {
      padding-top: 0rem;

      .camera {
        min-width: 1.79rem;
        height: 0.53rem;
        border-radius: 0.04rem;
        border: 0.01rem solid #D3D3D3;
        padding: 0 0.22rem;
        box-sizing: border-box;
        float: right;
        cursor: pointer;

        .icon {
          color: #AFB4BB;
          width: 0.2rem;

        }

        .camera-name {
          font-size: 0.17rem;


          color: #D3D3D3;

        }


      }

      .sec-right-operate {
        margin-top: 3rem;
        display: flex;
        flex-direction: row-reverse;

        .edit-circle {
          width: 1.79rem;
          height: 0.46rem;
          background: #5077D5;
          border-radius: 0.04rem;
          text-align: center;
          line-height: 0.46rem;
          color: #ffffff;
          font-size: 0.17rem;


          margin-right: 0.23rem;
          cursor: pointer;

        }

        .share-icon-wrap {
          min-width: 1.79rem;
          height: 0.46rem;
          border-radius: 0.04rem;
          border: 1px solid #D3D3D3;
          padding: 0 0.13rem;
          box-sizing: border-box;
          cursor: pointer;


          .icon {
            width: 0.2rem;
            color: #AFB4BB;

          }

          .share-name {
            font-size: 0.2rem;
           

            color: #AFB4BB;

          }

        }


      }

    }

  .main-cen {
    margin-top: 0.3rem;
  }





  .left {
    width: 9.96rem;

    .share-feed {
      width: 100%;
      height: 1.12rem;
      background: #FFFFFF;
      border-radius: 0.03rem;
      padding: 0 0.2rem 0 0.32rem;
      box-sizing: border-box;
      cursor: pointer;

      .avatar {
        width: 0.46rem;
        height: 0.46rem;
        border-radius: 50%;
        background-color: #efefef;
      }

      .comment {
        width: 8.75rem;
        height: 0.69rem;
        background: #FAFAFA;
        border-radius: 0.06rem;
        line-height: 0.69rem;
        padding-left: 0.32rem;
        box-sizing: border-box;
        font-size: 0.17rem;
        margin-left: 0.1rem;


        color: #898989;
      }


    }

    .floor2 {
      margin-top: 0.14rem;
      padding: 0 0.3rem;
      box-sizing: border-box;
      background: #ffffff;
      box-sizing: border-box;

      .main-left-navs {

        background: #ffffff;
        width: 100%;
        height: 0.86rem;
        line-height: 0.86rem;
        box-sizing: border-box;
        border-bottom: 1px solid #EAEAEA;

        ul {
          height: 0.86rem;
          line-height: 0.86rem;

        }

        li {
          font-size: 0.18rem;


          color: #202020;
          margin-right: 0.65rem;
          cursor: pointer;
        }

        .activeNav {
          color: #02428C;

        }
      }
    }


  }
}

.right {
  width: 3.56rem;
  margin-left: 0.13rem;
}

.common {
  width: 100%;
  height: 0.62rem;
  background: #ffffff;
  line-height: 0.62rem;
  // padding-left: 0.22rem;
  box-sizing: border-box;
  font-size: 0.2rem;


  color: #202020;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 0.18rem;
  }

  img {
    width: 0.32rem;
    height: 0.32rem;
    background-color: #02428C;
    margin-right: 0.06rem;

  }

}





//动态列表
.main-left-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding-bottom: 0.71rem;
  margin-bottom: 0.7rem;
  position: relative;

  ::v-deep ._v-container {
    overflow: visible;
  }

  li {
    width: 100%;
    box-sizing: border-box;
    background: #ffffff;
  }
}

// 话题标签
.topics {
  display: flex;
  flex-direction: row;

  label {
    display: inline-block;
    height: 0.25rem;
    line-height: 0.25rem;
    font-size: 0.14rem;
    margin-right: 0.1rem;
    padding: 0 0.1rem;
    background: rgba(0, 188, 255, 0.1);
    color: #519ee8;
    border-radius: 0.03rem;
    cursor: pointer;
  }
}

// 用户评论开始
.comments {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0.64rem;
  width: calc(100% - 0.89rem);
  height: auto;
  background: #f8f8f8;
  opacity: 1;
  padding: 0.1rem 0.13rem 0.2rem 0.1rem;

  .comments-item {
    .comments-item-bot-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 0.3rem;

      .comments-item-bot-item-left {
        font-size: 0.12rem;

        span:nth-child(2n + 1) {
          color: #333333;
        }

        span:nth-child(2n) {
          color: #b3b3b3;
        }
      }

      .comments-item-bot-item-right {
        font-size: 0.12rem;
        font-family: Microsoft YaHei;

        color: #519ee8;
      }
    }
  }

  .comments-item:last-child {
    border-bottom: 0;
  }
}

.comments-more {
  font-size: 0.12rem;
  font-family: Microsoft YaHei;

  line-height: 0.3rem;
  color: #519ee8;
}

//用户评论结束
.animate {
  animation: mymove 1s;
}

@keyframes mymove {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

//礼物列表
.gifts {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      width: 1rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 0 0.1rem;
      border: 0.015rem solid rgba(255, 255, 255, 0.8);
      align-items: center;
      justify-content: center;
      border-radius: 0.05rem;
      padding: 0.1rem 0;

      img {
        width: 0.5rem;
        height: 0.5rem;
        margin-bottom: 0.1rem;
      }

      .name {
        font-size: 0.14rem;
        color: #333333;
      }

      .price {
        font-size: 0.11rem;
        color: #838383;
        margin-top: 0.05rem;
      }
    }

    .activeColor {
      background: rgba(232, 104, 81, 0.1);
      border: 0.015rem solid rgba(232, 104, 81, 0.8);
    }
  }
}

.operates {
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .el-input {
    width: 30%;
  }
}

.btns {
  display: flex;
  flex-direction: row;
  align-items: center;

  .btn {
    width: 0.4rem;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: center;
    background: #f5f5f5;
    cursor: pointer;
  }

  ::v-deep .el-input__inner {
    border-radius: 0;
    height: 0.4rem;
    // border: 0;
  }
}

.waiting {
  border-radius: 20px;
  background: #519ee8;
  color: #ffffff !important;
  width: 0.79rem;
  height: 0.28rem;
  text-align: center;
  line-height: 0.28rem;
  position: absolute;
  bottom: 0.4rem;
}

.different {
  width: 2.3rem;
  height: 0.56rem;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;

  img {
    width: 0.32rem;
    height: 0.32rem;
    border-radius: 50%;
    margin-right: 0.08rem;
  }
}

// 圈子成员
.floor5 {
  width: 100%;
  padding: 0 0.2rem 0.33rem;
  box-sizing: border-box;
  background: #ffffff;
  margin-bottom: 0.17rem;

  ul {
    li {
      margin-bottom: 0.14rem;
      cursor: pointer;

      img {
        width: 0.55rem;
        height: 0.55rem;
        border-radius: 50%;
      }

      .user {
        margin-left: 0.06rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex: 1;

        .nickName {
          width: 1.41rem;
          height: 0.29rem;
          font-size: 0.16rem;


          color: #202020;
          line-height: 0.29rem;
          overflow: hidden;

        }

        .score {
          font-size: 0.14rem;


          color: #909090;
          line-height: 0.24rem;


        }
      }

      .timer {
        font-size: 0.14rem;


        color: #909090;
        line-height: 0.24rem;

      }

    }
  }

  .more {
    width: 2.07rem;
    height: 0.43rem;
    border-radius: 0.03rem;
    border: 1px solid #02428C;
    text-align: center;
    line-height: 0.43rem;
    font-size: 0.16rem;


    color: #02428C;
    cursor: pointer;
    margin: 0.3rem auto 0;
  }

}

//排行榜
.floor4 {
  width: 100%;
  background: #ffffff;


  ul {
    display: flex;
    flex-direction: column;
    padding: 0 0.2rem;

    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.2rem;
      height: 0.55rem;
      align-items: center;
      cursor: pointer;

      .biao {
        width: 0.29rem;

      }

      span {
        width: 0.29rem;
        height: 0.2rem;
        background: #E4E4E9;
        border-radius: 0.1rem;
        text-align: center;
        line-height: 0.2rem;
        display: inline-block;
        font-size: 0.12rem;
        font-family: Arial-Black, Arial;
        font-weight: 900;
        color: #9BA0A5;
      }

      .avatar {
        margin-left: 0.24rem;
        width: 0.55rem;
        height: 0.55rem;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
      }

      .user {
        margin-left: 0.06rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex:1;
        .nickName {
          font-size: 0.16rem;

          color: #202020;
          line-height: 0.29rem;
        }

        .score {
          font-size: 0.14rem;


          color: #8BA5E3;
          line-height: 0.24rem;
        }
      }
    }

    li:nth-child(1) span,
    li:nth-child(2) span,
    li:nth-child(3) span {
      // background: #02428C;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #eaeaea;

  }

  .mores {
    font-size: 0.17rem;


    color: #5077D5;
    line-height: 0.84rem;
    cursor: pointer;
    text-align: center;


  }




}

.tui {
  width: 3.02rem;
  height: 0.6rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #212121;
  text-align: center;
  line-height: 0.6rem;
  cursor: pointer;
  color: #202020;
  font-size: 0.16rem;
  margin: 0.3rem auto;
}

.button {
  width: 1.79rem;
  height: 0.46rem;
  border-radius: 0.03rem;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 0.17rem;
  color: #ffffff;
  line-height: 0.46rem;
  margin-right: 0.2rem;



}

.different {
  background: #F3F3F3;
  border: 0.01rem solid #F3F3F3;
  color: #939393;
}
.username{
  max-width: 2rem;
}
</style>
