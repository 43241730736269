<template>
  <div class="rank">
    <div class="common" style="cursor: pointer">
     {{ $t('con.hot_user') }}
    </div>
    <ul>
      <li class="flex-row-center-between" v-for="(item, index) in userList" :key="index" style="cursor: pointer"
        @click="handleSkip(`/personal?id=${item.id}`)">
        <Avatar size="medium3" :user="item" />
        <div class="user">
          <div class="nickName">{{ item.name || $t('auth.nullName') }}
          </div>
          <div class="desc x-ell-1">{{ item.introduction || $t('auth.nulldesc') }}</div>
        </div>
        <div class="focus" v-if="item.has_follow == 0" @click.stop="handleFollow(0, item.id, index)">  {{ $t('index.focus') }} </div>
        <div class="focus diff" v-if="item.has_follow == 1" @click.stop="handleFollow(1, item.id, index)">  {{ $t('index.focused') }} </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Avatar from "@/components/Avatar";
export default {
  components: { Avatar },
  name: "RankBox",
  data() {
    return {
      userList: [],//热门用户
    };
  },
  created() {
    //获取热门用户
    this.getHotUsers()
  },
  methods: {
    //获取热门用户
    getHotUsers() {
      this.$api.get('/api/users', { params: { type: 'hot', page: 1, limit: 10 } }).then(res => {
        let result = res.data.data
        this.userList = result.slice(0, 5)
      })
    },
    //关注 取消关注
    handleFollow(state, id,index) {
      if (state == 0) {
        this.$api.post(`/api/follow/user/${id}`).then(() => {
          this.userList[index].has_follow = 1
          this.$message.success(this.$t('circle.focus_success'))
        })
      } else {
        this.$api.delete(`/api/follow/user/${id}`).then(() => {
          this.userList[index].has_follow = 0
          this.$message.success(this.$t('circle.cancel_success'))
        })
      }
    },
    //跳转
    handleSkip(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped lang="scss">
.common {
  width: 100%;
  height: 0.62rem;
  background: #ffffff;
  line-height: 0.62rem;
  padding-left: 0.22rem;
  box-sizing: border-box;
  font-size: 0.18rem;


  color: #202020;
}

.rank {
  width: 100%;
  background: #FFFFFF;
  background: #ffffff;
  padding-bottom: 0.27rem;

  border-radius: 0.03rem;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0 0.2rem;

    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.22rem;
      height: 0.55rem;
      align-items: center;
      cursor: pointer;




      .user {
        margin-left: 0.06rem;
        display: flex;
        flex-direction: column;
        flex: 1;

        .nickName {
          font-size: 0.18rem;


          color: #202020;
          line-height: 0.29rem;
        }

        .desc {
          font-size: 0.14rem;


          color: #909090;
          line-height: 0.2rem;
        }
      }

      .focus {
        font-size: 0.14rem;


        color: #02428C;
        line-height: 0.2rem;

      }

      .diff {
        color: #909090;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }


  }
}
</style>
