// 头部组件
<template>
  <div @click.stop>
    <header>
      <div class="head-cen flex-row-center-between">
        <div class="flex-col-center">
          <img class="logo" src="@/assets/icons/logo.png" @click="handlePage('/')" style="cursor: pointer" />
          <!-- <div class="description" v-if="language=='zh'">{{ $t('index.biao') }}</div> -->
          <!-- <div class="description-en" v-else>{{ $t('index.biao') }}</div> -->
        </div>

        <div class="head-cen-main flex-row-center">
          <img :title="$t('index.home')" class="icon" style="width:0.24rem"
            :src="toUrl == '/' ? require('@/assets/icons/homed.png') : require('@/assets/icons/home.png')"
            @click="handlePage('/')" />
          <img :title="$t('feed.circle')" class="icon"
            :src="toUrl == '/circle' ? require('@/assets/icons/circled.png') : require('@/assets/icons/circle.png')"
            @click="handlePage('/circle')" />
          <img :title="$t('index.task')" class="icon"
            :src="toUrl == '/task' ? require('@/assets/icons/tasked.png') : require('@/assets/icons/task.png')"
            @click="handlePage('/task')" />
          <img :title="$t('index.activity')" class="icon"
            :src="toUrl == '/activity' ? require('@/assets/icons/activityed.png') : require('@/assets/icons/activity.png')"
            @click="handlePage('/activity')" />
          <div class="search flex-row-center-between">
            <input @keyup.enter="handleInput" v-model="search" type="text" :placeholder="placeholder" />
            <img @click="handleToSearch()" src="@/assets/icons/search.png" />
            <div class="select" :class="{ 'select-diff': visible }">
              <div class="option flex-row-center-between" v-for="(item, index) in list" :key="index"
                @click="handleLabel(item.label, item.id)">
                <div class="option-left x-ell-1">{{ item.label }}</div>
                <div class="option-right">{{ item.count }}条</div>
              </div>
            </div>
          </div>
          <div class="btn" v-if="userInfo" @click="publishTask">{{ $t('index.publish_task') }}</div>
          <img :title="$t('task.collect')" src="@/assets/icons/xin.png" class="icons" v-if="userInfo"
            @click="handlePage('/collect')" />
          <div title="消息" class="icons el-icon-message-solid" v-if="userInfo" @click="handlePage('/msg')">
            <div class="dot" v-if="num != 0"></div>
          </div>
          <div class="icons" v-if="userInfo"></div>
          <div class="user-info" v-if="userInfo">
            <el-dropdown>
              <div class="el-dropdown-link" style="display: flex; flex-direction: row; align-items: center">
                <el-image :src="userInfo.avatar" class="avatar" v-if="userInfo.avatar" />
                <img src="@/assets/icons/default.png" class="avatar" v-else />
                <p class="name x-ell-1" :title="userInfo.name">{{ userInfo.name || $t('auth.nullName') }}</p>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="flex-row-center my-dropdown" @click="handlePage('/personal')">
                    <img class="my-icon" src="@/assets/icons/my1.png" />
                    <span>{{ $t('index.my_page') }}</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="flex-row-center my-dropdown" @click="handlePage(`/personal?currentIndex=1`)">
                    <img class="my-icon" src="@/assets/icons/my2.png" />
                    <span> {{ $t('index.my_member') }}</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="flex-row-center my-dropdown" @click="handlePage(`/personal?currentIndex=3`)">
                    <img class="my-icon" src="@/assets/icons/my3.png" />
                    <span>{{ $t('index.my_friends') }}</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="flex-row-center my-dropdown" @click="handlePage('/income')">
                    <img class="my-icon" src="@/assets/icons/my4.png" />
                    <span>{{ $t('personal.my_income') }}</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="flex-row-center my-dropdown" @click="loginOut">
                    <img class="my-icon" src="@/assets/icons/my5.png" />
                    <span>{{ $t('index.logout') }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="user-info" v-else>
            <div class="login-btn" @click="handlePage('/login')">{{ $t('index.login_reg') }}</div>
          </div>
        </div>
        <div class="head-cen-right flex-row-center-center">
          <el-select v-model="language" @change="changeLanguage" size="mini" style="width: 0.96rem;">
            <el-option :label="$t('language.zh')" value="zh"></el-option>
            <el-option :label="$t('language.en')" value="en"></el-option>
          </el-select>
        </div>
      </div>

    </header>
    <!-- 未认证弹窗 -->
    <ApplyService :show="show3" @handleClose="show3 = false" :tip="$t('task.tip1')" />
  </div>
</template>
<script>
import ApplyService from '@/components/ApplyService.vue'
import { eventBus } from '@/main.js'
export default {
  components: { ApplyService },
  props: {
    toUrl: {
      type: String,
    },
  },
  data() {
    return {
      h: '0px',//搜索结果框高度
      language: this.$i18n.locale,//中英文切换
      placeholder: '',
      search: '',//搜索
      //用户信息
      userInfo: null,
      timer: null,
      num: 0,
      list: [],
      visible: false,
      search_title: '',
      search_title_en: '',
      show3: false,
    };
  },
  created() {
    //获取网站基础配置
    this.getConfig()
    // if(this.userInfo){
    //   this.getsStatistics()
    // }
    window.addEventListener("setItem", () => {
      console.log(this.$storage)
      let userInfo = this.$storage.getData("userInfo");
      if (userInfo) {
        this.userInfo = userInfo
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.timer)
    document.removeEventListener("click", this.bodyCloseMenus);
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
    this.timer = setInterval(() => {
      if (this.userInfo) {
        this.getsStatistics()
      }
    }, 10000)
    //获取用户信息
    this.getUserInfo()
  },
  watch: {
    search(val) {
      if (val.length == 0) {
        this.visible = false
      } else {
        this.handleInput()
      }
    }
  },
  methods: {
    bodyCloseMenus(e) {
   let self = this;
    if (self.visible == true){
     self.visible = false;
    }
  },
    publishTask() {
      if (this.userInfo && this.userInfo.certificate) {
        this.handlePage('/feed/publishTask')
      } else {
        this.$api.get(`/api/user`).then(res => {
          this.userInfo = res.data
          this.$storage.userInfo();
          if (this.userInfo && this.userInfo.certificate) {
            this.handlePage('/feed/publishTask')
          } else {
            this.show3 = true
          }
        })
      }
    },
    //获取网站基础配置
    getConfig() {
      this.$api.get('/api/init/config').then(res => {
        this.search_title = res.data.search_title
        this.search_title_en = res.data.search_title_en
        if (this.language == 'zh') {
          this.placeholder = this.search_title
        } else {
          this.placeholder = this.search_title_en
        }
      })
    },
    //获取统计
    getsStatistics() {
      this.$api.get('/api/notify/statistics').then(res => {
        let result = res.data
        this.num = Number(result.all) + Number(result.post) + Number(result.group) + Number(result.task) + Number(result.activity) + Number(result.system)
      })
    },
    //点击标签
    handleLabel(label, id) {
      this.visible = false
      const txt = label.replace(/#/g, "");
      this.search = ''
      this.$router.push(`/search?label_id=${id}&search=${txt}`)

    },
    //跳转到搜索页面
    handleToSearch() {
      this.$router.push(`/search?search=${this.search}`)
    },
    //输入框改变
    handleInput() {
      console.log(this.search)
      if (!this.search.length) {
        this.visible = false
        return
      }
      this.$api.get(`/api/search_label`, { params: { search: this.search } }).then(res => {
        this.list = res.data.data;
        if (this.list.length) {
          const txt = this.search.replace(/#/g, "");
          // this.$router.push(`/search?search=${txt}`)
          this.visible = true

        } else {
          this.visible = false
          // this.$router.push(`/search?search=${this.search}`)

        }
      })
    },
    //中英文切换
    changeLanguage(val) {
      this.language = val
      localStorage.setItem("languageSet", val); // 将用户设置存储到localStorage以便用户下次打开时使用此设置
      if (this.language == 'zh') {
        this.placeholder = this.search_title
      } else {
        this.placeholder = this.search_title_en
      }
      location.reload()
    },
    //退出
    loginOut() {
      this.$storage.clearData();
      window.location.href = "/";
    },
    //页面跳转
    handlePage(url) {
      this.$router.push(url)
    },
    //获取用户信息
    getUserInfo() {
      let userInfo = this.$storage.getData("userInfo");
      if (userInfo) {
        this.userInfo = userInfo
      }
    },

  }
};
</script>
<style scoped lang="scss">
header {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  height: 0.72rem;
  top: 0;


  .head-cen {
    width: 13.2rem;
    height: 0.72rem;
    margin: 0 auto;

    .logo {
      width: 1.4rem;
    }

    .description {
      font-family: cursive;
      margin-top: 0.05rem;
      font-size: 0.1rem;
      color: #333;
      letter-spacing: 0.04rem;
      font-weight: 800;
      min-width: max-content;
    }
    .description-en{
      margin-top: 0.05rem;
      font-size: 0.1rem;
      color: #333;
      font-weight: 400;
      min-width: max-content;

    }

    .head-cen-main {
      display: flex;
      flex: 1;
      margin-left: 1rem;
      // margin-right: 0.86rem;

      .icon {
        font-size: 0.23rem;
        color: #848484;
        cursor: pointer;
        margin-right: 0.55rem;
        width: 0.2rem;
      }

      .active {
        color: #02428C;
      }

      .search {
        width: 2.17rem;
        height: 0.4rem;
        background: #F5F5F5;
        border-radius: 0.06rem;
        margin-right: 0.24rem;
        padding-right: 0.12rem;
        box-sizing: border-box;
        position: relative;



        .select {
          width: 4rem;
          padding: 0 0.3rem;
          box-sizing: border-box;
          background-color: #ffffff;
          position: absolute;
          left: 0;
          top: 0.5rem;
          border-radius: 0.03rem;

          transition: all 0.2s;
          height: 0;
          overflow: hidden;

          .option {
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.18rem;
            border-bottom: 1px solid #EAEAEA;
            cursor: pointer;

            .option-left {
              color: #02428C;
              flex:1;
            }

            .option-right {
              color: #202020;
              font-size: 0.16rem;
              color: #8A8A8A;
            }
          }
        }

        .select-diff {
          border: 0.01rem solid #E5E5E5;
          height: auto;
        }


        input {
          width: 1.9rem;
          height: 0.4rem;
          background: #F5F5F5;
          border-radius: 0.06rem;
          border: none;
          outline: none;
          padding-left: 0.12rem;
          box-sizing: border-box;

        }

        img {
          height: 0.2rem;

          cursor: pointer;


        }
      }

      .btn {
        width: 0.89rem;
        height: 0.4rem;
        background: #02428C;
        border-radius: 0.06rem;
        text-align: center;
        line-height: 0.4rem;
        color: #ffffff;
        font-size: 0.14rem;
        cursor: pointer;
        margin-left: 0.65rem;

      }

      .icons {
        margin-left: 0.37rem;
        font-size: 0.24rem;
        color: #848484;
        cursor: pointer;
        width: 0.2rem;
        position: relative;

        .dot {
          position: absolute;
          width: 0.06rem;
          height: 0.06rem;
          background: #FF1E1E;
          border-radius: 50%;
          right: 0.01rem;
          top: 0.01rem;
        }
      }

      .user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: -0.2rem;

        .avatar {
          width: 0.24rem;
          height: 0.24rem;
          border-radius: 50%;

        }


        .name {
          display: block;
          white-space: nowrap;
          padding-left: 0.02rem;
          text-overflow: ellipsis;
          font-size: 0.12rem;

          max-width: 0.8rem;


          color: #333333;
        }

        .common {
          font-size: 0.16rem;
          font-family: Microsoft YaHei;

          line-height: 0.18rem;
          color: #519ee8;
          width: 0.4rem;

          a {
            color: #519ee8;
          }
        }

        .common:last-child {
          border-right: 0rem;
        }
      }
    }

    .head-cen-right {
      width: 1.04rem;
      height: 0.4rem;
    }
  }




















  .head-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.6rem;

    span {
      display: inline-block;
      width: 0.6rem;
      padding: 0 0 0 0.2rem;
      border-left: 0.01rem solid #999;
      // background-color: red;
    }
  }

  .head-left img {
    width: 2.24rem;
    padding-top: 0.02rem;
  }

  .head-main {
    height: 0.71rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


  }



}

.login-btn {
  min-width: 1.04rem;
  padding: 0 0.1rem;
  height: 0.4rem;
  background: #02428C;
  border-radius: 0.06rem;
  cursor: pointer;
  text-align: center;
  line-height: 0.4rem;
  font-size: 0.14rem;
  margin-left: 0.3rem;
  color: #ffffff;
  cursor: pointer;



}

.my-icon {
  width: 0.18rem;
  margin-right: 0.1rem;
}
</style>
