<!--
 * @Description: 数据为空
 * @Author: tl
 * @Date: 2021-12-08 09:24:57
 * @LastEditTime: 2021-12-08 09:42:59
 * @LastEditors: Please set LastEditors
 * @FilePath: \wanchuan\src\components\ViewEmpty.vue
-->
<template>
  <div class="empty">
    <img src="../assets/icon/empty.png" />
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2rem;
  padding-top: 0.2rem;

  p {
    font-size: 0.14rem;
    color: #666666;
    margin-top: 0.2rem;
  }
}
</style>
