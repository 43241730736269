<!-- 创建活动 -->
<template>
  <div class="release">
    <section class="main">
      <div class="title">{{ $t('activity.publish') }}</div>
      <div class="desc">{{ $t('feed.tit1') }}</div>
      <div class="release-form">
        <el-form ref="form" :rules="rules" :model="form" label-position="top">
          <el-form-item :label="$t('feed.activity_name')" prop="title">
            <el-input v-model="form.title" :placeholder="$t('feed.placeholder1')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('feed.activity_category')" prop="category_id">
            <el-cascader style="width: 100%;" :props="{ label: 'name', value: 'id', children: 'child' }"
            :placeholder="$t('feed.placeholder2')" v-model="form.category_arr" :options="category"
              @change="changeCategory"></el-cascader>
          </el-form-item>
          <el-form-item :label="$t('feed.activity_cover')" prop="cover">
            <el-upload class="avatar-uploader" ref="upload_img" action accept="image/jpeg, image/jpg, image/png"
              list-type="picture-card" :name="upload_name" :on-remove="handleRemove" :on-exceed="handleExceed" :limit="1"
              :file-list="file_list" :before-upload="beforeUpload" :class="{ hide: hideUpload }" :show-file-list="true">
              <i class="el-icon-upload"></i>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('feed.activity_detail')" prop="content">
            <el-input :rows="6" :placeholder="$t('feed.placeholder3')"  type="textarea" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item :label="$t('activity.activity_num')" prop="limit_join_user_count">
            <el-input v-model="form.limit_join_user_count" :placeholder="$t('feed.placeholder4')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('activity.address')" prop="country">
            <el-col :span="5">
              <el-select :placeholder="$t('feed.placeholder5')" style="width: 100%" v-model="form.country" @change="changeCountry">
                <el-option :label="item.name" :value="item.id" v-for="(item, index) in country" :key="index">{{ item.name
                }}</el-option>
              </el-select>
            </el-col>
            <el-col class="line" :span="1">-</el-col>
            <el-col :span="5">
              <el-select v-model="form.state" :placeholder="$t('feed.placeholder6')" style="width: 100%" @change="changeProvince">
                <el-option :label="item.name" :value="item.id" v-for="(item, index) in province" :key="index"></el-option>
              </el-select>
            </el-col>
            <el-col class="line" :span="1">-</el-col>
            <el-col :span="5">
              <el-select v-model="form.city" :placeholder="$t('feed.placeholder7')" style="width: 100%">
                <el-option :label="item.name" :value="item.id" v-for="(item, index) in city" :key="index"></el-option>
              </el-select>
            </el-col>
            <el-col :span="24">
              <el-input style="margin-top:15px" v-model="form.address" :placeholder="$t('feed.placeholder8')"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item :label="$t('activity.date')" prop="end_time">
            <el-col :span="5">
              <el-date-picker type="date" :placeholder="$t('feed.placeholder9')" v-model="form.start_time" style="width: 100%;"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
            </el-col>
            <el-col class="line" :span="1">{{ $t('activity.to') }}</el-col>
            <el-col :span="5">
              <el-date-picker type="date" :placeholder="$t('feed.placeholder10')" v-model="form.end_time" style="width: 100%;"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item :label="$t('feed.activity_label')" prop="label">
            <el-input v-model="form.label" :placeholder="$t('feed.placeholder11')" ></el-input>
          </el-form-item>
          <el-form-item :label="$t('feed.activity_file')" prop="image">
            <div class="tip">{{ $t('feed.tip1') }}</div>
            <el-upload class="avatar-uploader" ref="upload_img1" action accept="image/jpeg, image/jpg, image/png"
              list-type="picture-card" :name="upload_name1" :on-remove="handleRemove1" :on-exceed="handleExceed1"
              :limit="9" :file-list="file_list1" :before-upload="beforeUpload1" :class="{ hide: hideUpload1 }" multiple>
              <i class="el-icon-upload"></i>
              <p></p>
            </el-upload>
          </el-form-item>
          <div v-for="(item, index) in form.outline" :key="index">
            <el-form-item :label="$t('activity.link') + (index + 1)" style="position: relative;">
              <el-input v-model="item.name" :placeholder="$t('feed.placeholder12')"></el-input>
              <el-input v-model="item.url" :placeholder="$t('feed.placeholder13')" style="margin-top:15px"></el-input>
              <div class="del" @click="handleDel(index)">{{ $t('task.del') }}</div>
            </el-form-item>
          </div>
          <div class="add" @click="handleAdd">+{{ $t('feed.add_link') }}</div>
          <div class="release-form-btn" v-loading="loading" element-loading-spinner="el-icon-loading" @click="submit()"
            style="cursor: pointer">
            {{ $t('feed.confirm_publish') }}
          </div>
        </el-form>
      </div>
    </section>
  </div>
</template>
<script>
import uploadFile from "@/utils/upload-file";
export default {
  data() {
    return {
      form: {
        title: '',
        category_id: '',
        category_arr: [],
        cover: '',
        content: '',
        limit_join_user_count: '',
        country: '',
        state: '',
        city: '',
        address: '',
        start_time: undefined,
        end_time: undefined,
        label: '',
        image: '',
        outline: [{ name: '', url: '' }]
      },
      rules: {
        category_id:[{ required: true, message: this.$t('feed.activity_placeholder'), trigger: "blur" }],
        title: [{ required: true, message: this.$t('feed.placeholder1'), trigger: "blur" }],
        cover: [{ required: true, message: this.$t('feed.msg1'), trigger: "blur" }],
        content: [{ required: true, message: this.$t('feed.msg2'), trigger: "blur" }],
        limit_join_user_count: [{ required: true, message: this.$t('feed.msg3'), trigger: "blur" }],
        country: [{ required: true, message: this.$t('feed.msg4'), trigger: "blur" }],
        end_time: [{ required: true, message: this.$t('feed.msg5'), trigger: "blur" }],
        label: [{ required: true, message: this.$t('feed.msg6'), trigger: "blur" }],


      },
      category: [],//活动分类
      country: [],//国家列表
      province: [],//省份列表
      city: [],//城市列表
      //上传图片
      file_list: [],
      upload_name: "file", //图片上传的后端接受图片文件的 key
      hideUpload: false,
      file_list1: [],
      upload_name1: "file", //图片上传的后端接受图片文件的 key
      hideUpload1: false,
      loading: false,//加载效果
    };
  },
  created() {
    //获取活动分类列表
    this.getCategory();
    //获取国家列表
    this.getCountry()
  },
  methods: {
    //获取活动分类列表
    getCategory() {
      this.$api.get('/api/activity-category').then(res => {
        this.category = res.data
      })
    },
    //获取国家列表
    getCountry() {
      this.$api.get('/api/country').then(res => {
        this.country = res.data
      })
    },
    //选择国家改变
    changeCountry(id) {
      console.log(id)
      this.form.state = ''
      this.form.city = ''
      this.$api.get(`/api/state/${id}`).then(res => {
        this.province = res.data
      })
    },
    //选择省份改变
    changeProvince(id) {
      this.form.city = ''
      this.$api.get(`/api/city?pid=${id}`).then(res => {
        this.city = res.data
      })
    },
    //活动分类改变
    changeCategory(arr) {
      this.form.category_id = arr[arr.length - 1]
    },
    //活动提交
    submit() {
      if (this.form.outline&&this.form.outline.length && this.form.outline[0].name == '') {
        this.form.outline = []
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.compareDate(this.form.start_time, this.form.end_time)) {
            this.$message.warning( this.$t('feed.tip2'))
            return
          }
          
          this.loading = true

          this.$api
            .post("/api/activity", this.form)
            .then(() => {
              this.$message({
                message: this.$t('auth.activity_success'),
                type: "success",
              });
              this.$router.push('/activity')
              this.loading = false
            })
            .catch(() => {
              this.loading = false
              this.$message({
                message: this.$t('auth.operate_fail'),
                type: "warning",
              });
            });
        }
      })
    },
    //时间比较大小
    compareDate(d1, d2) {
      let date1 = new Date(Date.parse(d1))
      let date2 = new Date(Date.parse(d2))
      return date1 > date2
    },
    //上传图片
    handleExceed() {
      this.$alert(this.$t('feed.tip3'), {
        showConfirmButton: false
      });
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.form.cover = null;
      this.file_list = []
      //打开上传按钮
      this.hideUpload = false;
    },
    async beforeUpload(file) {

      let { data } = await uploadFile(file);
      this.form.cover = data.path
      this.file_list = [{ url: this.form.cover }]
      //隐藏上传按钮
      this.hideUpload = true;

    },
    //上传图片
    handleExceed1() {
      this.$alert(this.$t('feed.tip4'), {
        showConfirmButton: false
      });
    },
    // 文件列表移除文件时的钩子
    handleRemove1(file, fileList) {
      this.file_list1 = fileList
      this.form.image = fileList.map(item => item.url)
      //打开上传按钮
      this.hideUpload1 = false;
    },
    async beforeUpload1(file) {
      let { data } = await uploadFile(file);
      this.form.image = [...this.form.image, data.path]
      this.file_list1.push({ url: data.path })
      //隐藏上传按钮
      if (this.form.image.length >= 9) {
        this.hideUpload1 = true;
      }
    },
    //继续添加活动直播链接
    handleAdd() {
      this.form.outline.push({ name: '', url: '' })
    },
    //删除活动直播链接
    handleDel(index) {
      this.form.outline.splice(index, 1)
    }
  }
};
</script>
<style scoped lang="scss">
//主体
.main {
  margin: 0.92rem auto;
  display: flex;
  flex-direction: column;
  width: 12rem;
  background-color: #ffffff;
  border-radius: 0.03rem;
  padding: 0.5rem 1rem 0.75rem;
  box-sizing: border-box;


  .title {
    font-size: 0.24rem;
    color: #262423;
  }

  .desc {
    font-size: 0.14rem;
    color: #828282;
    margin-top: 0.06rem;
  }
}

.release-form {
  height: auto;
  background: #ffffff;
  margin-top: 0.2rem;



  p {
    font-size: 0.17rem;


    color: #BCBCBC;
  }

  .tip {
    font-size: 0.14rem;

    color: #B0B0B0;

  }

  .add {
    font-size: 0.16rem;
    color: #02428C;
    line-height: 0.29rem;
    margin-top: 0.23rem;
    cursor: pointer;
  }



  .release-form-btn {
    cursor: pointer;
    width: 2rem;
    height: 0.5rem;
    background: #02428C;
    border-radius: 0.04rem;
    text-align: center;
    line-height: 0.5rem;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-size: 0.17rem;
    color: #ffffff;
    margin: 0 auto;

  }
}

.line {
  text-align: center;
}


::v-deep .hide .el-upload--picture-card {
  display: none;
}

.del {
  position: absolute;
  color: #02428C;
  top: -0.54rem;
  left: 1.2rem;
  cursor: pointer;

}
::v-deep .el-upload-list__item.is-ready {
        display: none;
    }
</style>


