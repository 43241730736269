<template>
 
    <div class="source">
        <div class="operate-wrap flex-row-center-between">
            <div class="operate-left flex-row-center">
                <div class="operate-left-label">{{$t('circle.category')}} </div>
                <el-select v-model="category_id" :placeholder="$t('personal.placeholder18')" style="width: 100%" @change="handleChange">
                    <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt, index) in category" :key="index" />
                </el-select>
            </div>
            <div class="search flex-row-center">
                <input :placeholder="$t('personal.search_resource')" v-model="title" @keyup.enter="handleSearch" />
                <img src="@/assets/icons/search.png" @click="handleSearch" />
            </div>
        </div>
        <div v-if="list&&list.length">
            <div class="source-item" v-for="(item, index) in list" :key="index">
            <div class="source-item-head flex-row-center-between">
                <div class="flex-row-center">
                    <el-checkbox class="checkbox" v-model="item.checked"
                        @change="(val) => changeChecked(val, index)"></el-checkbox>
                    <Avatar size="medium" :showName="true" :user="item.user" />
                </div>
                <div class="focus-btn" @click="handleFollow(0, item.user.id, index)"
                    v-if="item.user && item.user.has_follow == 0">  {{ $t('index.focus') }}</div>
                <div class="focus-btn" @click="handleFollow(1, item.user.id, index)"
                    v-if="item.user && item.user.has_follow == 1">  {{ $t('index.focused') }}</div>
            </div>
            <div class="source-item-cen flex-row">
                <div class="cover">
                    <el-image class="cover1" :src="item.image[0]" v-if="item.image" />
                    <div class="mark" v-if="item.category && item.category.name">{{ item.category && item.category.name }}</div>
                </div>
                <div class="info">
                    <div class="floor1 flex-row-center">
                        <div class="label diff" v-if="item.type==2">{{  $t('circle.organ')   }}</div>
                        
                        <div class="label" v-if="item.type==1">{{ $t('circle.export')     }}</div>
                        
                        <div class="title">{{ item.title }}</div>
                    </div>
                    <div class="floor2" v-if="item.type == 1">{{$t('circle.main_character')}}：{{ item.main_character }}</div>
                    <div class="floor2" v-if="item.type == 1">{{$t('circle.other_character')}}：{{ item.other_character }}</div>
                    <div class="floor3">{{ item.introduction }}</div>
                    <div class="floor4 flex-row-center">
                        <div class="floor4-label flex-row-center">{{$t('circle.level')}}：
                            <img src="@/assets/icons/star.png" v-for="itemt in item.level" :key="itemt" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="source-item-bot flex-row-center-between">
                <div class="detail-btn" @click="handleSkip(`/sourceDetail?id=${item.id}`)">{{ $t('personal.view_detail') }}</div>
                <div class="el-icon-delete" style="color:#8C8C8C" @click="handleDel(index, item.id)"></div>
            </div>
        </div>
        <div class="source-operate flex-row-center-between">
            <div class="flex-row-center">
                <el-checkbox class="checkbox" v-model="checkedAll" @change="changeAll"></el-checkbox>
                <div class="all">{{ $t('personal.checked_all') }}</div>
            </div>
            <div class="flex-row-center">
                <div class="common" @click="show = true">{{ $t('personal.customer_service') }}  </div>
                <div class="common diff" @click="handleShow">{{ $t('personal.sec') }}  </div>
            </div>
        </div>

        </div>
        <view-empty v-else></view-empty>
       




        <CustomerService :show="show" @handleClose="show = false" />
    </div>
</template>
<script>
import CustomerService from './CustomerService.vue'
import Avatar from '@/components/Avatar.vue'
export default {
    props: ['list'],
    components: { Avatar, CustomerService },
    data() {
        return {
            show: false,
            category_id: '',//资源类型
            title: "",
            checkedAll: false,//全选
            category: [
                {id:1,name:  this.$t('circle.export') },
                {id:2,name:  this.$t('circle.organ') }
            ],

        };
    },
  
    methods: {
    
        //跳转
        handleSkip(url) {
            this.$router.push(url)
        },
        //选择资源类型
        handleChange(val) {
            this.$emit('categoryChange', val)
        },
        //资源搜索
        handleSearch() {
            this.$emit('searchResource', this.title)
        },
        //资源全选
        changeAll(val) {
            this.list.forEach(item => {
                item.checked = val
            })
        },
        //单选
        changeChecked(val, index) {
            this.list[index].checked = val
            if (this.list.findIndex(item => item.checked) != -1) {
                this.checkedAll = true
            } else {
                this.checkedAll = false
            }
        },
        //只显示选中
        handleShow() {
            this.list = this.list.filter(item => item.checked)
        },
        //取消收藏
        handleDel(index, id) {
            this.list.splice(index, 1)
            this.$api.delete(`/api/collect/resource/${id}`)
        },
        //关注 取消关注
        handleFollow(state, id, index) {
            if (state == 0) {
                this.$api.post(`/api/follow/user/${id}`).then(() => {
                    this.list[index].user.has_follow = 1
                    this.$message.success(this.$t('circle.focus_success'))
                })
            } else {
                this.$api.delete(`/api/follow/user/${id}`).then(() => {
                    this.list[index].user.has_follow = 0
                    this.$message.success(this.$t('circle.cancel_success'))
                })
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.source {
    width: 100%;
    margin-top: 0.45rem;
    padding-bottom: 0.8rem;

    .operate-wrap {

        .operate-left {
            .operate-left-label {
                font-size: 0.17rem;
                color: #8B8B8B;
                margin-right: 0.05rem;
                min-width: 1.25rem;
            }

        }

        .search {
            width: 2.17rem;
            height: 0.37rem;
            border-radius: 0.06rem;
            border: 0.01rem solid #D8D8D8;
            padding: 0 0.08rem;
            line-height: 0.37rem;
            box-sizing: border-box;

            input {
                flex: 1;
                outline: none;
                border: none;
            }

            img {
                width: 0.2rem;


            }

        }

    }

    .source-item {
        height: 6.16rem;
        border-radius: 0.04rem;
        border: 0.01rem solid #D8D8D8;
        width: 100%;
        margin-top: 0.3rem;

        box-sizing: border-box;

        .source-item-head {
            height: 1.11rem;
            padding: 0 0.26rem;
            border-bottom: 1px solid #EAEAEA;
            box-sizing: border-box;

            .checkbox {
                margin-right: 0.36rem;

            }

            .focus-btn {
                width: 0.69rem;
                height: 0.32rem;
                border-radius: 0.03rem;
                font-size: 0.17rem;
                color: #02428C;
                text-align: center;
                line-height: 0.32rem;
                cursor: pointer;
                border: 0.01rem solid #02428C;
            }

        }

        .source-item-cen {
            padding: 0.4rem 0.26rem;
            box-sizing: border-box;
            border-bottom: 1px solid #EAEAEA;

            .cover {
                width: 3.46rem;
                height: 2.75rem;
                border-radius: 0.06rem;
                margin-right: 0.32rem;
                position: relative;

                .cover1 {
                    width: 3.46rem;
                    height: 2.75rem;
                    border-radius: 0.06rem;

                }

                .mark {
                    position: absolute;
                    min-width: 1.02rem;
                    height: 0.37rem;
                    background: #FFEEDC;
                    border-radius: 0.06rem 0rem 0.06rem 0rem;
                    text-align: center;
                    padding: 0 0.05rem;
                    line-height: 0.37rem;
                    font-size: 0.16rem;
                    color: #A8641C;
                    top: 0;
                    left: 0;

                }
            }

            .info {
                flex: 1;

                .floor1 {
                    .label {
                        min-width: 0.5rem;
                        padding: 0 0.1rem;
                        height: 0.27rem;
                        background: #FF580F;
                        border-radius: 0.14rem;
                        text-align: center;
                        font-size: 0.14rem;
                        color: #FFFFFF;
                        line-height: 0.27rem;
                        margin-right: 0.09rem;
                    }
                    .diff{
                        background-color: #3675F6;
                    }

                    .title {
                        font-size: 0.2rem;

                        color: #262423;
                    }
                }

                .floor2 {
                    margin-top: 0.08rem;
                    font-size: 0.17rem;
                    color: #262423;
                    line-height: 0.24rem;
                }

                .floor3 {

                    font-size: 0.17rem;
                    margin-top: 0.08rem;
                    color: #8B8B8B;
                    line-height: 0.24rem;
                }

                .floor4 {
                    margin-top: 0.08rem;

                    .floor4-label {
                        font-size: 0.17rem;
                        color: #8B8B8B;

                        img {
                            margin-right: 0.06rem;
                        }
                    }

                }

                .floor5 {
                    font-size: 0.18rem;
                    margin-top: 0.08rem;
                    color: #FF471A;
                    line-height: 0.32rem;

                }

            }

        }

        .source-item-bot {
            padding: 0.45rem 0.26rem;

            .detail-btn {
                cursor: pointer;
                width: 1.73rem;
                font-size: 0.17rem;

                color: #8B8B8B;
                height: 0.5rem;
                border-radius: 0.04rem;
                border: 0.01rem solid #979797;
                text-align: center;
                line-height: 0.5rem;

            }
        }
    }

    .source-operate {
        margin-top: 0.5rem;
        padding-top: 0.68rem;


        .all {
            font-size: 0.17rem;
            margin-left: 0.13rem;
            color: #8B8B8B;

        }

        .common {
            min-width: 2.35rem;
            height: 0.58rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #8B8B8B;
            text-align: center;
            line-height: 0.58rem;
            cursor: pointer;
            margin-left: 0.58rem;
            color: #8B8B8B;
        }

        .diff {

            background: #02428C;
            border: 0.01rem solid #02428C;
            color: #ffffff;
        }


    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
        background: #02428C;
        border-color:  #02428C;
    }









}
</style>