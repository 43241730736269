<!-- 我的收益 -->
<template>
    <div class="income">
        <div class="title">{{ $t('personal.my_income') }}</div>
        <ul class="nav flex-row-center">
            <li @click="handleNav(index)" :class="{ 'active': current == index }" v-for="(item, index) in navs"
                class="flex-col-center">
                <div class="name">{{ item.name }}</div>
                <div class="line"></div>
            </li>
        </ul>
        <div class="head flex-row-center-between">
            <div class="head-left">{{ $t('personal.incomes') }}</div>
            <div class="head-cen">{{ $t('personal.income_detail') }}</div>
            <div class="head-right">{{ $t('personal.time') }}</div>
            <div class="operate"></div>
        </div>
        <div class="bot flex-row-center-between" v-for="(item, index) in list" :key="index">
            <div class="bot-left">{{ item.income }}</div>
            <div class="bot-cen">{{ item.title }}</div>
            <div class="bot-right">{{ item.complete_date }}</div>
            <div class="bot-operate" @click="handleSkip(`/incomeDetail?id=${item.id}&current=${current}`)">{{
                $t('personal.detail') }}</div>
        </div>
        <pagination :page-sizes="getPageSize()" v-show="total > 0" :total="total" align="center" :page.sync="params.page"
            :limit.sync="params.paginate" @pagination="handlePage" />
    </div>
</template>
<script>
import pagination from '@/components/pagination.vue'
export default {
    components: { pagination },
    data() {
        return {
            navs: [
                { name: this.$t('personal.income'), value: 'income' },
                { name: this.$t('personal.bole_income'), value: 'bole_income' },
                { name: this.$t('personal.fuxing_income'), value: 'fuxing_income' },
            ],
            current: 0,
            list: [],
            params: {
                page: 1,
                type: 'income',
                paginate: 20
            },
            total: 0
        }
    },
    created() {
        if (this.$route.query.current) {
            this.current = this.$route.query.current
            this.handleNav(this.current)
        } else {
            //查看列表
            this.getList()
        }
    },
    methods: {
        handlePage(val){
            this.getList(val.page)
        },
        //导航栏点击事件
        handleNav(index) {
            this.current = index;
            this.params.type = this.navs[index].value
            this.getList(1)
        },
        //查看列表
        getList(page) {
            if (page) this.params.page = page
            this.$api.get('/api/income', { params: this.params }).then((res) => {
                if (this.params.page == 1) {
                    this.list = res.data.data
                } else {
                    this.list = [...this.list, ...res.data.data]
                }
                this.total = res.data.meta.total
            })
        },
        getPageSize() { },
        //跳转
        handleSkip(url) {
            this.$router.push(url)
        }
    }
}

</script>
<style lang="scss" scoped>
.income {
    width: 13.2rem;
    height: auto;
    background: #FFFFFF;
    border-radius: 0.03rem;
    padding: 0.37rem 0.48rem;
    box-sizing: border-box;
    margin: 0.92rem auto;

    .title {

        color: #262423;
        font-size: 0.24rem;


    }

    .head {
        height: 0.85rem;
        line-height: 0.85rem;
        color: #8B8B8B;
        font-size: 0.16rem;
        border-bottom: 1px solid #EAEAEA;
        padding: 0 0.56rem;

        .head-left {
            width: 20%;
        }

        .head-cen {
            width: 50%;
            text-align: left;
        }

        .head-right {
            width: 20%;
            text-align: center;

        }

        .operate {
            width: 10%;
            text-align: right;
        }

    }

    .bot {
        height: 0.85rem;
        line-height: 0.85rem;
        color: #262423;
        font-size: 0.18rem;
        border-bottom: 1px solid #EAEAEA;
        padding: 0 0.56rem;

        .bot-left {
            width: 20%;
        }

        .bot-cen {
            width: 50%;
            text-align: left;
        }

        .bot-right {
            width: 20%;
            text-align: center;
        }

        .bot-operate {
            width: 10%;
            color: #02428C;
            text-align: right;
            cursor: pointer;
        }

    }

    .nav {
        margin-top: 0.29rem;
        border-bottom: 1px solid #EAEAEA;

        li {
            margin-right: 0.92rem;
            cursor: pointer;

            .name {
                font-size: 0.18rem;
                color: #202020;


            }

            .line {
                width: 0.8rem;
                height: 0.03rem;
                background: #ffffff;
                margin-top: 0.1rem;
                border-radius: 0.02rem;


            }

        }

        .active {
            .name {
                font-size: 0.18rem;
                color: #02428C;
            }

            .line {
                background: #02428C;
            }

        }

    }
}
</style>