//个人中心
import Personal from '../views/personal/index.vue'
//我的收藏
import Collect from '../views/personal/collect.vue'
//消息
import Msg from '../views/personal/msg.vue'
//编辑个人资料
import EditUserInfo from '../views/personal/editUserInfo.vue'
//我的积分明细
import Integral from '../views/personal/integral.vue'
//我的收益
import Income from '../views/personal/income.vue'
//收益明细
import IncomeDetail from '../views/personal/incomeDetail.vue'
// //实名认证
import Authentication from '../views/personal/authentication.vue'

export default [
  {
    path: '/personal',
    name: 'personal',
    component: Personal,
    meta: {
      title: '个人中心',
    },
  },
  {
    path: '/collect',
    name: 'Collect',
    component: Collect,
    meta: {
      title: '我的收藏',
    },
  },
  {
    path: '/msg',
    name: 'Msg',
    component: Msg,
    meta: {
      title: '消息',
    },
  },
  {
    path: '/editUserInfo',
    name: 'EditUserInfo',
    component: EditUserInfo,
    meta: {
      title: '编辑个人资料',
    },
  },
  {
    path: '/integral',
    name: 'Integral',
    component: Integral,
    meta: {
      title: '我的积分明细',
    },
  },
  {
    path: '/income',
    name: 'Income',
    component: Income,
    meta: {
      title: '我的收益',
    },
  },
  {
    path: '/incomeDetail',
    name: 'IncomeDetail',
    component: IncomeDetail,
    meta: {
      title: '收益明细',
    },
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: Authentication,
    meta: {
      title: '实名认证',
    },
  }

]
