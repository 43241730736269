<!-- 发布任务 -->
<template>
    <div class="release">
        <section class="main">
            <div class="title">{{ add ? $t('task.step1') : $t('task.edit_task') }}</div>
            <div class="desc" v-if="add">{{ $t('feed.task_step') }}</div>
            <ul class="flex-row-center-center steps" v-if="add">
                <li v-for="(item, index) in steps" :key="index" class="flex-row">
                    <div class="block flex-col-center-center">
                        <img :src="index <= type ? item.iconed : item.icon" />
                        <div class="tit" :class="{ 'active-color': index <= type }">{{ item.title }}</div>
                    </div>
                    <div class="line" v-if="index != 4"></div>
                </li>
            </ul>
            <div class="release-form">
                <el-form ref="form" :rules="rules" :model="form" label-position="top">
                    <el-form-item :label="$t('feed.task_title')" prop="title">
                        <el-input v-model="form.title" :placeholder="$t('feed.placeholder20')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('task.category')" prop="category_id">
                        <el-select v-model="form.category_id" :placeholder="$t('feed.placeholder21')" style="width: 100%">
                            <el-option :label="item.name" :value="item.id" v-for="(item, index) in category" :key="index" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('task.content')" prop="content">
                        <el-input :rows="6" :placeholder="$t('feed.placeholder22')" type="textarea"
                            v-model="form.content"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('feed.budget')" prop="budget">
                        <el-col :span="7">
                            <el-input v-model="form.budget" :placeholder="$t('feed.placeholder23')"></el-input>
                        </el-col>
                        <el-col class="line" :span="1">-</el-col>
                        <el-col :span="4">
                            <el-select v-model="form.currency_id" :placeholder="$t('feed.currency')" style="width: 100%">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in currency"
                                    :key="index"></el-option>
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('task.due_time')" prop="due_time">
                        <el-date-picker type="date" :placeholder="$t('feed.placeholder24')" v-model="form.due_time"
                            value-format="yyyy-MM-dd" format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$t('feed.end_time')" prop="end_time">
                        <el-date-picker type="date" :placeholder="$t('feed.placeholder25')"
                            v-model="form.end_time" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                            style="width: 100%;"></el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$t('feed.deliverable')" prop="deliverable">
                        <el-input :rows="6" :placeholder="$t('feed.placeholder26')" type="textarea"
                            v-model="form.deliverable"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('feed.task_label')" prop="label">
                        <el-input v-model="form.label" :placeholder="$t('feed.placeholder27')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('feed.country2')">
                        <el-col :span="5" class="sec" style="position:relative;" v-for="(v, i) in chooseCountry" :key="i">
                            <el-select filterable v-model="v.name" :placeholder="$t('feed.placeholder5')"
                                style="width: 100%">
                                <el-option :label="item.name" :value="item.name" v-for="(item, index) in country"
                                    :key="index"></el-option>
                            </el-select>
                            <div class="del" @click="handleDel(i, 1)">{{ $t('task.del') }}</div>
                        </el-col>
                        <el-col class="line add" :span="4">
                            <div @click="handleAdd(1)">+{{ $t('feed.add_country2') }}</div>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('task.city')">
                        <el-col class="sec" :span="5" style="position:relative" v-for="(v, i) in chooseCity" :key="i">
                            <ViewProvinceCity @getSelectedOptions="getSelectedOptions($event, i)" :value="v" />
                            <div class="del" @click="handleDel(i, 2)">{{ $t('task.del') }}</div>
                        </el-col>
                        <el-col class="line add" :span="4">
                            <div @click="handleAdd(2)">+{{ $t('feed.add_city') }}</div>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('task.industry')">
                        <el-col class="sec" :span="5" style="position:relative" v-for="(v, i) in chooseIndustry" :key="i">
                            <!-- <el-select v-model="v.id" placeholder="选择行业" style="width: 100%">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in industry"
                                    :key="index"></el-option>
                            </el-select> -->
                            <el-cascader style="width: 100%;"
                            filterable
                                :props="{ label: 'name', value: 'id', children: 'childrens', checkStrictly: true }"
                                :placeholder="$t('feed.placeholder28')" v-model="v.ids" :options="industry"></el-cascader>
                            <div class="del" @click="handleDel(i, 3)">{{ $t('task.del') }}</div>
                        </el-col>
                        <el-col class="line add" :span="4">
                            <div @click="handleAdd(3)">+{{ $t('feed.add_industry') }}</div>
                        </el-col>
                    </el-form-item>
                    <div class="release-form-btn" style="cursor: pointer" v-loading="loading"
                        element-loading-spinner="el-icon-loading" @click="submit()">
                        {{ add ? $t('feed.confirm_publish') : $t('feed.confirm_edit') }}
                    </div>
                </el-form>
            </div>
        </section>
    </div>
</template>
<script>
import ViewProvinceCity from '@/components/ViewProvinceCity.vue'
export default {
    components: { ViewProvinceCity },
    data() {
        return {
            add: true,//是否新增
            type: 0,
            //步骤
            steps: [
                {
                    icon: require('@/assets/icons/step1.png'),
                    iconed: require('@/assets/icons/step1.png'),
                    title: this.$t('task.step1'),
                    time: '2023-05-23 18:23:54'
                },
                {
                    icon: require('@/assets/icons/step2.png'),
                    iconed: require('@/assets/icons/steped2.png'),
                    title: this.$t('task.step2'),
                    time: '2023-05-23 18:23:54'
                },
                {
                    icon: require('@/assets/icons/step3.png'),
                    iconed: require('@/assets/icons/steped3.png'),
                    title: this.$t('task.step3'),
                    time: '2023-05-23 18:23:54'
                },
                {
                    icon: require('@/assets/icons/step4.png'),
                    iconed: require('@/assets/icons/steped4.png'),
                    title: this.$t('task.step4'),
                    time: '2023-05-23 18:23:54'
                },
                {
                    icon: require('@/assets/icons/step5.png'),
                    iconed: require('@/assets/icons/steped5.png'),
                    title: this.$t('task.step5'),
                    time: '2023-05-23 18:23:54'
                }
            ],
            category: [],//任务类型
            currency: [],//货币类型
            industry: [],//行业
            country: [],//国家
            city: [],//城市
            chooseCountry: [{ name: '' }],//选择的国家
            chooseCity: [''],//选择的城市
            chooseIndustry: [{ ids: [] }],
            loading: false,
            form: {
                title: '',//任务标题
                category_id: '',//任务类型
                content: '',//任务内容
                budget: '',//任务预算范围
                currency_id: '',//货币类型id
                due_time: null,//任务交付时间
                end_time: null,//任务有效截止时间
                deliverable: '',//任务主要交付内容
                label: '',//任务标签
                country: '',//目标国家   传国家名称   name1,name2
                city: '',//目标城市     传城市名称    name1,name2
                industry_ids: '',//目标行业    传行业id    id1,id2,id3
            },
            rules: {
                title: [{ required: true, message: this.$t('feed.msg13'), trigger: "blur" }],
                category_id: [{ required: true, message: this.$t('feed.placeholder21'), trigger: "blur" }],
                content: [{ required: true, message: this.$t('feed.msg14'), trigger: "blur" }],
                budget: [{ required: true, message: this.$t('feed.msg15'), trigger: "blur" }],
                currency_id: [{ required: true, message: this.$t('feed.msg16'), trigger: "blur" }],
                due_time: [{ required: true, message: this.$t('feed.msg17'), trigger: "blur" }],
                end_time: [{ required: true, message: this.$t('feed.msg18'), trigger: "blur" }],
                label: [{ required: true, message: this.$t('feed.msg19'), trigger: "blur" }],
            },
        };
    },
    created() {
        if (this.$route.query.id) {
            this.add = false
            this.$api.get(`/api/task/${this.$route.query.id}`).then(res => {
                let item = res.data.data
                this.form = {
                    id: item.id,
                    title: item.title,//任务标题
                    category_id:item.category&& item.category.id,//任务类型
                    content: item.content,//任务内容
                    budget: item.budget,//任务预算范围
                    currency_id:item.currency&& item.currency.id,//货币类型id
                    due_time: item.due_time,//任务交付时间
                    end_time: item.end_time,//任务有效截止时间
                    deliverable: item.deliverable,//任务主要交付内容
                    label: item.label,//任务标签
                    // country: item.country?item.country.join(','):'',//目标国家   传国家名称   name1,name2
                    // city: item.city?item.city.join(','):'',//目标城市     传城市名称    name1,name2
                    // industry_ids: item.industry?item.industry.map(item=>item.id).join(','):'',//目标行业    传行业id    id1,id2,id3
                }
                console.log(this.form)
                if (item.industry && item.industry.length) {

                    this.chooseIndustry = item.industry.map(item => {
                        return {
                            ids: [item.id]
                        }
                    })
                }
                if (item.city && item.city.length) {
                    this.chooseCity = item.city

                }
                if (item.country && item.country.length) {
                    this.chooseCountry = item.country.map(item => {
                        return {
                            name: item
                        }
                    })
                }

            })
        }
        //获取数据
        this.fetchData();
    },
    methods: {

        //获取数据
        fetchData() {
            this.$api
                .get("/api/task-category", { paginate: 0 })
                .then((res) => {
                    this.category = res.data
                });
            this.$api
                .get("/api/industry", { paginate: 0 })
                .then((res) => {
                    this.industry = res.data
                });
            this.$api
                .get("/api/currency", { paginate: 0 })
                .then((res) => {
                    this.currency = res.data
                });
            this.$api
                .get("/api/country", { paginate: 0 })
                .then((res) => {
                    this.country = res.data
                });

        },
        //新增
        handleAdd(state) {
            switch (state) {
                case 1:
                    this.chooseCountry.push({ name: '' })
                    console.log(this.chooseCountry)
                    break;
                case 2:
                    this.chooseCity.push('')
                    break;
                case 3:
                    this.chooseIndustry.push({ ids: [] })
                    break;
            }
        },
        //删除
        handleDel(index, state) {
            switch (state) {
                case 1:
                    this.chooseCountry.splice(index, 1)
                    break;
                case 2:
                    this.chooseCity.splice(index, 1)
                    break;
                case 3:
                    this.chooseIndustry.splice(index, 1)
                    break;
            }
        },
        //城市
        getSelectedOptions(event, i) {
            this.chooseCity[i] = event[event.length - 1]
        },
        //提交
        submit() {
            let date1 = new Date(this.form.due_time);
            let date2 = new Date(this.form.end_time);
            if (date1.getTime() < date2.getTime()) {
                this.$message({
                    message: this.$t('auth.tip20'),
                    type: "warning",
                });
                return
            }
            if (this.chooseCountry.length) {
                let arr1 = this.chooseCountry.map(item => item.name)
                this.form.country = arr1.join(',')
            }
            if (this.chooseCity.length) {
                this.form.city = this.chooseCity.join(',')
            }
            if (this.chooseIndustry.length) {
                let arr3 = []
                this.chooseIndustry.forEach(item => {
                    arr3.push(item.ids[item.ids.length - 1])
                })
                this.form.industry_ids = arr3.join(',')
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {

                    this.loading = true

                    this.$api
                        .post("/api/task", this.form)
                        .then(() => {
                            this.$message({
                                message: this.$t('auth.operate_success'),
                                type: "success",
                            });
                            this.$router.push('/publishSuccess')
                            this.loading = false
                        })
                        .catch(() => {
                            this.loading = false
                            // this.$message({
                            //     message: this.$t('auth.operate_fail'),
                            //     type: "warning",
                            // });
                        });
                }
            })
        }
    }
};
</script>
<style scoped lang="scss">
//主体
.main {
    margin: 0.92rem auto;
    display: flex;
    flex-direction: column;
    width: 12rem;
    background-color: #ffffff;
    border-radius: 0.03rem;
    padding: 0.5rem 1rem 0.75rem;
    box-sizing: border-box;


    .title {
        font-size: 0.24rem;
        color: #262423;
    }

    .desc {
        font-size: 0.14rem;
        color: #828282;
        margin-top: 0.06rem;
    }
}

.release-form {
    height: auto;
    background: #ffffff;
    margin-top: 0.2rem;



    p {
        font-size: 0.17rem;


        color: #BCBCBC;
    }

    .tip {
        font-size: 0.14rem;

        color: #B0B0B0;

    }

    .add {
        font-size: 0.16rem;
        color: #02428C;
        // line-height: 0.29rem;
        // margin-top: 0.23rem;
        cursor: pointer;
    }



    .release-form-btn {
        cursor: pointer;
        width: 2.35rem;
        height: 0.58rem;
        background: #02428C;
        border-radius: 0.04rem;
        text-align: center;
        line-height: 0.58rem;
        font-size: 0.16rem;
        font-family: PingFang SC;
        font-size: 0.17rem;
        color: #ffffff;
        margin: 0 auto;

    }
}

.line {
    text-align: center;
}

.steps {
    margin: 0.5rem auto 0;

    li {
        .block {
            width: 1.5rem;

            img {
                width: 0.84rem;
                height: 0.84rem;
            }

            .tit {
                font-size: 0.16rem;
                color: #BCBCBC;
                margin-top: 0.2rem;
                text-align: center;
            }

            .time {
                font-size: 0.16rem;
                color: #BCBCBC;
                line-height: 0.24rem;
                text-align: center;
            }

            .active-color {
                color: #02428C;
            }
        }

        .line {
            width: 1.12rem;
            height: 0.12rem;
            background: #F7F7F7;
            margin-top: 0.36rem;
            margin-left: -0.3rem;
        }

        .active-line {
            background-color: #DBE3F6;
        }


    }

    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4),
    li:nth-child(5) {
        .block {
            margin-left: -0.35rem;

        }
    }
}

.del {
    font-size: 0.14rem;
    color: #587DD7;
    position: absolute;
    right: 0;
    top: -0.35rem;
}

.sec {
    margin-right: 0.1rem;
    margin-bottom: 0.24rem;
}
</style>


