import { render, staticRenderFns } from "./agreement.vue?vue&type=template&id=7bf657ce&scoped=true&"
import script from "./agreement.vue?vue&type=script&lang=js&"
export * from "./agreement.vue?vue&type=script&lang=js&"
import style0 from "./agreement.vue?vue&type=style&index=0&id=7bf657ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bf657ce",
  null
  
)

export default component.exports