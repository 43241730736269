//客服联系方式
<template>
    <div class="apply">
        <el-dialog title="" :visible.sync="show" width="36%" :before-close="handleClose">
            <div class="tit-wrap flex-row-center">
                <div class="line"></div>
                <div class="tit">{{ $t('personal.customer_service') }}</div>
            </div>
            <div class="main flex-row-center-between">
                <div class="left">
                    <!-- <div class="left-item flex-row-center">
                        <img src="@/assets/icons/kf.png" />
                        <div class="left-item-tit">在线客服</div>
                        <div class="btn">联系客服</div>
                    </div> -->
                    <div class="left-item flex-row-center">
                        <img src="@/assets/icons/phone.png" />
                        <div class="left-item-tit">
                            {{$t('personal.phone_number')}}：{{ service_phone.replace(service_phone.substring(3, 7), "****") }}</div>
                        <div class="btn" @click="copy(service_phone)">{{ $t('personal.copy') }}</div>
                    </div>
                </div>
                <div class="right flex-col-center">
                    <img :src="service_qrcode"/>
                    <div class="tip">{{ $t('personal.scan') }}</div>
                </div>

            </div>
            <div class="know-btn" @click="handleClose">{{ $t('feed.know') }}</div>

            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["show"],
    data() {
        return {
            service_phone:'',
            service_qrcode:''
        };
    },
    created(){
        this.getInfo()
    },
    methods: {
         //获取内容
         getInfo() {
            this.$api.get('/api/init/config').then(res => {
                this.service_phone = res.data.service_phone
                this.service_qrcode = res.data.service_qrcode
                
            })
        },
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        //复制
        copy(val) {
            let oInput = document.createElement('input')
            
            oInput.value = val;
            document.body.appendChild(oInput)
            oInput.select() // 选择对象
            document.execCommand("Copy") // 执行浏览器复制命令
            oInput.style.display = 'none'
           this.$message.success(this.$t('personal.copy_success'))
        }
    }

};
</script>
<style scoped lang="scss">
.apply {
    .tit-wrap {
        .line {
            width: 0.04rem;
            height: 0.2rem;
            background: #5077D5;
            margin-right: 0.2rem;
        }

        .tit {
            color: #02428C;
            font-size: 0.18rem;
        }
    }

    .main {
        margin-top: 0.3rem;

        .left {
            .left-item {
                margin-bottom: 0.3rem;

                img {
                    width: 0.4rem;
                }

                .left-item-tit {
                    font-size: 0.18rem;
                    margin: 0 0.12rem;

                }

                .btn {
                    padding: 0 0.2rem;
                    height: 0.36rem;
                    border-radius: 0.04rem;
                    border: 0.01rem solid #02428C;
                    text-align: center;
                    line-height: 0.36rem;
                    font-size: 0.16rem;
                    color: #02428C;
                    cursor: pointer;

                }

            }

        }

        .right {
            margin-top: -0.3rem;

            img {
                width: 1.6rem;
                height: 1.6rem;
                background-color: #efefef;

            }
        }

        .tip {
            color: #262423;
            font-size: 0.16rem;
            margin-top: 0.06rem;
        }
    }

    .know-btn {
        width: 2.2rem;
        height: 0.52rem;
        border-radius: 0.04rem;
        border: 0.01rem solid #979797;
        text-align: center;
        line-height: 0.52rem;
        font-size: 0.16rem;
        color: #848484;
        margin: 0.3rem auto 0;
        cursor: pointer;



    }

}
</style>
  