import Vue from 'vue'
import VueRouter from 'vue-router'
//引入其他模块路由

import authRoutes from './auth.js' //登录
import feedRoutes from './feed.js' //动态
import homeRoutes from './home.js' //首页
import circleRoutes from './circle.js' //圈子
import personalRoutes from './personal.js' //发布
import userRoutes from './user.js' //用户
import activityRoutes from './activity.js' //用户
import taskRoutes from './task.js' //任务

Vue.use(VueRouter)
const routes = [
    ...homeRoutes, //首页
    ...authRoutes, //登录
    ...feedRoutes, //动态
    ...circleRoutes, //圈子
    ...personalRoutes, //个人中心
    ...userRoutes, //用户
    ...activityRoutes, //活动
    ...taskRoutes,//任务

]
const router = new VueRouter({
    routes
})
export default router