<template>
    <div>
        <div class="flex-row-center">
            <div class="block flex-row-center" @click="handleSkip(fuxing)">
                <img class="cover" :src="fuxing.avatar" v-if="fuxing.avatar" />
                <img v-else class="logo" :src="logo" />
                <div class="flex-col">
                    <div class="name">{{ fuxing.name }}</div>
                    <div class="desc">我的福星</div>
                </div>
            </div>
            <div class="block flex-row-center" @click="handleSkip(bole)">
                <img class="cover" :src="bole.avatar" v-if="bole.avatar" />
                <img v-else class="logo" :src="logo" />
                <div class="flex-col">
                    <div class="name">{{ bole.name }}</div>
                    <div class="desc">我的伯乐</div>
                </div>
            </div>

        </div>



        <div class="navber flex-row-center">
            <div class="navber-item" :class="{ 'active-nav': current == 0 }" @click="changeType(0)">{{
                $t('personal.bole_recommend') }}</div>
            <div class="navber-item" :class="{ 'active-nav': current == 1 }" @click="changeType(1)">{{
                $t('personal.fx_recommend') }}</div>
        </div>
        <ul class="users flex-col">
            <li class="flex-row-center-between" v-for="(item, index) in list" :key="index">
                <Avatar size="medium3" :user="item" :showName="true" />
                <div class="focus-btn" @click="handleFollow(0, item.id, index)" v-if="item.has_follow == 0"> {{
                    $t('index.focus') }}</div>
                <div class="focus-btn diff" @click="handleFollow(1, item.id, index)" v-if="item.has_follow == 1"> {{
                    $t('index.focused') }}</div>
            </li>
        </ul>
    </div>
</template>
<script>
import Avatar from "@/components/Avatar";
export default {
    components: { Avatar },
    props: ['list', 'id'],
    data() {
        return {
            current: 0,
            logo: require('@/assets/icons/logo.png'),
            fuxing: {
                name: '希客道',
                avatar: null
            },
            bole: {
                name: '希客道',
                avatar: null
            }


        };
    },
    watch: {
        id: {
            handler(val) {
                this.getInfo()
            },
            deep: true, // 深度监听
            immediate: true, // 初次监听即执行  
        },
    },
    created() {
        this.getInfo()

    },
    mounted() {


    },
    methods: {
        getInfo() {
            this.$api.get(`/api/user-bole?user_id=${this.id}`).then(res => {
                if (res.data.fuxing) {
                    this.fuxing = res.data.fuxing
                }else{
                    this.fuxing = {
                        name: '希客道',
                avatar: null
                    }
                }
                if (res.data.bole) {
                    this.bole = res.data.bole

                }else{
                    this.bole = {
                        name: '希客道',
                avatar: null
                    }
                }
            })

        },
        //导航点击事件
        changeType(current) {
            this.current = current
            this.$emit('changeType', current)
        },
        //跳转
        handleSkip(user) {
            if (user.avatar) {
                this.$router.push(`/personal?id=${user.id}`)
            }

        },
        //关注 取消关注
        handleFollow(state, id, index) {
            if (state == 0) {
                this.$api.post(`/api/follow/user/${id}`).then(() => {
                    this.$emit('handleFocus', { index, has_follow: 1 })
                    this.$message.success(this.$t('circle.focus_success'))
                })
            } else {
                this.$api.delete(`/api/follow/user/${id}`).then(() => {
                    this.$emit('handleFocus', { index, has_follow: 0 })
                    this.$message.success(this.$t('circle.cancel_success'))
                })
            }
        },

    },
};
</script>
<style scoped lang="scss">
.users {

    margin-top: 0.3rem;

    li {
        width: 100%;
        height: 0.98rem;
        background: #FAFAFA;
        border-radius: 0.09rem;
        padding: 0.13rem;
        box-sizing: border-box;
        margin-bottom: 0.2rem;

        .focus-btn {
            width: 1.44rem;
            height: 0.48rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #5F82D8;
            text-align: center;
            line-height: 0.48rem;
            cursor: pointer;
            font-size: 0.16rem;
            color: #5077D5;
        }

        .diff {
            border: 0.01rem solid #9A9A9A;
            color: #9A9A9A;
        }


    }

}

.navber {
    margin: 0.3rem 0 0;

    .navber-item {
        font-size: 0.18rem;
        color: #848484;
        margin-right: 0.72rem;
        cursor: pointer;
    }

    .active-nav {
        color: #262423;
    }
}

.cover {
    width: 0.5rem;
    height: 0.5rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0.1rem;
}

.logo {
    width: 0.5rem;
    margin-right: 0.1rem;
}

.block {
    padding: 0.05rem;
    border-radius: 0.03rem;
    background-color: #f7f7f7;
    margin-top: 0.1rem;
    margin-right: 0.1rem;
    cursor: pointer;
}

.name {
    color: #333;
    font-size: 0.16rem;
}

.desc {
    color: #999;
    font-size: 0.14rem;
    margin-top: 0.05rem;
}
</style>
