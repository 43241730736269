<template>
  <div>
    <div class="list" v-if="list.length">
      <div class="card" :style="index % 2 == 0 ? 'margin-left: 0' : 'margin-right: 0'" v-for="(item, index) in list"
        @click="goDetail(item)" :key="index" style="cursor: pointer">
        <div class="cover">
          <el-image :src="item.cover" fit="cover"></el-image>
        </div>
        <div class="right-box">
          <div class="flex-row-center">
            <div class="state" v-if="item.status == 0">{{ $t('activity.status0') }}</div>
            <div class="state" v-if="item.status == 1">{{ $t('task.ing') }}</div>
            <div class="state" v-if="item.status == 2">{{ $t('activity.status2') }}</div>
            <div class="title x-ell-1">{{ item.title }}</div>
          </div>
          <div class="info">
            <div>
              {{ $t('activity.date') }}：<span>{{ item.start_time }}-{{ item.end_time }}</span>
            </div>
            <div class="x-ell-1 address">
              {{ $t('activity.address') }}：<span>{{
                item.country }}{{ item.state }}{{ item.city }}{{ item.address }}</span>
            </div>
            <div>
              {{ $t('activity.num') }}：<span>{{ item.limit_join_user_count }}人</span>
            </div>
          </div>
          <div class="bottom-info">
            <div class="join-btn" v-if="item.has_join == 1" @click.stop="handleTip">
              {{ $t('activity.joined') }}
            </div>

            <div class="join-btn" @click.stop="apply(item.id, index)" v-else>
                {{ $t('activity.apply_join') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ViewEmpty v-else />
    <!-- 活动报名成功 -->
    <ApplySuccess :show="show" @handleClose="show = false" />
  </div>
</template>
<script>
import ApplySuccess from '@/components/ApplySuccess'
export default {
  name: "ActivityList",
  props: ["list"],
  components: { ApplySuccess },
  data() {
    return {
      show: false,
      loading: false,
      user: this.$storage.getData("userInfo"),
    };
  },
  methods: {
    //跳转到详情页面
    goDetail(item) {
      this.$router.push({
        path: "/activityDetail",
        query: {
          id: item.id,
        },
      });
    },
    //报名
    apply(id, index) {
      if(!this.user){
        this.$router.push('/login')
        return
      }
      this.loading = true
      this.$api.post(`/api/activity-join/${id}`).then(() => {
        this.loading = false
        this.show = true
        this.list[index].has_join = 1
        this.getMember()
      }).catch(() => {
        this.loading = false
        // this.$message.error('报名失败')
      })
    },
    //提示
    handleTip(){
      this.$message.warning(this.$t('activity.tip3'))
    }

  },
};
</script>
<style scoped lang="scss">
.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .card {
    width: 6.48rem;
    height: 4.62rem;
    background: #FFFFFF;
    border-radius: 0.03rem;
    margin-right: 0.24rem;
    margin-top: 0.3rem;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    .cover {
      width: 6.48rem;
      height: 2.88rem;
      background: #D8D8D8;
      border-radius: 0.04rem 0.04rem 0rem 0rem;

      .el-image {
        width: 6.48rem;
        height: 2.88rem;
        border-radius: 0.04rem 0.04rem 0rem 0rem;
        overflow: hidden;
      }
    }

    .right-box {
      margin-top: 0.17rem;
      padding: 0 0.2rem;
      box-sizing: border-box;

      .state {
        width: 0.86rem;
        height: 0.35rem;
        background: #6CBC73;
        border-radius: 0.17rem;
        font-size: 0.14rem;

        color: #FFFFFF;
        line-height: 0.35rem;
        margin-right: 0.15rem;
        text-align: center;

      }

      .title {
        flex: 1;
        font-size: 0.19rem;

        color: #363636;
        line-height: 0.32rem;
      }

      .info {
        font-size: 0.16rem;

        font-weight: 400;
        color: #919191;
        margin-top: 0.2rem;

        div {
          margin-bottom: 0.1rem;
        }

      }



      .join-btn {
        right: 0.2rem;
        bottom: 0.2rem;
        position: absolute;
        width: 1.73rem;
        height: 0.46rem;
        background: #02428C;
        border-radius: 0.23rem;
        line-height: 0.46rem;
        text-align: center;
        cursor: pointer;
        font-size: 0.17rem;


        color: #FFFFFF;
      }


    }
  }

  .card:nth-child(2n) {
    margin-right: 0;
  }
}
.address{
  width: 70%;
}
</style>
