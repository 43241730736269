<!-- 收益详情 -->
<template>
    <div class="detail-wrap">
        <div class="title" v-if="current==0">{{ $t('personal.tit3') }}</div>
        <div class="title" v-if="current==1">{{ $t('personal.tit4') }}</div>
        <div class="title" v-if="current==2">{{ $t('personal.tit5') }}</div>
        <div class="label" v-if="current==0">{{ $t('personal.tit6') }}：</div>
        <div class="label" v-if="current==2">{{ $t('personal.tit7') }}：</div>
        <div class="score" v-if="current==0">{{ item.user_income }}</div>
        <div class="score" v-if="current==1">{{ item.bole_income }}</div>
        <div class="score" v-if="current==2">{{ item.fuxing_income }}</div>
        <div class="label">{{ $t('personal.income_resource') }}：</div>
        <div class="val">{{ item.title }}</div>
        <div class="label" v-if="item.resource.length">{{ $t('personal.project_resource') }}：</div>


        <div class="flex-row-center source-item" v-for="(itemt,index) in item.resource" :key="index" @click="handleSkip(`/sourceDetail?id=${itemt.id}`)">
            <div class="mark" v-if="itemt.type==2">  {{ $t('circle.organ') }}</div>
            <div class="mark" v-if="itemt.type==1">   {{ $t('circle.export') }}</div>
            <div class="val">{{ itemt.title }}</div>
        </div>



        <div class="box">
            <div class="box-top flex-row-center">
                <div class="box-top1">{{ $t('personal.project_user') }}：</div>
                <div class="box-top2" @click="handleSkip(`/personal?id=${item.custom.id}`)">{{ item.user&&item.user.name }}</div>
                <div class="box-top3">{{ $t('personal.income') }}：{{ item.user_income }}</div>
            </div>
            <div class="box-cen flex-row-center">
                <div class="line"></div>
                <div class="box-cen-tit">{{ $t('personal.recommend_income') }}：</div>
            </div>
            <div class="box-bot flex-row-center">
                <div class="box-bot1">{{ $t('personal.bole') }}：</div>
                <div class="box-bot2" @click="handleSkip(`/personal?id=${item.bole.id}`)">{{ item.bole&&item.bole.name }}</div>
                <div class="box-bot3">{{ $t('personal.incomes') }}：{{ item.bole_income }}</div>
            </div>
            <div class="box-bot flex-row-center">
                <div class="box-bot1">{{ $t('personal.fuxing') }}：</div>
                <div class="box-bot2" @click="handleSkip(`/personal?id=${item.fuxing.id}`)">{{ item.fuxing&&item.fuxing.name }}</div>
                <div class="box-bot3">{{ $t('personal.incomes') }}：{{ item.fuxing_income }}</div>
            </div>
        </div>
        <div class="label">{{ $t('personal.project_customers') }}：</div>
            <div class="user" @click="handleSkip(`/personal?id=${item.user.id}`)" style="cursor: pointer;">{{ item.task&&item.task.user.name }}</div>
            <div class="label">{{ $t('personal.complete_time') }}：</div>
            <div class="val">{{ item.complete_date }}</div>
            <div class="btn" @click="handleSkip(`/taskDetail?id=${item.task_id}`)">{{ $t('personal.look_task') }}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            current:0,
            item:{}
        }
    },
    created(){
        const id = this.$route.query.id
        this.current = this.$route.query.current
        this.$api.get(`/api/income/${id}`).then(res=>{
           this.item= res.data
        })
    },
    methods:{
        //跳转
        handleSkip(url){
            this.$router.push(url)
        }
    }
}
</script>
<style lang="scss" scoped>
.detail-wrap {
    width: 13.2rem;
    height: auto;
    background: #FFFFFF;
    border-radius: 0.03rem;
    margin: 0.92rem auto;
    padding: 0 0.5rem 0.5rem;
    box-sizing: border-box;

    .title {
        line-height: 1rem;
        color: #262423;
        font-size: 0.24rem;
        border-bottom: 1px solid #EAEAEA;
    }

    .label {
        color: #8E8E8E;
        font-size: 0.14rem;
        margin: 0.15rem 0;
    }

    .score {

        color: #262423;
        font-size: 0.36rem;

    }

    .val {

        color: #262423;
        font-size: 0.18rem;
    }

    .mark {
        min-width: 0.5rem;
        height: 0.27rem;
        padding: 0 0.1rem;
        background: #FF580F;
        border-radius: 0.14rem;
        text-align: center;
        line-height: 0.27rem;
        font-size: 0.14rem;
        color: #ffffff;
        margin-right: 0.06rem;

    }

    .box {
        margin: 0.15rem 0;
        width: 4.05rem;
        height: 1.58rem;
        background: #FAFAFA;
        border-radius: 0.06rem;
        padding: 0.13rem 0.2rem;
        box-sizing: border-box;

        .box-top {
            .box-top1 {
                color: #262423;
                font-size: 0.16rem;
            }

            .box-top2 {
                color: #02428C;
                font-size: 0.18rem;
                cursor: pointer;
            }

            .box-top3 {
                color: #262423;
                font-size: 0.16rem;
                margin-left: 0.32rem;
            }

        }

        .box-cen {
            margin-top: 0.2rem;
            margin-bottom: 0.1rem;

            .line {
                width: 0.03rem;
                height: 0.14rem;
                background: #02428C;
                margin-right: 0.08rem;


            }

            .box-cen-tit {

                color: #6D6D6D;
                font-size: 0.16rem;

            }


        }

        .box-bot {
            margin-top: 0.05rem;
            color: #6D6D6D;
            font-size: 0.16rem;
            line-height: 0.25rem;

            .box-bot2 {
                color: #02428C;
                margin-right: 0.24rem;
                cursor: pointer;
            }
        }

    }

    .user {
        color: #02428C;
        font-size: 0.18rem;
    }
    .btn{
        width: 2.35rem;
height: 0.58rem;
border-radius: 0.04rem;
border: 0.01rem solid #979797;
text-align: center;
line-height: 0.58rem;
color: #848484;
font-size: 0.16rem;
cursor: pointer;
margin: 0.3rem auto;
    }

}
.source-item{
    margin-bottom: 0.05rem;
    cursor: pointer;
}
</style>
