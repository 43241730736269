
import PublishTask from '../views/feed/publishTask.vue'
import CreateActivity from '../views/feed/createActivity.vue'
import CreateCircle from '../views/feed/createCircle.vue'
import PublishSuccess from '../views/feed/publishSuccess.vue'
export default [
  {
    path: '/feed/activity',
    name: 'CreateActivity',
    component: CreateActivity,
    meta: {
      title: '发布活动',
    },
  },
  {
    path: '/feed/publishTask',
    name: 'PublishTask',
    component: PublishTask,
    meta: {
      title: '发任务',
    },
  },
  {
    path: '/createCircle',
    name: 'CreateCircle',
    component: CreateCircle,
    meta: {
      title: '创建圈子',
    },
  },
  {
    path: '/publishSuccess',
    name: 'PublishSuccess',
    component: PublishSuccess,
    meta: {
      title: '发布成功',
    },
  }
]
