<!-- 登陆 -->
<template>
  <div class="index">
    <!-- 主体部分---登录 -->
    <section v-if="flag == 0" class="main">
      <ul class="nav flex-row-center-between">
        <li @click="change(0)" class="flex-col-center">
          <div class="nav-tit" :class="{ 'active-tit': current == 0 }"> {{ $t('auth.option1') }}</div>
          <div class="line" :class="{ 'active': current == 0 }"></div>
        </li>
        <li @click="change(1)" class="flex-col-center">
          <div class="nav-tit" :class="{ 'active-tit': current == 1 }"> {{ $t('auth.option2') }}</div>
          <div class="line" :class="{ 'active': current == 1 }"></div>
        </li>
        <li @click="change(2)" class="flex-col-center">
          <div class="nav-tit" :class="{ 'active-tit': current == 2 }"> {{ $t('auth.option3') }}</div>
          <div class="line" :class="{ 'active': current == 2 }"></div>
        </li>
      </ul>
      <ul class="con">
        <!-- 邮箱验证码登录 -->
        <li v-if="current == 0">
          <div class="input-item">
            <input :placeholder="$t('auth.placeholder2')" class="input" v-model="form.username" />
          </div>
          <div class="input-item flex-row-center-between">
            <input :placeholder="$t('auth.placeholder4')" class="code-input" v-model="form.code" />
            <div class="vertify" @click="sendCode(1)">{{ title2 }}</div>
          </div>
        </li>
        <!-- 手机验证码登录 -->
        <li v-if="current == 1">
          <div class="input-item flex-row-center-between">
            <el-select placeholder="中国+86" class="select">
              <el-option label="中国+86" value="chinese"></el-option>
            </el-select>
            <input :placeholder="$t('auth.placeholder1')" class="input" :maxlength="11"
              oninput="value=value.replace(/[^\d]/g,'')" v-model="form.username" />
          </div>
          <div class="input-item flex-row-center-between">
            <input :placeholder="$t('auth.placeholder4')" class="code-input" v-model="form.code" />
            <div class="vertify" @click="sendCode(2)">{{ title1 }}</div>
          </div>
        </li>
        <!-- 账号登录 -->
        <li v-if="current == 2">
          <div class="input-item">
            <input :placeholder="$t('auth.placeholder9')" class="input" v-model="form.username" />
          </div>
          <div class="input-item">
            <input :placeholder="$t('auth.placeholder8')" type="password" class="input" v-model="form.password" />
          </div>
          <div class="forget-pwd" @click="handleSkip('/forget')">{{ $t('auth.forget_pwd') }} </div>
        </li>
      </ul>
      <div class="login-btn" @click="handleLogin" v-loading="loading" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.05)">
        {{ $t('index.login') }}
      </div>
      <div class="save-wrap flex-row-center-between">
        <div class="flex-row-center">
          <input type="checkbox" class="checkbox" v-model="checked" @change="changeChecked" />
          <div class="txt"> {{ $t('auth.remember') }}</div>
        </div>
        <div class="sec" @click="regFun">{{ $t('index.reg') }}</div>
      </div>
      <p class="secret  flex-row-center flex-row-wrap">
        <span> {{ $t('auth.tit12') }}</span>
       <a @click="handleSkip(`/agreement?type=xikedao_service_agreement`)">《{{ $t('auth.tit2')
        }}》</a><a @click="handleSkip(`/agreement?type=privacy_agreement`)">《{{ $t('auth.tit3') }}》</a>
      </p>
      <div class="tit-wrap flex-row-center-center">
        <div class="line"></div>
        <div class="tit">{{ $t('auth.tit6') }}</div>
        <div class="line"></div>

      </div>
      <ul class="way flex-row-center-between">
<!--        <g-signin-button-->
<!--          :params="googleSignInParams"-->
<!--          @success="onSignInSuccess"-->
<!--          @error="onSignInError">-->
<!--          Sign in with Google-->
<!--        </g-signin-button>-->
<!--        <li v-for="(item, index) in ways" :key="index" class="flex-col-center" @click="showToast">-->
<!--          <img class="img" fit="cover" :src="item.src" />-->
<!--          <div class="way-tit">{{ item.title }}</div>-->
<!--        </li>-->
      </ul>
    </section>
    <!-- 主体部分---注册 -->
    <section v-if="flag == 1" class="main">
      <ul class="nav flex-row-center-between">

        <li @click="changeReg(0)" class="flex-col-center">
          <div class="nav-tit" :class="{ 'active-tit': current2 == 0 }"> {{ $t('reg.phone_reg') }}</div>
          <div class="line" :class="{ 'active': current2 == 0 }"></div>
        </li>
        <li @click="changeReg(1)" class="flex-col-center">
          <div class="nav-tit" :class="{ 'active-tit': current2 == 1 }"> {{ $t('reg.mail_reg') }}</div>
          <div class="line" :class="{ 'active': current2 == 1 }"></div>
        </li>
      </ul>
      <ul class="con">
        <!-- 手机验证码注册 -->
        <li v-if="current2 == 0">
          <div class="input-item flex-row-center-between">
            <el-select placeholder="中国+86" class="select">
              <el-option label="中国+86" value="chinese"></el-option>
            </el-select>
            <input :placeholder="$t('auth.placeholder1')" class="input" v-model="regParams.phone"  oninput="value=value.replace(/[^\d]/g,'')"/>
          </div>
          <div class="input-item flex-row-center-between">
            <input :placeholder="$t('auth.placeholder4')" class="code-input" v-model="regParams.code" />
            <div class="vertify" @click="sendCode(3)">{{ title3 }}</div>
          </div>
          <div class="input-item">
            <input type="password" :placeholder="$t('reg.placeholder1')" class="input" v-model="regParams.password" />
          </div>
          <div class="input-item">
            <input type="password" :placeholder="$t('reg.placeholder2')" class="input" v-model="regParams.repassword" />
          </div>
        </li>
        <!-- 邮箱验证码注册 -->
        <li v-if="current2 == 1">
          <div class="input-item">
            <input :placeholder="$t('reg.placeholder3')" class="input" v-model="regParams.mail" />
          </div>
          <div class="input-item flex-row-center-between">
            <input :placeholder="$t('reg.placeholder4')" class="code-input" v-model="regParams.code" />
            <div class="vertify" @click="sendCode(4)">{{ title4 }}</div>
          </div>
          <div class="input-item">
            <input type="password" :placeholder="$t('reg.placeholder1')" class="input" v-model="regParams.password" />
          </div>
          <div class="input-item">
            <input type="password" :placeholder="$t('reg.placeholder2')" class="input" v-model="regParams.repassword" />
          </div>
        </li>
      </ul>
      <div class="login-btn" @click="handleReg" v-loading="loading" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.05)">
        {{ $t('index.reg') }}
      </div>
      <div class="save-wrap flex-row-center-between">

        <div class="sec" @click="flag = 0">{{ $t('index.login') }}</div>
      </div>
      <div class="secret flex-row-center flex-row-wrap">
        {{ $t('auth.tit12') }}<a @click="handleSkip(`/agreement?type=xikedao_service_agreement`)">《{{ $t('auth.tit2')
        }}》</a><a @click="handleSkip(`/agreement?type=privacy_agreement`)">《{{ $t('auth.tit3') }}》</a>
      </div>

    </section>





  </div>
</template>
<script>
export default {
  data() {
    return {
      googleSignInParams: {
        client_id: '943997256685-c68rgi7mi2inca4390k2c9o3fr8ojbce.apps.googleusercontent.com'
      },
      checked: false,
      flag: 0,//0登录 1注册
      form: {
        username: '',//用户名
        password: '',//密码登录
        code: '',//验证码登录
        invite_user_id: ''
      },
      current: 2,//登陆方式
      loading: false,//加载效果
      //快捷登录方式
      ways: [
        {
          title: this.$t('auth.way1'),
          src: require('@/assets/icons/way1.png')
        },
        {
          title: this.$t('auth.way2'),
          src: require('@/assets/icons/way2.png')
        },
        {
          title: this.$t('auth.way3'),
          src: require('@/assets/icons/way3.png')
        },
        {
          title: this.$t('auth.way4'),
          src: require('@/assets/icons/way4.png')
        },
        {
          title: 'Facebook',
          src: require('@/assets/icons/way5.png')
        }
      ],
      disabled1: false,
      timer1: null,
      second1: '',
      disabled2: false,
      timer2: null,
      second2: '',
      phoneRule: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
      emailRule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //注册模块参数
      regParams: {
        type: '',//注册类型
        phone: '',//手机号
        mail: '',//邮箱
        code: '',//验证码
        password: '',//密码
        repassword: '',//重复密码
        invite_user_id:''
      },
      current2: 1,
      disabled3: false,
      second3: '',
      timer3: null,
      disabled4: false,
      second4: '',
      timer4: null,

    };
  },
  created() {
    this.form.invite_user_id = this.$route.query.invite_user_id
    this.regParams.invite_user_id = this.$route.query.invite_user_id
    if (localStorage.getItem('username')) {
      this.form.username = localStorage.getItem('username')
    }
    if (localStorage.getItem('password')) {
      this.form.password = localStorage.getItem('password')
    }
    if (localStorage.getItem('checked')) {
      this.checked = localStorage.getItem('checked')
    }
  },
  computed: {
    title1() {
      return this.disabled1 ? `${this.second1} ${this.$t('auth.second')}` : this.$t('auth.get_code')
    },
    title2() {
      return this.disabled2 ? `${this.second2} ${this.$t('auth.second')}` : this.$t('auth.get_code')
    },
    title3() {
      return this.disabled3 ? `${this.second3} ${this.$t('auth.second')}` : this.$t('auth.get_code')
    },
    title4() {
      return this.disabled4 ? `${this.second4} ${this.$t('auth.second')}` : this.$t('auth.get_code')
    },
  },
  methods: {
    onSignInSuccess (googleUser) {
      console.log(googleUser)
    },
    onSignInError (error) {
      console.log('OH NOES', error)
    },
    //注册
    regFun() {
      this.flag = 1
      this.regParams = {
        type: '',//注册类型
        phone: '',//手机号
        mail: '',//邮箱
        code: '',//验证码
        password: '',//密码
        repassword: '',//重复密码
        invite_user_id:this.$route.query.invite_user_id
      }

    },
    //注册方式切换
    changeReg(index) {
      this.current2 = index
      this.regParams.type = index == 0 ? 'phone' : 'mail'

    },
    //显示提示
    showToast() {
      //this.$message.warning(this.$t('auth.tip13'))
    },
    //切换登录方式
    change(state) {
      this.current = state
      this.form.code = ''
    },
    sendCode(state) {
      switch (state) {
        case 1:
          if (this.form.username && this.emailRule.test(this.form.username)) {
            this.$api.post('/api/mail/code/login', { mail: this.form.username }).then(res => {
              this.$message({
                message: this.$t('auth.send_success'),
                center: true,
                type: 'success',
                customClass: 'messageClass'
              });
            })
            let s = 60  //倒计时间
            if (!this.timer2) {
              this.second2 = s
              this.disabled2 = true
              this.timer2 = setInterval(() => {
                if (this.second2 > 0 && this.second2 <= s) {
                  this.second2--
                } else {
                  this.disabled2 = false
                  clearInterval(this.timer2)
                  this.timer2 = null
                }
              }, 1000)
            }
          } else {
            //验证不通过
            this.$message({
              message: this.$t('auth.tip14'),
              center: true,
              type: 'error',
              customClass: 'messageClass'
            });
          }
          break;
        case 2:
          if (this.form.username && this.phoneRule.test(this.form.username)) {
            this.$api.post('/api/sms/code/login', { phone: this.form.username }).then(res => {
              this.$message({
                message: this.$t('auth.send_success'),
                center: true,
                type: 'success',
                customClass: 'messageClass'
              });
            })

            let s = 60  //倒计时间
            if (!this.timer1) {
              this.second1 = s
              this.disabled1 = true
              this.timer1 = setInterval(() => {
                if (this.second1 > 0 && this.second1 <= s) {
                  this.second1--
                } else {
                  this.disabled1 = false
                  clearInterval(this.timer1)
                  this.timer1 = null
                }
              }, 1000)
            }
          } else {
            //验证不通过
            this.$message({
              message: this.$t('auth.tip15'),
              center: true,
              type: 'error',
              customClass: 'messageClass'
            });
          }
          break;
        case 3:
          //手机号注册
          if (this.regParams.phone && this.phoneRule.test(this.regParams.phone)) {
            this.$api.post('/api/account/exist', { account: this.regParams.phone }).then(res => {

              if (res.data && res.data.message) {
                this.$message.warning(res.data.message)

              } else {
                this.$api.post('/api/sms/code/register', { phone: this.regParams.phone }).then(res => {
                  this.$message({
                    message: this.$t('auth.send_success'),
                    center: true,
                    type: 'success',
                    customClass: 'messageClass'
                  });
                })

                let s = 60  //倒计时间
                if (!this.timer3) {
                  this.second3 = s
                  this.disabled3 = true
                  this.timer3 = setInterval(() => {
                    if (this.second3 > 0 && this.second3 <= s) {
                      this.second3--
                    } else {
                      this.disabled3 = false
                      clearInterval(this.timer3)
                      this.timer3 = null
                    }
                  }, 1000)
                }
              }
            })
          } else {

            //验证不通过
            this.$message({
              message: this.$t('auth.tip15'),
              center: true,
              type: 'error',

            });
          }
          break;
        case 4:
          if (this.regParams.mail && this.emailRule.test(this.regParams.mail)) {
            this.$api.post('/api/account/exist', { account: this.regParams.mail }).then(res => {
              if (res.data && res.data.message) {
                this.$message.warning(res.data.message)
              } else {
                this.$api.post('/api/mail/code/register', { mail: this.regParams.mail }).then(res => {
                  this.$message({
                    message: this.$t('auth.send_success'),
                    center: true,
                    type: 'success',
                    customClass: 'messageClass'
                  });
                })
                let s = 60  //倒计时间
                if (!this.timer4) {
                  this.second4 = s
                  this.disabled4 = true
                  this.timer4 = setInterval(() => {
                    if (this.second4 > 0 && this.second4 <= s) {
                      this.second4--
                    } else {
                      this.disabled4 = false
                      clearInterval(this.timer4)
                      this.timer4 = null
                    }
                  }, 1000)
                }

              }


            })

          } else {
            //验证不通过
            this.$message({
              message: this.$t('auth.tip14'),
              center: true,
              type: 'error',
              customClass: 'messageClass'
            });
          }
      }
    },
    //登录
    handleLogin() {
      if (!this.form.username.length) {
        this.$message.warning(this.$t('auth.tip16'))
        return
      }
      if (this.current == 2 && !this.form.password.length) {
        this.$message.warning(this.$t('auth.tip17'))
        return
      }
      if (this.current != 2 && !this.form.code.length) {
        this.$message.warning(this.$t('auth.tip18'))
        return
      }
      this.loading = true
      let params = {
      }
      if (this.current == 2) {
        params = {
          username: this.form.username,
          password: this.form.password,
          invite_user_id:this.form.invite_user_id
        }
      } else {
        params = {
          username: this.form.username,
          code: this.form.code,
          invite_user_id:this.form.invite_user_id

        }
      }
      this.$api.post("/api/login", params).then((res) => {
        this.loading = false
        this.$message.success(this.$t('auth.login_success'))
        this.$storage.setData("access_token", res.data.access_token);
        //获取用户信息
        this.$storage.userInfo();
        this.getUser()
      }).catch(() => {
        this.loading = false
        // this.$message.error(this.$t('auth.login_fail'))
      })
    },
    //注册
    handleReg() {
      if (!this.regParams.password.length) {
        this.$message.warning(this.$t('reg.placeholder1'))
        return
      }
      if (!this.regParams.repassword.length) {
        this.$message.warning(this.$t('reg.placeholder2'))
        return
      }
      if (this.current2 == 0 && this.regParams.phone.length == 0) {
        this.$message.warning(this.$t('reg.tip1'))
        return
      }
      if (this.current2 == 1 && this.regParams.mail.length == 0) {
        this.$message.warning(this.$t('reg.tip2'))
        return
      }
      let rule = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/
      if (!rule.test(this.regParams.password)) {
        this.$message.warning(this.$t('reg.tip7'))
        return
      }
      if (this.regParams.password != this.regParams.repassword) {
        this.$message.warning(this.$t('reg.tip3'))
        return
      }

      let params = {
      }
      if (this.current2 == 0) {
        params = {
          type: 'phone',
          phone: this.regParams.phone,
          code: this.regParams.code,
          password: this.regParams.password,
          repassword: this.regParams.repassword,
          invite_user_id:this.regParams.invite_user_id
        }
      } else {
        params = {
          type: 'mail',
          mail: this.regParams.mail,
          code: this.regParams.code,
          password: this.regParams.password,
          repassword: this.regParams.repassword,
          invite_user_id:this.regParams.invite_user_id

        }
      }
      //验证验证码是否正确
      if (params.type == 'phone') {
        this.$api.post('/api/sms/code-check', { phone: this.regParams.phone, code: this.regParams.code }).then(res => {
          if (res.data) {
            if (res.data.status == 1) {
              this.reg(params)
            } else {
              this.$message.warning(this.$t('reg.tip4'))
            }
          }
        })
      }
      if (params.type == 'mail') {
        this.$api.post('/api/mail/code-check', { mail: this.regParams.mail, code: this.regParams.code }).then(res => {
          if (res.data) {
            if (res.data.status == 1) {
              this.reg(params)
            } else {
              this.$message.warning(this.$t('reg.tip4'))
            }
          }
        })
      }





    },
    //注册
    reg(params) {

      this.$api.post("/api/register", params).then((res) => {
        this.flag = 0
        this.$message.success(this.$t('reg.tip5'))
      })

    },
    //查看用户信息
    getUser() {
      this.$api.get('/api/user').then(res => {
        const user = res.data;
        if (user.avatar && user.avatar.length) {
          const redirect = this.$route.query.redirect;
          this.$router.push(redirect || "/");
        } else {
          this.$router.push('/improveInfo')
        }
      })
    },
    //跳转到首页
    toHome() {
      this.$router.push("/");
    },
    handleSkip(url) {
      this.$router.push(url)
    },
    //记住密码
    changeChecked() {
      if (this.checked) {
        localStorage.setItem('username', this.form.username)
        localStorage.setItem('password', this.form.password)
        localStorage.setItem('checked', true)
      } else {
        localStorage.removeItem('username')
        localStorage.removeItem('password')
        localStorage.removeItem('checked')
      }
    }
  },
};
</script>
<style scoped lang="scss">
//主体
.main {
  width: 9.3rem;
  // height: 8.35rem;
  background: #FFFFFF;
  border-radius: 0.06rem;
  padding: 0.86rem 1rem 0.5rem;
  box-sizing: border-box;
  margin: 0.92rem auto;

  .nav {
    li {
      cursor: pointer;

      .nav-tit {
        font-size: 0.18rem;


        color: #848484;
        line-height: 0.32rem;

      }

      .active-tit {
        font-size: 0.2rem;


        color: #02428C;

      }

      .line {
        width: 1.63rem;
        height: 0.03rem;
        background: #ffffff;
        margin-top: 0.12rem;

      }

      .active {
        background-color: #02428C;

      }
    }
  }

  .con {
    margin-top: 0.58rem;
    width: 100%;

    .input-item {
      width: 100%;
      height: 0.69rem;
      margin-bottom: 0.29rem;

      .select {
        margin-right: 0.09rem;

        ::v-deep .el-input__inner {

          height: 0.69rem;
          line-height: 0.69rem;

        }

      }

      .input {
        width: 100%;
        height: 0.69rem;
        background: #FBFBFB;
        border-radius: 0.04rem;
        outline: none;
        border: none;
        padding-left: 0.26rem;
        box-sizing: border-box;
      }

      .code-input {
        width: 4.12rem;
        height: 0.69rem;
        background: #FBFBFB;
        border-radius: 0.04rem;
        padding-left: 0.26rem;
        box-sizing: border-box;
        outline: none;
        border: none;


      }

      .vertify {
        width: 2.25rem;
        height: 0.69rem;
        background: #02428C;
        border-radius: 0.04rem;
        text-align: center;
        line-height: 0.69rem;
        font-size: 0.16rem;


        color: #FFFFFF;
        cursor: pointer;


      }

    }

    .forget-pwd {
      font-size: 0.17rem;
      color: #828282;
      text-align: right;
      // padding-right: 0.24rem;
      cursor: pointer;
      margin-top: -0.15rem;
    }

  }

  .login-btn {
    margin-top: 0.13rem;
    width: 100%;
    height: 0.69rem;
    background: #02428C;
    border-radius: 0.04rem;
    text-align: center;
    line-height: 0.69rem;
    font-size: 0.16rem;


    color: #FFFFFF;
    cursor: pointer;


  }

  .save-wrap {
    margin-top: 0.23rem;
    cursor: pointer;

    .txt {
      margin-left: 0.13rem;
      font-size: 0.17rem;


      color: #B2B2B2;


    }
  }

  .secret {
    margin-top: 0.19rem;
    font-size: 0.16rem;
    color: #B2B2B2;
    line-height: 0.24rem;
    word-wrap:break-word;



    a {
      color: #5F89FF;
      cursor: pointer;
    }

  }

  .tit-wrap {
    .tit {
      font-size: 0.2rem;
      color: #878787;
      line-height: 0.29rem;
      margin: 0.32rem 0.8rem 0.36rem;



    }

    .line {
      width: 1.89rem;
      height: 1px;
      background-color: #eaeaea;



    }

  }


  .way {
    padding: 0 0.46rem;

    .img {
      width: 0.49rem;
      height: 0.49rem;
      border-radius: 50%;
      cursor: pointer;

    }

    .way-tit {
      font-size: 0.16rem;
      color: #878787;
      line-height: 0.29rem;
      margin-top: 0.04rem;
      cursor: pointer;

    }

  }
}

.sec {
  font-size: 0.17rem;
  color: #828282;
  text-align: right;
  cursor: pointer;
  flex: 1;
  text-align: right;
}
</style>
