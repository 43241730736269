//加圈申请
<template>
    <div class="apply">
        <el-dialog title="" :visible.sync="show" width="8rem" :before-close="handleClose">
            <div class="title">{{ $t('con.join_apply') }}</div>
            <div class="label">
                {{$t('personal.leave_msg')}}（{{$t('con.optional')}}）</div>
            <el-input type="textarea" rows="5" :placeholder="$t('con.placeholder17')" v-model="comment" />
            <div class="btn" @click="handleConfirm">{{ $t('feed.know') }}</div>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["show"],
    data() {
        return {
            comment:''
        };
    },
    methods: {
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        //知道了
        handleConfirm(){
            this.$emit('handleConfirm',this.comment)
        }
    }

};
</script>
<style scoped lang="scss">
.apply {
    .title {
        color: #262423;
        font-size: 0.22rem;
        text-align: center;
    }

    .label {
        margin-top: 0.6rem;
        font-size: 0.16rem;
        color: #282828;
        margin-bottom: 0.1rem;
    }

    .btn {
        width: 2.3rem;
        height: 0.52rem;
        background: #02428C;
        border-radius: 0.04rem;
        font-size: 0.16rem;
        cursor: pointer;
        text-align: center;
        line-height: 0.52rem;
        margin: 0.6rem auto 0;
        color: #ffffff;


    }

}
</style>
  