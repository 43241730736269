<!-- 动态详情 -->
<template>
  <div class="container">
    <div class="card">
      <div class="top-info flex-row-center-between">
        <Avatar size="medium3" :showName="true" :user="card.user" />
      </div>
      <div class="content" :id="'site' + card.id">
        <div>
          <div class="other">
            {{ card.title }}
          </div>
          <div class="feeds-content" ref="detailDom">
            {{ card.content }}<span class="topic" v-for="(item, i) in card.label" :key="i"
              @click="handleLabel(item.label, item.id)">
              {{ item.label }}
            </span>
          </div>
          <div class="link" v-if="card.urls && card.urls.length && card.urls[0].name">
            <a class="link-item flex-row-center" v-for="(item, index) in card.urls" :key="index" target="_blank"
              :href="item.url">
              <img src="@/assets/icons/www.png" />
              <div class="link-name">{{ item.name }}</div>
            </a>
          </div>
          <div v-if="card.image && card.image.length">
            <ImageContainer :images="card.image" />
          </div>
          <div class="video-wrap" v-if="card.video">
            <div class="cover">
              <el-image style="width: 6.36rem; height: 3.59rem" :src="card.video_cover" fit="cover"
                v-if="show"></el-image>
              <img :src="require(`@/assets/icon/play.png`)" alt="" class="play-wrap" @click="handlePlay" v-if="show" />
              <video v-else style="width: 6.36rem; outline: none; " :controls="true" :poster="card.video_cover"
                :src="card.video" ref="videoRef"></video>
            </div>
          </div>
        </div>
      </div>
      <div class="bg" v-if="card.resource && card.resource.length">
        <div class="source-tit flex-row-center">
          <img src="@/assets/icons/list.png" />
          <div class="title">
            {{ $t('circle.source') }} （{{ card.resource.length }}）</div>
        </div>
        <ul class="source-ul flex-row-wrap">
          <li class="flex-row-center" v-for="(item, index) in card.resource" :key="index"
            @click="handleSkip(`/sourceDetail?id=${item.id}`)">
            <img :src="item.image[0]" v-if="item.image && item.image.length" />
            <img v-else />
            <div class="info">
              <div class="x-ell-1 tit">{{ item.title }}</div>
              <div class="flexr-row-center-between">
                <img class="star" src="@/assets/icons/star.png" v-for="itmet in item.level" :key="itmet" />
                <div class="mark" v-if="item.type == 1"> {{ $t('circle.export') }}</div>
                <div class="mark diff" v-if="item.type == 2"> {{ $t('circle.organ') }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="flex-row sec-wrapper" id="comment">
        <div class="time">{{ $t('task.publishat') }}{{ card.created_at }}</div>
      </div>
      <div class="bottom-info">
        <div class="flex-row">
          <div class="flex-row-center pointer mr" @click="handleZan(card.has_like, card.id)">
            <img :src="require(`@/assets/icons/zan${card.has_like == 1 ? 'ed' : ''}.png`)
              " />
            <span v-if="card.like_count">{{ card.like_count }}</span>
          </div>
          <div class="flex-row-center pointer mr">
            <img :src="require(`@/assets/icons/comment.png`)" />
            <span v-if="card.comment_count">{{ card.comment_count }}</span>
          </div>
          <div class="flex-row-center pointer mr" @click="handleShare">
            <img :src="require(`@/assets/icons/fenxiang.png`)" />
            <span>{{ $t('index.share') }}</span>
          </div>
          <div class="flex-row-center pointer" @click="handleCollect(card.has_collect, card.id)">
            <img :src="require(`@/assets/icons/heart${card.has_collect ? 'ed' : ''}.png`)
              " />
            <span>{{ $t('task.collect') }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="sec2">
          <div class="sec2-top flex-row-center-between ">
            <img v-if="user && user.avatar" :src="user.avatar" />
            <img src="@/assets/icons/default.png" v-else />
            <el-input :placeholder="placeholderTxt" class="input" :class="{ 'animate': checked }"
              v-model="commentReq.content" @keyup.enter.native="handleSend" ref="inputRef" />
            <el-button style="background: #02428C;border-color:#02428C ;" type="primary" @click="handleSend">{{
              $t('activity.send') }}</el-button>
          </div>
          <div class="sec2-bot-wrap" v-if="list.length">
            <div class="sec2-bot" v-for='(itemt, index) in list' :key="index">
              <div class="sec2-bot-item flex-row">
                <img :src="itemt.user && itemt.user.avatar" v-if="itemt.user && itemt.user.avatar"
                  @click="handleSkip(`/personal?id=${itemt.user.id}`)" />
                <img src="@/assets/icons/default.png" v-else @click="handleSkip(`/personal?id=${itemt.user.id}`)" />
                <div class="sec2-bot-info">
                  <div class="nickName">{{ itemt.user && itemt.user.name }}</div>
                  <div class="description" v-if="itemt.reply == ''">{{ itemt.content }}</div>
                  <div class="description" v-else>
                    <span class="c333">{{ $t('activity.reply') }}</span>
                    <span class="color">{{ itemt.reply.user.name }}</span>
                    <span class="c333">：</span>
                    {{ itemt.content }}
                  </div>
                  <div class="operate flex-row-center-between">
                    <div class="time">{{ itemt.created_at }}</div>
                    <div class="operate-item flex-row-center">
                      <img @click="handlecommentZan(itemt.has_like, itemt.id, index)" :src="require(`@/assets/icons/zan${itemt.has_like == 1 ? 'ed' : ''}.png`)
                        " />
                      <div @click="handlecommentZan(itemt.has_like, itemt.id, index)" class="txt">{{ itemt.like_count ||
                        $t('activity.zan')
                      }}</div>
                      <a href="#comment" class="flex-row-center"
                        @click="handleReply(card.id, itemt.id, itemt.user.name, itemt.id)">
                        <img src="@/assets/icons/comment.png" />
                        <div class="txt">{{
                          $t('activity.reply') }}</div>
                      </a>
                    </div>
                  </div>
                  <div class="sec2-bot-item flex-row" v-for="(v, i) in itemt.reply_list" :key="i">
                    <img :src="v.user && v.user.avatar" v-if="v.user && v.user.avatar"
                      @click="handleSkip(`/personal?id=${v.user.id}`)" />
                    <img src="@/assets/icons/default.png" v-else @click="handleSkip(`/personal?id=${v.user.id}`)" />
                    <div class="sec2-bot-info">
                      <div class="nickName">{{ v.user && v.user.name }}</div>
                      <div class="description" v-if="v.reply == ''">{{ v.content }}</div>
                      <div class="description" v-else>
                        <span class="c333">{{ $t('activity.reply') }}</span>
                        <span class="color">{{ v.reply.user.name }}</span>
                        <span class="c333">：</span>
                        {{ v.content }}
                      </div>
                      <div class="operate flex-row-center-between">
                        <div class="time">{{ v.created_at }}</div>
                        <div class="operate-item flex-row-center">
                          <img @click.stop="handlecommentZan(v.has_like, v.id, index, i)" :src="require(`@/assets/icons/zan${v.has_like == 1 ? 'ed' : ''}.png`)
                            " />
                          <div @click.stop="handlecommentZan(v.has_like, v.id, index, i)" class="txt">{{ v.like_count ||
                            $t('activity.zan')
                          }}</div>
                          <a class="flex-row-center" href="#comment"
                            @click="handleReply(card.id, itemt.id, v.user.name, v.id)">
                            <img src="@/assets/icons/comment.png" />
                            <div class="txt">{{
                              $t('activity.reply') }}</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分享弹窗 -->
    <SharePopup :show="visible" @handleClose="visible = false" />
  </div>
</template>
<script>
import Avatar from "@/components/Avatar.vue";
import SharePopup from '@/components/SharePopup'
import ImageContainer from "@/components/ImageContainer";
import {copyData} from '@/utils/copy.js'
export default {
  components: {
    Avatar,
    ImageContainer,
    SharePopup
  },
  data() {
    return {
      id: null,
      card: {},
      user: this.$storage.getData("userInfo"),
      userId: '',//当前用户id
      page: 1,
      listQuery: {
        page: 1,
        type: 'post',
        type_id: ''
      },
      list: [],//评论列表
      lastPage: 0,
      commentReq: {
        check_code: '', //重复码
        type: 'post',
        type_id: '',
        content: '', // 评论内容
        reply_id: '',
        index_reply_id: ''
      },
      placeholderTxt: this.$t('activity.tip'),
      visible: false,
      show: true,
      checked: false,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.commentReq.type_id = this.id
      this.listQuery.type_id = this.id
      this.getDetail()
      //获取评论列表
      this.getList()
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    // 监听如果页面发生滚动时
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //标签点击
    handleLabel(label, id) {
      const txt = label.replace(/#/g, "");
      this.$router.push(`/search?label_id=${id}&search=${txt}`)

    },
    //获取详情
    getDetail() {
      this.$api.get(`/api/post/${this.id}`).then(res => {
        this.card = res.data
        // if (this.card) {
        //   if (this.card && this.card.content && this.card.content.length) {
        //     let arr = this.card.content.split('#')
        //     this.$set(this.card, 'con', arr[0])
        //     if (arr && arr.length && arr.length >= 2) {
        //       let newArr = []
        //       arr.forEach((item, index) => {
        //         if (index != 0) {
        //           newArr.push(item)
        //         }
        //       })
        //       this.$set(this.card, 'label', `#${newArr.join('#')}`)
        //     } else {
        //       this.$set(this.card, 'label', ``)
        //     }
        //   }
        // }
      })
    },
    //关注 取消关注
    handleFollow(state, id) {
      if (state == 0) {
        this.$api.post(`/api/follow/user/${id}`).then(() => {
          this.card.user.has_follow = 1
          this.$message.success(this.$t('circle.focus_success'))
        })
      } else {
        this.$api.delete(`/api/follow/user/${id}`).then(() => {
          this.card.user.has_follow = 0
          this.$message.success(this.$t('circle.cancel_success'))
        })
      }
    },
    //删除帖子
    handleDel(id) {
      this.$api.delete(`/api/post/${id}`).then(() => {
        this.$message.success(this.$t('task.del_success'))
        this.$emit('updatePost')
      })
    },
    //点赞 取消点赞
    handleZan(state, id) {
      if (state == 0) {
        // 点赞
        this.card.has_like = 1
        this.card.like_count++
        this.$api.post(`/api/like/post/${id}`)
      } else {
        //取消点赞
        this.card.has_like = 0
        this.card.like_count--
        this.$api.delete(`/api/like/post/${id}`)
      }
    },
    //评论点赞 取消点赞
    handlecommentZan(state, id, index1, index2) {
      if(!this.user){
        this.$router.push('/login')
        return
      }

      if (state == 0) {
        // 点赞
        if (index2 == undefined) {
          this.list[index1].has_like = 1
          this.list[index1].like_count += 1

        } else {

          this.list[index1].reply_list[index2].has_like = 1
          this.list[index1].reply_list[index2].like_count += 1
        }

        this.$api.post(`/api/like/comment/${id}`)
      } else {
        //取消点赞
        if (index2 == undefined) {
          this.list[index1].has_like = 0
          this.list[index1].like_count -= 1
        } else {

          this.list[index1].reply_list[index2].has_like = 0
          this.list[index1].reply_list[index2].like_count -= 1
        }

        this.$api.delete(`/like/comment/${id}`)
      }
    },

    //收藏
    handleCollect(state, id) {
      if (state == 0) {
        // 点赞
        this.card.has_collect = 1
        this.$api.post(`/api/collect/post/${id}`)
      } else {
        //取消点赞
        this.card.has_collect = 0
        this.$api.delete(`/api/collect/post/${id}`)
      }
    },
    //分享
    handleShare() {
      // this.visible = true
      copyData(location.href)
      // this.visible = true
    },
    //跳转
    handleSkip(url) {
      this.$router.push(url)
    },
    //获取评论列表
    getList(page) {
      if (page) this.listQuery.page = page
      this.$api.get('/api/comment', { params: this.listQuery }).then((res) => {
        if (this.listQuery.page == 1) {
          this.list = res.data.data
        } else {
          this.list = [...this.list, ...res.data.data]
        }
        this.lastPage = res.data.meta.last_page

      })
    },
    //回复
    handleReply(type_id, index_reply_id, name, reply_id) {
      if(!this.user){
        this.$router.push('/login')
        return
      }



      this.$refs.inputRef.blur()
      this.commentReq.type_id = type_id
      this.commentReq.reply_id = reply_id
      this.commentReq.index_reply_id = index_reply_id
      this.placeholderTxt = `${this.user.name}${this.$t('activity.reply')}${name}:`
      this.$refs.inputRef.focus()
      this.checked = true
      setTimeout(() => {
        this.checked = false
      }, 2000)
    },
    //发送
    handleSend() {
      if (!this.user) {
        this.$message.warning(this.$t('index.tip2'))
        return
      }
      this.commentReq.check_code = this.user.id + new Date().getTime()
      if (this.commentReq.content == null || this.commentReq.content.length < 2) {
        this.$message.warning(this.$t('activity.tip2'))
        return
      }
      this.$api.post('/api/comment', this.commentReq).then(res => {
        this.$message.success(this.$t('activity.publish_success'))
        this.card.comment_count += 1
        this.commentReq.content = ''
        this.commentReq.reply_id = ''
        this.commentReq.index_reply_id = ''
        this.placeholderTxt = this.$t('activity.tip'),
          setTimeout(() => {
            this.getList(1)
          }, 1000)
      })
    },
    //页面滑到底部调用的方法
    handleScroll() {
      //下面这部分兼容手机端和PC端
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeitht =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
        if (this.listQuery.page <= this.lastPage) {
          this.listQuery.page++
          this.getList()
        }
      }
    },
    //播放
    handlePlay() {
      this.show = false
      setTimeout(() => {
        this.$refs.videoRef.play()
      }, 200)
    }


  },
};
</script>
<style scoped lang="scss">
.container {
  background: #FFFFFF;
  border-radius: 0.03rem;
  width: 13.2rem;
  margin: 0.92rem auto;
  padding: 0.3rem 0.5rem;
  box-sizing: border-box;
}

.card {
  width: 100%;
  padding: 0.2rem 0rem 0.48rem 0rem;
  box-sizing: border-box;

  .top-info {
    display: flex;
    flex-direction: row;
    align-items: center;


    ul {
      display: flex;
      flex-direction: row;
      margin-left: 0.2rem;
      align-items: center;
      margin-top: -0.2rem;

      li {
        font-size: 0.15rem;


        color: #02428C;
        line-height: 0.24rem;
      }

    }

  }

  .content {
    cursor: pointer;


    .feeds-content {
      white-space: pre-line;
      width: 100%;
      height: auto;
      font-size: 0.16rem;
      color: #202020;
      line-height: 0.3rem;
      word-break: break-all;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 5;
      // overflow: hidden;

    }

    .topic {
      color: 02428C;
      color: #02428C;
      font-size: 0.15rem;
      margin-left: 0.1rem;
    }
  }

  .bottom-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 0.4rem;
    color: #999;

    img {
      padding: 0 0.05rem;
      width: 0.24rem;
    }

    div {
      padding: 0 0.09rem;

      &:first-child {
        padding-left: 0;
      }
    }

    &>div:first-child {
      padding-left: 0;
    }

    .more {
      padding: 0 !important;
      margin: 0;

      .click-more {
        padding: 0.1rem 0 0.1rem 0.1rem;
      }
    }

    .el-dropdown {
      padding: 0.05rem;
    }
  }

  .other {

    color: #202020;
    line-height: 0.36rem;
    margin-top: 0.1rem;
    font-size: 0.2rem;


  }

  .circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.1rem;

    .circle-item {
      cursor: pointer;
      padding: 0.08rem 0.15rem;
      background-color: #519fe821;
      color: #519ee8;
      border-radius: 0.05rem;
      margin-left: 0.1rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:first-child {
        margin-left: 0;
      }

      .logo-img {
        width: 0.22rem;
        height: 0.22rem;
        border-radius: 10%;
        margin-right: 0.05rem;
        overflow: hidden;
      }
    }
  }
}

.categories-container {
  .categories-item {
    cursor: pointer;
    padding: 0.1rem 0.12rem;
    margin: 0.05rem;
    display: inline-block;
    border: 0.01rem solid #f3f3f3;
  }
}

.pointer {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;

}

.lookAll {
  color: #519ee8;
  cursor: pointer;
  margin-top: 0.1rem;
}

.video-wrap {
  position: relative;
  padding-bottom: 0.2rem;
  margin-top: 0.1rem;
  width: 6.36rem;
  height: 3.59rem;

  .play-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.8rem;
    height: 0.8rem;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    img {}
  }
}



.sec-wrapper {
  display: flex;
  margin-top: 0.1rem;
  color: #999;

  .time {
    color: #999;
  }

  .adoption {
    margin-right: 0.2rem;
    padding: 0 0.15rem;
    height: 0.2rem;
    line-height: 0.2rem;
    font-size: 0.1rem;
    color: #fff;
    background-color: #519ee8;
    border-radius: 0.2rem;
    cursor: pointer;
  }
}

.source-tit {
  height: 0.56rem;
  line-height: 0.56rem;

  img {
    width: 0.2rem;
    height: 0.2rem;
    margin-right: 0.05rem;
  }

  .title {
    font-size: 0.17rem;
    color: #02428C;

  }
}

.source-ul {
  li {
    width: 3.92rem;
    height: 0.66rem;
    border-radius: 0.03rem;
    border: 0.01rem solid #DDDDDD;
    padding: 0.07rem;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 0.14rem;
    margin-right: 0.14rem;

    img {
      width: 0.5rem;
      height: 0.5rem;
      // background: #D8D8D8;
      border-radius: 0.03rem;
      cursor: pointer;

    }

    .info {
      margin-left: 0.12rem;
      flex: 1;

      .tit {
        font-size: 0.17rem;
        color: #212121;
        line-height: 0.24rem;

      }

      .star {
        width: 0.16rem;
        height: auto;
        margin-right: 0.05rem;



      }


      .mark {
        width: 0.55rem;
        height: 0.26rem;
        background: #FEF0EA;
        border-radius: 0.03rem 0rem 0.03rem 0rem;
        text-align: center;
        line-height: 0.26rem;
        font-size: 0.14rem;
        color: #F17E4C;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .diff {
        color: #3B7DD2;
        background: #E3EFFE;

      }

    }


  }


}

.del-btn {
  width: 0.69rem;
  height: 0.32rem;
  border-radius: 0.03rem;
  border: 0.01rem solid #8A8A8A;
  text-align: center;
  line-height: 0.32rem;
  cursor: pointer;
  color: #8A8A8A;
  font-size: 0.16rem;

}

.sec2 {
  width: 100%;
  background: #FFFFFF;
  padding: 0.3rem 0rem;
  box-sizing: border-box;


  .sec2-top {
    img {
      width: 0.6rem;
      height: 0.6rem;
      background-color: #efefef;
      border-radius: 50%;
      margin-right: 0.29rem;

    }

    .input {
      flex: 1;
      margin-right: 0.1rem;
    }
  }

  .sec2-bot-wrap {
    width: 100%;
    height: auto;
    border-radius: 0.04rem;
    border: 0.01rem solid #E9E9E9;
    padding: 0.3rem;
    box-sizing: border-box;
    margin-top: 0.1rem;

  }

  .sec2-bot {



    .more {
      width: 1.84rem;
      height: 0.5rem;
      background: #F8F8F8;
      border-radius: 0.03rem;
      text-align: center;
      line-height: 0.5rem;
      cursor: pointer;
      font-size: 0.16rem;
      color: #737373;
      margin-top: 0.2rem;

    }

    .look {
      width: 2.42rem;
      height: 0.58rem;
      border-radius: 0.03rem;
      border: 0.01rem solid #5077D5;
      margin: 0.5rem auto;
      font-size: 0.16rem;
      cursor: pointer;
      color: #5077D5;
      text-align: center;
      line-height: 0.58rem;


    }

    .sec2-bot-item {
      margin-top: 0.2rem;
      width: 100%;

      >img {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-color: #efefef;
        margin-right: 0.2rem;
        cursor: pointer;



      }

      .sec2-bot-info {
        flex: 1;

        .nickName {
          color: #828282;
          font-size: 0.18rem;
        }

        .description {

          color: #202020;
          font-size: 0.18rem;
          margin-top: 0.08rem;
        }

        .operate {
          margin-top: 0.1rem;

          .time {
            color: #828282;
            font-size: 0.16rem;
          }

          .operate-item {
            cursor: pointer;

            img {
              width: 0.2rem;
              margin-right: 0.06rem;
            }

            .txt {
              color: #737373;
              font-size: 0.16rem;
              margin-right: 0.2rem;
            }

          }

        }
      }
    }

  }

}

.mr {
  margin-right: 1rem;
}

.link {
  margin-top: 0.14rem;

  .link-item {
    margin-bottom: 0.14rem;
    height: auto;
    background: #FBFBFB;
    border-radius: 0.04rem;
    padding: 0.05rem 0.1rem;
    box-sizing: border-box;

    img {
      width: 0.5rem;
    }

    .link-name {
      font-size: 0.16rem;
      margin-left: 0.2rem;
      font-weight: 500;
      color: #202020;
      line-height: 0.29rem;

    }
  }

}

.c333 {
  color: #333;
}

.color {
  color: #1296db;
  margin: 0 0.06rem;

}

@keyframes shake {
  10% {
    transform: rotate(5deg);
  }

  20% {
    transform: rotate(-2.5deg);
  }

  30% {
    transform: rotate(2.5deg);
  }

  40% {
    transform: rotate(-2.5deg);
  }

  50%,
  100% {
    transform: rotate(0deg);
  }
}

.animate {
  animation: shake 2s infinite;

}
</style>
