//邀请
<template>
    <div class="share">
        <el-dialog title="" :visible.sync="show" width="38%" :before-close="handleClose">
            <div class="title">{{ $t('circle.share') }}</div>
            <div class="desc">{{ $t('con.tit1') }}</div>
            <div class="label">{{ $t('con.tit2') }}</div>
            <div class="val">{{ $t('con.tit3') }}</div>
            <div class="box flex-row-center-between">
                <div class="box-left">{{ url }}</div>
                <div class="box-right" @click="copy(url)">{{ $t('con.tit4') }}</div>
            </div>
            <div class="label">{{ $t('con.tit5') }}</div>
            <div class="flex-col-center-center">
                <div class="code" id="qrcode" ref="qrcode"></div>
                <div class="tip">{{ $t('con.tit6') }}</div>
            </div>
            <div class="btn" @click="handleClose">{{ $t('con.know') }}</div>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
    props: ["show"],
    data() {
        return {
            url: ``
        };
    },
    watch: {
        show(val) {
            this.$nextTick(() => {
                this.url = ''
                this.getConfig()
            })
        }

    },
    created() {


    },
    methods: {
        //获取网站基础配置
        getConfig() {
            this.$api.get('/api/init/config').then(res => {
                let url = res.data.share_url
                let userInfo = this.$storage.getData("userInfo");
                if (userInfo) {
                    this.url = `${url}/#/login?invite_user_id=${btoa(userInfo.id)}`
                    this.qrcodeGenerate()
                }

            })
        },
        //生成二维码
        qrcodeGenerate() {
            if (this.url) {
                document.getElementById('qrcode').innerHTML = "";
                let qrcode = new QRCode(this.$refs.qrcode, {
                    width: 180, // 设置宽度，单位像素
                    height: 180, // 设置高度，单位像素
                    text: this.url // 设置二维码内容或跳转地址
                })
            }

        },
        //关闭
        handleClose() {
         document.getElementById('qrcode').innerHTML = "";
            this.$emit('handleClose')
        },
        //复制
        copy(val) {
            let oInput = document.createElement('input')
            
            oInput.value = val;
            document.body.appendChild(oInput)
            oInput.select() // 选择对象
            document.execCommand("Copy") // 执行浏览器复制命令
            oInput.style.display = 'none'
            this.$message.success(this.$t('con.copy_success_tip'))
        }
    }

};
</script>
<style scoped lang="scss">
.share {
    .title {
        font-size: 0.25rem;
        font-weight: 500;
        color: #262423;
        text-align: center;
    }

    .desc {
        margin-top: 0.1rem;
        font-size: 0.16rem;
        font-weight: 400;
        color: #828282;
        line-height: 0.29rem;
        text-align: center;

    }

    .label {
        margin-top: 0.3rem;
        font-weight: 400;
        color: #262423;
        line-height: 0.29rem;
        font-size: 0.16rem;


    }

    .val {
        font-weight: 400;
        color: #605F5E;
        line-height: 0.29rem;
        font-size: 0.16rem;
        margin-top: 0.1rem;

    }

    .box {
        margin-top: 0.2rem;

        .box-left {
            width: 75%;
            height: 0.52rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #E9E9E9;
            padding-left: 0.3rem;
            box-sizing: border-box;
            color: #393736;
            line-height: 0.52rem;
            overflow: hidden;

        }

        .box-right {
            width: 20%;
            height: 0.52rem;
            background: #02428C;
            border-radius: 0.04rem;
            text-align: center;
            line-height: 0.52rem;
            color: #ffffff;
            font-size: 0.15rem;
            cursor: pointer;

        }

    }

    .code {

        margin-top: 0.3rem;
    }

    .tip {
        color: #262423;
        line-height: 0.29rem;
        font-size: 0.16rem;
        margin-top: 0.2rem;

    }

    .btn {
        width: 2.1rem;
        height: 0.52rem;
        border-radius: 0.04rem;
        border: 0.01rem solid #979797;
        text-align: center;
        line-height: 0.52rem;
        cursor: pointer;
        color: #848484;
        font-size: 0.15rem;
        margin: 0.3rem auto 0;
    }
}
</style>
