<template>
  <div class="index">
    <!-- 主体部分 -->
    <section class="main">
      <!-- 主体左侧 -->
      <div class="main-left">
        <!-- 主体左侧分享最近动态 -->
        <div class="flex-row-center-between share-feed" @click="handlePost">
          <img class="avatar" :src="user.avatar" v-if="user && user.avatar" />
          <img class="avatar" src="@/assets/icons/default.png" v-else />
          <div class="comment" v-if="user">{{ placeholder }}</div>
          <div class="comment" v-else>{{ $t("index.tip") }}</div>
        </div>
        <!-- 主体左侧推荐关注 -->
        <div class="main-left-wrap">
          <!-- 主体左侧导航 -->
          <ul class="main-left-navs">
            <li
              v-for="(item, index) in navs"
              :key="index"
              :class="index == currentIndex ? 'activeNav' : ''"
              @click="onClickItem(index)"
            >
              {{ item.title }}
            </li>
          </ul>
          <!-- 动态列表开始 -->
          <ul
            class="main-left-list"
            ref="homeUl"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.05)"
          >
            <li v-for="(item, i) in list" :key="i">
              <FeedsCard
                :card="item"
                :isHome="true"
                size="medium2"
                @updatePost="getList(1)"
              />
              <!-- 广告位 -->
              <div v-if="i == 1" class="banner">
                <el-carousel height="1.89rem">
                  <el-carousel-item
                    v-for="(item, index) in swiperList"
                    :key="index"
                  >
                    <div
                      class="ban"
                      @click="handleSkip(item.target_address)"
                      :style="{
                        'background-image': 'url(' + item.url + ')',
                      }"
                    ></div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </li>
            <div class="mark" v-if="list.length">
              {{
                listQuery.page <= lastPage ? $t("index.sec1") : $t("index.sec2")
              }}
            </div>
            <div class="mark" v-else>{{ $t("index.sec3") }}</div>
          </ul>
          <!-- 动态列表结束 -->
        </div>
      </div>
      <!-- 主体右侧 -->
      <div class="main-right">
        <my-sidebar :show="true"></my-sidebar>
      </div>
    </section>
    <!-- 发布动态弹窗 -->
    <PostFeed
      :show="show"
      @handleClose="
        show = false;
        getList(1);
      "
    />
    <!-- 引导 -->
    <guide :show="visible" @hide="handleHide" />
  </div>
</template>
<script>
import mySidebar from "@/components/sidebar.vue";
import FeedsCard from "@/components/FeedsCard";
import PostFeed from "@/components/PostFeed";
import guide from "@/components/guide";
export default {
  components: { mySidebar, FeedsCard, PostFeed, guide },
  data() {
    return {
      show: false, //控制弹窗的显示和隐藏
      visible: false, //控制引导弹窗的显示和隐藏
      list: [], //动态列表
      placeholder: "",
      listQuery: {
        page: 1,
        type: "new",
      },
      lastPage: 0, //最后一页
      //导航
      navs: [
        {
          id: 0,
          title: this.$t("index.recommend"),
          value: "all",
        },
        {
          id: 1,
          title: this.$t("index.focus"),
          value: "follow",
        },
      ],
      currentIndex: 0, //当前选中导航
      isLogin: true,
      user: this.$storage.getData("userInfo"),
      swiperList: [], //广告图
      post_title: "",
      post_title_en: "",
    };
  },
  created() {
    //获取轮播图
    this.getBanner();
    //获取动态列表
    this.getList();
    //获取网站基础配置
    this.getConfig();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    if (this.user) {
      this.visible = this.user.is_new == 1 ? true : false;
    }
    window.addEventListener("setItem", () => {
      let userInfo = this.$storage.getData("userInfo");
      if (userInfo) {
        this.userInfo = userInfo;
      }
    });

    // 监听如果页面发生滚动时
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //关闭引导
    handleHide() {
      this.visible = false
      this.$api.post(`/api/user/not-new`).then(()=>{
        this.$storage.userInfo();
      })

    },
    //获取网站基础配置
    getConfig() {
      this.$api.get("/api/init/config").then((res) => {
        this.post_title = res.data.post_title;
        this.post_title_en = res.data.post_title_en;
        if (this.$i18n.locale == "zh") {
          this.placeholder = this.post_title;
        } else {
          this.placeholder = this.post_title_en;
        }
      });
    },
    //页面跳转
    handleSkip(url) {
      this.$router.push(url);
    },
    //获取轮播图
    getBanner() {
      this.$api
        .get(`/api/banner`, {
          address: "index",
        })
        .then((res) => {
          this.swiperList = res.data;
        });
    },
    //发布动态
    handlePost() {
      this.show = this.user ? true : false;
    },
    //导航栏点击事件
    onClickItem(index) {
      //重置当前导航
      this.currentIndex = index;
      this.listQuery.type = this.navs[index].value;
      this.listQuery.page = 1;
      this.getList();
    },
    //获取动态列表
    getList(page) {
      if (page) this.listQuery.page = page;
      if (this.listQuery.page == 1) {
        this.loading = true;
      }
      this.$api.get("/api/post", { params: this.listQuery }).then((res) => {
        if (this.listQuery.page == 1) {
          this.list = res.data.data;
        } else {
          this.list = [...this.list, ...res.data.data];
        }
        this.lastPage = res.data.meta.last_page;
        this.loading = false;
      });
    },
    //页面滑到底部调用的方法
    handleScroll() {
      //下面这部分兼容手机端和PC端
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeitht =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
        if (this.listQuery.page <= this.lastPage) {
          this.listQuery.page++;
          this.getList();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.data-none {
  width: 100%;
  padding: 0.2rem 0;
  text-align: center;
  color: #999;
}

.index {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

//主体
.main {
  width: 13.2rem;
  margin-top: 0.92rem;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .main-left {
    width: 9.96rem;
    display: flex;
    flex-direction: column;

    .share-feed {
      width: 100%;
      height: 1.12rem;
      background: #ffffff;
      border-radius: 0.03rem;
      padding: 0 0.2rem 0 0.32rem;
      box-sizing: border-box;
      cursor: pointer;

      .avatar {
        width: 0.46rem;
        height: 0.46rem;
        border-radius: 50%;
        object-fit: cover;
      }

      .comment {
        width: 8.75rem;
        height: 0.69rem;
        background: #fafafa;
        border-radius: 0.06rem;
        line-height: 0.69rem;
        padding-left: 0.32rem;
        box-sizing: border-box;
        font-size: 0.17rem;

        color: #898989;
      }
    }

    .main-left-wrap {
      margin-top: 0.14rem;
      padding: 0 0.3rem;
      box-sizing: border-box;
      background: #ffffff;

      border-radius: 0.03rem;

      .main-left-navs {
        display: flex;
        flex-direction: row;
        background: #ffffff;
        width: 100%;
        height: 0.86rem;
        line-height: 0.86rem;
        box-sizing: border-box;
        border-bottom: 1px solid #eaeaea;

        li {
          font-size: 0.18rem;
          color: #202020;
          margin-right: 0.65rem;
          cursor: pointer;
        }

        .activeNav {
          color: #02428c;
        }
      }
    }

    //动态列表
    .main-left-list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      padding-bottom: 0.2rem;
      margin-bottom: 0.1rem;
      position: relative;

      ::v-deep ._v-container {
        overflow: visible;
      }

      li {
        width: 100%;
        box-sizing: border-box;
        background: #ffffff;
      }
    }
  }
}

//主体右侧
.main-right {
  width: 3.08rem;
}

.active {
  color: #519ee8;
}

// 用户评论开始
.comments {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0.64rem;
  width: calc(100% - 0.89rem);
  height: auto;
  background: #f8f8f8;
  opacity: 1;
  padding: 0.1rem 0.13rem 0.2rem 0.1rem;

  .comments-item {
    .comments-item-bot-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 0.3rem;

      .comments-item-bot-item-left {
        font-size: 0.12rem;

        span:nth-child(2n + 1) {
          color: #333333;
        }

        span:nth-child(2n) {
          color: #b3b3b3;
        }
      }

      .comments-item-bot-item-right {
        font-size: 0.12rem;
        font-family: Microsoft YaHei;

        color: #519ee8;
      }
    }
  }

  .comments-item:last-child {
    border-bottom: 0;
  }
}

.comments-more {
  font-size: 0.12rem;
  font-family: Microsoft YaHei;

  line-height: 0.3rem;
  color: #519ee8;
}

//用户评论结束
.animate {
  animation: mymove 1s;
}

@keyframes mymove {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// 话题标签
.topics {
  display: flex;
  flex-direction: row;

  label {
    display: inline-block;
    height: 0.25rem;
    line-height: 0.25rem;
    font-size: 0.14rem;
    margin-right: 0.1rem;
    padding: 0 0.1rem;
    background: rgba(0, 188, 255, 0.1);
    color: #519ee8;
    border-radius: 0.03rem;
    cursor: pointer;
  }
}

.banner {
  width: 100%;
  height: 1.89rem;
  background: #f5f5f5;
  border-radius: 0.03rem;
  margin-top: -0.01rem;

  .ban {
    width: 100%;
    height: 1.89rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #abcdef;
    border-radius: 0.03rem;
    overflow: hidden;
    cursor: pointer;
  }
}

.mark {
  text-align: center;
  font-size: 0.15rem;
  color: #979da4;
  margin-top: 0.2rem;
}
</style>
