<!-- 我的任务 -->
<template>
    <div class="box">
        <div class="main">
            <div class="flex-row-center">
                <div class="title" @click="onClick(0)" :class="{ 'active-title': currentIndex == 0 }">{{ $t('task.publish')
                }} </div>
                <div class="title" @click="onClick(1)" :class="{ 'active-title': currentIndex == 1 }">{{ $t('task.accept')
                }} </div>
            </div>
            <ul class="nav flex-row-center">
                <li @click="changeNav(index)" :class="{ 'active': current == index }" v-for="(item, index) in navs"
                    class="flex-col-center">
                    <div class="name flex-row-center">
                        <span>{{ item.title }}</span>
                        <span v-if="item.count">({{ item.count }})</span>

                    </div>
                    <div class="line"></div>
                </li>
            </ul>
            <!-- 我发布的 -->
            <div v-if="currentIndex == 0 && listData.length">
                <div class="task-item" v-for="(item, index) in listData" :key="index">
                    <div class="task-head flex-row-center-between">
                        <div class="task-head-left">
                            <div class="task-state state1" v-if="item.status == 4 || item.status == 5">{{ $t('task.tasked')
                            }}
                            </div>
                            <div class="task-state state2" v-if="item.status == 0">{{ $t('task.tip2') }}</div>
                            <div class="task-state state3" v-if="item.status == 1">{{ $t('task.tip3') }}</div>
                            <div class="task-state state4" v-if="item.status == 2">{{ $t('task.task_status') }}：{{
                                $t('task.status3') }}</div>

                            <div class="task-bian">{{ $t('task.taskno') }}：{{ item.id }}</div>
                        </div>
                        <div class="task-head-right">
                            <div class="time">{{ $t('task.end_time') }}：{{ item.end_time }}</div>
                            <!-- <div class="time-diff">{{ $t('task.end_time') }}：已过期</div> -->
                        </div>
                    </div>
                    <div class="task-info" @click="handleSkip(`/taskDetail?id=${item.id}`)">
                        <div class="title">{{ item.title }}</div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task1.png" />
                            <div class="label-right">{{ $t('task.content') }}：</div>
                        </div>
                        <div class="desc">
                            {{ item.content }}</div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task2.png" />
                            <div class="label-right">{{ $t('task.deliverable') }}：</div>
                        </div>
                        <div class="desc">
                            {{ item.deliverable }}
                        </div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task3.png" />
                            <div class="label-right">{{ $t('task.category') }}：</div>
                            <div class="label-main">{{ item.category && item.category.name }}</div>
                            <img src="@/assets/icons/task4.png" />
                            <div class="label-right">{{ $t('task.budget') }}：</div>
                            <div class="label-main">{{ item.budget }}</div>
                            <img src="@/assets/icons/task5.png" />
                            <div class="label-right">{{ $t('task.due_time') }}：</div>
                            <div class="label-main">{{ item.due_time }}{{ $t('task.front') }}</div>
                        </div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task6.png" />
                            <div class="label-right">{{ $t('task.country') }}：</div>
                            <div class="label-main" v-if="item.country && item.country.length && item.country[0] != ''">
                                <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.country" :key="i">{{ itemt
                                }}</span>
                            </div>
                            <div class="label-main" v-else>
                                {{ $t('index.no_limit') }}
                            </div>
                        </div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task7.png" />
                            <div class="label-right">{{ $t('task.city') }}：</div>
                            <div class="label-main" v-if="item.city && item.city.length && item.city[0] != ''"> <span
                                    style="margin-right: 0.1rem;" v-for="(itemt, i) in item.city" :key="i">{{ itemt
                                    }}</span>
                            </div>
                            <div class="label-main" v-else>
                                {{ $t('index.no_limit') }}
                            </div>
                        </div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task8.png" />
                            <div class="label-right">{{ $t('task.industry') }}：</div>
                            <div class="label-main" v-if="item.industry && item.industry.length">
                                <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.industry" :key="i">{{
                                    itemt.name }}</span>
                            </div>
                            <div class="label-main" v-else>
                                {{ $t('index.no_limit') }}
                            </div>
                        </div>
                    </div>
                    <div class="sec-tit">{{ $t('task.task_user') }}</div>
                    <ul class="users flex-row-wrap flex-row-center-between">
                        <li v-for="(itemt, i) in item.task_user" class="flex-row-center">
                            <Avatar size="medium3" :user="itemt.user" :showName="true" />
                        </li>
                    </ul>
                    <div class="task-operate flex-row-center-between">
                        <div class="timer">{{ $t('task.publishat') }} {{ item.created_at | dateFormat }}</div>
                        <div class="btn flex-row-center">
                            <div class="common" @click.stop="handleSkip(`/taskDetail?id=${item.id}`)"> {{
                                $t('task.task_detail') }}</div>
                            <div class="common" v-if="item.status == 0"
                                @click.stop="handleSkip(`/feed/publishTask?id=${item.id}`)">{{ $t('task.edit_task') }}</div>
                            <div class="common diff" v-if="item.status == 0" @click.stop="handleCancel(item.id)">{{
                                $t('task.cancel_task') }}</div>
                            <div class="common diff" v-if="item.status == 2" @click.stop="handleDel(item.id)">{{
                                $t('task.del') }}</div>



                            <div class="common" @click="handleConfirm(item.id)" v-if="item.status == 2">
                                {{ $t('task.confirm_finish') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewEmpty v-if="currentIndex == 0 && listData.length == 0" />
            <!-- 我接单的 -->
            <div v-if="currentIndex == 1 && listData.length">
                <div class="task-item" v-for="(item, index) in listData" :key="index">
                    <div class="task-head flex-row-center-between">
                        <div class="task-head-left">
                            <!-- <div class="task-state state1" v-if="item.status == 1">任务状态：我已申请接单</div> -->
                            <div class="task-state state2" v-if="item.task && item.task.status == 0">{{
                                $t('task.tip2')
                            }}：{{ $t('task.tip6') }}</div>
                            <div class="task-state state3" v-if="item.task && item.task.status == 1">{{ $t('task.tip3') }}
                            </div>
                            <div class="task-state state4" v-if="item.task && item.task.status == 2">{{
                                $t('task.task_status') }}：{{
        $t('task.status3') }}</div>
                            <div class="task-state state1"
                                v-if="item.task && item.task.status == 4 || item.task && item.task.status == 5">{{
                                    $t('task.tasked') }}
                            </div>
                            <div class="task-bian">{{ $t('task.taskno') }}：{{ item.task && item.task.id }}</div>
                        </div>
                        <div class="task-head-right flex-row-center">
                            <div class="publisher">{{ $t('task.publisher') }}：</div>
                            <img :src="item.task && item.task.user && item.task.user.avatar" />
                            <div class="nickName">{{ item.task && item.task.user && item.task.user.name }}</div>
                        </div>
                    </div>
                    <div class="task-info" @click="handleSkip(`/taskDetail?id=${item.task.id}&id2=${item.id}`)">
                        <div class="title">{{ item.task && item.task.title }}</div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task1.png" />
                            <div class="label-right">{{ $t('task.content') }}：</div>
                        </div>
                        <div class="desc">
                            {{ item.task && item.task.content }}</div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task2.png" />
                            <div class="label-right">{{ $t('task.deliverable') }}：</div>
                        </div>
                        <div class="desc">
                            {{ item.task && item.task.deliverable }}
                        </div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task3.png" />
                            <div class="label-right">{{ $t('task.category') }}：</div>
                            <div class="label-main">{{ item.task && item.task.category && item.task.category.name }}</div>
                            <img src="@/assets/icons/task4.png" />
                            <div class="label-right">{{ $t('task.budget') }}：</div>
                            <div class="label-main">{{ item.task && item.task.budget }}</div>
                            <img src="@/assets/icons/task5.png" />
                            <div class="label-right">{{ $t('task.due_time') }}：</div>
                            <div class="label-main">{{ item.task && item.task.due_time }}{{ $t('task.front') }}</div>
                        </div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task6.png" />
                            <div class="label-right">{{ $t('task.country') }}：</div>
                            <div class="label-main"
                                v-if="item.task && item.task.country && item.task.country.length && item.task.country[0] != ''">
                                <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.task.country" :key="i">{{
                                    itemt
                                }}</span>
                            </div>
                            <div class="label-main" v-else>
                                {{ $t('index.no_limit') }}
                            </div>
                        </div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task7.png" />
                            <div class="label-right">{{ $t('task.city') }}：</div>
                            <div class="label-main"
                                v-if="item.task && item.task.city && item.task.city.length && item.task.city[0] != ''">
                                <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.task.city" :key="i">{{ itemt
                                }}</span>
                            </div>
                            <div class="label-main" v-else>
                                {{ $t('index.no_limit') }}
                            </div>
                        </div>
                        <div class="flex-row-center label">
                            <img src="@/assets/icons/task8.png" />
                            <div class="label-right">{{ $t('task.industry') }}：</div>
                            <div class="label-main" v-if="item.task && item.task.industry && item.task.industry.length">
                                <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.task.industry" :key="i">{{
                                    itemt.name }}</span>
                            </div>
                            <div class="label-main" v-else>
                                {{ $t('index.no_limit') }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="sec-tit">{{ $t('task.task_user') }}</div>
                    <ul class="users flex-row-wrap flex-row-center-between">
                        <li v-for="(itemt, i) in item.task_user" class="flex-row-center">
                            <Avatar size="medium3" :user="itemt.user" :showName="true" />
                        </li>
                    </ul> -->
                    <div class="task-operate flex-row-center-between">
                        <div class="timer">{{ $t('task.publishat') }} {{ item.created_at | dateFormat }}</div>
                        <div class="btn flex-row-center">
                            <div class="common" @click="handleSkip(`/taskDetail?id=${item.task.id}&id2=${item.id}`)"> {{
                                $t('task.task_detail')
                            }}</div>
                            <div class="common diff" v-if="item.status == 0" @click="handleCancelTask(item.id)">{{
                                $t('task.cancel_apply') }}</div>
                            <div class="common diff" v-if="item.status == 2" @click="handleDelete(item.id)">{{
                                $t('task.del') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewEmpty v-if="currentIndex == 1 && listData.length == 0" />
        </div>
    </div>
</template>
<script>
import { MessageBox } from 'element-ui';
import Avatar from "@/components/Avatar";
export default {
    components: { Avatar },
    name: "myTask",
    data() {
        return {
            currentIndex: 0,
            navs: [],
            navs1: [
                {
                    title: this.$t('task.all'),
                    status: '',
                    count: 0,
                },
                {
                    title: this.$t('task.status1'),
                    status: 1,
                    count: 0
                },
                {
                    title: this.$t('task.ing'),
                    status: 2,
                    count: 0
                },
                {
                    title: this.$t('task.completed'),
                    status: 4,
                    count: 0
                },

            ],
            navs2: [
                {
                    title: this.$t('task.all'),
                    status: '',
                    count: 0,
                },
                {
                    title: this.$t('task.applyed'),
                    status: 0,
                    count: 0
                },
                {
                    title: this.$t('task.ing'),
                    status: 2,
                    count: 0
                },
                {
                    title: this.$t('task.completed'),
                    status: 4,
                    count: 0
                },
            ],
            current: 0,//当前导航
            //查询条件
            formInline: {
                page: 1,
                type: 'my-publish',
                status: ''

            },
            listData: [],
            statistic: {
                publish: {
                    all: 0,
                    complete: 0,
                    has_apply: 0,
                    ing: 0
                },
                join: {
                    all: 0,
                    complete: 0,
                    has_apply: 0,
                    ing: 0
                }
            }

        };
    },
    created() {
        this.getList();
        this.getStatistic();
    },
    methods: {
        //确认完成
        handleConfirm(id) {
            MessageBox.confirm(this.$t('task.tip8'), this.$t('task.tit2'), {
                confirmButtonText: this.$t('task.confirm'),
                cancelButtonText: this.$t('task.cancel'),
                type: 'warning'
            }).then(() => {
                this.$api.get(`/api/task-complete/${id}`).then(res => {
                    this.getList();
                    this.getStatistic();
                    this.$message.success(this.$t('auth.operate_success'))
                })

            }).catch(() => {
            });

        },
        //撤销任务
        handleCancel(id) {
            this.$api.post('/api/task/revoke', { task_id: id }).then(() => {
                this.$message.success(this.$t('task.cancel_success'))
            }).catch(() => {
                this.$message.error(this.$t('task.cancel_fail'))
            })

        },
        //删除任务
        handleDel(id) {
            this.$api.delete(`/api/admin/task/${id}`).then(() => {
                this.$message.success(this.$t('task.del_success'))
                this.getList(1)
            }).catch(() => {
                this.$message.error(this.$t('task.del_fail'))

            })

        },
        //大导航点击事件
        onClick(index) {
            this.currentIndex = index
            this.formInline.type = index == 0 ? 'my-publish' : 'my-accept'
            this.formInline.page = 1
            this.getList()
            this.currentIndex == 0 ? this.navs = this.navs1 : this.navs = this.navs2
        },
        //导航点击事件
        changeNav(index) {
            this.current = index
            this.formInline.status = this.navs[index].status
            this.getList(1)

        },
        //获取列表
        getList(page) {
            if (page) this.formInline.page = page
            this.$api.get('/api/user-task', { params: this.formInline }).then(res => {
                let result = res.data.data
                this.lastPage = res.data.meta.last_page
                if (this.formInline.page == 1) {
                    this.listData = result
                } else {
                    if (result.length) {
                        this.listData = [...this.listData, ...result]
                    }
                }
            })
        },
        //统计
        getStatistic() {
            this.$api.get('/api/task/statistic').then(res => {
                this.statistic = res.data;
                this.navs1[0].count = this.statistic.publish.all
                this.navs1[1].count = this.statistic.publish.has_apply
                this.navs1[2].count = this.statistic.publish.ing
                this.navs1[3].count = this.statistic.publish.complete
                this.navs2[0].count = this.statistic.join.all
                this.navs2[1].count = this.statistic.join.has_apply
                this.navs2[2].count = this.statistic.join.ing
                this.navs2[3].count = this.statistic.join.complete
                this.currentIndex == 0 ? this.navs = this.navs1 : this.navs = this.navs2

            })
        },
        handleScroll() {
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            var windowHeitht =
                document.documentElement.clientHeight || document.body.clientHeight;
            var scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;
            if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
                if (this.formInline.page <= this.lastPage) {
                    this.formInline.page++;
                    this.getList()
                }
            }
        },
        //跳转
        handleSkip(url) {
            this.$router.push(url)
        },
        //取消申请
        handleCancelTask(id) {
            this.$api.post(`/api/task-cancel/${id}`).then(() => {
                this.$message.success(this.$t('task.cancel_success'))
                this.getList(1)
            }).catch(() => {
                this.$message.success(this.$t('task.cancel_fail'))

            })

        },
        //删除任务申请
        handleDelete(id) {
            this.$api.delete(`/api/task-cancel/${id}`).then(() => {
                this.$message.success(this.$t('task.del_success'))
                this.getList(1)
            }).catch(() => {
                this.$message.error(this.$t('task.del_fail'))

            })

        },
    }
};
</script>
<style scoped lang="scss">
.box {
    .main {
        margin: 1.09rem auto 0.5rem;
        width: 13.2rem;
        padding: 0.37rem 0.5rem;
        box-sizing: border-box;
        background-color: #ffffff;

        .title {
            font-size: 0.24rem;
            color: #848484;
            line-height: 0.4rem;
            margin-right: 0.9rem;
            cursor: pointer;

        }

        .active-title {
            color: #262423;
        }

        .nav {
            margin-top: 0.29rem;
            border-bottom: 1px solid #EAEAEA;

            li {
                margin-right: 0.92rem;
                cursor: pointer;

                .name {
                    font-size: 0.18rem;
                    color: #202020;


                }

                .line {
                    width: 0.39rem;
                    height: 0.03rem;
                    background: #ffffff;
                    margin-top: 0.1rem;
                    border-radius: 0.02rem;


                }

            }

            .active {
                .name {
                    font-size: 0.18rem;
                    color: #02428C;
                }

                .line {
                    background: #02428C;
                }

            }

        }

    }
}

.task-item {
    height: auto;
    border-radius: 0.04rem;
    border: 0.01rem solid #D8D8D8;
    margin-top: 0.3rem;
    padding-bottom: 0.3rem;

    .task-head {
        width: 100%;
        height: 1.11rem;
        padding: 0 0.26rem;
        box-sizing: border-box;
        border-bottom: 1px solid #EAEAEA;

        .task-head-left {
            .task-state {
                font-size: 0.2rem;

                color: #54BE13;
                line-height: 0.29rem;

            }

            .state1 {
                color: #54BE13;
            }

            .state2 {
                color: #02428C;
            }

            .state3 {
                color: #02428C;
            }

            .state4 {
                color: #202020;
            }

            .different {
                color: #E39B4B;
            }

            .task-bian {
                font-size: 0.17rem;

                color: #848484;
                margin-top: 0.07rem;

            }
        }

        .task-head-right {

            .publisher {
                font-size: 0.17rem;

                color: #848484;

            }

            img {
                margin: 0 0.14rem;
                width: 0.49rem;
                height: 0.49rem;
                background: #D8D8D8;
                border-radius: 50%;
            }

            .nickName {
                font-size: 0.23rem;

                color: #2B2B2B;

            }

            .time {
                min-width: 3.57rem;
                padding: 0 0.1rem;
                height: 0.49rem;
                background: #E6ECFB;
                border-radius: 0.04rem;
                text-align: center;
                line-height: 0.49rem;
                font-size: 0.17rem;
                color: #02428C;
            }

            .time-diff {
                width: 2.29rem;
                height: 0.49rem;
                background: #EDEDED;
                border-radius: 0.04rem;
                text-align: center;
                line-height: 0.49rem;
                color: #8F8F8F;
                font-size: 16rem;

            }
        }


    }

    .task-info {
        width: 100%;
        height: auto;
        padding: 0.3rem 0.26rem;
        box-sizing: border-box;
        border-bottom: 1px solid #EAEAEA;
        cursor: pointer;

        .title {
            font-size: 0.21rem;

            color: #202020;

        }

        .label {
            margin-top: 0.1rem;

            img {
                width: 0.18rem;

                margin-right: 0.1rem;

            }

            .label-right {
                font-size: 0.18rem;
                color: #02428C;

            }

            .label-main {
                font-size: 0.18rem;
                color: #828282;
                margin-right: 0.37rem;
            }

        }

        .desc {
            font-size: 0.18rem;
            color: #828282;
            line-height: 0.29rem;
            margin-top: 0.1rem;

        }


    }

    .task-operate {
        padding-left: 0.26rem;
        padding-right: 0.26rem;
        padding-top: 0.3rem;
        border-top: 1px solid #EAEAEA;

        .timer {

            color: #8A8A8A;
            font-size: 0.16rem;
        }



        .btn {
            .common {
                width: 1.73rem;
                height: 0.5rem;
                border-radius: 0.04rem;
                border: 0.01rem solid #02428C;
                text-align: center;
                line-height: 0.5rem;
                cursor: pointer;
                font-size: 0.17rem;
                color: #02428C;
                margin-left: 0.3rem;
            }

            .diff {
                border: 0.01rem solid #979797;
                color: #8B8B8B;

            }
        }
    }



}

.sec-tit {
    font-size: 0.17rem;
    color: #202020;
    margin: 0.3rem;
}



.users {
    padding: 0 0.3rem;

    li {
        width: 48%;
        height: 0.98rem;
        background: #FAFAFA;
        border-radius: 0.09rem;
        padding: 0.13rem;
        box-sizing: border-box;
        margin-bottom: 0.2rem;


    }

}</style>
