//置顶他的资源
<template>
    <div class="source-popup">
        <el-dialog :title="`${ $t('circle.top')}(${list.length})`" :visible.sync="show" width="6.5rem" :before-close="handleClose">
            <ul class="source-ul flex-row-wrap">
                <li class="flex-row-center-between" v-for="(item, index) in list" :key="index">
                    <div class="source-div flex-row-center">
                        <img class="avatar" :src="item.image && item.image[0]" />
                        <div class="info">
                            <div class="x-ell-1 tit">{{ item.title }}</div>
                            <div class="flexr-row-center-between">
                                <img class="star" src="@/assets/icons/star.png" v-for="(itemt) in item.level" :key="itemt" />
                                <div class="mark" v-if="item.type ==1">  {{ $t('circle.export') }}</div>
                                <div class="mark diff" v-else>  {{ $t('circle.organ') }}</div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="btn" @click="handleCancel(item.id,index)" v-if="item.is_to_top==1 ||item.has_top==1">{{ $t('circle.cancel_top') }}</div>
                    <div class="btn" @click="handleTop(item.id,index)" v-if="item.is_to_top==0 ||item.has_top==0">{{ $t('circle.to_top') }}</div>
                </li>
            </ul>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["show", "list",'id'],
    data() {
        return {};
    },
    methods: {
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        //取消置顶
        handleCancel(id,index){
            this.$api.post(`/api/untop/group/resource`,{resource_ids:[id],group_id:this.id}).then(()=>{
                this.$emit('handleUntop',index)
            })
        },
        //置顶
        handleTop(id,index){
            this.$api.post(`/api/top/group/resource`,{resource_ids:[id],group_id:this.id}).then(()=>{
                this.$emit('handletop',index)
            })
        }
    }
};
</script>
<style scoped lang="scss">
.source-popup {
    .source-ul {
        li{
            width: 100%;
        }
        .source-div {
            width: 3.92rem;
            height: 0.66rem;
            border-radius: 0.03rem;
            border: 0.01rem solid #DDDDDD;
            padding: 0.07rem;
            box-sizing: border-box;
            position: relative;
            margin-bottom: 0.14rem;
            margin-right: 0.14rem;

            .avatar {
                width: 0.5rem;
                height: 0.5rem;
                background: #D8D8D8;
                border-radius: 0.03rem;

            }

            .info {
                margin-left: 0.12rem;
                flex: 1;

                .tit {
                    font-size: 0.17rem;
                    color: #212121;
                    line-height: 0.24rem;

                }

                .star {
                    width: 0.16rem;
                    height: auto;
                    background-color: none;
                    margin-right: 0.04rem;
        
                    

                }

                .mark {
                    width: 0.55rem;
                    height: 0.26rem;
                    background: #FEF0EA;
                    border-radius: 0.03rem 0rem 0.03rem 0rem;
                    text-align: center;
                    line-height: 0.26rem;
                    font-size: 0.14rem;
                    color: #F17E4C;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                .diff {
                    color: #3B7DD2;
                    background: #E3EFFE;

                }

            }


        }
    

    }
    .btn {
        width: 1.3rem;
        height: 0.33rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #00428B;
        text-align: center;
        line-height: 0.33rem;
        cursor: pointer;
        font-size: 0.16rem;
        color: #00428B;

    }

}
</style>
  