import activity from '../views/activity/index.vue'
import create from '../views/feed/createActivity.vue'
// 活动详情
import ActivityDetail from '../views/activity/activityDetail.vue'

export default [
  {
    path: '/activity',
    name: 'activity',
    component: activity,
    meta: {
      title: '活动',
    },
  },
  {
    path: '/activity/create',
    name: 'create',
    component: create,
    meta: {
      title: '创建活动',
    },
  },
  {
    path: '/activityDetail',
    name: 'activityDetail',
    component: ActivityDetail,
    meta: {
      title: '活动详情',
    },
  },

]
