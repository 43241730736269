<template>
    <div>
        <div class="navber flex-row-center">
            <div class="navber-item" :class="{ 'active-nav': current == 0 }" @click="handleType('join', 0)">{{
                $t('personal.my_join') }}</div>
            <div class="navber-item" :class="{ 'active-nav': current == 1 }" @click="handleType('mine', 1)">{{
                $t('task.publish') }}</div>
        </div>
        <div class="list" v-if="list.length">
            <div class="card" :style="index % 2 == 0 ? 'margin-left: 0' : 'margin-right: 0'" v-for="(item, index) in list"
                @click="goDetail(item)" :key="index" style="cursor: pointer">
                <div class="cover" v-if="item.cover">
                    <el-image :src="item.cover" fit="cover"></el-image>
                </div>
                <div class="right-box">
                    <div class="flex-row-center">
                        <div class="state" v-if="item.status == 0">{{ $t('activity.status0') }}</div>
                        <div class="state" v-if="item.status == 1">{{ $t('task.ing') }}</div>
                        <div class="state" v-if="item.status == 2">{{ $t('activity.status2') }}</div>
                        <div class="title x-ell-1">{{ item.title }}</div>
                    </div>
                    <div class="info">
                        <div>
                            {{ $t('activity.date') }}：<span>{{ item.start_time }}-{{ item.end_time
                            }}</span>
                        </div>
                        <div class="x-ell-1 address">
                            {{ $t('activity.address') }}：<span>{{
                                item.country }}{{ item.state }}{{ item.city }}{{ item.address }}</span>
                        </div>
                        <div>
                            {{ $t('activity.activity_num') }}：<span>{{ item.limit_join_user_count }}人</span>
                        </div>
                    </div>
                    <div class="bottom-info flex-row-center">
                        <div class="cancel-btn" @click="handleCancel(index, item.id)">
                            {{ $t('personal.cancel_collect') }}

                        </div>
                        <div class="join-btn"  v-if="item.has_join==1">
                            {{  $t('activity.joined') }}
                           
                        </div>
                        <div class="join-btn" v-if="item.has_join==0&&item.status!=0" @click.stop="onJoin(item.id, index)">
                            {{ $t('activity.apply_join') }}
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <ViewEmpty v-else />
    </div>
</template>
<script>
export default {
    name: "ActivityList",
    props: ["list"],

    data() {
        return {
            current: 0
        };
    },
    methods: {
        //类型
        handleType(type, current) {
            this.current = current
            this.$emit('handleType', type)

        },
        //取消收藏
        handleCancel(index, id) {
            this.list.splice(index, 1)
            this.$api.delete(`/api/collect/activity/${id}`)
        },
        //参加活动
        onJoin(id, index) {
            this.loading = true
            this.$api.post(`/api/activity-join/${id}`).then(() => {
                this.loading = false
                this.show = true
                this.list[index].has_join = 1
                this.getMember()
            }).catch(() => {
                this.loading = false
                this.$message.error(this.$t('activity.apply_fail'))
            })
        },
        //详情
        goDetail(item) {
            this.$router.push({
                path: "/activityDetail",
                query: {
                    id: item.id,
                },
            });
        }
    },
};
</script>
<style scoped lang="scss">
.navber {
    margin: 0.3rem 0 0;

    .navber-item {
        font-size: 0.18rem;
        color: #848484;
        margin-right: 0.72rem;
        cursor: pointer;
    }

    .active-nav {
        color: #262423;
    }
}

.list {
    display: flex;
    flex-direction: column;
    ;


    .card {
        width: 100%;
        height: 7.2rem;
        border-bottom: 0.01rem solid #EAEAEA;
        cursor: pointer;
        position: relative;
        margin-top: 0.3rem;

        .cover {
            width: 100%;
            height: 5.36rem;
            background: #D8D8D8;
            border-radius: 0.04rem 0.04rem 0rem 0rem;

            .el-image {
                width: 100%;
                height: 5.36rem;
                border-radius: 0.04rem 0.04rem 0rem 0rem;
                overflow: hidden;
            }
        }

        .right-box {
            margin-top: 0.17rem;
            padding: 0 0.2rem;
            box-sizing: border-box;

            .state {
                width: 0.86rem;
                height: 0.35rem;
                background: #6CBC73;
                border-radius: 0.17rem;
                font-size: 0.14rem;
                color: #FFFFFF;
                line-height: 0.35rem;
                margin-right: 0.15rem;
                text-align: center;

            }

            .title {
                flex: 1;
                font-size: 0.19rem;
                color: #363636;
                line-height: 0.32rem;
            }

            .info {
                font-size: 0.16rem;
                font-weight: 400;
                color: #919191;
                margin-top: 0.2rem;

                div {
                    margin-bottom: 0.1rem;
                }

            }
            .bottom-info{
                right: 0rem;
                bottom: 0.8rem;
                position: absolute;

            }

            .join-btn {
                
                
                width: 1.73rem;
                height: 0.46rem;
                background: #02428C;
                border-radius: 0.23rem;
                line-height: 0.46rem;
                text-align: center;
                cursor: pointer;
                font-size: 0.17rem;
                color: #FFFFFF;
                border: 1px solid #02428C;
                margin-left: 0.2rem;
            }

            .cancel-btn {
                // right: 2.13rem;
                // bottom: 0.8rem;
                // position: absolute;
                width: 1.73rem;
                height: 0.46rem;
                background: #ffffff;
                border-radius: 0.23rem;
                line-height: 0.46rem;
                text-align: center;
                cursor: pointer;
                font-size: 0.17rem;
                color: #A6A6A6;
                border: 1px solid #A6A6A6;

            }


        }
    }

}
.address{
  width: 50%;
}
</style>
  