<template>
    <div class="source">
        <div class="add-btn" @click="show = true" v-if="id==userId">+{{$t('personal.add_resource')}}</div>
        <div class="source-item" v-for="(item, index) in list" :key="index"
            @click="handleSkip(`/sourceDetail?id=${item.id}`)">
            <div class="source-item-cen flex-row">
                <div class="cover">
                    <el-image class="cover1" :src="item.image[0]" v-if="item.image" />
                    <div class="mark" v-if="item.category && item.category.name">{{ item.category && item.category.name }}
                    </div>
                </div>
                <div class="info">
                    <div class="floor1 flex-row-center">
                        <div class="label">{{ item.type == 2 ? $t('circle.organ') : $t('circle.export') }}</div>
                        <div class="title">{{ item.title }}</div>
                    </div>
                    <div class="floor2" v-if="item.type == 1">{{$t('circle.main_character')}}：{{ item.main_character }}</div>
                    <div class="floor2" v-if="item.type == 1">{{$t('circle.other_character')}}：{{ item.other_character }}</div>
                    <div class="floor3">{{ item.introduction }}</div>
                    <div class="floor4 flex-row-center">
                        <div class="floor4-label flex-row-center">{{$t('circle.level')}}：
                            <img src="@/assets/icons/star.png" v-for="itemt in item.level" :key="itemt" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="userId==item.user.id" class="el-icon-edit icon" @click.stop="handleEdit(item)">编辑</div>
        </div>
        <!-- 新增资源 -->
        <AddSource :show="show" @handleClose="handleClose" :params="params"/>
    </div>
</template>
<script>
import AddSource from '@/components/AddSource.vue'
import Avatar from '@/components/Avatar.vue'
export default {
    name: "",
    props: ['list','id'],
    components: { Avatar, AddSource },
    data() {
        return {
            show: false,
            userId:this.$storage.getData("userInfo")?this.$storage.getData("userInfo").id:null,
            params:null
        };
    },
    methods: {
        //编辑资源
        handleEdit(item){
            this.params = item;
            this.show = true

        },
        //跳转
        handleSkip(url) {
            this.$router.push(url)
        },
        //关闭弹窗
        handleClose() {
            this.show = false;
            this.$emit('handleClose')

        }
    }
};
</script>
<style lang="scss" scoped>
.source {
    width: 100%;

    .add-btn {
        width: 1.66rem;
        height: 0.43rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #02428C;
        font-size: 0.16rem;
        color: #02428C;
        text-align: center;
        line-height: 0.43rem;
        cursor: pointer;
        margin-top: 0.3rem;

    }



    .source-item {
        height: auto;
        width: 100%;
        margin-top: 0.2rem;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;



        .source-item-cen {
            padding: 0.2rem 0rem;
            box-sizing: border-box;
            border-bottom: 1px solid #EAEAEA;

            .cover {
                width: 3.46rem;
                height: 2.75rem;
                border-radius: 0.06rem;
                margin-right: 0.32rem;
                position: relative;

                .cover1 {
                    width: 3.46rem;
                    height: 2.75rem;
                    border-radius: 0.06rem;

                }

                .mark {
                    position: absolute;
                    min-width: 1.02rem;
                    height: 0.37rem;
                    background: #FFEEDC;
                    border-radius: 0.06rem 0rem 0.06rem 0rem;
                    text-align: center;
                    line-height: 0.37rem;
                    font-size: 0.16rem;
                    color: #A8641C;
                    top: 0;
                    left: 0;

                }
            }

            .info {
                flex: 1;

                .floor1 {
                    .label {
                        width: 0.5rem;
                        height: 0.27rem;
                        background: #FF580F;
                        border-radius: 0.14rem;
                        text-align: center;
                        font-size: 0.14rem;
                        color: #FFFFFF;
                        line-height: 0.27rem;
                        margin-right: 0.09rem;
                    }

                    .title {
                        font-size: 0.2rem;

                        color: #262423;
                    }
                }

                .floor2 {
                    margin-top: 0.08rem;
                    font-size: 0.17rem;
                    color: #262423;
                    line-height: 0.24rem;
                }

                .floor3 {

                    font-size: 0.17rem;
                    margin-top: 0.08rem;
                    color: #8B8B8B;
                    line-height: 0.24rem;
                }

                .floor4 {
                    margin-top: 0.08rem;

                    .floor4-label {
                        font-size: 0.17rem;
                        color: #8B8B8B;

                        img {
                            margin-right: 0.06rem;
                        }
                    }

                }

                .floor5 {
                    font-size: 0.18rem;
                    margin-top: 0.08rem;
                    color: #FF471A;
                    line-height: 0.32rem;

                }

            }

        }


    }











}
.icon{
  color: #02428C;
  font-size: 0.12rem;
  cursor: pointer;
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
}
</style>