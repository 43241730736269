//任务
import Task from '../views/task/index.vue'
import TaskDetail from '../views/task/taskDetail.vue'
import MyTask from '../views/task/myTask.vue'
export default [
  {
    path: '/task',
    name: 'task',
    component: Task,
    meta: {
      title: '任务',
    },
  },
  {
    path: '/taskDetail',
    name: 'TaskDetail',
    component: TaskDetail,
    meta: {
      title: '任务详情',
    },
  },
  {
    path: '/myTask',
    name: 'MyTask',
    component: MyTask,
    meta: {
      title: '我的任务管理',
    },
  }
]
