//圈子成员
<template>
    <div class="circle-member">
        <el-dialog :title="`${$t('con.group_user')}(${list.length})`" :visible.sync="show" width="50%"
            :before-close="handleClose">
            <ul>
                <li v-for="(item, index) in list" :key="index" class="flex-row-center-between">
                    <div class="user-info flex-row-center">
                        <img :src="item.avatar" />
                        <div class="sec">
                            <div class="name x-ell-1">{{ item.name }}</div>
                            <div class="bio x-ell-1" :title="item.introduction">{{ item.introduction || $t('auth.nulldesc') }}</div>
                        </div>
                        <div class="time">{{ item.time }}</div>
                    </div>
                    <div class="btn-wrap flex-row-center">

                        <div class="btn" @click="handleFollow(0, item.id, index)"
                            v-if="item.has_follow == 0 && userId && userId != item.id"> {{ $t('index.focus') }}</div>
                        <div class="btn diff" @click="handleFollow(1, item.id, index)"
                            v-if="item.has_follow == 1 && userId && userId != item.id"> {{ $t('index.focused') }}</div>
                        <div class="btn diff" v-if="manage_id && manage_id == userId && manage_id != item.id"
                            @click="handleDel(item.id, index)">{{ $t('con.delete') }}</div>
                    </div>
                </li>
            </ul>
            <pagination :page-sizes="getPageSize()" v-show="total > 0" :total="total" align="center" :page.sync="page"
                :limit.sync="paginate" @pagination="handlePage" />
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>
import pagination from '@/components/pagination.vue'
export default {
    props: ["show", 'group_id', 'manage_id'],
    components: { pagination },
    data() {
        return {
            userId: this.$storage.getData("userInfo") ? this.$storage.getData("userInfo").id : null,//当前用户id
            list: [],
            total: 0,
            page: 1,
            paginate: 20
        };
    },
    created() {
        console.log(this.userId)
        this.getList()
    },
    methods: {
        handlePage(val){
            this.getList(val.page)
        },
        
        //获取圈子成员
        getList() {
            this.$api.get(`/api/group/users/${this.group_id}`, { page: this.page }).then(res => {
                this.list = res.data.data
                this.total = res.data.meta.total
            })
        },
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        //关注 取消关注
        handleFollow(state, id, index) {
            if (state == 0) {
                this.$api.post(`/api/follow/user/${id}`).then(() => {
                    this.list[index].has_follow = 1
                    this.$message.success(this.$t('circle.focus_success'))
                })
            } else {
                this.$api.delete(`/api/follow/user/${id}`).then(() => {
                    this.list[index].has_follow = 0
                    this.$message.success(this.$t('circle.cancel_success'))
                })
            }
        },
        //踢出
        handleDel(id, index) {
            this.$api.delete('/api/out-group', { params: { group_id: this.group_id, user_id: id } }).then(() => {
                this.list.splice(index, 1)
            })
        },
        getPageSize() {
        }
    }
};
</script>
<style scoped lang="scss">
.circle-member {
    ul {
        li {

            margin-bottom: 0.3rem;

            img {
                width: 0.55rem;
                height: 0.55rem;
                border-radius: 50%;
                background-color: #efefef;
            }

            .sec {
                margin-left: 0.06rem;
                flex:1;

                .name {
                    font-size: 0.18rem;
                    color: #202020;
                    line-height: 0.29rem;
                }

                .bio {
                    font-size: 0.15rem;
                    color: #909090;

                }
            }

            .time {
                font-size: 0.16rem;
                color: #909090;
                line-height: 0.24rem;
                margin-left: 0.13rem;
            }

            .btn-wrap {
                .btn {
                    width: 0.69rem;
                    height: 0.32rem;
                    border-radius: 0.03rem;
                    border: 0.01rem solid #02428C;
                    text-align: center;
                    line-height: 0.32rem;
                    cursor: pointer;
                    font-size: 0.16rem;
                    color: #02428C;
                    margin-left: 0.3rem;
                }

                .diff {
                    border: 0.01rem solid #9A9A9A;
                    color: #9A9A9A;
                }
            }
        }
    }
}
</style>
  