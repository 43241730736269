<!-- 引导 -->
<template>
    <div class="mask" v-if="show">
        <ul>
            <li v-for="(item, index) in steps" :key="index">
                <img :src="item.cover" v-if="item.show" />
                <div class="box" v-if="item.show">
                    <div class="num flex-row-center">
                        <div class="num-left">{{ index + 1 }}</div>
                        <div class="num-right">/4</div>

                    </div>
                    <div class="title">{{ item.title }}</div>
                    <div class="desc">{{ item.desc }}</div>
                    <div class="btn-wrap flex-row-center" v-if="index != 3">
                        <div class="btn" @click="skip()">{{ $t('auth.skip') }}</div>
                        <div class="btn diff" @click="next(index)">{{ $t('auth.next') }}</div>
                    </div>
                    <div v-else class="button" @click="skip()">{{ $t('con.knows') }}</div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>

export default {
    props: ['show'],
    data() {
        return {
            steps: [
                {
                    title: this.$t('con.guide_tit1'),
                    desc: this.$t('con.guide_desc1'),
                    cover: require('@/assets/icons/guide1.png'),
                    show: true,
                },
                {
                    title: this.$t('con.guide_tit2'),
                    desc: this.$t('con.guide_desc2'),
                    cover: require('@/assets/icons/guide2.png'),
                    show: false

                },
                {
                    title: this.$t('con.guide_tit3'),
                    desc: this.$t('con.guide_desc3'),
                    cover: require('@/assets/icons/guide3.png'),
                    show: false
                },
                {
                    title: this.$t('con.guide_tit4'),
                    desc: this.$t('con.guide_desc4'),
                    cover: require('@/assets/icons/guide4.png'),
                    show: false
                }
            ]
        }
    },
    methods: {
        skip() {
            this.$emit('hide')
        },
        next(index) {
            this.steps[index].show = false
            this.steps[index + 1].show = true
        }
    }
}

</script>
<style lang="scss" scoped>
.mask {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.5);

    ul {
        position: relative;
        width: 13.2rem;
        height: 100vh;
        margin: 0 auto;

        li {
            position: absolute;
            top: 0;
            right: 3.5rem;

            img {
                width: 2.66rem;
            }

            .box {
                position: absolute;
                top: 1.38rem;
                right: 1.8rem;
                color: #ffffff;
                width: 4.13rem;

                .num {
                    .num-left {
                        font-size: 0.22rem;
                    }

                    .num-right {
                        font-size: 0.18rem;
                    }

                }

                .title {
                    font-size: 0.19rem;
                    margin: 0.04rem 0;
                }

                .desc {
                    font-size: 0.15rem;


                }

                .btn-wrap {
                    margin-top: 0.3rem;

                    .btn {
                        cursor: pointer;
                        width: 1.16rem;
                        height: 0.36rem;
                        border-radius: 0.04rem;
                        border: 0.01rem solid #FFFFFF;
                        margin-right: 0.3rem;
                        text-align: center;
                        line-height: 0.36rem;

                    }

                    .diff {
                        background-color: #5077D5;
                        border-color: #5077D5;
                    }
                }

                .button {
                    margin: 0.3rem 0 0 0.3rem;
                    cursor: pointer;
                    width: 1.16rem;
                    height: 0.36rem;
                    border-radius: 0.04rem;
                    border: 0.01rem solid #5077D5;
                    margin-right: 0.3rem;
                    text-align: center;
                    line-height: 0.36rem;
                    background-color: #5077D5;
                    border-color: #5077D5;

                }


            }
        }

        li:nth-child(2) {
            top: 0.92rem;
            left: 0;

            img {
                width: 9.96rem;
            }

            .box {
                top: 2.5rem;
                left: 2.7rem;
            }
        }

        li:nth-child(3) {
            top: 0rem;
            right: 3rem;

            img {
                width: 0.78rem;
            }

            .box {
                top: 2.8rem;
                left: -0.9rem;
            }
        }

        li:nth-child(4) {
            top: 0.92rem;
            right: 0rem;

            img {
                width: 3.1rem
            }

            .box {
                top: 1.6rem;
                left: -2.1rem;
            }
        }
    }

}</style>