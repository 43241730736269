<!-- 搜索结果 -->
<template>
    <div class="search">
        <div class="label">
            <div class="label-top">{{ params.label_id || flag == 1 ? '#' : '' }}
                {{ params.search }}</div>
            <div class="label-bot">{{ $t('index.tit1') }}({{ total }})</div>
        </div>
        <ul class="navs">
            <li v-for="(item, index) in navs" :key="index" :class="index == current ? 'activeNav' : ''"
                @click="changeNav(index)">
                <div class="nav-tit"> {{ item.title }}</div>
                <div class="nav-line"> </div>
            </li>
        </ul>
        <div class="con">
            <!-- 动态 -->
            <div v-if="current == 0">
                <div v-if="post.length">
                    <div v-for="(item, i) in post" :key="i">
                        <FeedsCard :card="item" :isHome="true" size="medium" />
                    </div>
                </div>
                <view-empty v-else></view-empty>
            </div>
            <!-- 任务 -->
            <div v-if="current == 1">
                <div v-if="task.length">
                    <div v-for="(item, i) in task" :key="i">
                        <TaskCard :item="item" />
                    </div>
                </div>
                <view-empty v-else></view-empty>
            </div>
            <!-- 资源 -->
            <div v-if="current == 2">
                <div v-if="resource.length">
                    <TheSource :list="resource" />
                </div>
                <view-empty v-else></view-empty>
            </div>
            <!-- 圈子 -->
            <div v-if="current == 3">
                <div v-if="group.length">
                    <TheCircle :list="group" />
                </div>
                <view-empty v-else></view-empty>
            </div>
            <!-- 活动 -->
            <div v-if="current == 4">
                <div v-if="activity.length">
                    <ActivityItem :list="activity" />
                </div>
                <view-empty v-else></view-empty>
            </div>
            <!-- 用户 -->
            <div v-if="current == 5">
                <div v-if="user.length">
                    <TheUser :list="user" />
                </div>
                <view-empty v-else></view-empty>
            </div>
        </div>
    </div>
</template>
<script>
import ActivityItem from '@/views/personal/components/ActivityItem.vue'
import TaskCard from '@/views/personal/components/TaskCard.vue'
import FeedsCard from '@/components/FeedsCard.vue'
import TheSource from './components/TheSource.vue'
import TheCircle from './components/TheCircle.vue'
import TheUser from './components/TheUser.vue'

export default {
    name: "search",
    components: { FeedsCard, TaskCard, TheSource, TheCircle, ActivityItem, TheUser },
    data() {
        return {
            flag: 0,
            navs: [
                { title: this.$t('index.feed'), value: 'post' },
                { title: this.$t('index.task'), value: 'task' },
                { title: this.$t('circle.source'), value: 'resource' },
                { title: this.$t('feed.circle'), value: 'group' },
                { title: this.$t('index.activity'), value: 'activity' },
                { title: this.$t('index.user'), value: 'user' },
            ],
            params: {
                page: 1,
                type: 'post',
                search: '',
                label_id: ''
            },
            current: 0,//当前导航
            activity: [],//活动
            post: [],
            task: [],
            resource: [],
            group: [],
            activity: [],
            user: [],
            total: 0,
            loading: false,//加载效果
            flag: 0,

        };
    },
    watch: {
        '$route.query': { // $route可以用引号，也可以不用引号  监听的对象
            handler(val) {
                this.params.search = val.search
                this.params.label_id = val.label_id
                this.flag = val.flag
                if (val.current) {
                    this.changeNav(val.current)
                } else {
                    this.getList(1)
                }




            },
            deep: true, // 深度观察监听 设置为 true
            immediate: true, // 第一次初始化渲染就可以监听到
        }


    },
    methods: {
        //导航点击事件
        changeNav(index) {
            this.current = index
            this.params = {
                type: this.navs[index].value,
                page: 1,
                search: this.$route.query.search,
                label_id: this.params.label_id
            }
            this.lastPage = 0
            this.getList()
        },
        //获取列表
        getList(page) {
            if (page) this.params.page = page
            this.loading = true
            let params = {
            }
            if (this.params.label_id) {
                params = {
                    label_id: this.params.label_id,
                    page: this.params.page,
                    type: this.params.type
                }
            } else {
                if (this.flag == 1) {
                    params = {
                        label_id: this.params.search,
                        page: this.params.page,
                        type: this.params.type
                    }

                } else {
                    params = {
                        page: this.params.page,
                        search: this.params.search,
                        type: this.params.type
                    }

                }

            }
            this.$api.get(`/api/search`, { params }).then((res) => {
                this.navs[0].title = `${this.$t('index.feed')}(${res.data.post_count})`
                this.navs[1].title = `${this.$t('index.task')}(${res.data.task_count})`
                this.navs[2].title = `${this.$t('circle.source')}(${res.data.resource_count})`
                this.navs[3].title = `${this.$t('feed.circle')}(${res.data.group_count})`
                this.navs[4].title = `${this.$t('index.activity')}(${res.data.activity_count})`
                this.navs[5].title = `${this.$t('index.user')}(${res.data.user_count})`
                this.total = res.data.post_count + res.data.task_count + res.data.resource_count + res.data.group_count + res.data.activity_count + res.data.user_count
                if (this.params.page == 1) {
                    this[this.params.type] = res.data.list
                } else {
                    this[this.params.type] = [...this[this.params.type], ...res.data.list]
                }
                this.loading = false
            })
        }
    }
};
</script>
<style scoped lang="scss">
.search {
    width: 13.2rem;
    margin: 0.92rem auto;
    background: #FFFFFF;
    border-radius: 0.03rem;

    .label {
        width: 100%;
        height: 1rem;
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .label-top {
            font-size: 0.22rem;
            color: #5077D5;

        }

        .label-bot {
            margin-top: 0.06rem;
            font-size: 0.14rem;
            color: #737373;
            ;
        }


    }

    .navs {
        display: flex;
        flex-direction: row;
        background: #ffffff;
        width: 100%;
        height: 0.8rem;
        box-sizing: border-box;
        border-bottom: 1px solid #EAEAEA;
        border-top: 1px solid #EAEAEA;
        padding: 0 0.3rem;

        li {
            margin-right: 0.56rem;
            height: 0.8rem;
            cursor: pointer;
            position: relative;

            .nav-tit {
                margin-top: 0.32rem;
                font-size: 0.18rem;
                color: #767676;
            }

            .nav-line {
                width: 0.39rem;
                height: 0.03rem;
                // background: #ffffff;
                border-radius: 0.02rem;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .activeNav {
            .nav-tit {
                color: #5077D5;
            }

            .nav-line {
                background-color: #5077D5;
            }

        }



    }

    .con {
        padding: 0 0.3rem;
    }
}
</style>
