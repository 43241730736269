<!-- 实名认证 -->
<template>
    <div class="release">
        <section class="main">
            <div class="flex-row-center-between">
                <div>
                    <div class="title">{{ $t('personal.certificate') }}</div>
                    <div class="desc">{{ $t('personal.tip2') }}</div>

                </div>
                <div class="mark">
                    <div v-if="form.status == 1" class="mark">{{ $t('personal.mark1') }}</div>
                    <div v-if="form.status == 0" class="mark">{{ $t('personal.mark0') }}</div>
                    <div v-if="form.status == -1" class="mark">{{ $t('personal.mark2') }}</div>
                </div>

            </div>

            <div class="release-form">
                <el-form ref="form" :rules="rules" :model="form" label-position="top">
                    <el-form-item label="" prop="apply_type">
                        <el-radio-group v-model="form.apply_type">
                            <el-radio-button label="1">{{ $t('personal.apply_type1') }}</el-radio-button>
                            <el-radio-button label="2">{{ $t('personal.apply_type2') }}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <div v-if="form.apply_type == 1">
                        <el-form-item :label="$t('personal.certificate_type')" prop="certificate_type">
                            <el-select v-model="form.certificate_type" :placeholder="$t('personal.placeholder3')"
                                style="width: 100%">
                                <el-option :label="$t('personal.certificate_type1')" value="1"></el-option>
                                <el-option :label="$t('personal.certificate_type2')" value="2"></el-option>
                                <el-option :label="$t('personal.certificate_type3')" value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('personal.user_name')" prop="user_name">
                            <el-input v-model="form.user_name" :placeholder="$t('personal.placeholder4')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('personal.certificate_number')" prop="certificate_number">
                            <el-input v-model="form.certificate_number"
                                :placeholder="$t('personal.placeholder5')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('personal.certificate_img')" prop="certificate_img">
                            <div class="tip">{{ $t('personal.tip3') }}</div>
                            <el-upload class="avatar-uploader" ref="upload_img" action
                                accept="image/jpeg, image/jpg, image/png" list-type="picture-card" :name="upload_name"
                                :on-remove="handleRemove" :on-exceed="handleExceed" :limit="1" :file-list="file_list"
                                :before-upload="beforeUpload" :class="{ hide: hideUpload }">
                                <i class="el-icon-upload"></i>
                            </el-upload>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-form-item :label="$t('circle.orgname')" prop="institution_name">
                            <el-input v-model="form.institution_name" :placeholder="$t('personal.placeholder6')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('personal.business_license')" prop="business_license">
                            <div class="tip">{{ $t('personal.tip3') }}</div>
                            <el-upload class="avatar-uploader" ref="upload_img" action
                                accept="image/jpeg, image/jpg, image/png" list-type="picture-card" :name="upload_name1"
                                :on-remove="handleRemove1" :on-exceed="handleExceed1" :limit="1" :file-list="file_list1"
                                :before-upload="beforeUpload1" :class="{ hide: hideUpload1 }">
                                <i class="el-icon-upload"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item :label="$t('personal.legal_person')" prop="legal_person">
                            <el-input v-model="form.legal_person" :placeholder="$t('personal.placeholder7')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('personal.legal_person_img')" prop="legal_person_img">
                            <div class="tip">{{ $t('personal.tip3') }}</div>
                            <el-upload class="avatar-uploader" ref="upload_img" action
                                accept="image/jpeg, image/jpg, image/png" list-type="picture-card" :name="upload_name2"
                                :on-remove="handleRemove2" :on-exceed="handleExceed2" :limit="1" :file-list="file_list2"
                                :before-upload="beforeUpload2" :class="{ hide: hideUpload2 }">
                                <i class="el-icon-upload"></i>
                            </el-upload>
                        </el-form-item>
                        <div class="title">{{ $t('personal.tax_info') }}</div>
                        <el-form-item :label="$t('personal.tax_number')" prop="tax_number">
                            <el-input v-model="form.tax_number" :placeholder="$t('personal.placeholder8')"></el-input>
                        </el-form-item>
                    </div>
                    <div class="title">{{ $t('personal.tit8') }}</div>
                    <div class="desc">{{ $t('personal.tip3') }}</div>
                    <el-form-item :label="$t('personal.bank_account_holder')" prop="bank_account_holder">
                        <el-input v-model="form.bank_account_holder" :placeholder="$t('personal.placeholder9')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('personal.bank_account')" prop="bank_account">
                        <el-input v-model="form.bank_account" :placeholder="$t('personal.placeholder10')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('personal.bank_name')" prop="bank_name">
                        <el-input v-model="form.bank_name" :placeholder="$t('personal.placeholder11')"></el-input>
                    </el-form-item>
                    <!-- <el-form-item :label="$t('personal.bank_account_proof')" prop="bank_account_proof">
                        <div class="tip">{{ $t('personal.tip3') }}</div>
                        <el-upload class="avatar-uploader" ref="upload_img" action accept="image/jpeg, image/jpg, image/png"
                            list-type="picture-card" :name="upload_name3" :on-remove="handleRemove3"
                            :on-exceed="handleExceed3" :limit="1" :file-list="file_list3" :before-upload="beforeUpload3"
                            :class="{ hide: hideUpload3 }">
                            <i class="el-icon-upload"></i>
                        </el-upload>
                    </el-form-item> -->
                    <div class="title">{{ $t('personal.contact') }}</div>
                    <el-form-item :label="$t('personal.contact_name')" prop="contact_name">
                        <el-input v-model="form.contact_name" :placeholder="$t('personal.placeholder12')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('personal.position')" prop="position">
                        <el-input v-model="form.position" :placeholder="$t('personal.placeholder13')"></el-input>
                    </el-form-item>
                    <el-row>
                        <el-col :span="10" style="margin-right: 0.6rem;">
                            <el-form-item :label="$t('personal.phone_number')" prop="phone_number">
                                <div class="flex-row-center">
                                    <el-input v-model="form.phone_number"
                                        :placeholder="$t('personal.placeholder14')"></el-input>
                                    <div class="vertify" @click="sendCode(1)">{{ title1 }}</div>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item :label="$t('personal.sms_code')">
                                <div class="flex-row-center">
                                    <el-input v-model="form.sms_code" :placeholder="$t('auth.placeholder4')"></el-input>
                                    <div class="vertify" @click="handleConfirm(1)"> {{ flag1 ? '✓' :
                                        $t('personal.confirm_vertify') }}</div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="margin-right: 0.6rem;">
                            <el-form-item :label="$t('personal.email')" prop="email">
                                <div class="flex-row-center">
                                    <el-input v-model="form.email" :placeholder="$t('personal.placeholder15')"></el-input>
                                    <div class="vertify" @click="sendCode(2)">{{ title2 }}</div>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item :label="$t('personal.mail_code')" prop="mail_code">
                                <div class="flex-row-center">
                                    <el-input v-model="form.mail_code" :placeholder="$t('auth.placeholder4')"></el-input>
                                    <div class="vertify" @click="handleConfirm(2)"> {{ flag2 ? '✓' :
                                        $t('personal.confirm_vertify') }}</div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item :label="$t('personal.zip_code')" prop="zip_code">
                        <el-input v-model="form.zip_code" :placeholder="$t('personal.placeholder16')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('personal.email_address')" prop="address">
                        <el-input v-model="form.address" :placeholder="$t('personal.placeholder17')"></el-input>
                    </el-form-item>
                    <div class="operate-bot flex-row-center">
                        <el-checkbox v-model="form.checked" />
                        <div class="read">{{ $t('personal.readed') }}</div>
                        <a @click="visible = true">《{{ $t('auth.tit1') }}》</a>
                    </div>
                    <div class="release-form-btn" v-loading="loading" element-loading-spinner="el-icon-loading"
                        @click="submit()" style="cursor: pointer">
                        {{ $t('personal.submit') }}
                    </div>
                </el-form>
            </div>
        </section>
        <!-- 弹窗显示协议 -->
        <el-dialog :title="$t('personal.user_service_secret')" :visible.sync="visible" width="8rem"
            :before-close="handleClose">
            <div class="con" v-html="content"></div>
        </el-dialog>
    </div>
</template>
<script>
import uploadFile from "@/utils/upload-file";
export default {
    data() {
        return {
            content: '',
            visible: false,
            flag1: false,
            flag2: false,
            form: {
                apply_type: 1,//    实名认证类型，1个人；2机构
                certificate_type: '',//         申请所需证件类型，1身份证；2护照；3驾驶证（个人）
                user_name: '',//     真实姓名（个人）
                certificate_number: '',//      证件号码（个人）
                certificate_img: '',//      证件照片（个人）
                institution_name: '',//        机构名称（机构）
                business_license: '',//      营业执照照片（机构）
                legal_person: '',//      法人姓名（机构）
                legal_person_img: '',//       法人证件照片（机构）
                tax_number: '',//     开票税号（机构）
                bank_account_holder: '',//     银行账户开户人
                bank_account: '',//     银行账号
                bank_name: '',//     开户银行名称
                // bank_account_proof: '',//     银行账户证明
                contact_name: '',//     联系人姓名
                position: '',//      职务
                phone_number: '',//       手机号
                email: '',//      电子邮箱
                zip_code: '',//       邮政编码
                address: '',//       详细邮寄地址
                is_agree_agreement: '',//      是否同意协议
                mail_code: '',//邮箱验证码
                sms_code: '',//手机号验证码
                checked: false
            },
            rules: {
                apply_type: [{ required: true, message: this.$t('personal.msg1'), trigger: "blur" }],
                certificate_type: [{ required: true, message: this.$t('personal.msg2'), trigger: "blur" }],
                user_name: [{ required: true, message: this.$t('personal.msg3'), trigger: "blur" }],
                certificate_number: [{ required: true, message: this.$t('personal.msg4'), trigger: "blur" }],
                certificate_img: [{ required: true, message: this.$t('personal.msg5'), trigger: "blur" }],
                institution_name: [{ required: true, message: this.$t('personal.msg6'), trigger: "blur" }],
                business_license: [{ required: true, message: this.$t('personal.msg7'), trigger: "blur" }],
                legal_person: [{ required: true, message: this.$t('personal.msg8'), trigger: "blur" }],
                legal_person_img: [{ required: true, message: this.$t('personal.msg9'), trigger: "blur" }],
                tax_number: [{ required: true, message: this.$t('personal.msg10'), trigger: "blur" }],
                bank_account_holder: [{ required: true, message: this.$t('personal.msg11'), trigger: "blur" }],
                bank_account: [{ required: true, message: this.$t('personal.msg12'), trigger: "blur" }],
                bank_name: [{ required: true, message: this.$t('personal.msg13'), trigger: "blur" }],
                // bank_account_proof: [{ required: true, message: this.$t('personal.msg14'), trigger: "blur" }],
                contact_name: [{ required: true, message: this.$t('personal.msg15'), trigger: "blur" }],
                position: [{ required: true, message: this.$t('personal.msg16'), trigger: "blur" }],
                phone_number: [{ required: true, message: this.$t('personal.msg17'), trigger: "blur" }],
                email: [{ required: true, message: this.$t('personal.msg18'), trigger: "blur" }],
                address: [{ required: true, message: this.$t('personal.msg19'), trigger: "blur" }],
                is_agree_agreement: [{ required: true, message: this.$t('personal.msg20'), trigger: "blur" }],
            },
            //上传图片
            file_list: [],
            upload_name: "file", //图片上传的后端接受图片文件的 key
            hideUpload: false,
            file_list1: [],
            upload_name1: "file", //图片上传的后端接受图片文件的 key
            hideUpload1: false,
            file_list2: [],
            upload_name2: "file", //图片上传的后端接受图片文件的 key
            hideUpload2: false,
            file_list3: [],
            upload_name3: "file", //图片上传的后端接受图片文件的 key
            hideUpload3: false,
            loading: false,//加载效果
            disabled1: false,
            timer1: null,
            second1: '',
            disabled2: false,
            timer2: null,
            second2: '',
            phoneRule: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
            emailRule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        };
    },
    computed: {
        title1() {
            return this.disabled1 ? `${this.second1} ${this.$t('auth.second')}` : this.$t('auth.get_code')
        },
        title2() {
            return this.disabled2 ? `${this.second2} ${this.$t('auth.second')}` : this.$t('auth.get_code')
        },
    },
    created() {
        this.$api.get('/api/init/config').then(res => {
            this.content = res.data.user_service_agreement
        })

        this.$api.get('/api/my-certificate').then(res => {
            if (res.data.data) {
                this.form = res.data.data
                this.$set(this.form, 'checked', this.form.is_agree_agreement == 1 ? true : false)
                if (this.form.certificate_img) {
                    this.file_list = [{ url: this.form.certificate_img }]
                }
                if (this.form.business_license) {
                    this.file_list1 = [{ url: this.form.business_license }]
                }
                if (this.form.legal_person_img) {
                    this.file_list2 = [{ url: this.form.legal_person_img }]
                }
                // if (this.form.bank_account_proof) {
                //     this.file_list3 = [{ url: this.form.bank_account_proof }]
                // }
            }
        })
    },
    methods: {
        //关闭弹窗
        handleClose() {
            this.visible = false
        },
        sendCode(state) {
            switch (state) {
                case 1:
                    if (this.form.phone_number && this.phoneRule.test(this.form.phone_number)) {
                        this.$api.post('/api/sms/code/certificate', { phone: this.form.phone_number }).then(res => {
                            this.$message({
                                message: this.$t('auth.send_success'),
                                center: true,
                                type: 'success',
                                customClass: 'messageClass'
                            });
                        })

                        let s = 60  //倒计时间
                        if (!this.timer1) {
                            this.second1 = s
                            this.disabled1 = true
                            this.timer1 = setInterval(() => {
                                if (this.second1 > 0 && this.second1 <= s) {
                                    this.second1--
                                } else {
                                    this.disabled1 = false
                                    clearInterval(this.timer1)
                                    this.timer1 = null
                                }
                            }, 1000)
                        }
                    } else {
                        //验证不通过
                        this.$message({
                            message: this.$t('auth.tip15'),
                            center: true,
                            type: 'error',
                            customClass: 'messageClass'
                        });
                    }
                    break;
                case 2:
                    if (this.form.email && this.emailRule.test(this.form.email)) {
                        this.$api.post('/api/mail/code/certificate', { mail: this.form.email }).then(res => {
                            this.$message({
                                message: this.$t('auth.send_success'),
                                center: true,
                                type: 'success',
                                customClass: 'messageClass'
                            });
                        })

                        let s = 60  //倒计时间
                        if (!this.timer2) {
                            this.second2 = s
                            this.disabled2 = true
                            this.timer2 = setInterval(() => {
                                if (this.second2 > 0 && this.second2 <= s) {
                                    this.second2--
                                } else {
                                    this.disabled2 = false
                                    clearInterval(this.timer2)
                                    this.timer2 = null
                                }
                            }, 1000)
                        }
                    } else {
                        //验证不通过
                        this.$message({
                            message: this.$t('auth.tip14'),
                            center: true,
                            type: 'error',
                            customClass: 'messageClass'
                        });
                    }
                    break;
            }

        },
        //提交
        submit() {
            if (!this.form.checked) {
                this.$message.warning(this.$t('personal.msg21'))
                return
            } else {
                this.form.is_agree_agreement = 1
            }

            // return
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$api
                        .post("/api/user-certificate", this.form)
                        .then(() => {
                            this.$message({
                                message: this.$t('auth.notice'),
                                type: "success",
                            });
                            //获取用户信息
                            this.$storage.userInfo();
                            this.$router.push('/')
                            this.loading = false
                        })
                        .catch(() => {
                            this.loading = false
                            this.$message({
                                message: this.$t('auth.operate_fail'),
                                type: "warning",
                            });
                        });
                }
            })
        },
        //上传图片
        handleExceed() {
            this.$alert(this.$t('feed.tip3'), {
                showConfirmButton: false
            });
        },
        // 文件列表移除文件时的钩子
        handleRemove(file, fileList) {
            this.file_list = fileList
            let arr = fileList.map(item => item.url)
            this.form.certificate_img = arr.join(',')
            //打开上传按钮
            this.hideUpload = false;
        },
        async beforeUpload(file) {
            let { data } = await uploadFile(file);
            this.file_list.push({ url: data.path })
            let arr = this.file_list.map(item => item.url)
            this.form.certificate_img = arr.join(',')
            //隐藏上传按钮
            this.hideUpload = true;
        },
        //上传图片
        handleExceed1() {
            this.$alert(this.$t('feed.tip3'), {
                showConfirmButton: false
            });
        },
        // 文件列表移除文件时的钩子
        handleRemove1(file, fileList) {
            this.file_list1 = fileList
            let arr = this.file_list1.map(item => item.url)
            this.form.business_license = arr.join(',')
            //打开上传按钮
            this.hideUpload1 = false;
        },
        async beforeUpload1(file) {
            let { data } = await uploadFile(file);
            this.file_list1.push({ url: data.path })
            let arr = this.file_list1.map(item => item.url)
            this.form.business_license = arr.join(',')
            //隐藏上传按钮
            this.hideUpload1 = true;
        },
        //上传图片
        handleExceed2() {
            this.$alert(this.$t('feed.tip3'), {
                showConfirmButton: false
            });
        },
        // 文件列表移除文件时的钩子
        handleRemove2(file, fileList) {
            this.file_list2 = fileList
            let arr = this.file_list2.map(item => item.url)
            this.form.legal_person_img = arr.join(',')
            //打开上传按钮
            this.hideUpload2 = false;
        },
        async beforeUpload2(file) {
            let { data } = await uploadFile(file);
            this.file_list2.push({ url: data.path })
            let arr = this.file_list2.map(item => item.url)
            this.form.legal_person_img = arr.join(',')
            //隐藏上传按钮
            this.hideUpload2 = true;
        },
        //上传图片
        handleExceed3() {
            this.$alert(this.$t('feed.tip3'), {
                showConfirmButton: false
            });
        },
        // 文件列表移除文件时的钩子
        // handleRemove3(file, fileList) {
        //     this.file_list3 = fileList
        //     let arr = this.file_list3.map(item => item.url)
        //     this.form.bank_account_proof = arr.join(',')
        //     //打开上传按钮
        //     this.hideUpload3 = false;
        // },
        // async beforeUpload3(file) {
        //     let { data } = await uploadFile(file);
        //     this.file_list3.push({ url: data.path })
        //     let arr = this.file_list3.map(item => item.url)
        //     this.form.bank_account_proof = arr.join(',')
        //     //隐藏上传按钮
        //     this.hideUpload3 = true;
        // },
        //跳转
        handleSkip(url) {
            this.$router.push(url)
        },
        //验证码验证
        handleConfirm(state) {
            if (state == 1) {
                this.$api.post('/api/sms/code-check', { phone: this.form.phone_number, code: this.form.sms_code }).then(res => {
                    if (res.data) {
                        if (res.data.status == 1) {
                            this.flag1 = true
                        } else {
                            this.$message.warning(this.$t('authentication.code_error'))
                        }
                    }
                })
            }
            if (state == 2) {
                this.$api.post('/api/mail/code-check', { mail: this.form.email, code: this.form.mail_code }).then(res => {
                    if (res.data) {
                        if (res.data.status == 1) {
                            this.flag2 = true
                        } else {
                            this.$message.warning(this.$t('authentication.code_error'))
                        }
                    }
                })
            }


        },
    }
};
</script>
<style scoped lang="scss">
//主体
.main {
    margin: 0.92rem auto;
    display: flex;
    flex-direction: column;
    width: 13.2rem;
    background-color: #ffffff;
    border-radius: 0.03rem;
    padding: 0.5rem 1rem 0.75rem;
    box-sizing: border-box;


    .title {
        font-size: 0.24rem;
        color: #262423;
        margin-top: 0.2rem;
    }

    .desc {
        font-size: 0.14rem;
        color: #828282;
        margin-top: 0.08rem;
        margin-bottom: 0.2rem;
    }

    .mark {
        color: #02428C;
        font-size: 0.2rem;
    }
}

.release-form {
    height: auto;
    background: #ffffff;
    margin-top: 0.2rem;



    // p {
    //     font-size: 0.17rem;


    //     color: #BCBCBC;
    // }

    .tip {
        font-size: 0.14rem;

        color: #B0B0B0;

    }

    .add {
        font-size: 0.16rem;
        color: #02428C;
        line-height: 0.29rem;
        margin-top: 0.23rem;
        cursor: pointer;
    }



    .release-form-btn {
        cursor: pointer;
        width: 2.35rem;
        height: 0.58rem;
        background: #02428C;
        border-radius: 0.04rem;
        text-align: center;
        line-height: 0.58rem;
        font-size: 0.16rem;
        font-family: PingFang SC;
        font-size: 0.17rem;
        color: #ffffff;
        margin: 0 auto;

    }
}

.line {
    text-align: center;
}

.operate-bot {
    margin-top: 0.4rem;
    cursor: pointer;

    .read {
        font-size: 0.18rem;
        color: #B0B0B0;
        margin-left: 0.22rem;
    }

    a {
        color: #5077D5;
        text-decoration: none;
    }

}

.vertify {
    width: 1.63rem;
    height: 0.48rem;
    background: #02428C;
    border-radius: 0rem 0.04rem 0.04rem 0rem;
    text-align: center;
    line-height: 0.48rem;
    color: #ffffff;
    font-size: 0.16rem;
    cursor: pointer;

}

::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background: #02428C;
    border-color: #02428C;
}

.con {
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: #202020;
    ;
    margin: 0.2rem 0;

}
::v-deep .el-upload-list__item.is-ready {
        display: none;
    }
</style>


