<template>
  <div>
    <div>
      <div class="one" v-if="images.length === 1">
        <div class="img">
          <el-image @click="handleLook" :src="images[0]" style="max-width: 6rem"></el-image>
        </div>
      </div>
      <div class="two" v-if="images.length === 2">
        <div class="img">
          <el-image @click="handleLook" :src="images[0]" fit="cover"></el-image>
        </div>
        <div class="img">
          <el-image @click="handleLook" :src="images[1]" fit="cover"></el-image>
        </div>
      </div>
      <div class="three" v-if="images.length === 3">
        <div class="img" v-for="(item, index) in images" :key="index">
          <el-image @click="handleLook" :src="item" fit="cover"></el-image>
        </div>
      </div>
      <div class="four" v-if="images.length === 4">
        <div class="img" v-for="(item, index) in images" :key="index">
          <el-image @click="handleLook" :src="item" fit="cover"></el-image>
        </div>
      </div>
      <div class="five" v-if="images.length === 5">
        <div class="section-1">
          <div class="left">
            <div class="img">
              <el-image @click="handleLook" :src="images[0]" fit="cover"></el-image>
            </div>
          </div>
          <div class="right">
            <div class="img">
              <el-image @click="handleLook" :src="images[1]" fit="cover"></el-image>
            </div>
            <div class="img">
              <el-image @click="handleLook" :src="images[2]" fit="cover"></el-image>
            </div>
          </div>
        </div>
        <div class="section-2">
          <div class="imgs">
            <div class="img">
              <el-image @click="handleLook" :src="images[3]" fit="cover"></el-image>
            </div>
            <div class="img">
              <el-image @click="handleLook" :src="images[4]" fit="cover"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="six" v-if="images.length === 6">
        <div class="section-1">
          <div class="left">
            <div class="img">
              <el-image @click="handleLook" :src="images[0]" fit="cover"></el-image>
            </div>
          </div>
          <div class="right">
            <div class="img">
              <el-image @click="handleLook" :src="images[1]" fit="cover"></el-image>
            </div>
            <div class="img">
              <el-image @click="handleLook" :src="images[2]" fit="cover"></el-image>
            </div>
          </div>
        </div>
        <div class="section-2">
          <div class="imgs">
            <div class="img">
              <el-image @click="handleLook" :src="images[3]" fit="cover"></el-image>
            </div>
            <div class="img">
              <el-image @click="handleLook" :src="images[4]" fit="cover"></el-image>
            </div>
            <div class="img">
              <el-image @click="handleLook" :src="images[5]" fit="cover"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="seven" v-if="images.length === 7">
        <div class="section-1">
          <div class="img">
            <el-image @click="handleLook" :src="images[0]" fit="cover"></el-image>
          </div>
          <div class="img">
            <el-image @click="handleLook" :src="images[1]" fit="cover"></el-image>
          </div>
        </div>
        <div class="section-2">
          <div class="top">
            <div class="img">
              <el-image @click="handleLook" :src="images[2]" fit="cover"></el-image>
            </div>
            <div class="img">
              <el-image @click="handleLook" :src="images[3]" fit="cover"></el-image>
            </div>
          </div>
          <div class="center">
            <div class="img">
              <el-image @click="handleLook" :src="images[4]" fit="cover"></el-image>
            </div>
          </div>
          <div class="top">
            <div class="img">
              <el-image @click="handleLook" :src="images[5]" fit="cover"></el-image>
            </div>
            <div class="img">
              <el-image @click="handleLook" :src="images[6]" fit="cover"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="eight" v-if="images.length === 8">
        <div class="section-1">
          <div class="img">
            <el-image @click="handleLook" :src="images[0]" fit="cover"></el-image>
          </div>
          <div class="img">
            <el-image @click="handleLook" :src="images[1]" fit="cover"></el-image>
          </div>
          <div class="img">
            <el-image @click="handleLook" :src="images[2]" fit="cover"></el-image>
          </div>
        </div>
        <div class="section-2">
          <div class="left">
            <div class="img">
              <el-image @click="handleLook" :src="images[3]" fit="cover"></el-image>
            </div>
            <div class="img">
              <el-image @click="handleLook" :src="images[4]" fit="cover"></el-image>
            </div>
          </div>
        </div>
        <div class="section-1">
          <div class="img">
            <el-image @click="handleLook" :src="images[5]" fit="cover"></el-image>
          </div>
          <div class="img">
            <el-image @click="handleLook" :src="images[6]" fit="cover"></el-image>
          </div>
          <div class="img">
            <el-image @click="handleLook" :src="images[7]" fit="cover"></el-image>
          </div>
        </div>
      </div>
      <div class="nine" v-if="images.length === 9">
        <div class="section">
          <div class="img" v-for="(item, index) in images" :key="index">
            <el-image @click="handleLook" :src="item" fit="cover"></el-image>
          </div>
        </div>
      </div>
    </div>
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="urls" />
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: { ElImageViewer },
  props: ["images"],
  name: "ImageContainer",
  data() {
    return {
      urls: [], //预览的图片列表
      showViewer: false, //控制显示隐藏
    };
  },
  methods: {
    //关闭
    closeViewer() {
      this.showViewer = false;
    },
    //打开
    handleLook() {
      this.urls = this.images.map((item) => item);
      this.showViewer = true;
    },
  },
};
</script>
<style scoped lang="scss">
.el-image {
  border-radius: 0.08rem;
  overflow: hidden;
}

.one {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  .img {
    .el-image {}
  }
}

.two {
  width: 6.3rem;
  height: 2.7rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: row;

  .img {
    margin: 0 0.02rem;

    .el-image {
      width: 2.6rem;
      height: 2.6rem;
    }
  }
}

.three {
  width: 6.3rem;
  height: 2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: row;

  .img {
    margin: 0 0.02rem;

    .el-image {
      width: 2.1rem;
      height: 2.1rem;
    }
  }
}

.four {
  width: 6.3rem;
  height: 6.3rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .img {
    margin: 0 0.02rem;

    .el-image {
      width: 3.1rem;
      height: 3.1rem;
    }
  }
}

.five {
  width: 6.3rem;
  height: 7.4rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  .section-1 {
    width: 6.3rem;
    // height: 5.3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .left {
      width: 4.1rem;
      height: 4.1rem;

      .img {
        .el-image {
          width: 4.1rem;
          height: 4.1rem;
        }
      }
    }

    .right {
      width: 2.1rem;
      height: 2.1rem;

      .img {
        margin: 0.01rem 0.02rem;

        .el-image {
          width: 2.1rem;
          height: 2.05rem;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .section-2 {
    width: 6.3rem;
    height: 6.3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .imgs {
      display: flex;
      flex-direction: row;

      .img {
        margin: 0.02rem 0.015rem;

        .el-image {
          width: 3.1rem;
          height: 3.1rem;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.six {
  width: 6.3rem;
  height: 6.3rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  .section-1 {
    width: 6.3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .left {
      width: 4.17rem;
      height: 4.17rem;

      .img {
        .el-image {
          width: 4.17rem;
          height: 4.17rem;
        }
      }
    }

    .right {
      width: 2.08rem;
      height: 2.08rem;
      margin-left: 0.02rem;

      .img {
        margin: 0.01rem;

        .el-image {
          width: 2.08rem;
          height: 2.07rem;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .section-2 {
    width: 6.3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .imgs {
      display: flex;
      flex-direction: row;

      .img {
        margin: 0.02rem 0.015rem;

        .el-image {
          width: 2.08rem;
          height: 2.08rem;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.seven {
  width: 6.3rem;
  height: 6.3rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: row;

  >.section-1 {
    width: 3.16rem;
    height: 3.16rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0.01rem;

    .img {
      .el-image {
        width: 3.16rem;
        height: 3.16rem;
      }
    }
  }

  >.section-2 {
    >.top {
      width: 3.16rem;
      height: 1.58rem;
      display: flex;
      flex-direction: row;

      >.img {
        margin: 0 0.02rem;

        .el-image {
          width: 1.56rem;
          height: 1.56rem;
        }
      }
    }

    .center {
      width: 3.16rem;
      height: 3.18rem;
      overflow: hidden;

      .img {
        margin: 0.01rem 0.02rem;

        .el-image {
          width: 3.16rem;
          height: 3.16rem;
        }
      }
    }
  }
}

.eight {
  width: 6.3rem;
  //height: 6.3rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  >.section-1 {
    width: 6.3rem;
    height: 2.12rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .img {
      &:nth-child(2) {
        margin: 0.01rem 0.02rem;
      }

      .el-image {
        width: 2.08rem;
        height: 2.1rem;
      }
    }
  }

  >.section-2 {
    width: 6.3rem;
    height: 3.2rem;

    .left {
      width: 3.15rem;
      height: 3.16rem;
      display: flex;
      flex-direction: row;

      .img {
        margin: 0.02rem 0.01rem;

        .el-image {
          width: 3.15rem;
          height: 3.16rem;
        }
      }
    }
  }
}

.nine {
  width: 6.3rem;
  height: 6.3rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  .section {
    width: 6.3rem;
    height: 1.75rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .img {
      margin: 0 0.01rem 0.01rem 0.01rem;

      .el-image {
        width: 2.08rem;
        height: 2.08rem;
      }
    }
  }
}
</style>
