<!-- 资源详情 -->
<template>
    <div class="source">
        <div class="floor1">
            <div class="floor1-head flex-row-center">
                <div class="txt">{{ $t('circle.feed_detail') }}</div>
                <img src="@/assets/icons/san.png" />
                <div class="txt">{{ info.title }}</div>
                <img src="@/assets/icons/san.png" />
                <div class="txt diff">{{ $t('circle.source_detail') }}</div>
            </div>
            <div class="floor1-bot flex-row">
                <div class="floor1-bot-left" v-if="info.image && info.image.length">
                    <el-carousel height="3.46rem">
                        <el-carousel-item v-for="item in info.image" :key="item">
                            <img :src="item" class="cover" />
                        </el-carousel-item>
                    </el-carousel>
                    <div class="mark" v-if="info && info.category">{{ info.category.name }}</div>
                </div>
                <div class="floor1-bot-right">
                    <div class="tit-wrap flex-row-center">
                        <div class="biao">{{ info.type == 1 ? $t('circle.export') : $t('circle.organ') }}</div>
                        <div class="tit">{{ info.title }}</div>
                    </div>
                    <div class="desc" v-if="info.type == 1">{{ $t('circle.main_character') }} : {{ info.main_character }}
                    </div>
                    <div class="desc" v-if="info.type == 1">{{ $t('circle.other_character') }}：{{ info.other_character }}
                    </div>
                    <div class="detail">{{ info.introduction }}
                    </div>
                    <div class="rank flex-row-center" v-if="info.level">
                        <div class="label"> {{ $t('circle.level') }}：</div>
                        <img v-for="item in info.level" :key="item" src="@/assets/icons/star.png" />
                    </div>
                    <div class="btn-wrap flex-row-center">
                        <div class="btn" @click="handleApply(info.id)">
                            {{ info.has_apply == 1 ? $t('task.applyed') : $t('circle.apply_service') }}</div>
                        <div class="btn diff" @click="handleCollect(info.has_collect, info.id)">
                            {{ info.has_collect == 0 ? $t('circle.collect') : $t('task.collected') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="floor2">
            <div class="floor2-head flex-row-center-between">
                <Avatar size="medium3" :user="info.user" :showName="true" nameWidth="5rem" width="11rem" />
                <div class="focus" v-if="info && info.user && info.user.has_follow == 0" @click="handleFollow(0, item.id)">
                    {{ $t('index.focus') }}</div>
                <div class="focus diff" v-if="info && info.user && info.user.has_follow == 1"
                    @click="handleFollow(1, item.id)"> {{ $t('index.focused') }}
                </div>
            </div>
            <div class="title">{{ $t('circle.source_info') }}：</div>
            <div class="flex-row-center row">
                <div class="label" v-if="info.type == 2">{{ $t('circle.orgname') }}：</div>
                <div class="val" v-if="info.type == 2" style="margin-right:1rem">{{ info.organization }}</div>
                <div class="label">{{ $t('circle.country') }}：</div>
                <div class="val" style="margin-right:1rem"> {{ info.country }}</div>
                <div class="label"> {{ $t('circle.city') }}：</div>
                <div class="val" style="margin-right:1rem">{{ info.city }}</div>
                <div class="label">{{ $t('circle.category') }}：</div>
                <div class="val">{{ info.category && info.category.name }}</div>
            </div>
            <div class="row flex-row">
                <div class="label">{{ $t('circle.other_city') }}：</div>
                <div class="val">{{ info.other_city }}</div>
            </div>
            <div class="row flex-row">
                <div class="label">{{ $t('circle.industry') }}：</div>
                <div class="val"><span style="margin-right:5px" v-for="(item, index) in info.industry">{{ item.name
                }}</span>
                </div>
            </div>
            <!-- <div class="row flex-row-center">
                <div class="label">{{ $t('circle.category') }} ：</div>
                <div class="val">{{ info.category && info.category.name }} </div>
            </div> -->
            <div class="row flex-row">
                <div class="label">{{ $t('circle.introduction') }}：</div>
                <div class="val">{{ info.introduction }}</div>
            </div>
            <div class="row flex-row">
                <div class="label">{{ $t('circle.background') }} ：</div>
                <div class="val">{{ info.background }}</div>
            </div>
            <div class="row flex-row">
                <div class="label">{{ $t('circle.main_field') }}：</div>
                <div class="val">{{ info.main_field }}</div>
            </div>
            <div class="row flex-row">
                <div class="label">{{ $t('circle.achievement') }}：</div>
                <div class="val">{{ info.achievement }}</div>
            </div>
            <div class="row flex-row-center">
                <div class="label">{{ $t('circle.label') }}：</div>
                <div class="con different"> <span style="margin-right:0.05rem" v-for="(iten, index) in info.labelArr"
                        @click="handleLabel(iten)">#{{ iten }}</span></div>
            </div>
            <div class="row flex-row" v-if="info.out_line && info.out_line.length">
                <div class="label">{{ $t('circle.link') }}：</div>
                <div class="sec flex-col">
                    <div class="link-item flex-row-center flex-row-wrap" v-for="(itemt) in info.out_line">
                        <span class="label">{{ itemt.name }}：</span>
                        <a :href="itemt.url" target="_blank">{{ itemt.url }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="floor3" v-if="list.length">
            <div class="floor3-tit">{{ $t('circle.link_feed') }} ：</div>
            <ul>
                <li v-for="(item, index) in list" :key="index" @click="handleSkip(`/feedDetail?id=${item.id}`)">
                    <div class="name">{{ item.title }}</div>
                    <div class="flex content x-ell-2">
                        {{ item.content }}
                        <span>{{ $t('circle.look') }}</span>
                    </div>
                    <div class="description">{{ item.like_count }}{{
                        $t('activity.zan') }}{{ item.comment_count }}{{ $t('circle.comment') }}</div>
                </li>
            </ul>
            <div class="more" @click="handleMore">{{ $t('circle.look_more') }}</div>
        </div>
        <div class="floor4" v-if="sourceList.length">
            <div class="floor4-tit">{{ $t('circle.like_source') }} ：</div>
            <ul class="flex-row-wrap flex-row-center-between">
                <li v-for="(item, index) in sourceList" :key="index" class="flex-col">
                    <Avatar size="medium3" :user="item.user" :showName="true" />
                    <!-- <div class="floor4-item">服务定价: ¥75000</div> -->
                    <div class="floor4-item-label flex-row-center-between">
                        <div class="label">{{ $t('task.service_time') }}: {{ item.created_at }}</div>
                        <div class="look" @click="handleDetail(item.user.id)">{{ $t('task.view') }} </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 未认证弹窗 -->
        <ApplyService :show="show" @handleClose="show = false" :tip="$t('circle.no_apply')" />
        <!-- 申请接单成功 -->
        <CustomerService :show="show2" @handleClose="show2 = false" />
    </div>
</template>
<script>
import CustomerService from '@/views/personal/components/CustomerService'
import ApplyService from '@/components/ApplyService.vue'
import Avatar from "@/components/Avatar.vue";
export default {
    components: {
        CustomerService,
        Avatar,
        ApplyService
    },
    data() {
        return {
            show: false,
            show2: false,
            info: {},
            sourceList: [],//相似资源
            list: [],//关联动态
            lastPage: 0,
            listQuery: {
                page: 1,
                resource_id: '',
                paginate: 3
            },
            user: this.$storage.getData("userInfo")
        }
    },
    created() {
        if (this.$route.query.id) {
            this.listQuery.resource_id = this.$route.query.id
            this.getInfo();
            this.getList()
            this.getResource()
        }
    },
    methods: {
        //标签点击事件
        handleLabel(label) {
            const txt = label.replace(/#/g, ",");
            this.$router.push(`/search?search=${txt}&current=5&flag=1`)
        },
        //申请服务
        handleApply(id) {
            if (!this.user) {
                this.$router.push('/login')
                return
            }
            if (this.user && this.user.certificate) {
                this.$api.post(`/api/apply/resource/${id}`).then(() => {
                    this.show2 = true
                }).catch(() => {
                    this.$message.error(this.$t('circle.apply_fail'))
                })
            } else {
                this.$api.get(`/api/user`).then(res => {
                    this.user = res.data
                    this.$storage.userInfo();
                    if (this.user && this.user.certificate) {
                        this.$api.post(`/api/apply/resource/${id}`).then(() => {
                            this.show2 = true
                        }).catch(() => {
                            this.$message.error(this.$t('circle.apply_fail'))
                        })
                    } else {
                        this.show = true
                    }
                })

            }





        },
        getInfo() {
            this.$api({
                url: `/api/resource/${this.$route.query.id}`
            }).then((res) => {
                this.info = res.data.data
                if (this.info.label && this.info.label.length) {
                    let labelArr = this.info.label.split('#')
                    labelArr = labelArr.filter(item => item.length > 0)
                    this.$set(this.info, 'labelArr', labelArr)
                } else {
                    this.$set(this.info, 'labelArr', [])
                }

            });
        },
        //查看主页
        handleDetail(id) {
            this.$router.push(`/personal?id=${id}`)
        },
        //查询资源关联动态
        getList(page) {
            if (page) this.listQuery.page = page
            this.$api.get('/api/post', { params: this.listQuery }).then((res) => {
                if (this.listQuery.page == 1) {
                    this.list = res.data.data
                } else {
                    this.list = [...this.list, ...res.data.data]
                }
                this.lastPage = res.data.meta.last_page
            })
        },
        //查看更多资源动态
        handleMore() {
            if (this.listQuery.page <= this.lastPage) {
                this.listQuery.page++
                this.getList()
            } else {
                this.$message.warning(this.$t('circle.nomore'))
            }

        },
        //相似资源
        getResource() {
            this.$api.get(`/api/resource`, { params: { like_id: this.$route.query.id } }).then(res => {
                this.sourceList = res.data.data
            })
        },
        //收藏
        handleCollect(state, id) {
            if (!this.user) {
                this.$router.push('/login')
                return
            }
            if (state == 0) {
                // 收藏
                this.info.has_collect = 1
                this.$api.post(`/api/collect/resource/${id}`).then(() => {
                    this.$message.success(this.$t('circle.collect_success'))
                })
            } else {
                //取消点赞
                this.info.has_collect = 0
                this.$api.delete(`/api/collect/resource/${id}`).then(() => {
                    this.$message.success(this.$t('circle.cancel_collect'))
                })
            }
        },
        //关注 取消关注
        handleFollow(state, id) {
            if (state == 0) {
                this.$api.post(`/api/follow/user/${id}`).then(() => {
                    this.info.user.has_follow = 1
                    this.$message.success(this.$t('circle.focus_success'))
                })
            } else {
                this.$api.delete(`/api/follow/user/${id}`).then(() => {
                    this.info.user.has_follow = 0
                    this.$message.success(this.$t('circle.cancel_success'))
                })
            }
        },
        //跳转
        handleHref(href) {
            location.href = href
        },
        handleSkip(url) {
            this.$router.push(url)
        }
    },
};
</script>

<style lang="scss" scoped>
.source {
    width: 13.2rem;
    margin: 0.92rem auto 1rem;

    .floor1 {
        background-color: #ffffff;
        padding: 0.3rem;
        border-radius: 0.03rem;
        box-sizing: border-box;

        .floor1-head {
            .txt {
                font-size: 0.17rem;
                color: #848484;
            }

            .diff {
                color: #2B2B2B;
            }

            img {
                width: 0.07rem;
                margin: 0 0.09rem;
            }

        }

        .floor1-bot {
            margin-top: 0.3rem;

            .floor1-bot-left {
                width: 4.35rem;
                height: 3.46rem;
                border-radius: 0.06rem;
                position: relative;
                background-color: #efefef;

                .cover {
                    width: 4.35rem;
                    height: 3.46rem;
                    border-radius: 0.06rem;

                }

                .mark {
                    min-width: 1.02rem;
                    padding: 0 0.05rem;
                    height: 0.37rem;
                    background: #FFEEDC;
                    border-radius: 0.06rem 0rem 0.06rem 0rem;
                    position: absolute;
                    text-align: center;
                    line-height: 0.37rem;
                    font-size: 0.16rem;
                    color: #A8641C;
                    top: 0;
                    left: 0;
                    z-index: 9;

                }
            }

            .floor1-bot-right {
                margin-left: 0.2rem;
                flex: 1;

                .tit-wrap {
                    margin-bottom: 0.14rem;

                    .biao {
                        width: 0.5rem;
                        height: 0.27rem;
                        background: #FF580F;
                        border-radius: 0.14rem;
                        font-size: 0.14rem;
                        text-align: center;
                        line-height: 0.27rem;
                        color: #FFFFFF;
                    }

                    .tit {
                        font-size: 0.24rem;
                        color: #262423;
                        margin-left: 0.06rem;
                    }
                }

                .desc {
                    font-size: 0.16rem;
                    color: #262423;
                    line-height: 0.29rem;
                }

                .detail {
                    margin-top: 0.14rem;
                    color: #8B8B8B;
                    font-size: 0.16rem;
                    line-height: 0.29rem;
                }

                .rank {
                    .label {

                        color: #8B8B8B;
                        font-size: 0.16rem;

                    }

                    img {
                        width: 0.2rem;
                        margin-right: 0.1rem;

                    }
                }

                .price {
                    margin-top: 0.06rem;

                    .label {
                        color: #8B8B8B;
                        font-size: 0.16rem;

                    }

                    .val {

                        color: #FF471A;
                        font-size: 0.2rem;

                    }
                }

                .btn-wrap {
                    margin-top: 0.25rem;

                    .btn {
                        width: 2.35rem;
                        height: 0.58rem;
                        text-align: center;
                        line-height: 0.58rem;
                        color: #ffffff;
                        font-size: 0.16rem;
                        margin-right: 0.5rem;
                        border: 1px solid #02428C;
                        background-color: #02428C;
                        cursor: pointer;
                        border-radius: 0.06rem;

                    }

                    .diff {
                        border: 1px solid #02428C;
                        background-color: #ffffff;
                        color: #02428C;

                    }

                }
            }

        }

    }

    .floor2 {
        margin-top: 0.3rem;
        padding: 0.3rem;
        background-color: #ffffff;
        border-radius: 0.03rem;
        box-sizing: border-box;

        .floor2-head {
            padding-bottom: 0.3rem;
            border-bottom: 1px solid #EAEAEA;

            .focus {
                width: 0.69rem;
                height: 0.32rem;
                border-radius: 0.03rem;
                border: 0.01rem solid #02428C;
                text-align: center;
                line-height: 0.32rem;
                cursor: pointer;
                font-size: 0.16rem;
            }

            .diff {
                border: 0.01rem solid #9A9A9A;
                color: #9A9A9A;
            }
        }

        .title {
            font-size: 0.2rem;
            color: #202020;
            line-height: 0.8rem;
        }

        .row {
            margin-bottom: 0.2rem;
            font-size: 0.16rem;

            .label {
                color: #8B8B8B;
                line-height: 0.3rem;
            }

            .val {
                color: #262423;
                flex: 1;
                line-height: 0.3rem;
            }

            .sec {
                width: 7.49rem;
                height: auto;
                background: #FBFBFB;
                border-radius: 0.04rem;
                padding: 0.14rem 0.2rem;
                box-sizing: border-box;

                .link-item {
                    a {
                        color: #457FF6;
                        text-decoration: none;
                    }

                }

                img {
                    width: 0.79rem;
                    height: 0.81rem;
                    background: #efefef;
                    margin-right: 0.22rem;

                }


            }
        }

    }

    .floor3 {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border-radius: 0.03rem;
        padding: 0.3rem;
        box-sizing: border-box;
        margin-top: 0.3rem;

        .floor3-tit {
            font-size: 0.2rem;
            color: #202020;
        }

        ul {
            li {
                margin-top: 0.2rem;
                height: 1.8rem;
                background: #F9F9F9;
                width: 100%;
                padding: 0.22rem;
                box-sizing: border-box;
                border-radius: 0.06rem;

                .name {
                    font-size: 0.2rem;

                    color: #202020;
                    line-height: 0.36rem;

                }

                .content {
                    margin-top: 0.07rem;
                    font-size: 0.16rem;
                    color: #202020;
                    line-height: 0.35rem;

                    span {
                        color: #5278D5;
                        font-size: 0.16rem;
                        cursor: pointer;

                    }
                }

                .description {
                    margin-top: 0.07rem;
                    color: #8A8A8A;
                    font-size: 0.14rem;

                }


            }
        }

        .more {
            color: #5278D5;
            line-height: 0.32rem;
            margin-top: 0.4rem;
            cursor: pointer;
            text-align: center;
        }


    }

    .floor4 {
        margin-top: 0.3rem;
        border-radius: 0.06rem;
        padding: 0.3rem;
        box-sizing: border-box;
        background: #ffffff;

        .floor4-tit {
            font-size: 0.2rem;
            color: #202020;
        }

        ul {
            li {
                margin-top: 0.2rem;
                width: 49%;
                height: auto;
                background: #FAFAFA;
                border-radius: 0.09rem;
                padding: 0.16rem;
                box-sizing: border-box;

                .floor4-item {
                    font-size: 0.16rem;
                    color: #5077D5;
                    line-height: 0.29rem;
                    margin-top: 0.1rem;

                }

                .floor4-item-label {
                    font-size: 0.16rem;
                    color: #5077D5;
                    line-height: 0.29rem;

                    .label {
                        font-size: 0.16rem;
                    }

                }

                .look {
                    width: 1.44rem;
                    height: 0.42rem;
                    border-radius: 0.04rem;
                    border: 0.01rem solid #2380D8;
                    cursor: pointer;
                    text-align: center;
                    line-height: 0.42rem;
                    font-size: 0.16rem;

                }



            }
        }


    }

}


.different {
    color: #02428C;
    cursor: pointer;
}
</style>