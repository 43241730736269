<template>
  <div :class="size" @click="goDetail(user.id)" style="cursor: pointer">
    <div class="user-info flex-row">
      <div class="user-avatar">
        <el-image fit="fill" v-if="user && user.avatar" :src="user.avatar">
        </el-image>
        <el-image fit="cover" v-else :src="require('@/assets/icons/default.png')">
        </el-image>
        <img src="@/assets/icons/iconV.png" class="mark" v-if="user && user.certificate" />
      </div>
      <div class="info flex-col" v-if="showName">
        <div class="flex-row-center">
          <div class="name flex-row-center" :style="size == 'mini' ? 'font-size: 0.1rem' : ''">
            <div class="user-name x-ell-1" :style="{'max-width':nameWidth?nameWidth:''}"> {{ user && user.name }}</div>
            <div class="label flex-row-center-between" v-if="user && user.gard && user.gard.gard">
            <img src="@/assets/icons/hui.png" />
            <div class="label-left">{{ user && user.gard && user.gard.gard }}</div>
            <!-- <div class="label-right">北京市计算机圈</div> -->
          </div>
            <ul style="flex:1" class="ul" v-if="user&&userId!=user.id">
              <li v-if="user && user.has_follow == 0" @click.stop="handleFollow(0, user.id)" style="cursor: pointer">
                {{ $t('index.focus') }}
               
              </li>
              <li v-else @click.stop="handleFollow(1, user.id)" style="cursor: pointer;color: #8A8A8A;">
                {{ $t('index.focused') }}
              </li>
            </ul>
          </div>
          
        </div>
        <div class="desc x-ell-1" :style="{'width':width?width:'5rem'}">{{ user && user.introduction || $t('auth.nulldesc')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["size", "showName", "user","width",'nameWidth'],
  name: "Avatar",
  data() {
    return {
      defaultAtv: require("@/assets/icon/default.png"),
      userId: this.$storage.getData("userInfo")?this.$storage.getData("userInfo").id:'',
    };
  },
  methods: {
    goDetail(id) {

      this.$router.push(`/personal?id=${id}`);
    },
    //关注 取消关注
    handleFollow(state, id) {
      if (state == 0) {
        this.$api.post(`/api/follow/user/${id}`).then(() => {
          this.user.has_follow = 1
          this.$message.success(this.$t('circle.focus_success'))
        })
      } else {
        this.$api.delete(`/api/follow/user/${id}`).then(() => {
          this.user.has_follow = 0
          this.$message.success(this.$t('circle.cancel_success'))
        })
      }
    },
  },
};
</script>
<style scoped lang="scss">
.mini {
  .user-info {
    .user-avatar {
      width: 0.24rem;
      height: 0.24rem;
      position: relative;

      .el-image {
        width: 0.24rem;
        height: 0.24rem;
        border-radius: 50%;
      }

      .mark {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0.2rem;
        height: 0.2rem;
        text-align: center;
        line-height: 0.2rem;
        color: #ffffff;
        background: #ffffff;
        z-index: 9999;
        font-size: 0.16rem;
        border-radius: 50%;

      }

    }

    .info {
      margin-left: 0.14rem;
      margin-top: 0.06rem;


      .name {
        font-size: 0.2rem;
        color: #2B2B2B;
        margin-right: 0.14rem;
      


      }

      .label {
        padding: 0 0.09rem;
        height: 0.3rem;
        background: #E6ECFB;
        border-radius: 0.15rem;

        img {
          width: 0.19rem;



        }

        .label-left {
          font-size: 0.14rem;


          color: #02428C;
          margin: 0 0.06rem;

        }

        .label-right {
          font-size: 0.14rem;


          color: #02428C;

        }

      }

    }
  }
}

.medium {
  .user-info {
    .user-avatar {
      width: 0.69rem;
      height: 0.69rem;
      position: relative;

      .el-image {
        width: 0.69rem;
        height: 0.69rem;
        border-radius: 50%;
      }

      .mark {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0.2rem;
        height: 0.2rem;

        text-align: center;
        line-height: 0.2rem;
        color: #ffffff;
        background-color: #ffffff;
        font-size: 0.16rem;
        border-radius: 50%;

      }

    }

    .info {
      margin-left: 0.14rem;
      margin-top: 0.06rem;

      .name {
        font-size: 0.2rem;


        color: #2B2B2B;
        margin-right: 0.14rem;

      }

      .label {
        padding: 0 0.09rem;
        height: 0.3rem;
        background: #E6ECFB;
        border-radius: 0.15rem;

        img {
          width: 0.19rem;
          height: 0.19rem;


        }

        .label-left {
          font-size: 0.14rem;


          color: #02428C;
          margin: 0 0.06rem;

        }

        .label-right {
          font-size: 0.14rem;


          color: #02428C;

        }

      }

    }
  }
}

.medium2 {
  .user-info {
    .user-avatar {
      width: 0.6rem;
      height: 0.6rem;
      position: relative;

      .el-image {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
      }

      .mark {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0.2rem;
        height: 0.2rem;

        text-align: center;
        line-height: 0.2rem;
        color: #ffffff;
        background-color: #ffffff;
        font-size: 0.16rem;
        border-radius: 50%;

      }

    }

    .info {
      margin-left: 0.14rem;
      margin-top: 0.06rem;

      .name {
        font-size: 0.2rem;
        color: #2B2B2B;
        margin-right: 0.14rem;

      }

      .label {
        padding: 0 0.09rem;
        height: 0.3rem;
        background: #E6ECFB;
        border-radius: 0.15rem;

        img {
          width: 0.19rem;
          height: 0.19rem;


        }

        .label-left {
          font-size: 0.14rem;


          color: #02428C;
          margin: 0 0.06rem;

        }

        .label-right {
          font-size: 0.14rem;


          color: #02428C;

        }

      }

    }
  }
}

.medium3 {
  .user-info {
    .user-avatar {
      width: 0.5rem;
      height: 0.5rem;
      position: relative;

      .el-image {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
      }

      .mark {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0.2rem;
        height: 0.2rem;

        text-align: center;
        line-height: 0.2rem;
        color: #ffffff;
        background-color: #ffffff;
        font-size: 0.16rem;
        border-radius: 50%;

      }

    }

    .info {
      margin-left: 0.14rem;
      margin-top: 0.06rem;

      .name {
        font-size: 0.2rem;


        color: #2B2B2B;
        margin-right: 0.14rem;

      }

      .label {
        padding: 0 0.09rem;
        height: 0.3rem;
        background: #E6ECFB;
        border-radius: 0.15rem;

        img {
          width: 0.19rem;
          height: 0.19rem;


        }

        .label-left {
          font-size: 0.14rem;


          color: #02428C;
          margin: 0 0.06rem;

        }

        .label-right {
          font-size: 0.14rem;


          color: #02428C;

        }

      }

    }
  }
}

.big {
  .user-info {
    .user-avatar {
      width: 1rem;
      height: 1rem;
      position: relative;

      .el-image {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
      }

      .mark {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0.2rem;
        height: 0.2rem;

        text-align: center;
        line-height: 0.2rem;
        color: #ffffff;
        background-color: #ffffff;
        font-size: 0.16rem;
        border-radius: 50%;

      }

    }

    .info {
      margin-left: 0.14rem;
      margin-top: 0.06rem;

      .name {
        font-size: 0.2rem;


        color: #2B2B2B;
        margin-right: 0.14rem;

      }

      .label {
        padding: 0 0.09rem;
        height: 0.3rem;
        background: #E6ECFB;
        border-radius: 0.15rem;

        img {
          width: 0.19rem;
          height: 0.19rem;


        }

        .label-left {
          font-size: 0.14rem;


          color: #02428C;
          margin: 0 0.06rem;

        }

        .label-right {
          font-size: 0.14rem;


          color: #02428C;

        }

      }

    }
  }
}


.desc {
  font-size: 0.16rem;
  width: 5rem;
  color: #8A8A8A;
  line-height: 0.24rem;
  margin-top: 0.04rem;


}

.ul {
  display: flex;
  flex-direction: row;
  margin-left: 0.2rem;
  align-items: center;


  li {
    font-size: 0.15rem;


    color: #02428C;

  }

}

.user-name{
          // width: 1rem;
         
        }
</style>
