<template>
    <div @click="handleSkip(`/taskDetail?id=${item.id}`)" class="task-item">
        <div class="task-head flex-row-center-between">
            <div class="state state1" v-if="item.status == -1">{{ $t('task.status4') }}</div>
            <div class="state state1" v-if="item.status == 0">{{ $t('personal.status0') }}</div>
            <div class="state state2" v-if="item.status == 1">{{ $t('personal.status1') }}</div>
            <div class="state state2" v-if="item.status == 2">{{ $t('task.status2') }}</div>
            <div class="state state2" v-if="item.status == 4">{{ $t('task.status4') }}</div>
            <div class="gou flex-row-center" v-if="item.status == 3">
                <img src="@/assets/icons/gou.png" />
                <span>{{ $t('task.status3') }}</span>
            </div>
            <div class="btn">
                {{ $t('task.task_detail') }}

            </div>
        </div>
        <div class="task-info">
            <div class="title flex-row-wrap">
                <div class="tit">{{ item.title }}
                    <span class="topic" v-for="(itemt, i) in item.label.split(',')">{{ itemt }}</span>

                </div>
            </div>
            <div class="flex-row-center label">
                <img src="@/assets/icons/task1.png" />
                <div class="label-right">{{ $t('task.content') }}：</div>
            </div>
            <div class="desc">
                {{ item.content }}</div>
            <div class="flex-row-center label">
                <img src="@/assets/icons/task2.png" />
                <div class="label-right">{{ $t('task.deliverable') }}：</div>
            </div>
            <div class="desc">
                {{ item.deliverable }}
            </div>
            <div class="flex-row-center label">
                <img src="@/assets/icons/task3.png" />
                <div class="label-right">{{ $t('task.category') }}：</div>
                <div class="label-main">{{ item.category && item.category.name }}</div>
                <img src="@/assets/icons/task4.png" />
                <div class="label-right">{{ $t('task.budget') }}：</div>
                <div class="label-main">{{ item.budget }}</div>
                <img src="@/assets/icons/task5.png" />
                <div class="label-right">{{ $t('task.due_time') }}：</div>
                <div class="label-main">{{ item.due_time }}{{ $t('task.front') }}</div>
            </div>
            <div class="flex-row-center label">
                <img src="@/assets/icons/task6.png" />
                <div class="label-right">{{ $t('task.country') }}：</div>
                <div class="label-main" v-if="item.country && item.country.length&&item.country[0]!=''">
                    <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.country" :key="i">{{ itemt
                    }}</span>

                </div>
                <div class="label-main" v-else>
                    {{ $t('index.no_limit') }}
                   
                </div>
            </div>
            <div class="flex-row-center label">
                <img src="@/assets/icons/task7.png" />
                <div class="label-right">{{ $t('task.city') }}：</div>
                <div class="label-main" v-if="item.city&&item.city.length&&item.city[0]!=''"> <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.city" :key="i">{{
                    itemt }}</span>
                </div>
                <div class="label-main" v-else>
                     {{ $t('index.no_limit') }}
                </div>
            </div>
            <div class="flex-row-center label">
                <img src="@/assets/icons/task8.png" />
                <div class="label-right">{{ $t('task.industry') }}：</div>
                <div class="label-main" v-if="item.industry&&item.industry.length">
                    <span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.industry" :key="i">{{
                        itemt.name }}</span>
                </div>
                <div class="label-main" v-else>
                     {{ $t('index.no_limit') }}
                </div>
            </div>
            <div class="flex-row-center label">
                <img src="@/assets/icons/task9.png" />
                <div class="label-right">{{ $t('task.label') }}：</div>
                <div class="label-main"><span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.label.split(',')">{{
                    itemt }}</span>
                </div>
            </div>
            <div class="timer">{{ $t('task.end_time') }}：{{ item.end_time }}</div>
        </div>
        <div class="sec-tit" v-if="item.task_user && item.task_user.length">{{ $t('task.task_user') }}</div>
        <ul class="users flex-row-wrap flex-row-center-between">
            <li v-for="(itemt, i) in item.task_user" class="flex-row-center">
                <Avatar size="medium3" :user="itemt.user" :showName="true" />
            </li>
        </ul>
    </div>
</template>
<script>
import Avatar from "@/components/Avatar";
export default {
    components: { Avatar },
    props: ['item'],
    data() {
        return {};
    },
    created() {
    },
    methods: {
        //跳转
        handleSkip(url) {
            this.$router.push(url)
        }
    },
};
</script>
<style scoped lang="scss">
.task-item {
    border-radius: 0.03rem;
    border: 0.01rem solid #EAEAEA;


    cursor: pointer;
    margin-top: 0.3rem;
    padding-bottom: 0.2rem;

    .task-head {
        height: 0.8rem;
        padding: 0 0.3rem;
        box-sizing: border-box;
        border-bottom: 1px solid #EAEAEA;

        .btn {
            cursor: pointer;
            width: 2rem;
            height: 0.5rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #979797;
            text-align: center;
            line-height: 0.5rem;
            color: #848484;
            font-size: 0.16rem;
        }

        .state {
            width: 0.98rem;
            height: 0.4rem;
            background: #E9FBE4;
            border-radius: 0.06rem;
            text-align: center;
            line-height: 0.4rem;
            font-size: 0.17rem;
            color: #54BE13;

        }

        .state2 {
            background-color: #FDEDDB;
            color: #E39B4B;
        }

        .gou {
            img {
                width: 0.26rem;
                margin-right: 0.05rem;
            }

            span {
                font-size: 0.17rem;

                color: #02428C;
            }

        }

    }

    .task-info {
        padding: 0.2rem 0.3rem;
        border-bottom: 1px solid #EAEAEA;

        .title {

            color: #202020;

            .tit {
                font-size: 0.2rem;

            }

            .topic {
                font-size: 0.16rem;
                color: #02428C;
                display: inline-block;

            }

        }

        .label {
            margin-top: 0.18rem;

            img {
                width: 0.18rem;

                margin-right: 0.1rem;

            }

            .label-right {
                font-size: 0.16rem;
                color: #02428C;

            }

            .label-main {
                font-size: 0.16rem;
                color: #828282;
                margin-right: 0.37rem;
            }

        }

        .desc {
            font-size: 0.16rem;
            color: #828282;
            line-height: 0.29rem;
            margin-top: 0.1rem;

        }
    }
}

.sec-tit {
    font-size: 0.17rem;
    color: #202020;
    margin: 0.3rem;
}

.timer {
    width: 3.8rem;
    height: 0.55rem;
    background: #E6ECFB;
    border-radius: 0.04rem;
    text-align: center;
    line-height: 0.55rem;
    cursor: pointer;
    color: #02428C;
    font-size: 0.16rem;
    margin-top: 0.2rem;


}

.users {
    padding: 0 0.3rem;

    li {
        width: 48%;
        height: 0.98rem;
        background: #FAFAFA;
        border-radius: 0.09rem;
        padding: 0.13rem;
        box-sizing: border-box;
        margin-bottom: 0.2rem;


    }

}
</style>
