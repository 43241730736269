<template>
  <div class="home">
	<el-backtop></el-backtop>
	<div class='content'></div>
</div>

  <!-- <div>
    <ul>
      <li v-for="item in list" :key="item.id" @click="onClickItem(item.id)">
        <img :src="item.img" />
      </li>
    </ul>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      scrollTop: "",
      list: [
        {
          id: 1,
          img: require("../assets/icon/top.png"),
        },
      ],
    };
  },
  methods: {
    //点击事件
    onClickItem(id) {
      //返回顶部
      this.goTop();
    },
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    goTop() {
      let timer = null,
        _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 50;
          document.body.scrollTop = document.documentElement.scrollTop =
            _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
ul {
  position: fixed;
  right: 0.5rem;
  bottom: 1.5rem;
  width: 0.5rem;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

li {
  width: 0.5rem;
  height: 0.5rem;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-bottom: 0.01rem;
  box-sizing: border-box;
  margin-bottom: 0.01rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

ul li img {
  width: 0.5rem;
}
</style>
