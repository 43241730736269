<!-- 我的主页 -->
<template>
  <div class="user flex-row">
    <div class="user-left">
      <div class="floor1">
        <div class="user-info flex-row-center-between">
          <div class="user-avatar">
            <el-image class="avatar" :src="user.avatar" v-if="user.avatar && user.avatar.length" />
            <img class="avatar" src="@/assets/icons/default.png" v-else />
            <img class="icon" src="@/assets/icons/iconV.png" v-if="user && user.certificate" />
          </div>
          <div class="user-sec">
            <div class="user-name-wrap flex-row-center">
              <div class="user-name x-ell-1">{{ user.name }}</div>
              <div class="label flex-row-center-between" @click="handleOpen2"
                v-if="user && user.gard && user.gard.gard">
                <img src="@/assets/icons/hui.png" />
                <div class="label-left">{{ user.gard && user.gard.gard }}</div>
                <div class="label-right"></div>
              </div>
              <div class="mark" v-if="certificate">
                <div v-if="certificate.status == 1" class="mark">{{ $t('personal.mark1') }}</div>
                <div v-if="certificate.status == 0" class="mark">{{ $t('personal.mark0') }}</div>
                <div v-if="certificate.status == -1" class="mark">{{ $t('personal.mark2') }}</div>
              </div>
            </div>
            <div class="ip">{{ $t('personal.ip') }} ：{{ user.ip_address}}</div>
            <div class="score flex-row-center">
              <div class="score-item" @click="handleLookFocus"> {{ $t('index.focus') }}：{{ user.follow_count }}</div>
              <div class="score-item" @click="handleLookFen">{{ $t('personal.fen') }}：{{ user.fans_count }}</div>
              <div class="score-item" @click="toIntegral">{{ $t('circle.point') }}：{{ user.points }}</div>
            </div>
          </div>
          <div class="user-operate" v-if="isSelf">
            <div class="share-wrap flex-row-center" @click="visible6 = true">
              <img src="@/assets/icons/share.png" />
              <span>{{ $t('circle.share') }}</span>
            </div>
            <div class="edit-btn" @click="handleSkip('/editUserInfo')">{{ $t('personal.edit_user') }}</div>
          </div>
          <div class="focus-btn" v-if="!isSelf && user.has_follow_user != 1"
            @click="handleFollow(user.has_follow_user, user.id)"> {{ $t('index.focus') }}</div>
          <div class="focus-btn diff" v-if="!isSelf && user.has_follow_user == 1"
            @click="handleFollow(user.has_follow_user, user.id)"> {{ $t('index.focused') }}</div>
        </div>
        <div class="desc">
          {{ user.introduction }}
        </div>
        <div class="flex-col">
          <div class="tab-wrap flex-row-center" v-if="user.country && user.country.length">
            <div class="tab-left">{{ $t('personal.country') }}：</div>
            <div class="tab-right flex-row-center">
              <span style="margin-right: 0.1rem;" v-for="(item, index) in user.country" :key="index">{{ item.name
              }}</span>
            </div>
          </div>
          <div class="tab-wrap flex-row-center" v-if="user.city && user.city.length">
            <div class="tab-left">{{ $t('personal.city') }}：</div>
            <div class="tab-right flex-row-center">
              <span style="margin-right: 0.1rem;" v-for="(item, index) in user.city" :key="index">{{ item.name }}</span>
            </div>
          </div>
          <div class="tab-wrap flex-row-center" v-if="user.industry && user.industry.length">
            <div class="tab-left">{{ $t('personal.industry') }}：</div>
            <div class="tab-right flex-row-center">
              <span style="margin-right: 0.1rem;" v-for="(item, index) in user.industry" :key="index">{{ item.name
              }}</span>
            </div>
          </div>
          <div class="tab-wrap flex-row-center" v-if="user.labelArr && user.labelArr.length">
            <div class="tab-left">{{ $t('personal.label') }}：</div>
            <div class="tab-right different flex-row-center">
              <span style="margin-right:0.05rem" v-for="(iten, index) in user.labelArr" @click="handleLabel(iten)">#{{ iten
              }}</span>

            </div>
          </div>
        </div>
      </div>
      <!-- 导航 -->
      <ul class="nav flex-row-center">
        <li class="flex-col-center" @click="handlenNav(index)" v-for="(item, index) in navs" :key="index" :class="{ 'active': current == index }">
          <div class="name">{{ item }}</div>
          <div class="line"></div>
        </li>
      </ul>
      <ul class="con">
        <!-- 动态 -->
        <li v-if="current == 0">
          <div v-for="(item, i) in feedList" :key="i">
            <FeedsCard :card="item" :isHome="false" size="medium2" @updatePost="getList(1)" />
          </div>
        </li>
        <!-- 资源 -->
        <li v-if="current == 1">
          <SourceCard :id="user.id" :list="sourceList" @handleClose="getList(1)" />
        </li>
        <!-- 圈子 -->
        <li v-if="current == 2">
          <CircleCard :list="circleList" @handleClose="getList(1)" @handleCircleType="handleCircleType" />
        </li>
        <!-- 人脉 -->
        <li v-if="current == 3">
          <ConnectCard :id="user.id" :list="users" @changeType="changeType" @handleFocus="handleFocus" />
        </li>
        <!-- 任务 -->
        <li v-if="current == 4">
          <div v-for="(item, i) in taskList" :key="i">
            <TaskCard :item="item" />
          </div>
        </li>
        <!-- 活动 -->
        <li v-if="current == 5">
          <ActivityCard :list="activityList" @handleType="handleType" />
        </li>
        <!-- 荣誉 -->
        <li v-if="current == 6">
          <HonorCard :list="honorList" />
        </li>
      </ul>
    </div>
    <div class="user-right">
      <div class="sec1 flex-col-center" v-if="isSelf">
        <div class="sec1-top flex-row-center-between">
          <div class="sec1-top-tit flex-row-center">
            <img src="@/assets/icons/sy.png" />
            <div class="sec1-top-name">{{ $t('personal.my_income') }}</div>
          </div>
          <div class="look-detail" @click="handleSkip(`/income`)">{{ $t('personal.look_detail') }}</div>
        </div>
        <div class="sec1-cen flex-col-center" @click="handleSkip(`/income?current=0`)">
          <div class="common-top">{{ user.income }}</div>
          <div class="common-bot">{{ $t('personal.income') }}</div>
        </div>
        <div class="sec1-bot flex-row-center-between">
          <div class="common-wrap flex-col-center" @click="handleSkip(`/income?current=2`)">
            <div class="common-top">{{ user.fuxing_income }}</div>
            <div class="common-bot">{{ $t('personal.fuxing_income') }}</div>
          </div>
          <div class="common-wrap flex-col-center" @click="handleSkip(`/income?current=1`)">
            <div class="common-top">{{ user.bole_income }}</div>
            <div class="common-bot">{{ $t('personal.bole_income') }}</div>
          </div>
        </div>
      </div>
      <div class="sec2" v-if="isSelf">
        <div class="common" @click="onClick('/rankList')">
          <img src="@/assets/icons/rank.png" />
          <span>{{ $t('personal.rank') }}</span>
        </div>
        <ul>
          <li class="flex-row-center" v-for="(item, index) in arr" :key="index" @click="toUserDetail(item.user.id)">
            <img class="biao" src="@/assets/icons/rank2.png" v-if="index == 0" />
            <img class="biao" src="@/assets/icons/rank1.png" v-else-if="index == 1" />
            <img class="biao" src="@/assets/icons/rank3.png" v-else-if="index == 2" />
            <span v-else>{{ index + 1 }}</span>
            <img class="avatar" v-if="item.user && item.user.avatar" :src="item.user && item.user.avatar" />
            <img class="avatar" src="@/assets/icon/default.png" v-else />
            <div class="users">
              <div class="nickName x-ell-1">{{ item.user && item.user.name || $t('auth.nullName') }}</div>
              <div class="score">{{ item.points }}{{ $t('circle.point') }}</div>
            </div>
          </li>
          <div class="line"></div>
        </ul>
        <div class="mores" @click="visible = true">
          {{ $t('task.lookAll') }}
        </div>
      </div>
      <!-- 他加入的圈子 -->
      <div class="sec3" v-if="!isSelf && hotCircle.length">
        <div class="common" @click="onClick('/rankList')">
          <span style="font-size: 0.18rem;">{{ $t('personal.tit1') }}</span>
        </div>
        <ul>
          <li v-for="(item, index) in hotCircle" :key="index" @click="onClick(`/circleDetail?id=${item.id}`)"
            style="cursor: pointer" class="flex-row-center-between">
            <div class="li-left">
              <img :src="item.icon" />
            </div>
            <div class="li-rig">
              <div class="li-rig-top">{{ item.name }}</div>
              <div class="li-rig-bot">{{ item.user_count }}{{ $t('personal.tit2') }}</div>
            </div>
            <div class="enter" v-if="item.has_join == 0" @click.stop="handleJoin(item.id)">{{ $t('circle.enter') }}</div>
            <div class="enter" v-if="item.has_join == 1">{{ $t('auth.joined') }}</div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 积分天梯榜 -->
    <PopupIntegral :visible="visible" @handleClose="visible = false" />
    <!-- 个人认证晋升说明弹窗 -->
    <PopupLevel :visible="visible2" @handleClose="visible2 = false" :user="user" />
    <!-- 粉丝列表 -->
    <FanList v-if="user_id"  :show="visible3" @handleClose="visible3 = false" :user_id="user_id" @updateUser="getUser" />
    <!-- 关注列表 -->
    <FocusList v-if="user_id"  :show="visible4" @handleClose="visible4 = false" :user_id="user_id" @updateUser="getUser" />
    <!-- 加圈申请 -->
    <ApplyJoin :show="visible5" @handleClose="visible5 = false" @handleConfirm="handleConfirm" />
    <!-- 分享弹窗 -->
    <SharePopup :show="visible6" @handleClose="visible6 = false" />
  </div>
</template>
<script>
import CircleCard from './components/CircleCard.vue'
import SharePopup from '@/components/SharePopup.vue'
import ApplyJoin from '@/components/ApplyJoin.vue'
import FeedsCard from "@/components/FeedsCard"
import PopupIntegral from './components/PopupIntegral'
import PopupLevel from './components/PopupLevel'
import TaskCard from './components/TaskCard.vue'
import HonorCard from './components/honorCard.vue'
import SourceCard from './components/SourceCard.vue'
import FanList from './components/FanList.vue'
import FocusList from './components/FocusList.vue'
import ActivityCard from './components/ActivityCard.vue'
import ConnectCard from './components/ConnectCard.vue'
export default {
  components: { FeedsCard, PopupIntegral, ActivityCard, PopupLevel, TaskCard, HonorCard, FanList, SourceCard, ConnectCard, FocusList, ApplyJoin, SharePopup, CircleCard },
  data() {
    return {
      user_id: null,
      user: {},//个人信息
      navs: [this.$t('index.feed'), this.$t('personal.users'), this.$t('feed.circle'), this.$t('index.friend'), this.$t('index.task'), this.$t('index.activity'), this.$t('personal.honor')],
      current: 0,//当前导航
      feedList: [],//动态列表
      page1: 1,
      lastPage1: 0,
      sourceList: [],//资源
      page2: 1,
      lastPage2: 0,
      users: [],//人脉
      type1: 'bole',
      page3: 1,
      lastPage3: 0,
      taskList: [],//任务
      page4: 1,
      lastPage4: 0,
      activityList: [],//活动
      page5: 1,
      lastPage5: 0,
      honorList: [],//荣誉
      isSelf: true,
      hotCircle: [],//热门圈子
      loading: false,
      //加圈
      form: {
        id: '',
        comment: ''
      },
      arr: [],  //积分天梯榜
      visible: false,//控制弹窗的显示和隐藏
      visible2: false,//控制个人认证晋升说明弹窗的显示和隐藏
      visible3: false,//控制粉丝列表弹窗的显示和隐藏
      visible4: false,//控制关注列表的显示和隐藏
      visible5: false,//加圈申请弹窗
      visible6: false,//控制分享弹窗的显示和隐藏
      type: 'join',
      certificate: null,
      circleList: [],//圈子列表
      page6: 1,
      lastPage6: 0,
      circleType: 'has_join'
    }

  },
  watch: {
    '$route.query.id': {
      handler(val) {
        if (val) {
          this.user = {}
          this.current = 0
          this.feedList = []
          this.sourceList = []
          this.users = []
          this.honorList = []
          this.taskList = []
          this.activityList = []
          this.taskList = []
          this.circleList = []



          let user = this.$storage.getData("userInfo")
          if(user&&user.id == val){
            this.isSelf = true
          }else{
            this.isSelf = false
          }

          this.certificate = null
          this.user_id = val
          this.getOtherUser()
          //获取热门圈子列表
          this.getHotCircle()
          //获取列表
          this.getList()
        } else {
          this.isSelf = true
          this.user_id = this.$storage.getData("userInfo")
            ? this.$storage.getData("userInfo").id
            : null
          //获取用户信息
          this.getUser()
          this.getCertificate()
          //获取积分排行榜
          this.getPointsRank()
          this.getList()

        }
      }
    }

  },
  created() {
    if (this.$route.query.id) {
      this.certificate = null
      this.user_id = this.$route.query.id
      let userInfo = this.$storage.getData("userInfo")
      if (userInfo&&this.user_id==userInfo.id) {
        this.isSelf = true
        this.getCertificate()
        //获取用户信息
        this.getUser()
        //获取积分排行榜
        this.getPointsRank()

      } else {
        this.isSelf = false
        this.getOtherUser()
        //获取热门圈子列表
        this.getHotCircle()
      }

    } else {
      this.getCertificate()
      this.isSelf = true
      this.user_id = this.$storage.getData("userInfo")
        ? this.$storage.getData("userInfo").id
        : null
      //获取用户信息
      this.getUser()
      //获取积分排行榜
      this.getPointsRank()
    }
    if (this.$route.query.currentIndex) {
      this.current = Number(this.$route.query.currentIndex)
    }
    //获取列表
    this.getList()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {


    // 监听如果页面发生滚动时
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //查看关注
    handleLookFocus(){
      this.visible4 = true
      // if(this.isSelf){
      //   this.visible4 = true
      // }
    },
     //查看粉丝
     handleLookFen(){
      this.visible3 = true
      // if(this.isSelf){
      //   this.visible3 = true
      // }
    },
    //标签点击事件
    handleLabel(label) {
      const txt = label.replace(/#/g, ",");
      this.$router.push(`/search?search=${txt}&current=5&flag=1`)

    },
    //圈子类型改变
    handleCircleType(type) {
      this.circleType = type;
      this.getList(1)

    },
    //去积分明细页面
    toIntegral() {
      // if (this.isSelf) {
        this.handleSkip(`/integral?isSelf=${Number(this.isSelf)}&user_id=${this.user_id}`)
      // }
    },
    //获取实名认证
    getCertificate() {
      this.$api.get('/api/my-certificate').then(res => {
        if (res.data.data) {
          this.certificate = res.data.data

        }
      })

    },
    //关注 取消关注
    handleFollow(state, id) {
      if (state == 0) {
        this.$api.post(`/api/follow/user/${id}`).then(() => {
          this.user.has_follow_user = 1
          this.$message.success(this.$t('circle.focus_success'))
        })
      } else {
        this.$api.delete(`/api/follow/user/${id}`).then(() => {
          this.user.has_follow_user = 0
          this.$message.success(this.$t('circle.cancel_success'))
        })
      }
    },
    //积分天梯榜用户点击事件
    toUserDetail(id) {
      let user = this.$storage.getData("userInfo")
      if (user) {
        if (user.id == id) {
          this.isSelf = true
        } else {
          this.isSelf = false
        }
      }
      this.$router.push(`/personal?id=${id}`)


      // this.user_id = id
      // this.getOtherUser()
      // //获取热门圈子列表
      // this.getHotCircle()
      // //获取列表
      // this.getList()
    },
    //导航栏点击事件
    handlenNav(index) {
      this.current = index
      this.getList()

    },
    //人脉类型改变
    changeType(current) {
      this.type1 = current == 0 ? 'bole' : 'fuxing'
      this.getList(1)
    },
    //关注 取消关注
    handleFocus({ index, has_follow }) {
      this.users[index].has_follow = has_follow

    },
    //获取用户信息
    getUser() {
      this.$api.get('/api/user').then(res => {
        this.user = res.data
        this.user_id = this.user.id
        if (this.user.label && this.user.label.length) {
          let labelArr = this.user.label.split('#')
          labelArr = labelArr.filter(item => item.length > 0)
          this.$set(this.user, 'labelArr', labelArr)
        } else {
          this.$set(this.user, 'labelArr', [])
        }

      })
    },
    //获取他人用户信息
    getOtherUser() {
      this.$api.get(`/api/user/${this.user_id}`).then(res => {
        this.user = res.data
      })

    },
    //获取热门圈子
    getHotCircle() {
      this.$api.get('/api/group', { params: { user_id: this.user_id, page: 1 } }).then(res => {
        let result = res.data.data
        this.hotCircle = result
      })
    },
    //加入圈子
    handleJoin(id) {
      this.form.id = id;
      this.visible5 = true
    },
    //确认加入圈子
    handleConfirm(comment) {
      this.form.comment = comment
      this.$api.post(`/api/join-group/${this.form.id}`, this.form).then(() => {
        this.$message.success(this.$t('auth.operate_success'))
        this.visible5 = false
        this.getHotCircle()
      }).catch(() => {
        this.$message.error(this.$t('auth.operate_fail'))
      })
    },
    //活动类型
    handleType(type) {
      this.type = type;
      this.getList(1)

    },
    //获取列表
    getList(page) {
      switch (this.current) {
        case 0:
          //动态
          if (page) this.page1 = page
          this.loading = true
          this.$api.get('/api/post', { params: { page: this.page1, user_id: this.user_id } }).then((res) => {
            if (this.page1 == 1) {
              this.feedList = res.data.data
            } else {
              this.feedList = [...this.feedList, ...res.data.data]
            }
            this.lastPage1 = res.data.meta.last_page
            this.loading = false
          })
          break;
        case 1:

          //资源
          if (page) this.page2 = page
          this.loading = true
          this.$api.get('/api/resource', { params: { page: this.page2, user_id: this.user_id } }).then((res) => {
            if (this.page2 == 1) {
              this.sourceList = res.data.data
            } else {
              this.sourceList = [...this.sourceList, ...res.data.data]
            }
            this.lastPage2 = res.data.meta.last_page
            this.loading = false
          })
          break;
        case 2:
          //圈子
          if (page) this.page6 = page
          this.loading = true
          this.$api.get('/api/group', { params: { page: this.page6, user_id: this.user_id, type: this.circleType } }).then((res) => {
            if (this.page6 == 1) {
              this.circleList = res.data.data
            } else {
              this.circleList = [...this.circleList, ...res.data.data]
            }
            this.lastPage6 = res.data.meta.last_page
            this.loading = false
          })
          break;
        case 3:
          //人脉
          if (page) this.page3 = page
          this.loading = true
          this.$api.get('/api/social-relationship', { params: { page: this.page3, user_id: this.user_id, type: this.type1 } }).then((res) => {
            if (this.page3 == 1) {
              this.users = res.data.data
            } else {
              this.users = [...this.users, ...res.data.data]
            }
            this.lastPage3 = res.data.meta.last_page
            this.loading = false
          })
          break;
        case 4:
          //任务
          if (page) this.page4 = page

          this.loading = true
          let user = this.$storage.getData("userInfo")

          this.$api.get('/api/user-task-personal', { params: { page: this.page4, user_id: user.id != this.user_id ? this.user_id : '' } }).then((res) => {
            if (this.page4 == 1) {
              this.taskList = res.data.data
            } else {
              this.taskList = [...this.taskList, ...res.data.data]
            }
            this.lastPage4 = res.data.meta.last_page
            this.loading = false
          })
          break;
        case 5:
          //活动
          if (page) this.page5 = page
          this.loading = true
          this.$api.get('/api/activity', { params: { page: this.page5, user_id: this.user_id, type: this.type } }).then((res) => {
            if (this.page5 == 1) {
              this.activityList = res.data.data
            } else {
              this.activityList = [...this.activityList, ...res.data.data]
            }
            this.lastPage5 = res.data.meta.last_page
            this.loading = false
          })
          break;
        case 6:
          //荣誉

          this.loading = true
          this.$api.get('/api/user-honor', { params: { user_id: this.user_id } }).then((res) => {
            this.honorList = res.data
            this.loading = false
          })
          break;
      }
    },
    //跳转
    handleSkip(url) {
      this.$router.push(url)
    },
    //积分天梯榜
    getPointsRank() {
      this.$api.get('/api/points-rank').then(res => {
        this.arr = res.data.list
      })
    },
    //打开认证等级弹窗
    handleOpen2(){
      let user = this.$storage.getData("userInfo")
      if(user&&user.id == this.user_id){
        this.visible2 = true
      }

    },
    //页面滑到底部调用的方法
    handleScroll() {
      //下面这部分兼容手机端和PC端
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeitht =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
        switch (this.current) {
          case 0:
            //动态
            if (this.page1 <= this.lastPage1) {
              this.page1++
              this.getList()
            }
            break;
          case 1:
            //资源
            if (this.page2 <= this.lastPage2) {
              this.page2++
              this.getList()
            }
            break;
          case 2:
            //圈子
            if (this.page6 <= this.lastPage6) {
              this.page6++
              this.getList()
            }
            break;
          case 3:
            //人脉
            if (this.page3 <= this.lastPage3) {
              this.page3++
              this.getList()
            }
            break;
          case 4:
            //任务
            if (this.page4 <= this.lastPage4) {
              this.page4++
              this.getList()
            }
            break;
          case 5:
            //活动
            if (this.page5 <= this.lastPage5) {
              this.page5++
              this.getList()
            }
            break;
          case 6:
            //荣誉
            break;
        }
      }
    },
  }
}

</script>
<style lang="scss" scoped>
.user {
  margin: 0.92rem auto 0;
  width: 13.2rem;

  .user-left {
    width: 9.6rem;
    background: #FFFFFF;
    border-radius: 0.03rem;
    padding: 0.3rem;
    box-sizing: border-box;

    .floor1 {
      .user-info {
        .user-avatar {
          width: 1rem;
          height: 1rem;
          position: relative;

          .avatar {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
          }

          .icon {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0.24rem;
          }
        }

        .user-sec {
          flex: 1;
          margin-left: 0.14rem;

          .user-name-wrap {
            .user-name {
              font-size: 0.2rem;
              color: #2B2B2B;
              margin-right: 0.14rem;
              font-weight: bold;

            }

            .label {
              padding: 0 0.09rem;
              height: 0.3rem;
              background: #E6ECFB;
              border-radius: 0.15rem;
              cursor: pointer;

              img {
                width: 0.19rem;



              }

              .label-left {
                font-size: 0.14rem;


                color: #02428C;
                margin: 0 0.06rem;

              }

              .label-right {
                font-size: 0.14rem;


                color: #02428C;

              }

            }
          }

          .ip {

            color: #8A8A8A;
            font-size: 0.16rem;
            margin: 0.14rem 0;
          }

          .score {
            .score-item {
              font-size: 0.18rem;
              color: #2D2D2D;
              cursor: pointer;
              margin-right: 0.3rem;
              font-weight: bold
            }
          }

        }

        .user-operate {
          .share-wrap {
            cursor: pointer;

            img {
              width: 0.2rem;
            }

            span {
              color: #737373;
              margin-left: 0.04rem;
              font-size: 0.18rem;
            }
          }

          .edit-btn {
            margin-top: 0.4rem;
            width: 1.58rem;
            height: 0.4rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #02428C;
            text-align: center;
            line-height: 0.4rem;
            cursor: pointer;
            color: #02428C;
            font-size: 0.16rem;


          }
        }

      }

      .desc {
        font-size: 0.16rem;
        color: #8a8a8a;
        line-height: 0.3rem;
        margin-top: 0.2rem;
      }

      .tab-wrap {
        margin-top: 0.2rem;

        border-radius: 0.04rem;
        font-size: 0.16rem;
        font-weight: 500;


        .tab-left {
          color: #737373;
          background: #F6F6F6;
          height: 0.37rem;
          line-height: 0.37rem;
          padding-left: 0.13rem;
        }

        .tab-right {
          color: #2B2B2B;
          background: #F6F6F6;
          height: 0.37rem;
          line-height: 0.37rem;
          padding-right: 0.13rem;
        }

        .different {
          color: #02428C;
          cursor: pointer;
        }


      }

    }

    .nav {
      margin-top: 0.3rem;
      border-bottom: 1px solid #EAEAEA;

      li {
        margin-right: 0.8rem;
        cursor: pointer;

        .name {
          font-size: 0.18rem;
          color: #202020;


        }

        .line {
          width: 0.39rem;
          height: 0.03rem;
          background: #ffffff;
          margin-top: 0.1rem;
          border-radius: 0.02rem;


        }

      }

      .active {
        .name {
          font-size: 0.18rem;
          color: #02428C;
        }

        .line {
          background: #02428C;
        }

      }

    }

  }

  .user-right {
    width: 3.5rem;
    margin-left: 0.1rem;

    .sec1 {
      height: 3rem;
      background: #FFFFFF;
      border-radius: 0rem 0rem 0.03rem 0.03rem;
      padding: 0.19rem 0.17rem;
      box-sizing: border-box;

      .sec1-top {
        width: 100%;

        .sec1-top-tit {
          img {
            width: 0.22rem;
          }

          .sec1-top-name {
            font-size: 0.2rem;
            color: #202020;
            margin-left: 0.09rem;
          }
        }

        .look-detail {
          color: #02428C;
          font-size: 0.16rem;
          cursor: pointer;
        }


      }

      .sec1-cen {
        margin-top: 0.5rem;
        cursor: pointer;

      }

      .sec1-bot {
        margin-top: 0.5rem;
        cursor: pointer;
      }

      .common-wrap {
        margin: 0 0.4rem;
        cursor: pointer;
      }

      .common-top {
        font-size: 0.3rem;
        color: #02428C;
      }

      .common-bot {
        font-size: 0.16rem;
        color: #202020;
        margin-top: 0.1rem;

      }

    }

    .sec2 {
      margin-top: 0.2rem;
      width: 100%;
      background: #ffffff;
      border-radius: 0rem 0rem 0.03rem 0.03rem;

      .common {
        width: 100%;
        height: 0.62rem;
        background: #ffffff;
        line-height: 0.62rem;
        padding-left: 0.22rem;
        box-sizing: border-box;
        font-size: 0.2rem;


        color: #202020;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-size: 0.18rem;
        }

        img {
          width: 0.32rem;
          height: 0.32rem;
          background-color: #02428C;
          margin-right: 0.06rem;

        }

      }


      ul {
        display: flex;
        flex-direction: column;
        padding: 0 0.2rem;

        li {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.29rem;
          height: 0.55rem;
          align-items: center;
          cursor: pointer;

          .biao {
            width: 0.29rem;

          }

          span {
            width: 0.29rem;
            height: 0.2rem;
            background: #E4E4E9;
            border-radius: 0.1rem;
            text-align: center;
            line-height: 0.2rem;
            display: inline-block;
            font-size: 0.12rem;
            font-family: Arial-Black, Arial;
            font-weight: 900;
            color: #9BA0A5;
          }

          .avatar {
            margin-left: 0.24rem;
            width: 0.55rem;
            height: 0.55rem;
            background: rgba(0, 0, 0, 0);
            border-radius: 50%;
          }

          .users {
            margin-left: 0.06rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            flex: 1;

            .nickName {
              font-size: 0.16rem;

              color: #202020;
              line-height: 0.29rem;
            }

            .score {
              font-size: 0.14rem;


              color: #8BA5E3;
              line-height: 0.24rem;
            }
          }
        }

        li:nth-child(1) span,
        li:nth-child(2) span,
        li:nth-child(3) span {
          // background: #02428C;
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: #eaeaea;

      }

      .mores {
        font-size: 0.17rem;


        color: #5077D5;
        line-height: 0.84rem;
        cursor: pointer;
        text-align: center;


      }



    }

  }

  .focus-btn {
    width: 0.69rem;
    height: 0.32rem;
    border-radius: 0.03rem;
    border: 0.01rem solid #02428C;
    cursor: pointer;
    color: #02428C;
    text-align: center;
    line-height: 0.32rem;
    font-size: 0.16rem;

  }

  .diff {
    color: #848484;
    border: 0.01rem solid #848484;
  }

  .sec3 {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 0.03rem;
    padding: 0.2rem 0.2rem;
    box-sizing: border-box;

    ul {

      li {
        width: 100%;
        margin-top: 0.14rem;

        .li-left {
          img {
            width: 0.52rem;
            height: 0.52rem;
            border-radius: 0.06rem;
            background-color: #efefef;

          }
        }

        .li-rig {
          margin-left: 0.07rem;
          flex: 1;

          .li-rig-top {
            font-size: 0.16rem;
            color: #202020;
            line-height: 0.2rem;


          }

          .li-rig-bot {
            font-size: 0.14rem;
            color: #909090;
            line-height: 0.23rem;
          }

          .li-rig-bottom {
            img {
              width: 0.14rem;
              margin-right: 0.06rem;
            }
          }

        }

        .enter {
          width: 0.63rem;
          height: 0.29rem;
          border-radius: 0.03rem;
          border: 1px solid #02428C;
          text-align: center;
          line-height: 0.29rem;
          font-size: 0.14rem;


          color: #02428C;


        }

      }

      li:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.mark {
  color: #02428C;
  font-size: 0.14rem;
  margin-left: 0.1rem;
  min-width: 1rem;
}
</style>
