//首页模块
import Index from '../views/home/index.vue'
//动态详情
import FeedDetail from '../views/home/feedDetail.vue'
//搜索结果
import Search from '../views/home/search.vue'
export default [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      title: '首页-推荐',
    },
  },
  {
    path: '/feedDetail',
    name: 'FeedDetail',
    component: FeedDetail,
    meta: {
      title: '动态详情',
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      title: '搜索结果',
    },
  },


]
