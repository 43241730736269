<template>
    <div class="my-honor">
        <ul class="flex-row-wrap">
            <li class="flex-col-center" v-for="(item, index) in list" :key="index" :class="{ 'active-border': item.has == 1 }">
                <img :src="item.has == 1 ? require('@/assets/icons/honored.png') : require('@/assets/icons/honor.png')" />
                <div class="tit" :class="{ 'active': item.has == 1 }">{{ item.name }}</div>
                <div class="desc" v-if=" item.has == 1">{{ $t('personal.has_date') }}：{{ item.has_date }}</div>
                <div class="desc" v-if=" item.has == 0">{{ $t('personal.lock') }}</div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props:['list'],
    data() {
        return {
           
        }
    }
}

</script>
<style lang="scss" scoped>
.my-honor {
    ul {
        li {
            margin: 0.1rem 0.12rem 0 0;
            width: 2.15rem;
            height: 3rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #8F8F8F;
            box-sizing: border-box;

            img {
                width: 0.45rem;
                

            }

            .tit {
                margin: 0.3rem auto 0.3rem;
                font-size: 0.22rem;
                color: #9A9A9A;
            }

            .active {
                color: #2E2E2E;
            }

            .desc {

                color: #959595;
                font-size: 0.15rem;
                margin: 0 auto;
            }



        }

        .active-border {
            border-color: #02428C;
        }

        li:nth-child(4n) {
            margin-right: 0;
        }
    }
}</style>