//粉丝列表
<template>
    <div class="circle-member">
        <el-dialog :title="`${$t('personal.fen')}(${total})`" :visible.sync="show" width="50%" :before-close="handleClose">
            <ul>
                <li v-for="(item, index) in list" :key="index" class="flex-row-center-between">
                    <div class="user-info flex-row-center">
                        <img :src="item.avatar" />
                        <div class="sec">
                            <div class="name x-ell-1">{{ item.name }}</div>
                            <div class="bio x-ell-1">{{ item.introduction || $t('auth.nulldesc') }}</div>
                        </div>
                    </div>
                    <div class="btn-wrap flex-row-center" v-if="user&&user.id==user_id">
                        <div class="btn" v-if="item.has_follow == 0" @click="handleFollow(0, item.id, index)"> {{
                            $t('personal.focus2') }}</div>
                        <div class="btn" v-if="item.has_follow == 1" @click="handleFollow(1, item.id, index)"> {{
                            $t('index.focused') }}</div>
                    </div>
                </li>
            </ul>
            <pagination :page-sizes="getPageSize()" v-show="total > 0" :total="total" align="center"
                :page.sync="params.page" :limit.sync="params.paginate" @pagination="handlePage" />
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>
import pagination from '@/components/pagination.vue'
export default {
    props: ["show", 'user_id'],
    components: { pagination },
    data() {
        return {
            user: this.$storage.getData("userInfo"),
            list: [],
            params: {
                follow_type: 'user',
                page: 1,
                paginate: 20,
                user_id: this.user_id,
            },
            total: 0
        };
    },
    watch: {
        user_id(val) {
            this.params.user_id = val
            this.getList()
        }
    },
    created(){
        if(this.user_id){
            this.params.user_id = this.user_id
            this.getList()
        }
    },
    methods: {
        handlePage(val) {
            this.getList(val.page)
        },
        //查看列表
        getList(page) {
            if (page) this.params.page = page
            this.$api.get('/api/follow_me_list', { params: this.params }).then(res => {
                this.list = res.data.data
                this.total = res.data.meta.total
            })
        },
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        getPageSize() {

        },
        //关注 取消关注
        handleFollow(state, id, index) {
            if (state == 0) {
                this.$api.post(`/api/follow/user/${id}`).then(() => {
                    this.list[index].has_follow = 1
                    this.$message.success(this.$t('circle.focus_success'))
                    this.$emit('updateUser')
                })
            } else {
                this.$api.delete(`/api/follow/user/${id}`).then(() => {
                    this.list[index].has_follow = 0
                    this.$message.success(this.$t('circle.cancel_success'))
                    this.$emit('updateUser')
                })
            }
        },
    }

};
</script>
<style scoped lang="scss">
.circle-member {
    ul {
        li {

            margin-bottom: 0.3rem;

            img {
                width: 0.55rem;
                height: 0.55rem;
                border-radius: 50%;
                background-color: #efefef;
            }

            .sec {
                margin-left: 0.06rem;
                flex: 1;

                .name {
                    font-size: 0.18rem;
                    color: #202020;
                    line-height: 0.29rem;
                }

                .bio {
                    font-size: 0.16rem;
                    color: #909090;

                }
            }

            .time {
                font-size: 0.16rem;
                color: #909090;
                line-height: 0.24rem;
                margin-left: 0.13rem;
            }

            .btn-wrap {
                .btn {
                    width: 1.01rem;
                    height: 0.32rem;
                    border-radius: 0.03rem;
                    border: 0.01rem solid #909090;
                    text-align: center;
                    line-height: 0.32rem;
                    cursor: pointer;
                    font-size: 0.16rem;
                    color: #909090;
                    margin-left: 0.3rem;
                }

                .diff {
                    border: 0.01rem solid #9A9A9A;
                    color: #9A9A9A;
                }
            }
        }
    }
}
</style>
  