<!-- 我的积分明细 -->
<template>
  <div class="integral">
    <div class="floor1">
      <div class="floor1-head flex-row-center-between">
        <div class="floor1-head-tit">{{ $t("personal.my_point") }}</div>
        <div class="floor1-head-rig flex-row-center" @click="show = true">
          <img src="@/assets/icons/warn.png" />
          <div class="tip">{{ $t("personal.point_tip") }}</div>
        </div>
      </div>
      <div class="floor1-cen flex-row-center-between">
        <div class="floor1-cen-left flex-col-center">
          <div class="score">{{ user.points }}</div>
          <div class="score-tip">{{ $t("personal.point_balance") }}</div>
        </div>
      </div>
      <div class="echarts flex-col-center-center">
        <radar
          :averageArr="averageArr"
          :userArr="userArr"
          :indicator="indicator"
        />
      </div>
    </div>
    <div class="floor2" v-if="isSelf">
      <div class="floor2-head flex-row-center">
        <div class="floor2-tit">{{ $t("personal.tit22") }}</div>
        <div class="floor2-tip">{{ $t("personal.desc1") }}</div>
      </div>
      <ul class="flex-col">
        <li
          v-for="(item, index) in task"
          :key="index"
          class="flex-row-center-between"
        >
          <div class="li-left">{{ index + 1 }}.{{ item.title }}</div>
          <div class="li-right" :class="{ active: item.status == 1 }">
            {{
              item.status == 1 ? $t("index.completed") : $t("index.unCompleted")
            }}
          </div>
        </li>
      </ul>
    </div>
    <div class="floor3" v-if="isSelf">
      <div class="floor3-head flex-row-center-between">
        <div class="title">{{ $t("personal.point_detail") }}</div>
        <div class="flex-row-center">
          <div class="label">{{ $t("personal.choose") }}：</div>
          <el-select
            :placeholder="$t('personal.type')"
            v-model="formInline.type"
            @change="getList(1)"
          >
            <el-option :label="$t('personal.all')" value="" />
            <el-option :label="$t('personal.deal')" value="deal" />
            <el-option :label="$t('circle.source')" value="resource" />
            <el-option :label="$t('personal.active')" value="active" />
            <el-option :label="$t('personal.promotion')" value="promotion" />
            <el-option :label="$t('personal.business')" value="business" />
          </el-select>
        </div>
      </div>
      <div class="head flex-row-center-between">
        <div class="head-left">{{ $t("circle.point") }}</div>
        <div class="head-cen">{{ $t("personal.point_source") }}</div>
        <div class="head-right">{{ $t("personal.time") }}</div>
      </div>
      <div
        class="bot flex-row-center-between"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="bot-left">{{ item.points }}</div>
        <div class="bot-cen">{{ item.description }}</div>
        <div class="bot-right">{{ item.created_at }}</div>
      </div>
      <pagination
        :page-sizes="getPageSize()"
        v-show="total > 0"
        :total="total"
        align="center"
        :page.sync="formInline.page"
        :limit.sync="formInline.paginate"
        @pagination="handlePage"
      />
    </div>
    <!-- 积分注意事项 -->
    <el-dialog
      :title="$t('personal.point_tip')"
      :visible.sync="show"
      width="50%"
      :before-close="handleClose"
    >
      <div class="detail" v-html="content"></div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/pagination";
import radar from "./components/radar";
export default {
  components: { Pagination, radar },
  data() {
    return {
      user: { points: 0 },
      //雷达数据
      userArr: [],
      averageArr: [],
      indicator: [],
      task: [], //每日活跃任务
      list: [],
      total: 0,

      formInline: {
        page: 1,
        type: "",
        paginate: 10,
      },
      show: false, //控制弹窗的心事和隐藏
      //积分注意事项
      content: "",
      isSelf: true,
    };
  },
  created() {
    this.isSelf = Boolean(Number(this.$route.query.isSelf));
    if (this.isSelf) {
      //获取用户信息
      this.getUser();
    } else {
      //获取其他人的用户信息
      this.getOtherUser();
    }
    //获取积分雷达图数据
    this.getRadar();
    //获取积分任务
    this.getPointsTask();
    //获取积分记录
    this.getList();
    //获取积分规则
    this.getPointsRule();
  },
  methods: {
    //获取用户信息
    getUser() {
      this.$api.get("/api/user").then((res) => {
        this.user = res.data;
      });
    },
    //获取其他人的用户信息
    getOtherUser() {
      this.$api.get(`/api/user/${this.$route.query.user_id}`).then((res) => {
        this.user = res.data;
      });
    },
    //获取积分雷达图数据
    getRadar() {
      let params = {}
      if(!this.isSelf){
        params = {
          user_id:this.$route.query.user_id
        }
      }
      this.$api.get('/api/user-points',{params}).then((res) => {
        const result = res.data;
        this.averageArr = [
          result.average.deal,
          result.average.business,
          result.average.promotion,
          result.average.active,
          result.average.resource,
        ];
        this.userArr = [
          result.user.deal.points,
          result.user.business.points,
          result.user.promotion.points,
          result.user.active.points,
          result.user.resource.points,
        ];
        this.indicator = [
          {
            name: `${this.$t("personal.deal")}`,
            value: result.user.deal.points,
            average: result.average.deal,
            max: result.max.deal,
          },
          {
            name: `${this.$t("personal.business")}`,
            value: result.user.business.points,
            average: result.average.business,
            max: result.max.business,
          },
          {
            name: `${this.$t("personal.promotion")}`,
            value: result.user.promotion.points,
            average: result.average.promotion,
            max: result.max.promotion,
          },
          {
            name: `${this.$t("personal.active")}`,
            value: result.user.active.points,
            average: result.average.active,
            max: result.max.active,
          },
          {
            name: `${this.$t("circle.source")}`,
            value: result.user.resource.points,
            average: result.average.resource,
            max: result.max.resource,
          },
        ];
      });
    },
    //获取积分任务
    getPointsTask() {
      this.$api.get("/api/points-task").then((res) => {
        this.task = res.data;
      });
    },
    //获取积分记录
    getList(page) {
      if (page) this.formInline.page = page;
      this.$api
        .get("/api/user-points-log", { params: this.formInline })
        .then((res) => {
          this.list = res.data.data;
          this.total = res.data.total;
        });
    },
    //获取积分规则
    getPointsRule() {
      this.$api.get("/api/init/config").then((res) => {
        this.content = res.data.points_rule;
      });
    },
    getPageSize() {},
    handlePage(val) {
      this.getList(val.page);
    },
    //关闭弹窗
    handleClose() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.integral {
  width: 13.2rem;
  margin: 0.92rem auto;

  .floor1 {
    background: #ffffff;
    border-radius: 0.03rem;
    height: auto;
    padding: 0.3rem 0.52rem;
    box-sizing: border-box;

    .floor1-head {
      .floor1-head-tit {
        font-size: 0.24rem;
        font-weight: 500;
        color: #262423;
      }

      .floor1-head-rig {
        img {
          width: 0.2rem;
          margin-right: 0.07rem;
        }

        .tip {
          color: #000000;
          font-size: 0.15rem;
          cursor: pointer;
        }
      }
    }

    .floor1-cen {
      margin-top: 0.3rem;
      margin-bottom: 0.2rem;

      .floor1-cen-left {
        .score {
          font-size: 0.45rem;
          color: #02428c;
        }

        .score-tip {
          color: #262423;
          font-size: 0.14rem;
        }
      }

      .floor1-cen-right {
        .common {
          margin-left: 0.66rem;

          .block {
            width: 0.43rem;
            height: 0.23rem;
            background: #c23431;
            border-radius: 0.04rem;
          }

          .diff {
            background-color: #304554;
          }

          .txt {
            font-size: 0.14rem;
            color: #262423;
            margin-left: 0.16rem;
          }
        }
      }
    }

    .echarts {
      // height: 1rem;
      // width: 100%;
      // padding: 0.3rem;
      // background-color: #abcdef;
    }
  }

  .floor2 {
    margin-top: 0.3rem;
    background: #ffffff;
    border-radius: 0.03rem;
    padding: 0.3rem 0.52rem;
    box-sizing: border-box;

    .floor2-tit {
      color: #262423;
      font-size: 0.24rem;
    }

    .floor2-tip {
      font-size: 0.15rem;
      color: #919191;
      margin-left: 0.15rem;
    }

    ul {
      li {
        margin-top: 0.3rem;

        .li-left {
          font-size: 0.18rem;
          color: #262423;
        }

        .li-right {
          width: 0.9rem;
          height: 0.36rem;
          background: #02428c;
          border-radius: 0.22rem;
          text-align: center;
          line-height: 0.36rem;
          color: #ffffff;
          cursor: pointer;
          font-size: 0.14rem;
        }

        .active {
          color: #616161;
          background: #d8d8d8;
        }
      }
    }
  }

  .floor3 {
    background-color: #ffffff;
    background: #ffffff;
    border-radius: 0.03rem;
    padding: 0.3rem 0.52rem;
    box-sizing: border-box;
    margin-top: 0.3rem;

    .floor3-head {
      padding-bottom: 0.35rem;
      border-bottom: 1px solid #eaeaea;

      .label {
        font-size: 0.15rem;
        color: #8b8b8b;
        margin-right: 0.2rem;
      }

      .title {
        color: #262423;
        font-size: 0.24rem;
      }
    }

    .head {
      height: 0.85rem;
      line-height: 0.85rem;
      color: #8b8b8b;
      font-size: 0.16rem;
      border-bottom: 1px solid #eaeaea;
      padding: 0 0.56rem;

      .head-left {
        width: 25%;
      }

      .head-cen {
        width: 50%;
        text-align: center;
      }

      .head-right {
        width: 25%;
        text-align: right;
        padding-right: 0.28rem;
      }
    }

    .bot {
      height: 0.85rem;
      line-height: 0.85rem;
      color: #262423;
      font-size: 0.18rem;
      border-bottom: 1px solid #eaeaea;
      padding: 0 0.56rem;

      .bot-left {
        width: 13%;
      }

      .bot-cen {
        width: 74%;
        text-align: center;
        line-height: 30px;
      }

      .bot-right {
        width: 23%;
        text-align: right;
      }
    }
  }
}

.detail {
  font-size: 0.15rem;
  color: #262423;
  line-height: 0.23rem;
}
</style>
