<!-- 任务列表 -->
<template>
	<div class="task flex-row">
		<div class="left">
			<div class="left-head flex-row-center-between">
				<ul class="flex-row-center">
					<li @click="onClickItem(index)" v-for="(item, index) in tabs" :key="index"
						:class="{ 'active': currentIndex == index }">{{ item.name
						}}</li>
				</ul>
				<div class="search-wrap flex-row-center-between">
					<input v-model="formInline.search" :placeholder="$t('task.search')" @keyup.enter="getList(1)" />
					<img src="@/assets/icons/search.png" @click="getList(1)" />
				</div>
			</div>
			<!-- 任务列表 -->
			<ul class="task-list">
				<li @click="handleSkip(`/taskDetail?id=${item.id}`)" class="task-item" v-for="(item, index) in listData"
					:key="index">
					<div class="task-head flex-row-center-between">
						<Avatar size="medium3" :user="item.user" :showName="true" />
						<div class="state state1" v-if="item.status == 0">{{ $t('task.status0') }}</div>
						<div class="state" v-if="item.status == 1"> {{ $t('task.status1') }}</div>
						<div class="state state1" v-if="item.status == -1">{{ $t('task.status5') }}</div>
						<div class="state state1" v-if="item.status == 4">{{ $t('index.completed') }}</div>
						<div class="state state1" v-if="item.status == 5">{{ $t('index.completed') }}</div>
						<div class="state state2" v-if="item.status == 2">{{ $t('task.status2') }}</div>
						<div class="gou flex-row-center" v-if="item.status == 3">
							<img src="@/assets/icons/gou.png" />
							<span>{{ $t('task.status3') }}</span>
						</div>
					</div>
					<div class="task-info">
						<div class="title flex-row-wrap">
							<div class="tit">{{ item.title }}
								<span class="topic" v-for="(itemt, i) in item.labelArr">{{ itemt }}</span>
							</div>
						</div>
						<div class="flex-row-center label">
							<img src="@/assets/icons/task1.png" />
							<div class="label-right">{{ $t('task.content') }}：</div>
						</div>
						<div class="desc">
							{{ item.content }}</div>
						<div class="flex-row-center label">
							<img src="@/assets/icons/task2.png" />
							<div class="label-right">{{ $t('task.deliverable') }}：</div>
						</div>
						<div class="desc">
							{{ item.deliverable }}
						</div>
						<div class="flex-row-center label">
							<img src="@/assets/icons/task3.png" />
							<div class="label-right">{{ $t('task.category') }}：</div>
							<div class="label-main">{{ item.category && item.category.name }}</div>
							<img src="@/assets/icons/task4.png" />
							<div class="label-right">{{ $t('task.budget') }}：</div>
							<div class="label-main">{{ item.budget }}</div>
							<img src="@/assets/icons/task5.png" />
							<div class="label-right">{{ $t('task.due_time') }}：</div>
							<div class="label-main">{{ item.due_time }}{{ $t('task.front') }}</div>
						</div>
						<div class="flex-row-center label">
							<img src="@/assets/icons/task6.png" />
							<div class="label-right">{{ $t('task.country') }}：</div>
							<div class="label-main" v-if="item.country && item.country.length&&item.country[0]!=''">
								<span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.country" :key="i">{{ itemt
								}}</span>
							</div>
							<div class="label-main" v-else>
								 {{ $t('index.no_limit') }}
							</div>
						</div>
						<div class="flex-row-center label">
							<img src="@/assets/icons/task7.png" />
							<div class="label-right">{{ $t('task.city') }}：</div>
							<div class="label-main" v-if="item.city && item.city.length&&item.city[0]!=''"> <span style="margin-right: 0.1rem;"
									v-for="(itemt, i) in item.city" :key="i">{{ itemt }}</span>
							</div>
							<div class="label-main" v-else>
								 {{ $t('index.no_limit') }}
							</div>

						</div>
						<div class="flex-row-center label">
							<img src="@/assets/icons/task8.png" />
							<div class="label-right">{{ $t('task.industry') }}：</div>
							<div class="label-main" v-if="item.industry && item.industry.length">
								<span style="margin-right: 0.1rem;" v-for="(itemt, i) in item.industry" :key="i">{{
									itemt.name }}</span>
							</div>
							<div class="label-main" v-else>
								 {{ $t('index.no_limit') }}
							</div>
						</div>
						<div class="flex-row-center label">
							<img src="@/assets/icons/task9.png" />
							<div class="label-right">{{ $t('task.label') }}：</div>
							<div class="label-main"><span style="margin-right: 0.1rem;"
									v-for="(itemt, i) in item.labelArr">{{ itemt }}</span>
							</div>
						</div>
						<div class="timer">{{ $t('task.end_time') }}：{{ item.end_time }}</div>
					</div>
					<div class="sec-tit" v-if="item.task_user && item.task_user.length">{{ $t('task.task_user') }}</div>
					<ul class="users flex-row-wrap flex-row-center-between">
						<li v-for="(itemt, i) in item.task_user" class="flex-row-center">
							<Avatar size="medium3" :user="itemt.user" :showName="true" />
						</li>
					</ul>
					<div class="btn-wrap flex-row-center">
						<div class="btn btn1" v-if="item.has_join == 0 && item.status >= 1&&item.status<4" @click.stop="handleApply(item.id)"
							v-loading="loading" element-loading-spinner="el-icon-loading">{{ $t('task.apply') }}</div>

						<div class="btn btn3" v-if="item.has_join != 1 && item.extra_join == 1"
							@click.stop="handleApply(item.id, 1)">{{ $t('task.apply2') }}</div>
						<div class="btn btn2" @click.stop="handleCollect(item.has_collect, item.id, index)">
							{{ item.has_collect ? $t('task.collected') : $t('task.collect_task') }}
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="right" v-if="user">
			<div class="right1">
				<div class="tit">{{ $t('task.my_task') }}</div>
				<ul>
					<li v-for="(item, index) in myTask" :key="index" @click="handleSkip(`/taskDetail?id=${item.id}`)">
						<div class="mark mark1" v-if="item.status == -1">{{ $t('task.status4') }}</div>
						<div class="mark mark1" v-if="item.status == 0">{{ $t('task.status0') }}</div>
						<div class="mark mark1" v-if="item.status == 1">{{ $t('task.status6') }}</div>
						<div class="mark mark1" v-if="item.status == 2">{{ $t('task.status2') }}</div>
						<div class="mark mark1" v-if="item.status == 3">{{ $t('task.status3') }}</div>
						<div class="mark mark1" v-if="item.status == 4">{{ $t('task.status4') }}</div>
						<div class="con x-ell-2">{{ item.title }}</div>
						<div class="timer">{{ item.created_at | dateFormat }}</div>
					</li>
				</ul>
				<div class="more" @click="handleSkip('/myTask')">{{ $t('task.look') }}</div>
			</div>
			<div class="right2" v-if="myCollect.length">
				<div class="tit">{{ $t('task.collected_task') }}</div>
				<ul>
					<li v-for="(item, index) in myCollect" :key="index" @click="handleSkip(`/taskDetail?id=${item.id}`)">
						<div class="mark mark1" v-if="item.status == -1">{{ $t('task.status4') }}</div>
						<div class="mark mark1" v-if="item.status == 0">{{ $t('task.status0') }}</div>
						<div class="mark mark4" v-if="item.status == 1">{{ $t('task.status1') }}</div>
						<div class="mark mark5" v-if="item.status == 2">{{ $t('task.status2') }}</div>
						<div class="mark mark6" v-if="item.status == 3">{{ $t('task.status3') }}</div>
						<div class="mark mark1" v-if="item.status == 4">{{ $t('task.status4') }}</div>
						<div class="con x-ell-2">{{ item.title }}</div>
						<div class="timer">{{ item.created_at | dateFormat }}</div>
					</li>
				</ul>
				<div class="more" @click="handleSkip('/collect')">{{ $t('task.look') }}</div>
			</div>
		</div>
		<!-- 申请接单 -->
		<ApplyOrder :show="show" @handleClose="show = false" @sub="handleJoin" />
		<!-- 申请接单成功 -->
		<CustomerService :show="show2" @handleClose="show2 = false" />
		<!-- 未认证弹窗 -->
		<ApplyService :show="show3" @handleClose="show3 = false" :tip="$t('task.tip1')" />
	</div>
</template>
<script>
import CustomerService from '@/views/personal/components/CustomerService'
import ApplyService from '@/components/ApplyService.vue'
import ApplyOrder from '@/components/ApplyOrder'
import Avatar from "@/components/Avatar";
export default {
	components: { Avatar, ApplyOrder, CustomerService, ApplyService },
	data() {
		return {
			user: this.$storage.getData("userInfo"),
			show: false,//控制申请弹窗的显示和隐藏
			show2: false,
			show3: false,
			tabs: [{
				name: this.$t('task.hot')
			},
			{
				name: this.$t('task.new')
			}
			],
			currentIndex: 0,
			listData: [],
			//查询条件
			formInline: {
				search: '',
				page: 1,
				paginate: 20,
				order: '',
				sort: '',
				category_id: "",//任务类型
				currency_id: '',//货币类型
				type: 'hot',//类型
				status: '',//任务状态
				order_by: '',
			},
			myTask: [],//我的任务
			myCollect: [],//我收藏的任务
			lastPage: 0,
			loading: false,
			user: this.$storage.getData("userInfo"),
			form: {
				id: '',
				resource_ids: []
			}
		};
	},
	created() {
		this.getList()
		//我的任务
		this.getMyTask()
		//我的收藏
		this.getCollect()
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	mounted() {
		//监听如果页面发生滚动时
		window.addEventListener("scroll", this.handleScroll);
	},
	methods: {
		//导航点击事件
		onClickItem(index) {
			this.currentIndex = index;
			this.formInline.type = index == 0 ? 'hot' : 'new'
			this.formInline.page = 1
			this.getList()
		},
		//获取列表
		getList(page) {

			if (page) this.formInline.page = page
			if (this.formInline.page == 1) {
				this.showLoading()
			}
			this.$api.get('/api/task', { params: this.formInline }).then(res => {
				let result = res.data.data
				this.hideLoading()
				this.lastPage = res.data.meta.last_page
				if (this.formInline.page == 1) {
					this.listData = result
				} else {
					if (result.length) {
						this.listData = [...this.listData, ...result]
					}
				}
				if (this.listData && this.listData.length) {
					this.listData.forEach(item => {
						if (item.label && item.label.length) {
							this.$set(item, 'labelArr', item.label.split(','))
						} else {
							this.$set(item, 'labelArr', [])
						}
					})
				}

			})
		},
		//获取我的任务
		getMyTask() {
			if (!this.user) return
			this.$api.get('/api/task', { params: { type: 'mine' } }).then((res) => {
				this.myTask = res.data.data.slice(0, 3)
			})
		},
		//我的收藏
		getCollect() {
			if (!this.user) return
			this.$api.get('/api/collect/task', { params: { page: 1 } }).then((res) => {
				this.myCollect = res.data.data.slice(0, 3)
			})
		},
		handleScroll() {
			var scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;
			var windowHeitht =
				document.documentElement.clientHeight || document.body.clientHeight;
			var scrollHeight =
				document.documentElement.scrollHeight || document.body.scrollHeight;
			if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
				if (this.formInline.page <= this.lastPage) {
					this.formInline.page++;
					this.getList()
				}
			}
		},
		//跳转
		handleSkip(url) {
			this.$router.push(url)
		},
		//申请接单

		handleApply(id, is_extra) {
			// if (is_extra) {
			// 		this.$set(this.form, 'is_extra', is_extra)
			// 	}
			// 	this.form.id = id;
			// 	this.show = true

			if (this.user && this.user.certificate) {
				if (is_extra) {
					this.$set(this.form, 'is_extra', is_extra)
				}
				this.form.id = id;
				this.show = true
			} else {
				this.$api.get(`/api/user`).then(res => {
					this.user = res.data
					this.$storage.userInfo();
					if (this.user && this.user.certificate) {
						if (is_extra) {
							this.$set(this.form, 'is_extra', is_extra)
						}
						this.form.id = id;
						this.show = true
					} else {
						this.show3 = true
					}
				})

			}
		},
		//接单
		handleJoin(resource_ids) {
			this.form.resource_ids = resource_ids
			this.loading = true
			this.$api.post(`/api/task-join/${this.form.id}`, this.form).then(() => {
				this.$message.success(this.$t('task.success'))
				this.getList(1)
				this.loading = false
				this.show = false
				this.show2 = true
			}).catch(() => {
				this.loading = false
				this.show = false
				this.$message.error(this.$t('task.fail'))
			})
		},
		//收藏 取消收藏
		handleCollect(state, id, index) {
			if (state == 0) {
				// 收藏
				this.listData[index].has_collect = 1
				this.$api.post(`/api/collect/task/${id}`)
			} else {
				//取消点赞
				this.listData[index].has_collect = 0
				this.$api.delete(`/api/collect/task/${id}`)
			}
			this.getCollect()
		},
	},
};
</script>
<style scoped lang="scss">
.task {
	margin: 0.92rem auto 1.12rem;
	width: 13.2rem;

	.left {
		width: 9.96rem;

		.left-head {
			width: 9.96rem;
			height: 0.89rem;
			background: #FFFFFF;
			border-radius: 0.03rem;
			padding: 0 0.3rem;
			box-sizing: border-box;

			ul {
				li {
					margin-right: 0.65rem;
					font-size: 0.18rem;
					color: #202020;
					cursor: pointer;
				}

				.active {
					color: #02428C;
				}
			}

			.search-wrap {
				width: 2.17rem;
				height: 0.46rem;
				border-radius: 0.06rem;
				border: 0.01rem solid #F5F5F5;
				padding-left: 0.09rem;
				padding-right: 0.09rem;
				box-sizing: border-box;
				background-color: #F5F5F5;

				input {
					flex: 1;
					height: 0.46rem;
					outline: none;
					border: none;
					background-color: #F5F5F5;
					box-sizing: border-box;
				}

				img {
					width: 0.2rem;

				}

			}


		}

		.task-list {
			.task-item {
				background-color: #ffffff;
				border-radius: 0.03rem;
				cursor: pointer;
				margin-top: 0.2rem;
				padding-bottom: 0.2rem;

				.task-head {
					height: 1.17rem;
					padding: 0 0.3rem;
					box-sizing: border-box;
					border-bottom: 1px solid #EAEAEA;

					.state {
						min-width: 0.98rem;
						height: 0.4rem;
						background: #E9FBE4;
						border-radius: 0.06rem;
						text-align: center;
						line-height: 0.4rem;
						font-size: 0.17rem;
						color: #54BE13;

					}

					.state2 {
						background-color: #FDEDDB;
						color: #E39B4B;
					}

					.gou {
						img {
							width: 0.26rem;
							margin-right: 0.05rem;
						}

						span {
							font-size: 0.17rem;

							color: #02428C;
						}

					}

				}

				.task-info {
					padding: 0.2rem 0.3rem;
					border-bottom: 1px solid #EAEAEA;

					.title {

						color: #202020;

						.tit {
							font-size: 0.2rem;

						}

						.topic {
							font-size: 0.16rem;
							color: #02428C;
							display: inline-block;

						}

					}

					.label {
						margin-top: 0.18rem;

						img {
							width: 0.18rem;

							margin-right: 0.1rem;

						}

						.label-right {
							font-size: 0.16rem;
							color: #02428C;

						}

						.label-main {
							font-size: 0.16rem;
							color: #828282;
							margin-right: 0.37rem;
						}

					}

					.desc {
						font-size: 0.16rem;
						color: #828282;
						line-height: 0.29rem;
						margin-top: 0.1rem;

					}
				}
			}
		}

		.sec-tit {
			font-size: 0.17rem;
			color: #202020;
			margin: 0.3rem;
		}

		.timer {
			width: 3.8rem;
			height: 0.55rem;
			background: #E6ECFB;
			border-radius: 0.04rem;
			text-align: center;
			line-height: 0.55rem;
			cursor: pointer;
			color: #02428C;
			font-size: 0.16rem;
			margin-top: 0.2rem;


		}

		.users {
			padding: 0 0.3rem;

			li {
				width: 48%;
				height: 0.98rem;
				background: #FAFAFA;
				border-radius: 0.09rem;
				padding: 0.13rem;
				box-sizing: border-box;
				margin-bottom: 0.2rem;




			}

		}

		.btn-wrap {
			margin: 0.3rem;

			.btn {
				width: 2.35rem;
				height: 0.58rem;
				border-radius: 0.04rem;
				border: 0.01rem solid #02428C;
				text-align: center;
				line-height: 0.58rem;
				color: #ffffff;
				background-color: #02428C;
				font-size: 0.17rem;
			}

			.btn1 {
				margin-right: 0.35rem;
			}

			.btn2 {
				border: 0.01rem solid #979797;
				color: #979797;
				background-color: #ffffff;
			}

			.btn3 {
				margin-right: 0.35rem;
				border: 0.01rem solid #71B260;
				background-color: #71B260;
			}
		}



	}

	.right {
		width: 3.08rem;
		margin-left: 0.16rem;

		.right1 {
			width: 3.08rem;
			height: auto;
			background: #FFFFFF;
			border-radius: 0.03rem;
			padding: 0.24rem;
			box-sizing: border-box;
		}

		.right2 {
			width: 3.08rem;
			height: auto;
			background: #FFFFFF;
			border-radius: 0.03rem;
			margin-top: 0.2rem;
			padding: 0.24rem;
			box-sizing: border-box;

		}

		.tit {
			font-size: 0.2rem;
			color: #202020;
			line-height: 0.29rem;

		}

		ul {

			li {
				padding-bottom: 0.13rem;
				padding-top: 0.24rem;
				border-bottom: 1px solid #EAEAEA;
				cursor: pointer;

				.mark {
					font-size: 0.17rem;
				}

				.mark1 {
					color: #02428C;
				}

				.mark2 {
					color: #02428C;
				}

				.mark3 {
					color: #686868;
				}

				.mark4 {
					color: #58BF19;
				}

				.mark5 {
					color: #E5A45A;
				}

				.mark6 {
					color: #686868;
				}

				.con {
					font-size: 0.17rem;
					margin-top: 0.12rem;
					color: #202020;
					line-height: 0.24rem;
				}

				.timer {
					font-size: 0.17rem;
					margin-top: 0.12rem;
					color: #9C9C9C;
					line-height: 0.24rem;
				}
			}

		}

		.more {
			width: 2.07rem;
			height: 0.43rem;
			border-radius: 0.03rem;
			border: 0.01rem solid #02428C;
			margin: 0.3rem auto 0;
			cursor: pointer;
			text-align: center;
			line-height: 0.43rem;
			color: #02428C;

		}
	}

}
</style>
