<template>
    <el-dialog title="" :visible.sync="visible" width="8rem" :before-close="handleClose" class="my-level">
        <div class="level-top">
            <div class="level-title">{{ $t('personal.certification_level') }}</div>
            <div class="flex-row-center">
                <img src="@/assets/icons/level.png" class="level-img" />
            </div>
            <div class="level-desc">
                {{ $t('personal.current_level') }} ：{{ info.user && info.user[current] && info.user[current].name }}</div>
            <ul class="flex-row-center-between navbar">
                <li @click="handleNav(index)" :class="{ 'active': currentIndex == index }" v-for="(item, index) in navs"
                    :key="index">{{ item.title }}</li>
            </ul>
            <div class="progress-wrap">
                <div class="progress"
                    :style="{ width: (info.user[type].points / (info.user[diff] + info.user[type].points)) + '%' }">
                </div>
            </div>
            <div class="progress-desc flex-row-center-between">
                <div> {{ $t('personal.current_level1') }}{{ info.user[current].name }}{{ $t('personal.current_level2') }}{{
                    info.user && info.user[diff] }}{{ $t('personal.score') }}
                </div>
                <div>{{ info.user && info.user[type] && info.user[type].points }}/{{
                    info.user[diff] + info.user[type].points }}</div>
            </div>
            <div class="level-tit">{{ $t('personal.tit10') }}：</div>
            <div class="level-wrap flex-row-center-between">
                <div class="level-item flex-row-center">
                    <img src="@/assets/icons/yuan.png" />
                    <div class="level-item-desc">{{ $t('personal.tit11') }}</div>
                </div>
                <div class="level-item flex-row-center">
                    <img src="@/assets/icons/yuan.png" />
                    <div class="level-item-desc">{{ $t('personal.tit12') }}</div>
                </div>
                <div class="level-item flex-row-center">
                    <img src="@/assets/icons/yuan.png" />
                    <div class="level-item-desc">{{ $t('personal.tit13') }}</div>
                </div>
            </div>
        </div>
        <div class="level-bot">
            <div class="level-tit">{{ $t('personal.tit14') }}</div>
            <div class="level-detail">
                <div v-html="content"></div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible', 'user'],
    data() {
        return {

            content: '',
            info: {
                user: {
                    active: {},
                    active_current: {},
                    active_next: {},
                    active_next_points_diff: {},
                    business: {},
                    business_current: {},
                    business_next: {},
                    business_next_points_diff: {},
                    deal: {},
                    deal_current: {},
                    deal_next: {},
                    deal_next_points_diff: {},
                    promotion: {},
                    promotion_current: {},
                    promotion_next: {},
                    promotion_next_points_diff: {},
                    resource: {},
                    resource_current: {},
                    resource_next: {},
                    resource_next_points_diff: {},
                }
            },
            currentIndex: 0,
            navs: [
                {
                    title: this.$t('personal.deal'),
                    type: 'deal',
                    current: 'deal_current',
                    next: 'deal_next',
                    diff: 'deal_next_points_diff',
                },
                {
                    title: this.$t('personal.business'),
                    type: 'business',
                    current: 'business_current',
                    next: 'business_next',
                    diff: 'business_next_points_diff',
                },
                {
                    title: this.$t('personal.promotion'),
                    type: 'promotion',
                    current: 'promotion_current',
                    next: 'promotion_next',
                    diff: 'promotion_next_points_diff',
                },
                {
                    title: this.$t('personal.active'),
                    type: 'active',
                    current: 'active_current',
                    next: 'active_next',
                    diff: 'active_next_points_diff',
                },
                {
                    title: this.$t('circle.source'),
                    type: 'resource',
                    current: 'resource_current',
                    next: 'resource_next',
                    diff: 'resource_next_points_diff',
                },

            ],
            type: 'deal',
            current: 'deal_current',
            next: 'deal_next',
            diff: 'deal_next_points_diff',

        }
    },
    created() {
        this.getPointsRule()

        this.getUserPoints()


    },
    methods: {
        //导航栏点击事件
        handleNav(index) {
            this.currentIndex = index;
            this.type = this.navs[index].type
            this.current = this.navs[index].current
            this.next = this.navs[index].next
            this.diff = this.navs[index].diff


        },
        //获取规则
        getPointsRule() {
            this.$api.get('/api/init/config').then(res => {
                this.content = res.data.certification_level_description
            })
        },
        //获取认证信息
        getUserPoints() {
            let user = this.$storage.getData("userInfo")

            if (user) {
                this.$api.get('/api/user-points').then(res => {
                    this.info = res.data
                    console.log(res.data)
                })

            }


        },
        handleClose() {
            this.$emit('handleClose')
        }
    }
}

</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
    padding: 0;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
}

::v-deep .el-dialog__body {
    padding: 0;
}

::v-deep .el-dialog__footer {
    padding: 0;
}


.my-level {
    .level-top {
        width: 100%;
        height: auto;
        padding: 0 0.5rem 0.3rem;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #5281F2, #ffffff);

        .level-title {
            color: #5E709D;
            font-size: 0.22rem;
            text-align: center;
            padding-bottom: 0.3rem;
            padding-top: 0.3rem;
        }

        .level-img {
            width: 0.8rem;
            margin: 0 auto;

        }

        .level-desc {
            margin-top: 0.15rem;
            font-size: 0.24rem;
            color: #0274FF;
            text-align: center;
        }

        .progress-desc {
            margin-top: 0.1rem;
            width: 100%;
        }

        .progress-wrap {
            margin-top: 0.5rem;
            height: 0.09rem;
            background: #D1D8E7;
            border-radius: 0.04rem;
            position: relative;
            width: 100%;

            .progress {
                width: 70%;
                height: 0.09rem;
                background: #0274FF;
                border-radius: 0.04rem;
                border-radius: 0.04rem;
                position: absolute;
                left: 0;
                top: 0;
            }


        }

        .level-wrap {
            margin-top: 0.3rem;
            width: 100%;

            img {
                width: 0.13rem;
                margin-right: 0.08rem;
            }

            .level-item-desc {
                color: #272524;
                font-size: 0.18rem;
            }

        }

    }

    .level-tit {
        margin-top: 0.6rem;
        color: #272524;
        font-size: 0.2rem;
        font-weight: bold;

    }

    .level-bot {
        padding: 0 0.5rem 0.3rem;

        .level-detail {
            color: #272524;
            font-size: 0.16rem;
            line-height: 0.35rem;
            margin: 0.2rem 0;

        }

    }


}

.navbar {
    width: 90%;
    margin: 0.2rem auto 0;

    li {
        width: 1rem;
        height: 0.42rem;
        line-height: 0.42rem;
        background: #A8C1FC;
        color: #5E709D;
        text-align: center;
        border-radius: 0.03rem 0.03rem 0 0;
        cursor: pointer;
    }

    .active {
        background-color: #5077D5;
        color: #f1f1f1;
        cursor: pointer;

    }
}
</style>