<!-- 发布动态 -->
<template>
    <div class="post-feed">
        <el-dialog title="" :visible.sync="show" width="50%" :before-close="handleClose" :close-on-click-modal="false">
            <div class="floor1 flex-row-center">
                <img class="avatar" :src="user.avatar" v-if="user && user.avatar" />
                <img class="avatar" src="@/assets/icons/default.png" v-else />
                <div class="floor1-title">{{ $t('post.publish_feed') }}</div>
            </div>
            <div class="floor2">
                <el-input v-model="form.title" :placeholder="$t('post.placeholder1')" />
            </div>
            <div class="floor3 flex-col">
                <div class="floor3-head flex-row-center">
                    <div class="flex-row-center floor3-item" @click="uploadImg">
                        <span>{{ $t('post.add_img') }}</span>
                        <img src="@/assets/icons/picture.png" class="pic" />
                    </div>
                    <div class="flex-row-center floor3-item" @click="uploadVideo">
                        <span> {{ $t('post.add_video') }}</span>
                        <img src="@/assets/icons/video.png" class="video" />
                    </div>
                    <div class="flex-row-center floor3-item" @click="handleAddLabel">
                        <span>{{ $t('post.add_label') }}</span>
                        <img src="@/assets/icons/topic.png" class="topic" />
                    </div>
                </div>
                <div class="floor3-bot">
                    <el-input class="textarea" :autosize="{ minRows: 5,maxRows: 100}" :rows="4" type="textarea" :placeholder="$t('post.placeholder2')" v-model="form.content" />
                    <input class="label" v-model="form.label" />
                    <el-upload v-show="visible" class="uploadImg" action :limit="9" multiple accept="image/*"
                        :on-change="onChange" :show-file-list="showImg" :fileList="fileList" ref="imageUpload"
                        list-type="picture-card" :class="{ hide: hideUploadEdit }" :auto-upload="false"
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <i v-loading="loading"></i>
                    <el-upload class="uploadVideo" action multiple accept=".mp4" :on-change="onChangeVideo"
                        :show-file-list="false" ref="videoUpload">
                    </el-upload>
                    <div class="video-wrap" v-if="form.video && form.video.length">
                        <video style="height: 0.8rem; outline: none; width: 0.8rem" controls="controls" :src="form.video" />
                        <i class="el-icon-delete" @click="handleDelVideo"></i>
                    </div>
                </div>
            </div>
            <div class="floor4">
                <div class="floor4-item" v-for="(item, index) in form.urls" :key="index">
                    <div class="floor4-item-top flex-row-center-between">
                        <div class="floor4-item-top-left">{{ $t('post.urls') }}</div>
                        <div class="floor4-item-top-right" @click="delLink(index)">{{ $t('task.del') }}</div>
                    </div>
                    <div class="floor4-item-cen">
                        <el-input :placeholder="$t('post.url_name')" v-model="item.name" style="margin-top: 0.1rem;" />
                        <el-input :placeholder="$t('post.url_web')" v-model="item.url" style="margin-top: 0.1rem;" />
                    </div>
                </div>
                <div class="floor4-item-bot" @click="addLink">{{ $t('post.add_url') }}</div>
            </div>
            <!-- 添加新资源 -->
            <div v-if="flag == 0">
                <div class="floor5" v-if="list.length || list2.length">
                    <div class="navs flex-row-center-between">
                        <ul class="flex-row-center">
                            <li v-for="(item, index) in navs" :key="index" class="flex-col-center"
                                @click="changeNav(index)">
                                <div class="nav-tit" :class="{ 'active-color': currentIndex == index }">{{ item.title }}
                                </div>
                                <div class="nav-line" :class="{ 'active-bg': currentIndex == index }"></div>
                            </li>
                        </ul>
                        <div class="navs-right" @click="flag = 1">{{ $t('post.choose_resource') }}</div>
                    </div>
                    <!-- 自主添加资源 -->
                    <div v-if="currentIndex == 0">
                        <div v-for="(item, index) in list" :key="index">
                            <el-form size="medium" :ref="'form' + index" :rules="rules" :model="item" label-position="left"
                                label-width="85px">
                                <div class="flex-row-center-between">
                                    <div class="tabs flex-row-center">
                                        <div class="tab tab1" :class="{ 'active': item.type == 2 }"
                                            @click="changeTab(index, 2)">
                                            {{ $t('circle.organ') }}</div>
                                        <div class="tab tab2" :class="{ 'active': item.type == 1 }"
                                            @click="changeTab(index, 1)">
                                            {{ $t('circle.export') }}</div>
                                    </div>
                                    <div class="del-source" @click="delSource(index)">{{ $t('post.del_resource') }}</div>
                                </div>

                                <el-form-item :label="$t('con.resource_img')" prop="image">
                                    <el-upload class="uploadImg" action :limit="9" multiple accept="image/*"
                                        :on-change="(file) => onChange1(file, index)" :show-file-list="showImg"
                                        :fileList="item.fileList" ref="imageUpload" list-type="picture-card"
                                        :class="{ hide: item.hideUploadEdit }" :auto-upload="false"
                                        :on-remove="(file) => handleRemove1(file, index)">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item :label="$t('con.resource_tit')" prop="title">
                                    <el-input v-model="item.title" :placeholder="$t('con.placeholder3')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.orgname')" prop="organization" v-if="item.type == 2">
                                    <el-input v-model="item.organization" :placeholder="$t('con.placeholder4')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.main_character')" prop="main_character"
                                    v-if="item.type == 1">
                                    <el-input v-model="item.main_character"
                                        :placeholder="$t('con.placeholder5')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.other_character')" prop="other_character"
                                    v-if="item.type == 1">
                                    <el-input v-model="item.other_character"
                                        :placeholder="$t('con.placeholder6')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.country')">
                                    <el-col :span="6">
                                        <el-select filterable :placeholder="$t('con.placeholder7')" style="width: 100%"
                                            v-model="item.countryId" @change="(event) => changeCountry(event, index)">
                                            <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt, i) in country"
                                                :key="i"></el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-select filterable  v-model="item.stateId"
                                            :placeholder="$t('con.placeholder8')" style="width: 100%"
                                            @change="(event) => changeProvince(event, index)">
                                            <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt, i) in province"
                                                :key="i"></el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-select filterable v-model="item.cityId" :placeholder="$t('con.placeholder9')"
                                            style="width: 100%" @change="(event) => changeCity(event, index)">
                                            <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt, i) in city"
                                                :key="i"></el-option>
                                        </el-select>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :label="$t('con.other_city')" prop="other_city">
                                    <el-input v-model="item.other_city" :placeholder="$t('con.placeholder10')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.industry')">
                                    <el-col class="sec" :span="7" v-for="(v, i) in item.chooseIndustry" :key="i">
                                        <el-cascader style="width: 100%;"
                                            :props="{ label: 'name', value: 'id', children: 'childrens', checkStrictly: true }"
                                            :placeholder="$t('feed.placeholder28')" v-model="v.ids"
                                            :options="industry"></el-cascader>
                                        <div class="del" @click="handleDel(index, i, 1)">{{ $t('task.del') }}</div>
                                    </el-col>
                                    <el-col class="line add" :span="5">
                                        <div @click="handleAdd(1, index)">+{{ $t('con.continue_industry') }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :label="$t('circle.category')" prop="category_id">
                                    <el-select v-model="item.category_id" :placeholder="$t('personal.placeholder18')"
                                        style="width: 100%">
                                        <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt, index) in category"
                                            :key="index" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('circle.introduction')" prop="introduction">
                                    <el-input :rows="6" :placeholder="$t('con.placeholder11')" type="textarea"
                                        v-model="item.introduction" :maxLength="250"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.background')" prop="background">
                                    <el-input :rows="6" :placeholder="$t('con.placeholder12')" type="textarea"
                                        v-model="item.background"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.main_field')" prop="main_field">
                                    <el-input :rows="6" :placeholder="$t('con.placeholder13')" type="textarea"
                                        v-model="item.main_field"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.achievement')" prop="achievement">
                                    <el-input :rows="6" :placeholder="$t('con.placeholder14')" type="textarea"
                                        v-model="item.achievement"></el-input>
                                </el-form-item>
                                <el-form-item   prop="reliable_relationship">
                                    <!-- 
                                        :label="$t('index.relation')"
                                     -->
                                     <template #label>
                                        <div>{{ $t('index.relation') }}</div>
                                        <div class="tips">*{{ $t('index.bgVisible') }}</div>
                                     </template>

                                    <el-input :rows="6" :placeholder="$t('index.reliable_relationship')"  type="textarea"
                                        v-model="item.reliable_relationship"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.link')" prop="out_line">
                                    <div v-for="(itemt, index2) in item.out_line" :key="index2" class="item">
                                        <el-input v-model="itemt.name" :placeholder="$t('con.placeholder15')"></el-input>
                                        <el-input v-model="itemt.url" :placeholder="$t('con.placeholder16')"
                                            style="margin-top:15px"></el-input>
                                        <div class="del" @click="handleDel(index, index2, 2)">{{ $t('task.del') }}</div>
                                    </div>
                                    <div class="add" @click="handleAdd(2, index)">+{{ $t('con.add_resource_link') }}</div>
                                </el-form-item>
                                <el-form-item :label="$t('index.label')" prop="label">
                                    <el-input v-model="item.label" :placeholder="$t('index.placeholder')"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <!-- 后台协助 -->
                    <div v-if="currentIndex == 1">
                        <div v-for="(item, index) in list2" :key="index">
                            <el-form size="medium" :ref="'form' + index" :rules="rules" :model="item" label-position="left"
                                label-width="75px">
                                <div class="flex-row-center-between">
                                    <div class="tabs flex-row-center">
                                        <div class="tab tab1" :class="{ 'active': item.type == 2 }"
                                            @click="changeTab(index, 2)">
                                            {{ $t('circle.organ') }}</div>
                                        <div class="tab tab2" :class="{ 'active': item.type == 1 }"
                                            @click="changeTab(index, 1)">
                                            {{ $t('circle.export') }}</div>
                                    </div>
                                    <div class="del-source" @click="delSource(index)">{{ $t('post.del_resource') }}</div>
                                </div>
                                <el-form-item :label="$t('con.resource_tit')" prop="title">
                                    <el-input v-model="item.title" :placeholder="$t('con.placeholder3')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.orgname')" prop="organization" v-if="item.type == 2">
                                    <el-input v-model="item.organization" :placeholder="$t('con.placeholder4')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.main_character')" prop="main_character"
                                    v-if="item.type == 1">
                                    <el-input v-model="item.main_character"
                                        :placeholder="$t('con.placeholder5')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.other_character')" prop="other_character"
                                    v-if="item.type == 1">
                                    <el-input v-model="item.other_character"
                                        :placeholder="$t('con.placeholder6')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('index.relation')"  prop="reliable_relationship">
                                    <el-input :rows="6" :placeholder="$t('index.reliable_relationship')"  type="textarea"
                                        v-model="item.reliable_relationship"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="add-btn" @click="add">+{{ $t('post.add_resource') }}</div>
            </div>
            <!-- 添加已有资源 -->
            <div v-if="flag == 1">
                <div class="floor5" v-if="list.length || list2.length">
                    <div class="navs flex-row-center-between">
                        <div class="navs-left">{{ $t('post.choose_resource') }}</div>
                        <div class="navs-right" @click="flag = 0">{{ $t('post.add_resource') }}</div>
                    </div>
                    <div v-for="(item, index) in list3" :key="index" class="floor5-item flex-row-center-between">
                        <div class="item-left flex-row-center" v-if="item.type" @click="handshow(index)">
                            <div class="tit">{{ $t('circle.source') }}:</div>
                            <div class="mark" v-if="item.type == 2"> {{ $t('circle.organ') }}</div>
                            <div class="mark" v-if="item.type == 1"> {{ $t('circle.export') }}</div>
                            <div class="con">{{ item.title }}</div>
                        </div>
                        <div v-else class="item-left" style="width: 100%;" @click="handshow(index)">{{
                            $t('post.choose_resource2') }}</div>
                        <div class="item-right"></div>

                    </div>

                </div>
                <div class="add-btn" @click="add2">+{{ $t('post.add_resource2') }}</div>
            </div>
            <div class="floor6 flex-row-center">
                <el-checkbox v-model="checkedVal" @change="changeCheckVal" />
                <div class="floor6-left" @click="circleVisible = !circleVisible">{{ $t('post.async') }}</div>
                <div class="floor6-right" @click="circleVisible = !circleVisible">{{ $t('post.manage_group') }}</div>
            </div>
            <div class="floor7" v-if="circleVisible">
                <div class="floor7-top flex-row-center-between">
                    <div class="floor7-tit">{{ $t('post.manage_group') }}</div>
                    <div class="floor7-top-right flex-row-center">
                        <div class="btn" @click="checkedAll">{{ $t('personal.checked_all') }}</div>
                        <!-- <div class="btn" @click="handleConfirm">{{ $t('post.sure') }}</div> -->
                    </div>
                </div>
                <ul class="flex-row-wrap">
                    <li v-for="(item, i) in list4">
                        <el-checkbox v-model="item.checked" @change="(event)=>changeCheck(event,i)">{{ item.name
                        }}</el-checkbox>
                    </li>
                </ul>
            </div>
            <div class="submit-btn" @click="handleSubmit">{{ $t('con.publish') }}</div>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
        <ViewSelect :show="shows" @handleClose="shows = false" @handleChoose="(event) => handleChoose(event)" />
    </div>
</template>
<script>
import uploadFile from "@/utils/upload-file";
import ViewSelect from './ViewSelect.vue'
import { Loading } from "element-ui";
import $ from "jquery";
export default {
    props: ["show"],
    components: { ViewSelect },
    data() {
        return {
            visible: false,//控制显示和隐藏
            circleVisible: false,//控制圈子的显示和隐藏
            user: this.$storage.getData("userInfo"),
            form: {
                title: '',//动态标题
                content: '',//动态内容
                image: [],
                video: '',
                topic_id: '',
                label: '',
                group_ids: [],
                video_cover: '',
                urls: [
                    {
                        name: '',
                        url: ''
                    }
                ],
                resource: [],
                resource_admin: [],
                sync_group: 0
            },
            showImg: true,//显示图片
            hideUploadEdit: false,//是否显示上传按钮
            fileList: [],//图片列表
            loading: false,//加载效果
            country: [],//国家
            province: [],//省份
            city: [],//城市
            industry: [],//行业
            category: [],//资源类别
            list: [],
            rules: {},
            list2: [],//后台协助
            list3: [],//已有资源
            list4: [],//圈子
            rules2: {},
            navs: [
                { title: this.$t('post.way1') },
                { title: this.$t('post.way2') }
            ],
            currentIndex: 0,
            flag: 0,//0添加资源 1选择已有资源
            shows: false,
            index: -1,
            checkedVal: false,//是否同步
        };
    },
    watch: {
        show(val) {
            this.fetchData()
        }
    },
    methods: {
        //添加标签
        handleAddLabel() {
            this.form.label += '#'
        },
        //导航改变
        changeNav(index) {
            this.currentIndex = index
        },
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        //添加更多链接
        addLink() {
            this.form.urls.push({ name: '', url: '' },)
        },
        //删除链接
        delLink(index) {
            this.form.urls.splice(index, 1)
        },
        //上传图片改变
        async onChange(file) {
            this.loading = true
            this.hideUploadEdit = false;
            this.showImg = true;
            this.form.video = '';
            console.log(file)
            let { data } = await uploadFile(file);
            this.form.image.push(data.path)
            this.fileList.push({ url: data.path })
            this.loading = false
        },
        //上传视频改变
        async onChangeVideo(file) {
            this.hideUploadEdit = true;
            this.showImg = false;
            this.form.image = [];
            this.fileList = [];
            this.loading = true;
            let { data } = await uploadFile(file);
            this.form.video = data.path
            this.form.video_cover = data.cover
            this.fileList = [{ url: data.path }]
            this.loading = false
        },
        //移除图片
        handleRemove(e) {
            this.form.image = this.form.image.filter(imgUrl => imgUrl != e.url)
            this.hideUploadEdit = this.form.iamge.length >= 9 ? true : false;
        },
        //上传图片
        uploadImg() {
            this.visible = true
            this.hideUploadEdit = false;
            this.showImg = true;
            this.form.video = '';
            this.form.video_cover = '';
            $(".uploadImg .el-upload__input").click();
        },
        //上传视频
        uploadVideo() {
            this.hideUploadEdit = true;
            this.showImg = false;
            this.form.image = []
            $(".uploadVideo .el-upload__input").click();
        },
        //删除视频
        handleDelVideo() {
            this.hideUploadEdit = false;
            this.showImg = true;
            this.form.video = '';
        },
        //发布
        handleSubmit() {
            if (this.list4 && this.list4.length) {
                let checkedList = this.list4.filter(item => item.checked)
                this.form.group_ids = checkedList.map(item => item.id)

            }
            if (this.list && this.list.length) {
                this.list.forEach(item => {
                    let arr = []
                    item.chooseIndustry.forEach(item => {

                        arr.push(item.ids[item.ids.length - 1])
                    })
                    item.industry_ids = arr.join(',')
                })

            }


            if (!this.form.title.length) {
                this.$message.warning(this.$t('post.tip1'))
                return
            }
            if (!this.form.content.length) {
                this.$message.warning(this.$t('post.tip2'))
                return
            }
            // if (this.flag == 0) {
            //     if (this.currentIndex == 0) {
            //         this.form.resource = this.list
            //     }
            //     if (this.currentIndex == 1) {
            //         this.form.resource_admin = this.list2
            //     }
            // } else {
            //     let resource_ids = this.list3.map(item => item.id)
            //     this.$set(this.form, 'resource_ids', resource_ids)
            // }
            if (this.list && this.list.length&&this.list[0].title!='') {
                this.form.resource = this.list
            }

            if (this.list2 && this.list2.length&&this.list2[0].title!='') {
                this.form.resource_admin = this.list2
            }
            if (this.list3 && this.list3.length) {
                let resource_ids = this.list3.map(item => item.id)
                if(resource_ids[0]!=''){
                    this.$set(this.form, 'resource_ids', resource_ids)
                }
            }




            if (this.flag == 0) {
                if (this.currentIndex == 0) {
                    this.form.resource = this.list
                }
                if (this.currentIndex == 1) {
                    this.form.resource_admin = this.list2
                }
            } else {
                let resource_ids = this.list3.map(item => item.id)
                this.$set(this.form, 'resource_ids', resource_ids)
            }







            if (this.form.urls && this.form.urls.length && this.form.urls[0].name == '') {
                this.form.urls = []
            }
            this.loading = true
            console.log(this.form)

            this.$api.post('/api/post', this.form).then((res) => {
                this.$message.success(this.$t('activity.publish_success'))
                this.$emit('handleClose')
            }).catch(() => {
                // this.$message.error(this.$t('con.publish_fail'))
            }).finally(() => {
                this.loading = false
            })
        },
        //获取数据
        fetchData() {
            this.$api
                .get("/api/industry", { paginate: 0 })
                .then((res) => {
                    this.industry = res.data
                });
            this.$api
                .get("/api/country", { paginate: 0 })
                .then((res) => {
                    this.country = res.data
                });
            this.$api
                .get("/api/resource-category", { paginate: 0 })
                .then((res) => {
                    this.category = res.data
                });
            if (this.user) {
                this.$api.get('/api/join-group', { params: { paginate: 0 } }).then(res => {
                    this.list4 = res.data.data
                    if (this.list4 && this.list4.length) {
                        this.list4.forEach(item => {
                            if (item.sync == 1) {
                                this.$set(item, 'checked', true)
                            } else {
                                this.$set(item, 'checked', false)
                            }

                        })
                    }
                })
            }
        },
        //删除资源
        delSource(index) {
            if (this.currentIndex == 0) {
                this.list.splice(index, 1)
            } else {
                this.list2.splice(index, 1)
            }
        },
        //选择国家改变
        changeCountry(id, index) {
            this.list[index].state = ''
            this.list[index].stateId = ''
            this.list[index].city = ''
            this.list[index].cityId = ''
            this.country.find(item => {
                if (item.id == id) {
                    this.list[index].country = item.name
                }
            })
            this.$api.get(`/api/state/${id}`).then(res => {
                this.province = res.data
            })
        },
        //选择城市改变
        changeCity(id, index) {
            this.city.find(item => {
                if (item.id == id) {
                    this.list[index].city = item.name
                }
            })
        },
        //选择省份改变
        changeProvince(id, index) {
            this.list[index].city = ''
            this.list[index].cityId = ''
            this.$api.get(`/api/city?pid=${id}`).then(res => {
                this.city = res.data
            })
        },
        //新增
        handleAdd(state, index) {
            switch (state) {
                //新增行业
                case 1:
                    this.list[index].chooseIndustry.push({ ids: [] })
                    break;
                //新增外链
                case 2:
                    this.list[index].out_line.push({ name: '', url: '' })
                    break;
            }
        },
        //删除
        handleDel(index1, index2, state) {
            switch (state) {
                //删除行业
                case 1:
                    this.list[index1].chooseIndustry.splice(index2, 1)
                    break;
                //新增外链
                case 2:
                    this.list[index1].out_line.splice(index2, 1)
                    break;
            }
        },
        //上传图片改变
        async onChange1(file, index) {
            this.list[index].hideUploadEdit = false
            let { data } = await uploadFile(file);
            this.list[index].image.push(data.path)
            this.list[index].fileList.push({ url: data.path })
        },
        //移除图片
        handleRemove1(e, index) {
            this.list[index].image = this.list[index].image.filter(imgUrl => imgUrl != e.url)
            this.list[index].hideUploadEdit = this.list[index].image.length >= 9 ? true : false;
        },
        //改变资源类别
        changeTab(index, type) {
            if (this.currentIndex == 0) {
                this.list[index].type = type
            } else {
                this.list2[index].type = type
            }
        },
        //全选
        checkedAll() {
            this.list4.forEach(item => {
                item.checked = true
            })
            this.handleConfirm()
        },
        //是否同步
        changeCheckVal(checkedVal) {
            this.checkedVal = checkedVal
            this.form.sync_group = checkedVal ? 1 : 0
            // this.circleVisible = checkedVal
            this.list4.forEach(item => {
                item.checked = checkedVal
            })

        },
        //确定
        handleConfirm() {
            if (this.list4 && this.list4.length) {
                let arr = this.list4.filter(item => item.checked)
                let group_ids = arr.map(item => item.id)
                this.$api.post(`/api/group/sync/post`, { group_ids })
            }

        },
        changeCheck(check, index) {

            if (check) {
                this.$api.post(`/api/group/sync/post`, { group_ids:[this.list4[index].id] })
                // this.$api.post(`/api/group/sync/post/${this.list4[index].id}`)
            }

        },
        //添加已有资源
        add2() {
            this.list3.push({ id: '', show: false })
        },
        //新增
        add() {
            if (this.currentIndex == 0) {
                this.list.push({
                    hideUploadEdit: false,//是否显示上传按钮
                    title: '',//资源标题
                    type: 1,//资源类别   （机构/专家）
                    image: [],//资源图片
                    fileList: [],
                    main_character: '',//主要角色（type 为专家时）
                    other_character: '',//其他角色 （type 为专家时）
                    organization: '',//     机构名称  （type 为机构时）
                    country: '',//     所属国家
                    countryId: '',
                    state: '',
                    stateId: '',
                    city: '',//   总部所在城市
                    cityId: '',
                    other_city: '',//     其他城市   城市1,城市2
                    category_id: '',//     资源类型id
                    introduction: '',//    资源介绍
                    background: '',//     资源背景
                    main_field: '',//   主要领域
                    achievement: '',//    关键成就
                    out_line: [],//   外部链接   （跟活动一样传json格式）
                    label: '',//   标签     ()
                    industry_ids: '',
                    fee_description: '',//费用说明
                    chooseIndustry: [],//选择的行业
                    reliable_relationship:''
                })
            }
            if (this.currentIndex == 1) {
                this.list2.push({
                    type: 1,//资源类别   （机构/专家）
                    title: '',//资源标题
                    organization: '',//     机构名称  （type 为机构时）
                    main_character: '',//主要角色
                    other_character: '',//其他角色
                    reliable_relationship:''
                })
            }
        },
        //选择已有资源
        handleChoose(item) {
            this.list3[this.index] = { ...this.list3[this.index], ...item }
            this.shows = false
        },
        //打开下拉框
        handshow(index) {
            this.shows = !this.shows
            this.index = index

        }
    }

};
</script>
<style scoped lang="scss">
.post-feed {
    .floor1 {
        img {
            width: 0.69rem;
            height: 0.69rem;
            border-radius: 50%;
            overflow: hidden;
            background-color: #efefef;
        }

        .floor1-title {
            margin-left: 0.14rem;
            font-size: 0.2rem;
            color: #2B2B2B;
        }
    }

    .floor2 {
        margin-top: 0.2rem;
    }

    .floor3 {
        width: 100%;
        min-height: 2.6rem;
        border-radius: 0.04rem;
        border: 0.01rem solid #E9E9E9;
        margin-top: 0.2rem;

        .floor3-head {
            width: 100%;
            height: 0.6rem;
            background: #F7F7F7;
            border-radius: 0.03rem 0.03rem 0rem 0rem;
            padding-left: 0.35rem;
            box-sizing: border-box;

            .floor3-item {
                margin-right: 1.11rem;
                cursor: pointer;

                span {
                    font-size: 0.16rem;
                    color: #828282;
                    margin-right: 0.04rem;
                }

                .pic {
                    width: 0.16rem;

                }

                .video {
                    width: 0.18rem;
                }

                .topic {
                    width: 0.14rem;
                }
            }

        }

        .label {
            width: 80%;
            outline: none;
            border: none;
            color: #02428C;
            padding: 0 0.2rem;
        }

        ::v-deep .el-textarea__inner {
            border: none;

        }

    }

    .floor4 {
        margin-top: 0.2rem;

        .floor4-item-bot {
            text-align: right;
            font-size: 0.15rem;
            color: #5077D5;
            cursor: pointer;

        }

        .floor4-item {
            .floor4-item-top {
                font-size: 0.15rem;

                .floor4-item-top-left {
                    color: #838383;
                }

                .floor4-item-top-right {
                    color: #5077D5;
                    cursor: pointer;
                }

            }

            .floor4-item-cen {
                margin-bottom: 0.1rem;
            }


            .floor4-btn {
                font-size: 0.16rem;
                color: #5077D5;
                cursor: pointer;
                width: 1rem;
                text-align: center;
            }
        }

    }

    .floor5 {
        margin-top: 0.2rem;

        .navs {
            flex: 1
        }

        .floor5-item {
            position: relative;
            height: 0.6rem;
            background: #FCFCFC;
            border-radius: 0.04rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            width: 100%;
            cursor: pointer;

            .item-left {

                .tit {
                    color: #02428C;
                }

                .mark {
                    width: 0.5rem;
                    height: 0.27rem;
                    background: #FF580F;
                    border-radius: 0.14rem;
                    font-size: 0.14rem;
                    text-align: center;
                    line-height: 0.27rem;
                    color: #FFFFFF;
                    margin: 0 0.06rem;
                }

                .con {

                    color: #262423;

                }
            }

            .item-right {
                width: 0;
                height: 0;
                border-left: 0.06rem solid transparent;
                border-right: 0.06rem solid transparent;
                border-top: 0.06rem solid #8B8B8B;

            }

        }

        .navs {
            margin-bottom: 0.2rem;

            ul {
                li {
                    margin-right: 0.3rem;
                    cursor: pointer;

                    .nav-tit {
                        min-width: 0.7rem;
                        height: 0.32rem;
                        font-size: 0.17rem;
                        font-weight: 400;
                        color: #828282;
                        text-align: center;
                        line-height: 0.32rem;
                    }

                    .nav-line {
                        min-width: 0.7rem;
                        height: 0.02rem;
                        background: #ffffff;
                        border-radius: 0.02rem;

                    }

                    .active-color {
                        color: #02428C;
                    }

                    .active-bg {
                        background: #02428C;
                    }

                }
            }

            .navs-left {
                font-size: 0.18rem;
                font-weight: 600;
                color: #2B2B2B;
                line-height: 0.32rem;

            }

            .navs-right {
                font-size: 0.15rem;
                color: #5077D5;
                cursor: pointer;

            }

        }

        .del-source {
            font-size: 0.15rem;
            color: #5077D5;
            cursor: pointer;
        }





    }

    .add-btn {
        width: 1.94rem;
        height: 0.43rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #5077D5;
        text-align: center;
        line-height: 0.43rem;
        color: #5077D5;
        font-size: 0.15rem;
        cursor: pointer;
        margin-top: 0.2rem;

    }

    .floor6 {
        margin-top: 0.4rem;

        .floor6-left {
            font-size: 0.16rem;
            color: #B0B0B0;
            margin: 0 0.14rem;
        }

        .floor6-right {
            font-size: 0.16rem;
            color: #02428C;
            cursor: pointer;
        }

    }

    .floor7 {
        height: auto;
        background: #FCFCFC;
        border-radius: 0.03rem;
        margin: 0.3rem;
        box-sizing: border-box;
        padding: 0.2rem 0.3rem;

        .floor7-tit {
            font-size: 0.18rem;
            font-weight: 500;
            color: #202020;

        }

        .floor7-top-right {
            .btn {
                color: #5077D5;
                font-size: 0.16rem;
                margin-left: 0.3rem;
                cursor: pointer;
            }
        }

        ul {
            li {
                width: 33%;
                color: #B0B0B0;
                margin-top: 0.2rem;
            }
        }


    }

    .submit-btn {
        margin: 0.5rem auto 0;
        width: 2rem;
        height: 0.5rem;
        text-align: center;
        line-height: 0.5rem;
        color: #ffffff;
        font-size: 0.16rem;
        cursor: pointer;
        background-color: #5077D5;
        border-radius: 0.06rem;
    }

    ::v-deep .el-upload-list__item.is-ready {
        display: none;
    }

    // 上传文件
    ::v-deep .el-upload--picture-card {
        width: 0.8rem;
        height: 0.8rem;
        line-height: 0.8rem;
        margin: 0.06rem;
    }

    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
        width: 0.8rem;
        height: 0.8rem;
        margin: 0.06rem;
    }

    ::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        width: 0.8rem;
        height: 0.8rem;

    }

    .add {
        font-size: 0.16rem;
        color: #02428C;
        line-height: 0.4rem;
        cursor: pointer;
        text-align: left;
    }



    .line {
        text-align: center;
    }

    .sec {
        margin-right: 0.1rem;
        margin-bottom: 0.3rem;
        position: relative;

        .del {
            font-size: 0.14rem;
            color: #587DD7;
            position: absolute;
            right: 0;
            top: -0.3rem;
            cursor: pointer;
        }

    }

    .item {
        margin-bottom: 0.3rem;
        position: relative;

        .del {
            font-size: 0.14rem;
            color: #587DD7;
            position: absolute;
            right: 0;
            top: -0.3rem;
            cursor: pointer;

        }
    }


    .tabs {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;

        .tab {
            width: 0.8rem;
            height: 0.36rem;
            border: 0.01rem solid #565656;
            text-align: center;
            line-height: 0.36rem;
            color: #828282;
            font-size: 0.16rem;
            cursor: pointer;
        }

        .tab1 {
            border-radius: 0.04rem 0rem 0rem 0.04rem;
        }

        .tab2 {
            border-radius: 0rem 0.04rem 0.04rem 0rem;
        }

        .active {
            background-color: #02428C;
            color: #ffffff;
            border-color: #02428C;
        }

        .add-btn {
            width: 1.94rem;
            height: 0.43rem;
            border-radius: 0.03rem;
            border: 0.01rem solid #5077D5;
            text-align: center;
            line-height: 0.43rem;
            cursor: pointer;
            font-size: 0.16rem;
            color: #5077D5;
        }

    }
}
.textarea{
    border-bottom: 1px solid #eaeaea;
   
}
.el-textarea__inner{
    overflow: hidden !important;
 } 

.img-self {
  margin-right: 4px;
  position: relative;
  .delete-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.646);
    text-align: center;
    line-height: 150px;
    font-size: 20px;
    color: #fff;
    border-radius: 4px;
  }
}
.tips{
    color:red;
    font-weight: bold;
    font-size: 0.14rem;
}
</style>
  
