<!-- 编辑动态 -->
<template>
    <div class="post-feed">
        <el-dialog title="" :visible.sync="show" width="50%" :before-close="handleClose">
            <div class="floor1 flex-row-center">
                <img class="avatar" :src="user.avatar" v-if="user && user.avatar" />
                <img class="avatar" src="@/assets/icons/default.png" v-else />
                <div class="floor1-title">{{ $t('feed.edit_feed') }}</div>
            </div>
            <div class="floor2">
                <el-input v-model="form.title" :placeholder="$t('post.placeholder1')" />
            </div>
            <div class="floor3 flex-col">
                <div class="floor3-head flex-row-center">
                    <div class="flex-row-center floor3-item" @click="uploadImg">
                        <span>{{ $t('post.add_img') }}</span>
                        <img src="@/assets/icons/picture.png" class="pic" />
                    </div>
                    <div class="flex-row-center floor3-item" @click="uploadVideo">
                        <span> {{ $t('post.add_video') }}</span>
                        <img src="@/assets/icons/video.png" class="video" />
                    </div>
                    <div class="flex-row-center floor3-item" @click="handleAddLabel">
                        <span>{{ $t('post.add_label') }}</span>
                        <img src="@/assets/icons/topic.png" class="topic" />
                    </div>
                </div>
                <div class="floor3-bot">
                    <el-input class="textarea" :autosize="{ minRows: 5, maxRows: 100 }" type="textarea"
                        :placeholder="$t('post.placeholder2')" v-model="form.content" />
                    <input class="label" v-model="form.label" />
                    <el-upload v-show="visible" class="uploadImg" action :limit="9" multiple accept="image/*"
                        :on-change="onChange" :show-file-list="showImg" :fileList="fileList" ref="imageUpload"
                        list-type="picture-card" :class="{ hide: hideUploadEdit }" :auto-upload="false"
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <i v-loading="loading"></i>
                    <el-upload class="uploadVideo" action multiple accept=".mp4" :on-change="onChangeVideo"
                        :show-file-list="false" ref="videoUpload">
                    </el-upload>
                    <div class="video-wrap" v-if="form.video && form.video.length">
                        <video style="height: 0.8rem; outline: none; width: 0.8rem" controls="controls" :src="form.video" />
                        <i class="el-icon-delete" @click="handleDelVideo"></i>
                    </div>
                </div>
            </div>
            <div class="floor4">
                <div class="floor4-item" v-for="(item, index) in form.urls" :key="index">
                    <div class="floor4-item-top flex-row-center-between">
                        <div class="floor4-item-top-left">{{ $t('post.urls') }}</div>
                        <div class="floor4-item-top-right" @click="delLink(index)">{{ $t('task.del') }}</div>
                    </div>
                    <div class="floor4-item-cen">
                        <el-input :placeholder="$t('post.url_name')" v-model="item.name" style="margin-top: 0.1rem;" />
                        <el-input :placeholder="$t('post.url_web')" v-model="item.url" style="margin-top: 0.1rem;" />
                    </div>
                </div>
                <div class="floor4-item-bot" @click="addLink">{{ $t('post.add_url') }}</div>
            </div>
            <div class="floor6 flex-row-center">
                <el-checkbox v-model="checkedVal" @change="changeCheckVal" />
                <div class="floor6-left" @click="circleVisible = !circleVisible">{{ $t('post.async') }}</div>
                <div class="floor6-right" @click="circleVisible = !circleVisible">{{ $t('post.manage_group') }}</div>
            </div>
            <div class="floor7" v-if="circleVisible">
                <div class="floor7-top flex-row-center-between">
                    <div class="floor7-tit">{{ $t('post.manage_group') }}</div>
                    <div class="floor7-top-right flex-row-center">
                        <div class="btn" @click="checkedAll">{{ $t('personal.checked_all') }}</div>
                        <div class="btn" @click="handleConfirm">{{ $t('post.sure') }}</div>
                    </div>
                </div>
                <ul class="flex-row-wrap">
                    <li v-for="(item, index) in list4">
                        <el-checkbox v-model="item.checked">{{ item.name
                        }}</el-checkbox>
                    </li>
                </ul>
            </div>
            <div class="submit-btn" @click="handleSubmit">{{ $t('con.publish') }}</div>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>
import uploadFile from "@/utils/upload-file";
import $ from "jquery";
export default {
    props: ["show", "params"],
    data() {
        return {
            visible: false,//控制显示和隐藏
            circleVisible: false,//控制圈子的显示和隐藏
            user: this.$storage.getData("userInfo"),
            form: {
                id: null,
                title: '',//动态标题
                content: '',//动态内容
                image: [],
                video: '',
                topic_id: '',
                label: '',
                group_ids: [],
                video_cover: '',
                urls: [
                    {
                        name: '',
                        url: ''
                    }
                ],
                sync_group: 0
            },
            showImg: true,//显示图片
            hideUploadEdit: false,//是否显示上传按钮
            fileList: [],//图片列表
            loading: false,//加载效果
            checkedVal: false,//是否同步
            list4: []
        };
    },
    watch: {
        show(val) {
            this.fetchData()
            if (this.params) {

                let { id, title, content, label, image, urls } = this.params
                let labelStr = ''
                if (label && label.length) {
                    let labelArr = label.map(item => item.label)
                    labelStr = labelArr.join('')
                }
                this.form = {
                    id, title, content, label: labelStr, image, urls,
                    sync_group: 0
                }

                if (this.form.image && this.form.image.length) {
                    this.visible = true

                    this.fileList = this.form.image.map(item => {
                        return {
                            url: item
                        }
                    })

                }
            }

        }

    },
    created() {






    },
    methods: {
        //添加标签
        handleAddLabel() {
            this.form.label += '#'
        },
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        //添加更多链接
        addLink() {
            this.form.urls.push({ name: '', url: '' },)
        },
        //删除链接
        delLink(index) {
            this.form.urls.splice(index, 1)
        },
        //上传图片改变
        async onChange(file) {
            this.loading = true
            this.hideUploadEdit = false;
            this.showImg = true;
            this.form.video = '';
            let { data } = await uploadFile(file);
            this.form.image.push(data.path)
            this.fileList.push({ url: data.path })
            this.loading = false
        },
        //上传视频改变
        async onChangeVideo(file) {
            this.hideUploadEdit = true;
            this.showImg = false;
            this.form.image = [];
            this.fileList = [];
            this.loading = true;
            let { data } = await uploadFile(file);
            this.form.video = data.path
            this.form.video_cover = data.cover
            this.fileList = [{ url: data.path }]
            this.loading = false
        },
        //移除图片
        handleRemove(e) {
            this.form.image = this.form.image.filter(imgUrl => imgUrl != e.url)
            this.hideUploadEdit = this.form.iamge.length >= 9 ? true : false;
        },
        //上传图片
        uploadImg() {
            this.visible = true
            this.hideUploadEdit = false;
            this.showImg = true;
            this.form.video = '';
            this.form.video_cover = '';
            $(".uploadImg .el-upload__input").click();
        },
        //上传视频
        uploadVideo() {
            this.hideUploadEdit = true;
            this.showImg = false;
            this.form.image = []
            $(".uploadVideo .el-upload__input").click();
        },
        //删除视频
        handleDelVideo() {
            this.hideUploadEdit = false;
            this.showImg = true;
            this.form.video = '';
        },
        //发布
        handleSubmit() {
            let checkedList = this.list4.filter(item => item.checked)
            this.form.group_ids = checkedList.map(item => item.id)
            if (!this.form.title.length) {
                this.$message.warning(this.$t('post.tip1'))
            }
            if (!this.form.content.length) {
                this.$message.warning(this.$t('post.tip2'))
            }
            if (this.form.urls && this.form.urls.length && this.form.urls[0].name == '') {
                this.form.urls = []
            }
            this.loading = true


            this.$api.post('/api/post', this.form).then((res) => {
                this.$message.success(this.$t('activity.publish_success'))
                this.$emit('handleClose')
            }).catch(() => {
                this.$message.error(this.$t('con.publish_fail'))
            }).finally(() => {
                this.loading = false
            })
        },
        //获取数据
        fetchData() {

            if (this.user) {
                this.$api.get('/api/join-group', { params: { paginate: 0 } }).then(res => {
                    this.list4 = res.data.data
                    if (this.list4 && this.list4.length) {
                        this.list4.forEach(item => {
                            if (item.sync == 1) {
                                this.$set(item, 'checked', true)
                            } else {
                                this.$set(item, 'checked', false)
                            }

                        })
                    }
                })
            }
        },

        //全选
        checkedAll() {
            this.list4.forEach(item => {
                item.checked = true
            })
        },
        //是否同步
        changeCheckVal(checkedVal) {
            this.checkedVal = checkedVal
            this.form.sync_group = checkedVal ? 1 : 0
            // this.circleVisible = checkedVal
            this.list4.forEach(item => {
                item.checked = checkedVal
            })

        },
        //确定
        handleConfirm() {
            if (this.list4 && this.list4.length) {
                let arr = this.list4.filter(item => item.checked)
                let group_ids = arr.map(item => item.id)
                this.$api.post(`/api/group/sync/post`, { group_ids })
            }

        },
        // changeCheck(check, index) {
        //     if (check) {
        //         this.$api.post(`/api/group/sync/post/${this.list4[index].id}`)
        //     }

        // },
    }

};
</script>
<style scoped lang="scss">
.post-feed {
    .floor1 {
        img {
            width: 0.69rem;
            height: 0.69rem;
            border-radius: 50%;
            overflow: hidden;
            background-color: #efefef;
        }

        .floor1-title {
            margin-left: 0.14rem;
            font-size: 0.2rem;
            color: #2B2B2B;
        }
    }

    .floor2 {
        margin-top: 0.2rem;
    }

    .floor3 {
        width: 100%;
        height: auto;
        border-radius: 0.04rem;
        border: 0.01rem solid #E9E9E9;
        margin-top: 0.2rem;

        .floor3-head {
            width: 100%;
            height: 0.6rem;
            background: #F7F7F7;
            border-radius: 0.03rem 0.03rem 0rem 0rem;
            padding-left: 0.35rem;
            box-sizing: border-box;

            .floor3-item {
                margin-right: 1.11rem;
                cursor: pointer;

                span {
                    font-size: 0.16rem;
                    color: #828282;
                    margin-right: 0.04rem;
                }

                .pic {
                    width: 0.16rem;

                }

                .video {
                    width: 0.18rem;
                }

                .topic {
                    width: 0.14rem;
                }
            }

        }

        .label {
            width: 80%;
            outline: none;
            border: none;
            color: #02428C;
            padding: 0 0.2rem;
        }

        ::v-deep .el-textarea__inner {
            border: none;

        }

    }

    .floor4 {
        margin-top: 0.2rem;

        .floor4-item-bot {
            text-align: right;
            font-size: 0.15rem;
            color: #5077D5;
            cursor: pointer;

        }

        .floor4-item {
            .floor4-item-top {
                font-size: 0.15rem;

                .floor4-item-top-left {
                    color: #838383;
                }

                .floor4-item-top-right {
                    color: #5077D5;
                    cursor: pointer;
                }

            }

            .floor4-item-cen {
                margin-bottom: 0.1rem;
            }


            .floor4-btn {
                font-size: 0.16rem;
                color: #5077D5;
                cursor: pointer;
                width: 1rem;
                text-align: center;
            }
        }

    }

    .floor5 {
        margin-top: 0.2rem;

        .navs {
            flex: 1
        }

        .floor5-item {
            position: relative;
            height: 0.6rem;
            background: #FCFCFC;
            border-radius: 0.04rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            width: 100%;
            cursor: pointer;

            .item-left {

                .tit {
                    color: #02428C;
                }

                .mark {
                    width: 0.5rem;
                    height: 0.27rem;
                    background: #FF580F;
                    border-radius: 0.14rem;
                    font-size: 0.14rem;
                    text-align: center;
                    line-height: 0.27rem;
                    color: #FFFFFF;
                    margin: 0 0.06rem;
                }

                .con {

                    color: #262423;

                }
            }

            .item-right {
                width: 0;
                height: 0;
                border-left: 0.06rem solid transparent;
                border-right: 0.06rem solid transparent;
                border-top: 0.06rem solid #8B8B8B;

            }

        }

        .navs {
            margin-bottom: 0.2rem;

            ul {
                li {
                    margin-right: 0.3rem;
                    cursor: pointer;

                    .nav-tit {
                        width: 0.7rem;
                        height: 0.32rem;
                        font-size: 0.17rem;
                        font-weight: 400;
                        color: #828282;
                        text-align: center;
                        line-height: 0.32rem;
                    }

                    .nav-line {
                        width: 0.7rem;
                        height: 0.02rem;
                        background: #ffffff;
                        border-radius: 0.02rem;

                    }

                    .active-color {
                        color: #02428C;
                    }

                    .active-bg {
                        background: #02428C;
                    }

                }
            }

            .navs-left {
                font-size: 0.18rem;
                font-weight: 600;
                color: #2B2B2B;
                line-height: 0.32rem;

            }

            .navs-right {
                font-size: 0.15rem;
                color: #5077D5;
                cursor: pointer;

            }

        }

        .del-source {
            font-size: 0.15rem;
            color: #5077D5;
            cursor: pointer;
        }





    }

    .add-btn {
        width: 1.94rem;
        height: 0.43rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #5077D5;
        text-align: center;
        line-height: 0.43rem;
        color: #5077D5;
        font-size: 0.15rem;
        cursor: pointer;
        margin-top: 0.2rem;

    }

    .floor6 {
        margin-top: 0.4rem;

        .floor6-left {
            font-size: 0.16rem;
            color: #B0B0B0;
            margin: 0 0.14rem;
        }

        .floor6-right {
            font-size: 0.16rem;
            color: #02428C;
            cursor: pointer;
        }

    }

    .floor7 {
        height: auto;
        background: #FCFCFC;
        border-radius: 0.03rem;
        margin: 0.3rem;
        box-sizing: border-box;
        padding: 0.2rem 0.3rem;

        .floor7-tit {
            font-size: 0.18rem;
            font-weight: 500;
            color: #202020;

        }

        .floor7-top-right {
            .btn {
                color: #5077D5;
                font-size: 0.16rem;
                margin-left: 0.3rem;
                cursor: pointer;
            }
        }

        ul {
            li {
                width: 33%;
                color: #B0B0B0;
                margin-top: 0.2rem;
            }
        }


    }

    .submit-btn {
        margin: 0.5rem auto 0;
        width: 2rem;
        height: 0.5rem;
        text-align: center;
        line-height: 0.5rem;
        color: #ffffff;
        font-size: 0.16rem;
        cursor: pointer;
        background-color: #5077D5;
        border-radius: 0.06rem;
    }

    // 上传文件
    ::v-deep .el-upload--picture-card {
        width: 0.8rem;
        height: 0.8rem;
        line-height: 0.8rem;
        margin: 0.06rem;
    }

    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
        width: 0.8rem;
        height: 0.8rem;
        margin: 0.06rem;
    }

    ::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        width: 0.8rem;
        height: 0.8rem;

    }

    .add {
        font-size: 0.16rem;
        color: #02428C;
        line-height: 0.4rem;
        cursor: pointer;
        text-align: left;
    }



    .line {
        text-align: center;
    }

    .sec {
        margin-right: 0.1rem;
        margin-bottom: 0.3rem;
        position: relative;

        .del {
            font-size: 0.14rem;
            color: #587DD7;
            position: absolute;
            right: 0;
            top: -0.3rem;
            cursor: pointer;
        }

    }

    .item {
        margin-bottom: 0.3rem;
        position: relative;

        .del {
            font-size: 0.14rem;
            color: #587DD7;
            position: absolute;
            right: 0;
            top: -0.3rem;
            cursor: pointer;

        }
    }


    .tabs {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;

        .tab {
            width: 0.8rem;
            height: 0.36rem;
            border: 0.01rem solid #565656;
            text-align: center;
            line-height: 0.36rem;
            color: #828282;
            font-size: 0.16rem;
            cursor: pointer;
        }

        .tab1 {
            border-radius: 0.04rem 0rem 0rem 0.04rem;
        }

        .tab2 {
            border-radius: 0rem 0.04rem 0.04rem 0rem;
        }

        .active {
            background-color: #02428C;
            color: #ffffff;
            border-color: #02428C;
        }

        .add-btn {
            width: 1.94rem;
            height: 0.43rem;
            border-radius: 0.03rem;
            border: 0.01rem solid #5077D5;
            text-align: center;
            line-height: 0.43rem;
            cursor: pointer;
            font-size: 0.16rem;
            color: #5077D5;
        }

    }
}

::v-deep .el-upload-list__item.is-ready {
    display: none;
}

.textarea {
    border-bottom: 1px solid #eaeaea;

}

.el-textarea__inner {
    overflow: hidden !important;
}</style>
  
