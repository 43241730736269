<!-- 新用户信息 -->
<template>
    <div class="imprve-info">
        <div class="main flex-col-center">
            <div class="avatar-wrap" @click="handleUpload">
              
                <el-image class="avatar" :src="form.avatar" v-if="form.avatar && form.avatar.length" />
                <img class="avatar" src="@/assets/icons/default.png" v-else />
                <img class="avatar-icon" src="@/assets/icons/avatar.png" />
            </div>
            <div class="form-item">
                <div class="form-label">{{ $t('auth.nick_name') }}</div>
                <div class="form-input">
                    <el-input :placeholder="$t('auth.placeholder5')" v-model="form.name" :maxlength="16"/>
                </div>
            </div>
            <div class="form-item">
                <div class="form-label"> {{ $t('auth.job') }}</div>
                <div class="form-input">
                    <el-input :placeholder="$t('auth.placeholder6')" v-model="form.career" />
                    <!-- <el-select v-model="form.profession_id" :placeholder="$t('auth.placeholder6')" style="width: 100%">
                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in list"></el-option>
                    </el-select> -->
                </div>
            </div>
            <div class="form-item">
                <div class="form-label"> {{ $t('auth.introduce') }}</div>
                <div class="form-input">
                    <el-input type="textarea" :placeholder="$t('auth.placeholder7')" v-model="form.introduction" />
                </div>
            </div>
            <div class="btn" @click="handleSubmit" v-loading="loading" element-loading-spinner="el-icon-loading">{{ $t('auth.submit') }}</div>
        </div>
        <input type="file" style="display:none ;" ref="inputRef" @change="change" />
    </div>
</template>
<script>
import uploadFile from "@/utils/upload-file";
export default {
    data() {
        return {
            loading: false,//加载效果
            form: {
                name: '',//昵称
                career: '',//专业
                introduction: '',//简介
                avatar: null,//头像
            },
            // list: [],//职业列表
        }
    },
    created() {
        //查看职业列表
        // this.getProfession()
        //查看用户信息
        this.getUser()
    },
    methods: {
        //查看用户信息
        getUser() {
            this.$api.get('/api/user').then(res => {
                const user = res.data;
                this.form = {
                    name: user.name,//昵称
                    avatar: user.avatar,//头像
                    career:user.career,
                    introduction: user.introduction,//简介
                }
            })
        },
        //查看职业列表
        // getProfession() {
        //     this.$api.get('/api/profession').then(res => {
        //         this.list = res.data
        //     })
        // },
        //打开上传文件弹窗
        handleUpload() {
            this.$refs.inputRef.click()
        },
        //文件改变
        async change(e) {
            let file = e.target.files[0]
            let { data } = await uploadFile(file);
            this.form.avatar = data.path
        },
        //提交
        handleSubmit() {
            if (!this.form.name.length) {
                this.$message.warning(this.$t('auth.tip10'))
                return
            }
            if (!this.form.avatar) {
                this.$message.warning(this.$t('auth.tip11'))
                return
            }
            this.loading = true
            this.$api.post('/api/user', this.form).then(() => {
                this.$message.success(this.$t('auth.operate_success'))
                //获取用户信息
                this.$storage.userInfo();
                this.$router.push('/focus')
            }).catch(() => {
                this.$message.error(this.$t('auth.operate_fail'))
            }).finally(() => {
                this.loading = false
            })
        }
    }
};
</script>
<style scoped lang="scss">
.imprve-info {

    width: 100%;

    .main {
        width: 9.3rem;
        height: 8.6rem;
        background: #FFFFFF;
        border-radius: 0.06rem;
        padding: 0rem 1.4rem 0.3rem;
        box-sizing: border-box;
        margin: 1.2rem auto;

        .avatar-wrap {
            position: relative;
            width: 1.1rem;
            height: 1.1rem;
            margin: 0 auto 0.26rem;
            cursor: pointer;

            .avatar {
                width: 1.1rem;
                height: 1.1rem;
                border-radius: 50%;
                background-color: #efefef;

            }

            .avatar-icon {
                position: absolute;
                width: 0.49rem;
                height: 0.49rem;
                border-radius: 50%;
                bottom: 0;
                right: 0;
                background-color: #efefef;

            }


        }

        .form-item {
            width: 100%;

            .form-label {
                margin: 0.2rem 0 0.2rem;
                font-size: 0.18rem;


                color: #828282;
            }

            .form-input {
                ::v-deep .el-input__inner {
                    height: 0.6rem;
                    background: #FBFBFB;
                    border-radius: 0.04rem;
                    width: 100%;
                    outline: none;
                    border: none;
                    font-size: 0.18rem;
                    padding-left: 0.26rem;
                    box-sizing: border-box;
                    line-height: 0.6rem;
                }

                ::v-deep .el-textarea__inner {
                    width: 100%;
                    height: 2.12rem;
                    background: #FBFBFB;
                    border-radius: 0.04rem;
                    padding: 0.2rem 0.26rem;
                    box-sizing: border-box;
                    font-size: 0.18rem;
                    border: none;
                    outline: none;
                }
            }



        }

        .btn {
            width: 100%;
            text-align: center;
            line-height: 0.6rem;
            height: 0.6rem;
            background: #02428C;
            font-size: 0.18rem;
            cursor: pointer;
            color: #FFFFFF;
            border-radius: 0.04rem;
            margin-top: 0.3rem;

        }

    }

}
</style>
