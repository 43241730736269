/*
 * @Description: 复制
 * @Author: tl
 * @Date: 2021-10-28 10:10:04
 * @LastEditTime: 2021-11-19 15:54:05
 * @LastEditors: Please set LastEditors
 * @FilePath: \cms-web-train\src\utils\copy.js
 */
import {
  Message
} from 'element-ui';
export function copyData(val) {
  let oInput = document.createElement('input')
  
  oInput.value = val;
  document.body.appendChild(oInput)
  oInput.select() // 选择对象
  document.execCommand("Copy") // 执行浏览器复制命令
  oInput.style.display = 'none'
  Message({
    type: 'success',
    message: ' 已复制链接，赶快分享给你的朋友吧！'
  })
}
