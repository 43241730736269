<template>
    <div>
        <div v-if="list.length">
            <div class="task-item" v-for="(item, index) in list" :key="index"
                @click="handleSkip(`/taskDetail?id=${item.id}`)">
                <div class="task-head flex-row-center-between">
                    <div class="task-head-left">
                        <div class="task-state flex-row-center">{{ $t('task.task_status') }}：

                            <span v-if="item.status == -1">{{ $t('task.status7') }}</span>
                            <span v-if="item.status == 0">{{ $t('task.status0') }}</span>
                            <span v-if="item.status == 1">{{ $t('task.status6') }}</span>
                            <span v-if="item.status == 2">{{ $t('task.status2') }}</span>
                            <span v-if="item.status == 3">{{ $t('task.status3') }}</span>
                            <span v-if="item.status == 4">{{ $t('task.status4') }}</span>
                        </div>
                        <div class="task-bian">{{ $t('task.taskno') }}：{{ item.id }}</div>
                    </div>
                    <div class="task-head-right flex-row-center">
                        <div class="publisher">{{ $t('task.publisher') }}：</div>
                        <img :src="item.user && item.user.avatar" />
                        <div class="nickName">{{ item.user && item.user.name }}</div>
                    </div>
                </div>
                <div class="task-info">
                    <div class="title">{{ item.title }}</div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task1.png" />
                        <div class="label-right">{{ $t('task.content') }}：</div>
                    </div>
                    <div class="desc">
                        {{ item.content }}</div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task2.png" />
                        <div class="label-right">{{ $t('task.deliverable') }}：</div>
                    </div>
                    <div class="desc">
                        {{ item.deliverable }}
                    </div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task3.png" />
                        <div class="label-right">{{ $t('task.category') }}：</div>
                        <div class="label-main">{{ item.type }}</div>
                        <img src="@/assets/icons/task4.png" />
                        <div class="label-right">{{ $t('task.budget') }}：</div>
                        <div class="label-main">{{ item.budget }}</div>
                        <img src="@/assets/icons/task5.png" />
                        <div class="label-right">{{ $t('task.due_time') }}：</div>
                        <div class="label-main">{{ item.due_time }}{{ $t('task.front') }}</div>
                    </div>

                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task6.png" />
                        <div class="label-right">{{ $t('task.country') }}：</div>
                        <div class="label-main" v-if="item.country && item.country.length&&item.country[0]!=''"> <span
                                style="margin-right: 0.1rem;" v-for="(itemt, i) in item.country" :key="i">{{ itemt
                                }}</span></div>
                        <div class="label-main" v-else>
                             {{ $t('index.no_limit') }}
                        </div>
                    </div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task7.png" />
                        <div class="label-right">{{ $t('task.city') }}：</div>
                        <div class="label-main" v-if="item.city && item.city.length&&item.city[0]!=''"><span style="margin-right: 0.1rem;"
                                v-for="(itemt, i) in item.city" :key="i">{{ itemt }}</span></div>
                        <div class="label-main" v-else>
                             {{ $t('index.no_limit') }}
                        </div>
                    </div>
                    <div class="flex-row-center label">
                        <img src="@/assets/icons/task8.png" />
                        <div class="label-right">{{ $t('task.industry') }}：</div>
                        <div class="label-main" v-if="item.industry && item.industry.length"><span
                                style="margin-right: 0.1rem;" v-for="(itemt, i) in item.industry" :key="i">{{
                                    itemt.name }}</span></div>

                        <div class="label-main" v-else>
                             {{ $t('index.no_limit') }}
                        </div>
                    </div>
                </div>
                <div class="task-operate flex-row-center-between">
                    <div class="time">{{ $t('task.end_time') }}：{{ item.end_time }}</div>
                    <div class="btn flex-row-center">
                        <div class="common"  v-if="item.has_join == 0 && item.status >= 1&&item.status<4" @click.stop="handleApply(item.id)" v-loading="loading"
                            element-loading-spinner="el-icon-loading">{{ $t('task.apply') }}</div>
                        <div class="common"  v-if="item.has_join != 1 && item.extra_join == 1"  @click.stop="handleApply(item.id, 1)">{{
                            $t('task.apply2') }}</div>
                        <div class="common diff" @click.stop="handleCancel(item.id, index)"> {{
                            $t('personal.cancel_collect') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <ViewEmpty v-else />

        <!-- 申请接单 -->
        <ApplyOrder :show="show" @handleClose="show = false" @sub="handleJoin" />
        <!-- 申请接单成功 -->
        <CustomerService :show="show2" @handleClose="show2 = false" />
        <!-- 未认证弹窗 -->
        <ApplyService :show="show3" @handleClose="show3 = false" :tip="$t('task.tip1')" />
    </div>
</template>
<script>
import CustomerService from '@/views/personal/components/CustomerService'
import ApplyService from '@/components/ApplyService.vue'
import ApplyOrder from '@/components/ApplyOrder'
export default {
    components: { ApplyOrder, CustomerService, ApplyService },
    name: "",
    props: ["list"],

    data() {
        return {
            show: false,//控制申请弹窗的显示和隐藏
            show2: false,
            show3: false,
            loading: false,
            form: {
                id: '',
                resource_ids: []
            },
            user: this.$storage.getData("userInfo")
        };
    },
    methods: {
        handleSkip(url) {
            this.$router.push(url)
        },
        //取消收藏
        handleCancel(id, index) {
            this.list.splice(index, 1)
            this.$api.delete(`/api/collect/task/${id}`)
        },
        //申请接单
        handleApply(id, is_extra) {
            if (is_extra) {
                    this.$set(this.form, 'is_extra', is_extra)
                }
                this.form.id = id;
                this.show = true
            // if (this.user && this.user.certificate) {
            //     if (is_extra) {
            //         this.$set(this.form, 'is_extra', is_extra)
            //     }
            //     this.form.id = id;
            //     this.show = true
            // } else {
            //     this.$api.get(`/api/user`).then(res => {
            //         this.user = res.data
            //         this.$storage.userInfo();
            //         if (this.user && this.user.certificate) {
            //             if (is_extra) {
            //                 this.$set(this.form, 'is_extra', is_extra)
            //             }
            //             this.form.id = id;
            //             this.show = true
            //         } else {
            //             this.show3 = true
            //         }
            //     })

            // }
        },
        //接单
        handleJoin(resource_ids) {
            this.form.resource_ids = resource_ids
            this.loading = true
            this.$api.post(`/api/task-join/${this.form.id}`, this.form).then(() => {
                this.$message.success(this.$t('task.success'))
                this.loading = false
                this.show = false
                this.show2 = true
            }).catch(() => {
                this.loading = false
                this.show = false
                this.$message.error(this.$t('task.fail'))
            })
        },
    }

};
</script>
<style scoped lang="scss">
.task-item {
    height: 7.52rem;
    border-radius: 0.04rem;
    border: 0.01rem solid #D8D8D8;
    margin-top: 0.3rem;
    cursor: pointer;

    .task-head {
        width: 100%;
        height: 1.11rem;
        padding: 0 0.26rem;
        box-sizing: border-box;
        border-bottom: 1px solid #EAEAEA;

        .task-head-left {
            .task-state {
                font-size: 0.2rem;

                color: #54BE13;
                line-height: 0.29rem;

            }

            .different {
                color: #E39B4B;
            }

            .task-bian {
                font-size: 0.17rem;

                color: #848484;
                margin-top: 0.07rem;

            }
        }

        .task-head-right {
            .publisher {
                font-size: 0.17rem;

                color: #848484;

            }

            img {
                margin: 0 0.14rem;
                width: 0.49rem;
                height: 0.49rem;
                background: #D8D8D8;
                border-radius: 50%;
            }

            .nickName {
                font-size: 0.23rem;

                color: #2B2B2B;

            }
        }


    }

    .task-info {
        width: 100%;
        height: 5.2rem;
        padding: 0.3rem 0.26rem;
        box-sizing: border-box;
        border-bottom: 1px solid #EAEAEA;

        .title {
            font-size: 0.21rem;

            color: #202020;

        }

        .label {
            margin-top: 0.1rem;

            img {
                width: 0.18rem;

                margin-right: 0.1rem;

            }

            .label-right {
                font-size: 0.18rem;
                color: #02428C;

            }

            .label-main {
                font-size: 0.18rem;
                color: #828282;
                margin-right: 0.37rem;
            }

        }

        .desc {
            font-size: 0.18rem;
            color: #828282;
            line-height: 0.29rem;
            margin-top: 0.1rem;

        }


    }

    .task-operate {
        padding-left: 0.26rem;
        padding-right: 0.26rem;
        margin-top: 0.3rem;

        .time {
            min-width: 3.57rem;
            height: 0.49rem;
            background: #E6ECFB;
            border-radius: 0.04rem;
            text-align: center;
            line-height: 0.49rem;
            font-size: 0.17rem;
            color: #02428C;
        }

        .btn {
            .common {
                min-width: 1.73rem;
                height: 0.5rem;
                border-radius: 0.04rem;
                border: 0.01rem solid #02428C;
                text-align: center;
                line-height: 0.5rem;
                cursor: pointer;
                font-size: 0.17rem;
                color: #02428C;
                margin-left: 0.3rem;
            }

            .diff {
                border: 0.01rem solid #979797;
                color: #8B8B8B;

            }
        }
    }



}
</style>
  