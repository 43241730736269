<!-- 圈子列表 -->
<template>
  <div class="circle">
    <div class="main">
      <div class="circle-head">
        <div class="tit flex-row-center-between">
          <div class="name">{{ $t('circle.tit1') }}</div>
          <div class="create-circle" @click="goCreate">+{{ $t('circle.create_circle') }} </div>
        </div>
        <ul class="flex-row-center flex-row-wrap" v-if="array&&array.length">
          <li v-for="(item, index) in array" :key="index" class="flex-row-center">
            <div class="cover" style="cursor: pointer" @click="toDetail(item.id)" :style="{
              'background-image': 'url(' + item.icon + ')',
            }"></div>
            <div class="flex-col">
              <div class="title x-ell-1">{{ item.name }}</div>
              <div class="state" v-if="user && user.name == item.user.name">{{ $t('circle.creater') }}</div>
              <div class="state" v-else>{{ $t('circle.member') }}</div>
            </div>
          </li>
        </ul>
        <div v-else></div>
      </div>
      <!-- 圈子分类 -->
      <div class="sec-head flex-row-center-between">
        <div class="fixed-nav" ref="fixednav">
          <div class="fixed-nav-content flex-row-wrap">
            <p  v-for="(item, index) in category" :key="index" style="cursor: pointer"
            :class="{'active':current == item.id }"
            class="select-tab"

            
              
              @click="onClickItem(item.id, index, $event)">{{ item.name }}</p>
          </div>
        </div>

        <div class="flex-row-center">
          <div class="search flex-row-center">
            <input :placeholder="$t('circle.search')" type="text" v-model="formInline.search" @keyup.enter="getList(1)" />
            <img src="@/assets/icons/search.png" @click="getList(1)" />
          </div>
        </div>
      </div>
      <!-- 圈子列表 -->
      <ul class="list" v-if="listData.length">
        <li v-for="(item, index) in listData" :key="index">
          <div class="cover" style="cursor: pointer" @click="toDetail(item.id)" :style="{
            'background-image': 'url(' + item.cover + ')',
          }">
          </div>
          <div class="circle-logo" style="cursor: pointer" @click="toDetail(item.id)" :style="{
            'background-image': 'url(' + item.icon + ')',
          }"></div>
          <div class="info">
            <div class="title x-ell-1" @click="toDetail(item.id)" style="cursor: pointer">
              {{ item.name }}
            </div>
            <div class="summary x-ell-2" @click="toDetail(item.id)" style="cursor: pointer">
              {{ item.description }}
            </div>
            <div class="btn" @click="handleJoin(item.id)" style="cursor: pointer" v-if="item.has_join == -2">
              <p> {{ $t('circle.apply_join') }}</p>
            </div>
            <div class="btn diff" style="cursor: pointer" v-if="item.has_join == 1">
              <p> {{ $t('auth.joined') }}</p>
            </div>
            <div class="btn diff" style="cursor: pointer" v-if="item.has_join == 0">
              <p> {{ $t('activity.status0') }}</p>
            </div>
          </div>
        </li>
      </ul>
      <view-empty v-else style="margin-top:0.2rem ;"></view-empty>
    </div>
    <!-- 加圈申请 -->
    <ApplyJoin :show="show" @handleClose="show = false" @handleConfirm="handleConfirm" />
  </div>
</template>
<script>
import ApplyJoin from '@/components/ApplyJoin.vue'
import ViewEmpty from '../../components/ViewEmpty.vue';
export default {
  components: { ApplyJoin,ViewEmpty },
  data() {
    return {
      user: this.$storage.getData("userInfo"),
      show: false,//控制弹窗的显示和隐藏
      total: 0,//圈子数量
      category: [],
      current: '',
      formInline: {
        page: 1,
        size: 20,
        search: '',
        category_id: ''
      },
      listData: [],
      total: 0,
      lastPage: 0,
      form: {
        id: '',
        comment: ''
      },
      //加入的圈子
      array: [

      ],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.current = this.$route.query.id
      this.formInline.category_id = this.$route.query.id
    }
    this.getList()
    //获取分类
    this.getCategory();
    //获取加入的圈子
    this.getCircleList()

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //获取圈子
    getCircleList() {

      if (!this.user) return
      this.$api.get('/api/group?type=has_join', { params: { paginate: 0 } }).then(res => {
        this.array = res.data.data
      })
    },
    //获取分类
    getCategory() {
      this.$api
        .get("/api/group/category", { params: { paginate: 1, limit: 100 } })
        .then((res) => {
          this.category = res.data.data
          this.category.unshift({
            id: '',
            name: this.$t('index.recommend'),
          })
        });
    },
    //导航点击事件
    onClickItem(id, index, event) {
      if (id != this.current) {
        this.current = id;
        this.formInline.category_id = id
        this.formInline.page = 1;
        this.getList()
        // 计算当前按钮的位置，看是否需要移动
        // const spanLeft = event.clientX; // 当前点击的元素左边距离
        // const divBox = document.querySelector(".select-tab").clientWidth / 2; // 点击的元素一半宽度
        // const totalWidths = document.body.clientWidth; // 屏幕总宽度
        // const widths = totalWidths / 2; // 一半的屏幕宽度
        // const spanRight = totalWidths - spanLeft; // 元素的右边距离
        // const scrollBox = document.querySelector(".fixed-nav"); // 获取最外层的元素
        // const scrollL = scrollBox.scrollLeft; // 滚动条滚动的距离
        // // 当元素左边距离 或者 右边距离小于100时进行滑动
        // // if (spanRight < 10 || spanLeft < 10) {
        //   scrollBox.scrollLeft = scrollL + (spanLeft - widths) + divBox;
        // }
      }
    },
    //获取列表
    getList(page) {
      if (page) this.formInline.page = page
      if (this.formInline.page == 1) {
        this.showLoading()
      }
      this.$api.get('/api/group', { params: this.formInline }).then(res => {
        let result = res.data.data
        this.hideLoading()
        this.lastPage = res.data.meta.last_page
        if (this.formInline.page == 1) {
          this.listData = result
        } else {
          if (result.length) {
            this.listData = [...this.listData, ...result]
          }
        }
      })
    },
    handleScroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeitht =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
        if (this.formInline.page <= this.lastPage) {
          this.formInline.page++;
          this.getList()
        }
      }
    },
    //创建
    goCreate() {
      if (this.user) {
        this.$router.push("/createCircle");
      } else {
        this.$message.warning('登陆后才可以创建圈子哦')
      }

    },

    //跳转到圈子详情页面
    toDetail(id) {
      this.$router.push(`/circleDetail?id=${id}`)
    },
    //加入圈子
    handleJoin(id) {
      if (!this.user) {
        this.$router.push('/login')
        return
      }
      this.form.id = id;
      this.show = true
    },
    //确认加入圈子
    handleConfirm(comment) {
      this.form.comment = comment
      this.$api.post(`/api/join-group/${this.form.id}`, this.form).then(() => {
        this.$message.success(this.$t('auth.operate_success'))
        this.getList(1)
        this.show = false
      }).catch(() => {
        this.$message.error(this.$t('auth.operate_fail'))
      })
    }
  },
};
</script>
<style scoped lang="scss">
.circle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

//主体
.main {
  width: 13.2rem;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 0.92rem;
  margin-bottom: 2rem;
  overflow: hidden;

  .circle-head {
    border-radius: 0.03rem;
    background-color: #ffffff;
    padding: 0 0.3rem 0;
    box-sizing: border-box;

    .tit {
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      border-bottom: 1px solid #EAEAEA;

      .name {
        font-size: 0.2rem;
        color: #262423;
      }

      .create-circle {
        width: 2.07rem;
        height: 0.43rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #02428C;
        text-align: center;
        font-size: 0.16rem;


        color: #02428C;
        cursor: pointer;
        line-height: 0.43rem;


      }
    }

    ul {
      padding-top: 0.3rem;

      li {
        margin-right: 0.4rem;
        width: 2.7rem;
        margin-bottom: 0.3rem;

        .cover {
          margin-right: 0.13rem;
          width: 0.63rem;
          height: 0.63rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
          position: relative;
          border-radius: 0.03rem;
          background-color: #efefef;

        }

        .title {
          font-size: 0.18rem;
          cursor: pointer;
          color: #202020;
          line-height: 0.29rem;

        }

        .state {
          margin-top: 0.09rem;
          min-width: 0.6rem;
          max-width: 0.8rem;
          display: inline-block;
          height: 0.26rem;
          background: #ECECEC;
          border-radius: 0.13rem;
          text-align: center;
          line-height: 0.26rem;
          font-size: 0.13rem;


          color: #838383;

        }
      }
    }
  }
}

//圈子分类
.sec-head {
  height: 0.89rem;
  background: #FFFFFF;
  border-radius: 0.03rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  margin-top: 0.2rem;

  .fixed-nav {
    width: 10.2rem;
    -WEBkit-overflow-scrolling: touch;
    overflow-x: auto;
    // background-color: #abcdef;

    .fixed-nav-content {
      display: flex;
      

      p {
        font-size: 0.15rem;
        cursor: pointer;
        line-height: 0.25rem;
        color: #202020;
        // display: flex;
        width: 0.8rem;
        // background-color: #abcdef;
        margin-right: 0.6rem;
        flex-shrink: 0
      }

      .active {
        color: #02428C;
      }
    }


  }



  .search {
    width: 2.17rem;
    height: 0.46rem;
    border-radius: 0.06rem;
    border: 0.01rem solid #F5F5F5;
    padding-left: 0.09rem;
    padding-right: 0.09rem;
    box-sizing: border-box;
    background-color: #F5F5F5;

    input {
      flex: 1;
      height: 0.46rem;
      outline: none;
      border: none;
      background-color: #F5F5F5;
      box-sizing: border-box;
    }

    img {
      width: 0.2rem;
      height: 0.2rem;
      cursor: pointer;
    }

  }

  .publish {
    width: 1.84rem;
    height: 0.46rem;
    background: #02428C;
    border-radius: 0.04rem;
    cursor: pointer;
    text-align: center;
    line-height: 0.46rem;
    font-size: 0.17rem;

    color: #ffffff;
    cursor: pointer;
    margin-left: 0.95rem;
  }

}

//圈子列表
.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    width: 3.69rem;
    height: 3.99rem;
    background: #FFFFFF;
    border-radius: 0.06rem;
    margin: 0.2rem 0.3rem 0 0;
    overflow: hidden;


    .cover {
      width: 3.69rem;
      height: 1.44rem;
      background-color: #ffffff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      position: relative;

      img {
        position: absolute;
        display: inline-block;
        bottom: 0.05rem;
        right: 0.05rem;
      }
    }

    .circle-logo {
      width: 0.84rem;
      height: 0.84rem;
      border-radius: 0.06rem;
      border: 2px solid #ffffff;
      margin: -0.6rem 1.425rem 0;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      background-color: #efefef;
    }

    .info {
      position: relative;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        margin-top: 0.16rem;
        font-size: 0.2rem;


        color: #262423;
        line-height: 0.32rem;
        cursor: pointer;
      }

      .summary {
        margin-top: 0.1rem;
        font-size: 0.15rem;
        padding: 0 0.3rem;
        box-sizing: border-box;
        color: #838383;
        line-height: 0.29rem;
        cursor: pointer;
        height: 0.58rem;
      }

      .expense {
        font-size: 0.14rem;
        font-family: PingFang SC;

        color: #ff6200;
        padding-right: 0.05rem;
      }

      .btn {
        width: 2.07rem;
        height: 0.43rem;
        border-radius: 0.03rem;
        border: 1px solid #02428C;
        text-align: center;
        font-size: 0.17rem;
        color: #02428C;
        line-height: 0.43rem;
        margin-top: 0.36rem;


      }

      .diff {
        background: #F3F3F3;
        border: 0.01rem solid #F3F3F3;
        color: #939393;
      }

      .price-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: 100%;

        .price-btn {
          cursor: pointer;
          min-width: 1rem;
          padding: 0 0.1rem;
          height: 0.32rem;
          border: 0.01rem solid #b3b3b3;
          opacity: 1;
          border-radius: 0.16rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          line-height: 0.3rem;
          font-size: 0.1rem;
          font-family: PingFang SC;

          color: #519fe8be;
        }
      }

      .different {
        border: 1px solid #b3b3b3;
        color: #b3b3b3;
      }
    }
  }
}


.waiting {
  border-radius: 20px;
  background: #519ee8;
  color: #ffffff !important;
  width: 1rem;
  padding: 0 0.1rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  margin-top: 0.05rem;
}

::-webkit-scrollbar {
  width: 0.01rem;
  opacity: 0;
  display: none;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  width: 0.01rem;
  border-radius: 0.01rem;
  opacity: 0;
}
</style>
