<!--
 * @Descripttion: 
 * @version: 
 * @Author: tl
 * @Date: 2022-05-11 16:05:39
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-07-28 21:43:29
-->
<template>
    <div class="cascaderArea">
        <el-cascader filterable v-if="show" ref="cascaderRefArea" :props="props" v-model="selectedOptions" :placeholder="value"
            @change="handleChange" :style="{ width }"></el-cascader>
    </div>
</template>
<script>
export default {
    name: "cascaderArea",
    props: {
        limit: {
            type: Number,
            default: 0,
        },
        width: {
            type: String,
            default: "190px",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        checkStrictly: {
            type: Boolean,
            default: true,
        },
        value: {
            type: String,
            default: '',

        }
    },
    data() {
        return {
            show: true,
            selectedOptions: [],
            placeholder: this.$t('con.placeholder2'),
            props: {
                lazy: true,
                checkStrictly: this.checkStrictly,
                multiple: this.multiple,
                lazyLoad: (node, resolve) => {
                    const { level } = node; // node 节点数据
                    const nodes = []; // 动态节点
                    let type = level == 0 ? "0" : node.value; // 0 代表第一次请求
                    this.provinceFn(type, node.data?.name || "", level)
                        .then((res) => {

                            res.map((item) => {
                                let area = {
                                    value: item.id,
                                    name: item.id,
                                    label: item.name,
                                    // leaf: node.level >= 2,
                                };
                                nodes.push(area);
                            });
                            resolve(nodes); // 回调
                        })
                        .catch((error) => {
                            this.$message.error(error);
                        });
                },
            },
        };
    },
    methods: {
        setDefault(val) {
            this.selectedOptions = val;
            this.placeholder = val
        },
        async provinceFn(type, id, level) {
            if (type == 0) {
                return this.getCountry()
            }
            if (level == 1) {
                return this.getProvinceList(id)
            }
            if (level == 2) {
                return this.getCityList(id);
            }
            return []
        },
        //获取国家
        async getCountry() {
            return new Promise((rec, rej) => {
                this.$api({
                    url: `/api/country`,
                })
                    .then((res) => {
                        rec(res.data);
                    })
            });
        },
        //获取省份
        async getProvinceList(id) {
            return new Promise((rec, rej) => {
                this.$api({
                    url: `/api/state/${id}`,
                })
                    .then((res) => {
                        rec(res.data);
                    })
            });
        },
        async getCityList(id) {
            return new Promise((rec, rej) => {
              this.$api.get(`/api/city?pid=${id}`)
                    .then((res) => {
                        rec(res.data);
                    })
            });
        },
        handleChange(value) {
            this.$emit("getSelectedOptions", value);
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-cascader__dropdown {
    z-index: 10000 !important;
}

::v-deep .el-input__inner {
    &::placeholder {
        color: #666;
    }
}

.cascaderArea {
    position: relative;
    z-index: 1 !important;
}

::v-deep .el-cascader {
    z-index: 1 !important;
}
</style>
  