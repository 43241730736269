
module.exports = {
    language: {
        zh: '中文',
        en:'English'
    },
    //首页:
    index: {
        bgVisible:'仅后台可见',
        relation:'可靠关系',
        reliable_relationship:'请简要描述用户与该人脉资源的可靠关系,仅后台可见',
        biao:'全球人脉资本银行',
        home:'首页',
        shareTip:'随手分享，人脉变现',
        tip:"你还未登录无法发布动态哦",
        recommend:'推荐',
        focus:'关注',
        focused:'已关注',
        share:'分享',
        tip2:'登录后才能发布评论哦',
        no_data:'暂无内容',
        tit1:'关联内容',
        feed:'动态',
        task:'任务',
        activity:'活动',
        user:'用户',
        search:'产业资源 一搜就有',
        publish_task:'+ 发任务',
        tip3:'请输入搜索内容',
        login_reg:'登录/注册',
        logout:'退出登录',
        my_member:'我的人脉',
        my_resource:'我的资源',
        my_page:'我的主页',
        my_friends:'我的伙伴',
        friend:'伙伴',
        login:'登录',
        reg:'注册',
        no_limit:'无限制',
        label:'资源标签',
        placeholder:'请为资源打上标签，以方便其他用户搜索，#开头间隔',
        sec1:'加载更多',
        sec2:'没有更多了',
        sec3:'暂无数据',
        completed:'已完成',
        unCompleted:'未完成',
        edit:'编辑',
        del:'删除'

    },
    //任务模块
    task:{
        tasked:'任务状态：任务已完成',
        confirm_finish:'确认完成',
        search:'搜索任务',
        status0:'等待系统审核',
        status1:'等待接单',
        status2:'已被接单',
        status3:'任务进行中',
        status4:'已撤销',
        status5:'已驳回',
        status6:'审核通过',
        status7:'审核不通过',
        content:'任务内容',
        deliverable:'主要交付产出',
        category:'任务类型',
        budget:'预算',
        due_time:'交付时间',
        country:'目标国家',
        front:'前',
        city:'目标城市',
        industry:'目标行业',
        label:'话题标签',
        end_time:'信息有效时间',
        task_user:'接单用户',
        apply:'我要接单',
        apply2:'我愿替补接单',
        collect:'收藏',
        collected:'已收藏',
        collect_task:'收藏任务',
        my_task:'我的任务管理',
        look:'查看全部任务',
        lookAll:'查看全部',
        collected_task:'收藏的任务',
        tip1:'无法接单哦',
        hot:'热门',
        new:'最新',
        success:'接单成功',
        fail:'接单失败',
        publish:'我发布的',
        accept:'我接单的',
        tip2:'任务状态：等待系统审核',
        tip3:'任务状态：申请通过，等待匹配接单用户',
        task_status:'任务状态',
        taskno:'任务编号',
        publisher:'发布者',
        publishat:'发布于',
        task_detail:'任务详情',
        cancel_apply:'取消申请',
        del:'删除',
        all:'全部',
        edit_task:'编辑任务',
        cancel_task:'取消任务',
        applyed:'已申请',
        ing:'进行中',
        completed:'任务完成',
        cancel_success:'取消成功',
        cancel_fail:'取消失败',
        del_success:'删除成功',
        del_fail:'删除失败',
        task_manage:'任务管理',
        tip4:'当前任务信息提交成功',
        tip5:' 平台会在近期与您联系以确认任务详情，请保持关注',
        service_ing:'服务进行中',
        task_info:'任务信息',
        tip6:'申请已通过，等待平台和发布方确认',
        servicer_info:'服务商信息',
        tip7:'等待接单中，暂无信息',
        tit1:'相似服务供应商推荐',
        service_time:'服务发布时间',
        view:'查看主页',
        contact:'联系平台方',
        step1:'发布任务',
        step2:'等待平台审核',
        step3:'通过审核，匹配服务中',
        step4:'匹配成功，任务进行中',
        step5:'任务费用结算完成',
        step6:'已申请接单，等待平台确认',
        step7:'接单成功服务进行中',
        step8:'发布方确认任务已完成',
        tip8:'是否确认任务已完成?',
        tit2:'提示',
        cancel:'取消',
        confirm:'确认'
    },
    //活动模块
    activity:{
        search:'搜索活动',
        publish:'发布活动',
        date:'活动时间',
        address:'活动地点',
        link:'活动宣发链接',
        to:'至',
        document:'文档',
        download_document:'下载文档',
        apply:'申请参加活动',
        apply2:'匿名参加活动',
        collect_activity:'收藏活动',
        send:'发送',
        reply:'回复',
        reply_comment:'回复评论',
        zan:'点赞',
        member:'活动报名人员',
        apply3:'报名',
        tip:'友善评论是最美好的事情',
        apply_fail:'报名失败',
        tip2:'评论内容过短',
        publish_success:'发布成功',
        status0:'待审核',
        status2:'已结束',
        num:'人数',
        activity_num:'活动人数',
        joined:'已参加',
        apply_join:'申请参加',
        tip3:'您已参加该活动',
    },
    auth:{
        activity_success:'活动发布成功',
        notice:'认证已提交，请耐心等待',
        tit1:'用户服务协议',
        tit2:'希客道服务协议',
        tit3:'隐私保护指引',
        tit4:'你可能会感兴趣的用户',
        tip1:'喜欢就关注吧',
        nullName:'暂无昵称',
        nulldesc:'暂无介绍',
        tit5:'你可能会感兴趣的圈子',
        tip2:'破圈，就是信仰',
        joined:'已加入',
        join:'直接加入',
        skip:'跳过',
        next:'下一步',
        ok:'选好了',
        findPwd:'找回密码',
        tip3:'验证码将会发送至你的注册手机号或者邮箱',
        phone:'手机号',
        email:'邮箱',
        code:'验证码',
        tip4:'验证码已发送至你的注册',
        placeholder1:'请输入手机号码',
        placeholder2:'请输入邮箱',
        placrholder3:'请输入你注册的手机号或者邮箱号',
        modify:'修改',
        placeholder4:'请输入6位验证码',
        set_newpwd:'设置新密码',
        set_pwd:'验证成功请设置你的登录密码',
        confirm_pwd:'验证你的新密码',
        sure:'确认',
        operate_success:'操作成功',
        operate_fail:'操作失败',
        tip5:'请输入你的新密码',
        tip6:'请验证你的新密码',
        send_success:'发送成功',
        tip7:'请输入验证码',
        tip8:'请输入手机/邮箱',
        tip9:'请输入正确的手机/邮箱',
        second:'秒',
        get_code:'获取验证码',
        nick_name:'用户昵称',
        placeholder5:'给自己起个好听的昵称吧',
        job:'职业(选填)',
        placeholder6:'输入你的职业',
        introduce:'简介(选填)',
        placeholder7:'介绍一下自己吧',
        submit:'提交',
        tip10:'昵称不能为空',
        tip11:'请上传头像',
        option1:'邮箱验证码登录',
        option2:'手机验证码登录',
        option3:'密码登录',
        placeholder8:'请输入密码',
        forget_pwd:'忘记密码',
        login_reg:'登录/注册',
        remember:'记住我',
        tit12:' 未注册手机/邮箱验证后自动登录，注册即代表同意',
        tit6:'快速登录',
        way1:'微博',
        way2:'微信',
        way3:'谷歌',
        way4:'领英',
        tip13:'暂未配置',
        tip14:'邮箱格式有误',
        tip15:'手机号格式有误',
        tip16:'用户名不能为空',
        tip17:'密码不能为空',
        tip18:'验证码不能为空',
        login_success:'登录成功',
        login_fail:'登录失败',
        placeholder9:'请输入您的手机号/邮箱',
        tip19:'手机号已经验证成功，暂时无法修改哦',
        tip20:'信息有效时间应小于交付时间'

    },
    //圈子
    circle:{
        apply_join3:'加入',
        feed_detail:'动态详情',
        source_detail:'资源详情',
        export:'专家',
        organ:'机构',
        main_character:'主要角色',
        other_character:'其他角色',
        level:'资源等级',
        apply_service:'申请服务',
        collect:'收藏至心愿单',
        source_info:'资源详细信息',
        orgname:'机构名称',
        country:'所属国家',
        city:'总部所在城市',
        category:'资源类型',
        other_city:'业务延伸城市',
        industry:'所属行业',
        introduction:'资源简介',
        background:'主要领域',
        main_field:'新近发展',
        achievement:'历史成就',
        label:'资源标签',
        link:'资源外链',
        link_feed:'关联动态',
        look:'查看原文',
        comment:'评论',
        look_more:'查看更多',
        like_source:'相似资源推荐',
        no_apply:'无法申请服务',
        apply_fail:'申请失败',
        nomore:'没有更多了',
        collect_success:'收藏成功',
        cancel_collect:'取消收藏成功',
        focus_success:'关注用户成功',
        cancel_success:'取消关注成功',
        tit1:'你加入的圈子',
        create_circle:'创建一个圈子',
        creater:'创建人',
        member:'成员',
        search:'搜索圈子',
        apply_join:'申请加入',
        circle_creater:'圈子创建人',
        circle_type:'圈子类型',
        label:'圈子标签',
        created_at:'创建时间',
        edit_bg:'替换背景图',
        share:'分享邀请用户',
        edit_circle:'编辑圈子资料',
        tip1:'资源嘛。就是用来分享的',
        circle_member:'圈子成员',
        look_all:'查看全部成员',
        enter:'加入',
        circle_score:'圈子积分榜',
        point:'积分',
        my:'我的',
        del_circle:'退出圈子',
        feed_list:'动态',
        source:'资源',
        tip2:'该圈子暂无成员',
        tit2:'圈子置顶资源',
        tit3:'管理圈子置顶',
        top:'置顶他/她的资源',
        to_top:'置顶',
        cancel_top:'取消置顶',
        tip3:'只有圈主才有权限置顶哦'
    },
    //发布
    feed:{
        activity_placeholder:'活动类型不能为空',
        edit_feed:'编辑动态',
        tit1:'活动信息请认真填写，保证真实有效',
        activity_name:'活动名称',
        placeholder1:'请输入活动名称',
        activity_category:'活动类型',
        placeholder2:'请选择活动类型',
        activity_cover:'活动封面',
        activity_detail:'活动详情',
        placeholder3:'请详细描述您要发布的活动详情',
        placeholder4:'请输入您本次活动的人数上限',
        placeholder5:'选择国家',
        placeholder6:'选择省份',
        placeholder7:'选择城市',
        placeholder8:'请输入您本次活动的详细地址',
        placeholder9:'选择活动开始时间',
        placeholder10:'选择活动结束时间',
        activity_label:'活动标签',
        placeholder11:'请为您的活动打上标签，以方便其他用户搜索，#开头',
        activity_file:'活动文件',
        tip1:'请确保文件上的文字清晰可见，支持jpg png jpeg pdf,文件不超过5MB',
        placeholder12:'请输入宣发平台及主题',
        placeholder13:'请添加您本次活动现场宣发链接',
        add_link:'继续添加活动宣发链接',
        confirm_publish:'确定发布',
        msg1:'请上传活动封面',
        msg2:'请输入活动详情',
        msg3:'请输入活动人数',
        msg4:'请输入活动地点',
        msg5:'请选择活动时间',
        msg6:'请输入活动标签',
        tip2:'活动结束时间不能小于活动开始时间',
        tip3:'图片最多上传一张，请删除再重新上传！',
        tip4:'图片最多上传9张，请删除再重新上传！',
        edit:'编辑',
        add:'创建',
        circle_name:'圈子名称',
        placeholder14:'请输入圈子名称',
        placeholder15:'请选择圈子的行业类型',
        placeholder16:'请为圈子打上标签，以方便其他用户搜索，#开头间隔',
        circle_avatar:'圈子头像',
        circle_cover:'圈子封面',
        circle_desc:'圈子简介',
        placeholder17:'请描述一下你的圈子的特点吧',
        join_type:'加入方式',
        placeholder18:'请选择圈子的加入方式',
        option1:'所有用户',
        option2:'蓝v认证',
        option3:'城市顾问',
        option4:'城市合伙人',
        add_question:'添加申请问题',
        question:'问题',
        placeholder19:'请输入设置的申请问题',
        adder_question:'继续添加申请问题',
        sure:'确定',
        msg7:'请上传圈子封面',
        msg8:'请上传圈子头像',
        msg9:'请输入圈子描述',
        msg10:"请选择圈子类型",
        msg11:'请选择加入方式',
        msg12:'请输入圈子标签',
        loading:'加载中',
        tip5:'图片最多上传20张，请删除再重新上传！',
        circle:'圈子',
        tit2:'任务提交成功',
        tip6:'任务已提交至平台，等待审核，期间平台会与您联系，请关注',
        know:'知道了',
        task_step:'任务流程',
        task_title:'任务标题',
        placeholder20:'请简单描述一下你的任务',
        placeholder21:'请选择任务类型',
        placeholder22:'详细描述一下你的任务需求吧，越详细越容易精准匹配到服务供应商哦',
        budget:'任务预算',
        placeholder23:'请输入你的任务预算，例如：10万-20万',
        currency:'请选择货币单位',
        placeholder24:'请选择任务交付时间',
        end_time:'信息有效时间（过期需要重新发布）',
        placeholder25:'选择信息有效时间',
        deliverable:'主要交付内容(选填)',
        placeholder26:'交付内容描述地越清晰，越容易响应',
        task_label:'任务标签',
        placeholder27:'请为任务打上标签，以方便其他用户搜索，#开头间隔',
        country2:'目标国别',
        add_country2:'添加更多目标国别',
        add_city:'添加更多目标城市',
        confirm_publish:'确认发布',
        confirm_edit:'确认修改',
        add_industry:'添加更多目标行业',
        placeholder28:'选择行业',
        msg13:'请输入任务标题',
        msg14:'请输入任务内容',
        msg15:'请输入任务预算范围',
        msg16:'请选择货币类型',
        msg17:'请选择任务交付时间',
        msg18:'任务有效截止时间',
        msg19:'请输入任务标签'
    },
    //个人中心
    personal:{
        user_service_secret:'用户服务协议',
        my_joined:'我加入的',
        my_create:'我创建的',
        msg_notice:'消息通知',
        all_read:'全部已读',
        leave_msg:'留言',
        agree:'同意',
        refuse:'拒绝',
        no_msg:'暂无消息',
        placeholder1:'请输入拒绝理由',
        all_msg:'全部消息',
        feed_msg:'动态消息',
        group_msg:'圈子消息',
        task_msg:'任务消息',
        activity_msg:'活动消息',
        system_msg:'系统消息',
        tip1:'请输入拒绝原因',
        my_point:'我的积分',
        point_tip:'积分注意事项',
        point_balance:'积分余额',
        tit22:'每日活跃任务',
        desc1:'完成对应任务会获得相应积分',
        point_detail:'我的积分明细',
        choose:'选择积分维度',
        type:'选择维度',
        deal:'交易',
        active:'活跃',
        promotion:'推广',
        business:'商机',
        point_source:'积分来源',
        time:"时间",
        ip:'IP所属地',
        fen:'粉丝',
        edit_user:'编辑个人资料',
        country:'优势国别',
        city:'优势城市',
        industry:'优势行业',
        label:'优势标签',
        my_income:'我的收益',
        look_detail:'查看明细',
        income:'直接收益',
        fuxing_income:'福星收益',
        bole_income:'伯乐收益',
        rank:'积分天梯榜',
        tit1:'他加入的圈子',
        tit2:'人已加入',
        users:'人脉',
        honor:'荣誉',
        tit3:'直接收益详情',
        tit4:'伯乐收益详情',
        tit5:'福星收益详情',
        tit6:'直接收益数',
        tit7:'分成收益数',
        income_resource:'收益来源项目名称',
        project_resource:'项目资源',
        project_user:'接单用户',
        recommend_income:'推荐收益',
        bole:'伯乐',
        fuxing:'福星',
        incomes:'收益',
        project_customers:'项目发布人',
        complete_time:'项目完成时间',
        look_task:'查看原任务',
        income_detail:'收益详情',
        detail:'详情',
        certificate:'实名认证',
        modify_avatar:'修改头像',
        introduction:'简介',
        placeholder2:'添加你的优势标签，以方便其他用户搜索，#开头间隔',
        my_collect:'我的收藏',
        tip2:'提交资料后大概需要三个工作日审核，请耐心',
        apply_type1:'个人',
        apply_type2:'机构',
        certificate_type:'证件类型',
        placeholder3:'请选择你的证件类型',
        certificate_type1:'身份证',
        certificate_type2:'护照',
        certificate_type3:'驾驶证',
        user_name:'真实姓名',
        placeholder4:'请输入你的真实姓名',
        certificate_number:'证件号码',
        placeholder5:'请输入证件号码',
        certificate_img:'证件照片',
        tip3:'请确保图片上的文字清晰可见，图片支持jpg png jpeg,文件不超过5MB',
        placeholder6:'请输入真实的机构名称',
        business_license:'机构营业执照',
        legal_person:'法人姓名',
        placeholder7:'请输入法人真实姓名',
        legal_person_img:'法人证件照',
        tax_info:'发票信息',
        tax_number:'发票税号',
        placeholder8:'请输入发票税号',
        tit8:'银行账户信息',
        tip4:'用于账户资金结算，请认真填写',
        bank_account_holder:'开户人姓名',
        placeholder9:'请输入开户人姓名',
        bank_account:'银行账号',
        placeholder10:'请输入银行卡账号',
        bank_name:'开户银行',
        placeholder11:'请输入开户行名称',
        bank_account_proof:'银行账户证明',
        contact:'联系人信息',
        contact_name:'联系人',
        placeholder12:'请输入联系人姓名',
        position:'联系人职务',
        placeholder13:'请输入联系人职务',
        phone_number:'联系电话',
        placeholder14:'请输入联系人手机号',
        sms_code:'手机验证码',
        confirm_vertify:'确认验证',
        email:'电子邮箱',
        mail_code:'邮箱验证码',
        placeholder15:'请输入联系人邮箱',
        zip_code:'邮政编码',
        email_address:'邮寄地址',
        placeholder16:'请输入邮政编码',
        placeholder17:'请输入详细邮寄地址',
        readed:'我已阅读并同意',
        submit:'提交认证',
        msg1:'请选择实名认证类型',
        msg2:'请选择申请所需证件类型',
        msg3:'请输入真实姓名',
        msg4:'请输入证件号码',
        msg5:'请上传证件照片',
        msg6:'请输入机构名称',
        msg7:'请上传营业执照图片',
        msg8:'请输入法人姓名',
        msg9:'请上传法人证件照片',
        msg10:'请输入开票税号',
        msg11:'请输入银行帐户开户人',
        msg12:'请输入银行账号',
        msg13:'请输入开户银行名称',
        msg14:'银行账户证明不能为空',
        msg15:'请输入联系人姓名',
        msg16:'请输入职务',
        msg17:'请输入手机号',
        msg18:'请输入邮政编码',
        msg19:'请输入详细邮寄地址',
        msg20:'请选择是否同意协议',
        msg21:'请同意用户协议',
        cancel_collect:'取消收藏',
        status0:'等待审核',
        status1:'已通过',
        placeholder18:'请选择资源类型',
        search_resource:'搜索资源',
        view_detail:'查看详情',
        checked_all:'全选',
        customer_service:'客服联系方式',
        sec:'只显示选中',
        add_resource:'添加新资源',
        tit9:'积分雷达图',
        your_point:'您的积分',
        average:'用户平均积分',
        certification_level:'认证等级',
        current_level:'当前认证等级',
        current_level1:'当前等级为',
        current_level2:'距离下一等级还差',
        score:'分',
        tit10:'专享权益',
        tit11:'享受更高的订单分成',
        tit12:'申请接单优先匹配',
        tit13:'资源服务优先推送',
        tit14:'认证等级规则',
        placeholder19:'选择国别',
        my_rank:'我的排名',
        has_date:'获得时间',
        lock:'暂未解锁',
        focus_list:'关注列表',
        cancel_focus:'取消关注',
        focus2:'互相关注',
        copy:'复制',
        scan:'扫码添加官方微信',
        copy_success:'复制成功',
        bole_recommend:'伯乐推荐（千里马）',
        fx_recommend:'福星推荐（福德马）',
        my_join:'我参加的',
        all:'全部',
        mark1:'认证通过',
        mark0:'待审核',
        mark2:'审核失败'
    },
    //组件
    con:{
        placeholder1:'搜索您已有的资源',
        choose:'选择',
        operate:'选择已有资源',
        placeholder2:'请选择城市',
        hot_resource:'热门资源',
        no_tit:'暂无标题',
        tab1:'关于希客道',
        tab2:'事业合伙人',
        tab3:'服务条款',
        tab4:'隐私政策',
        tab5:'成功案例',
        tab6:'商业合作',
        tab7:'媒体报道',
        tab8:'广告合作',
        tit1:'您可以通过分享二维码，或者链接邀请新用户注册，成为TA的伯乐，获得人脉资本收益分成。伯乐的伯乐是福星，也有分成收益哦！',
        tit2:'通过链接分享邀请',
        tit3:'分享：希客道作为“全球人脉资本银行”，既能够帮助您储蓄和变现人脉资本，又能够服务您搜寻和对接产业专家或机构资源。我推荐您加入希客道，为您的人脉资本增值！请点这个链接注册',
        tit4:'复制分享',
        tit5:'通过二维码分享',
        tit6:'用手机微信直接扫码进入网站注册',
        know:'知道了',
        hot_user:'热门用户',
        activity_member:'活动成员',
        publish_resource:'发布资源',
        resource_img:'资源图片',
        resource_tit:'资源标题',
        placeholder3:'请输入资源标题',
        placeholder4:'请输入真实的机构名称',
        placeholder5:'请输入主要角色',
        placeholder6:'请输入其他角色',
        placeholder7:'请选择机构所属的国家',
        placeholder8:'请选择总部省份',
        placeholder9:'请选择总部城市',
        other_city:'延伸城市',
        placeholder10:'请输入业务延伸城市，个数不限，单个城市之间请空格间隔',
        continue_industry:'继续添加所属行业',
        placeholder11:'请简单介绍一下该资源，限250字内',
        placeholder12:'请介绍机构从事的主要领域和业务',
        placeholder13:'请介绍机构的新近成果',
        placeholder14:'请输入该机构详细的历史成就信息',
        placeholder15:'添加外链主题',
        placeholder16:'添加外链网址',
        add_resource_link:'添加更多资源外链',
        placeholder17:'请为资源打上标签，以方便其他用户搜索，#开头间隔',
        publish:'发布',
        msg1:'请上传资源图片',
        publish_fail:'发布失败',
        join_apply:'加圈申请',
        optional:'选填',
        placeholder17:'说说你想加入的理由吧',
        apply_order:'申请接单',
        tit7:'先申请接单，等待平台与发布方确认后即可接',
        tit8:'我能提供的资源服务',
        choose_resource:'请选择资源',
        add_service:'继续添加资源服务',
        sub:'提交',
        cancel:'取消',
        tit9:'你还未成为认证蓝V',
        tit10:'为了保证买卖双方权益与保障，请先实名认证成为蓝v，再申请服务',
        to_certificate:'去认证',
        next:'下次去',
        tit11:'活动报名成功，记得准时参加哦',
        group_user:'空间成员',
        delete:'踢出',
        knows:'了解了',
        guide_tit1:'破圈',
        guide_desc1:'有任何的产业资源需求，都可以点这里发布哦',
        guide_tit2:'发布动态',
        guide_desc2:'有产业资源都可以分享来赚钱哦',
        guide_tit3:'收藏心愿单',
        guide_desc3:'有喜欢的动态、任务、资源等都可以先收藏到这里',
        guide_tit4:'创建新圈子',
        guide_desc4:'和志同道合的朋友一起聚资源',
        copy_success_tip:'复制成功,快去分享给你的好友吧！'


    },
    post:{
        publish_feed:'发布动态',
        placeholder1:'请输入动态标题',
        add_img:'添加图片',
        add_video:'添加视频',
        add_label:'添加标签',
        placeholder2:'请说说发生的有趣的事情吧',
        urls:'链接',
        url_name:'添加链接主题',
        url_web:'添加链接网址',
        add_url:'添加更多链接',
        choose_resource:'选择已有资源',
        del_resource:'删除资源',
        add_resource:'添加新资源',
        choose_resource2:'请选择资源',
        add_resource2:'添加已有资源',
        tip1:'动态标题不能为空',
        tip2:'动态内容不能为空',
        way1:'自主添加',
        way2:'后台协助',
        sure:'确定',
        manage_group:'管理同步空间',
        async:'动态自动发布到我的空间'
    },
    reg:{
        phone_reg:'手机注册',
        mail_reg:'邮箱注册',
        placeholder1:'请设置您的密码',
        placeholder2:'请重复您的密码',
        placeholder3:'请输入邮箱',
        placeholder4:'请输入验证码',
        tip1:'请输入您的手机号',
        tip2:'请输入您的邮箱',
        tip3:'两次输入的密码不一致',
        tip4:'验证码输入有误',
        tip5:'注册成功，请登录',
        tip6:'注册失败',
        tip7:'请输入6-18位含字母的密码'
    },
    authentication:{
      code_error:'验证码错误'
    }
}
