//忘记密码
<template>
    <div>
        <div class="forget-wrap" v-if="current == 0">
            <div class="title">{{ $t('auth.findPwd') }}</div>
            <div class="desc">{{ $t('auth.tip3') }}</div>
            <input class="ipt" :placeholder="$t('auth.placrholder3')" v-model="val" />
            <div class="btn" @click="handleNext(1)">{{ $t('auth.next') }}</div>
        </div>
        <div class="forget-wrap" v-if="current == 1">
            <div class="title">{{ $t('auth.findPwd') }}</div>
            <div class="desc">{{ $t('auth.tip4') }}<span v-if="form.phone">{{ $t('auth.phone') }}</span>
                <span v-if="form.mail">{{ $t('auth.email') }}</span>
            </div>
            <div class="input-item flex-row-center-between">
                <input :placeholder="$t('auth.placeholder1')" v-model="form.phone" class="input" v-if="form.phone" />
                <input :placeholder="$t('auth.placeholder2')" v-model="form.mail" class="input" v-if="form.mail" />
                <div class="edit" @click="handleModify">{{ $t('auth.modify') }}</div>
            </div>
            <div class="input-item flex-row-center-between">
                <input :placeholder="$t('auth.placeholder4')" class="code-input" v-model="form.code"
                    @blur="handleVertify" />
                <div class="vertify" @click="sendCode()">{{ title }}</div>
            </div>
            <div class="btn" @click="handleNext(2)">{{ $t('auth.next') }}</div>
        </div>
        <div class="forget-wrap" v-if="current == 2">
            <div class="title">{{ $t('auth.set_newpwd') }}</div>
            <div class="desc">{{ $t('auth.set_pwd') }}</div>
            <div class="input-item flex-row-center-between">
                <input :placeholder="$t('auth.set_newpwd')" type="password" class="input" v-model="form.password" />
            </div>
            <div class="input-item flex-row-center-between">
                <input :placeholder="$t('auth.confirm_pwd')" type="password" class="input" v-model="form.re_password" />
            </div>
            <div class="btn" @click="handleSure">{{ $t('auth.sure') }}</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            current: 0,
            val: '',
            form: {
                password: '',
                re_password: '',
                code: ''
            },
            second: '',
            disabled: false,
            timer: '',
            phoneRule: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
            emailRule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            status: 0,
        };
    },
    computed: {
        title() {
            return this.disabled ? `${this.second} ${this.$t('auth.second')}` : this.$t('auth.get_code')
        },
    },
    methods: {
        //下一步
        handleNext(current) {
            if (current == 1) {
                if (this.val && this.val.length) {
                    if (this.phoneRule.test(this.val)) {
                        //手机号
                        this.$set(this.form, 'phone', this.val)
                        console.log(this.form)
                        this.current = current
                    } else if (this.emailRule.test(this.val)) {
                        //邮箱
                        this.$set(this.form, 'mail', this.val)
                        this.current = current
                    } else {
                        this.$message.warning(this.$t('auth.tip9'))
                    }
                } else {
                    this.$message.warning(this.$t('auth.tip8'))
                }

            } else {
                if (this.form.code.length == 0) {
                    this.$message.warning(this.$t('auth.tip7'))
                    return
                }
                this.current = current

            }

        },
        //发送验证码
        sendCode() {
            let operate = this.form.phone ? '/api/forget/password/sms/code' : '/api/mail/code/forget_password'
            let params = this.form.phone ? { phone: this.form.phone } : { mail: this.form.mal }
            this.$api.post(operate, params).then(res => {
                this.$message({
                    message: this.$t('auth.send_success'),
                    center: true,
                    type: 'success',
                    customClass: 'messageClass'
                });
            })
            let s = 60  //倒计时间
            if (!this.timer) {
                this.second = s
                this.disabled = true
                this.timer = setInterval(() => {
                    if (this.second > 0 && this.second <= s) {
                        this.second--
                    } else {
                        this.disabled = false
                        clearInterval(this.timer)
                        this.timer = null
                    }
                }, 1000)
            }
        },
        //重置密码
        handleSure() {
            if (this.form.password.length == 0) {
                this.$message.warning(this.$t('auth.tip5'))
            }
            let rule = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/
            if (!rule.test(this.form.password)) {
                this.$message.warning(this.$t('reg.tip7'))
                return
            }
            if (this.form.re_password.length == 0) {
                this.$message.warning(this.$t('auth.tip6'))
                return
            }
            this.$api.post('/api/reset/password', this.form).then(() => {
                this.$message.success(this.$t('auth.operate_success'))
                this.$router.push('/login')
            }).catch(() => {
                this.$message.error(this.$t('auth.operate_fail'))
            })
        },
        hanldeSkip(url) {
            this.$router.push(url)
        },
        //修改手机号
        handleModify() {
            if (this.status == 0) {
                this.current = 0
            } else {
                this.$message.warning(this.$t('auth.tip19'))

            }

        },
        //验证验证码输入是否正确
        handleVertify() {
            if (this.form.phone) {
                this.$api.post('/api/sms/code-check', { phone: this.form.phone, code: this.form.code }).then(res => {
                    if (res.data) {
                        if (res.data.status == 1) {
                            this.status = 1
                        } else {
                            this.$message.warning(this.$t('reg.tip4'))
                        }
                    }
                })
            }
            if (this.form.mail) {
                this.$api.post('/api/mail/code-check', { mail: this.form.mail, code: this.form.code }).then(res => {
                    if (res.data) {
                        if (res.data.status == 1) {
                            this.status = 1
                        } else {
                            this.$message.warning(this.$t('reg.tip4'))
                        }
                    }
                })
            }




        }
    }

};
</script>
<style scoped lang="scss">
//主体
.forget-wrap {
    margin: 1.64rem auto 0;
    width: 9.3rem;
    height: 5.37rem;
    background: #FFFFFF;
    border-radius: 0.06rem;
    padding: 0.65rem 1.27rem;
    box-sizing: border-box;

    .title {
        font-size: 0.23rem;

        color: #202020;
        line-height: 0.32rem;

    }

    .desc {
        margin-top: 0.09rem;
        font-size: 0.17rem;

        margin-bottom: 0.39rem;
        color: #8B8B8B;

    }

    .ipt {
        width: 100%;
        margin-top: 0.39rem;
        height: 0.69rem;
        background: #FBFBFB;
        border-radius: 0.04rem;
        padding-left: 0.26rem;
        box-sizing: border-box;
        outline: none;
        border: none;


    }

    .input-item {
        width: 100%;
        height: 0.69rem;
        margin-bottom: 0.29rem;

        .edit {
            font-size: 0.2rem;
            font-weight: 400;
            color: #02428C;
            margin-right: 0.23rem;
            cursor: pointer;
        }



        .input {
            flex: 1;
            height: 0.69rem;
            background: #FBFBFB;
            border-radius: 0.04rem;
            outline: none;
            border: none;
            padding-left: 0.26rem;
            box-sizing: border-box;
        }

        .code-input {
            width: 4.12rem;
            height: 0.69rem;
            background: #FBFBFB;
            border-radius: 0.04rem;
            padding-left: 0.26rem;
            box-sizing: border-box;
            outline: none;
            border: none;


        }

        .vertify {
            width: 2.25rem;
            height: 0.69rem;
            background: #02428C;
            border-radius: 0.04rem;
            text-align: center;
            line-height: 0.69rem;
            font-size: 0.17rem;


            color: #FFFFFF;
            cursor: pointer;


        }

    }

    .btn {
        margin-top: 0.49rem;
        height: 0.69rem;
        background: #02428C;
        border-radius: 0.04rem;
        text-align: center;
        line-height: 0.69rem;
        cursor: pointer;
        font-size: 0.18rem;
        color: #FFFFFF;

    }


}
</style>
