<!-- 活动详情 -->
<template>
  <div class="activity flex-row">
    <div class="left">
      <div class="sec1">
        <div class="tit">{{ item.title }}</div>
        <div class="desc flex-row-center">
          <div class="desc-left">{{ $t('task.publishat') }}：{{ item.created_at }}</div>
          <div class="desc-right">{{ $t('task.publisher') }}：{{ item.user && item.user.name }}</div>
        </div>
        <img :src="item.cover" class="cover"/>
        <div class="con" v-html="item.content"></div>
        <div class="topic flex-row-center">
          <div class="topic-item" v-for="(itemt, index) in item.labelArr" @click="handleLabel(itemt)">#{{ itemt }}</div>
        </div>
        <div class="block flex-row-center">{{ $t('activity.date') }}：{{ item.start_time }}<span>{{ $t('activity.to') }}</span>{{ item.end_time }}</div>
        <div class="block">{{ $t('activity.address') }}：{{ item.country }}{{ item.state }}{{ item.city }}{{ item.address }}</div>
        <div class="block"> {{ $t('activity.link') }}</div>
        <div class="link">
          <div class="link-item flex-row-center" v-for="(itemt, keys) in item.outline">
            <div class="label">{{ itemt.name }}：</div>
            <a :href="itemt.url" target="_blank">{{ itemt.url }}</a>
          </div>
        </div>
        <div class="flex-row-center-between flex-row-wrap">
          <div class="word-wrap flex-row-center-between" v-for="(itemt, index) in item.image" :key="index"
            @click="download(itemt)">
            <img src="@/assets/icons/word.png" />
            <div class="word-tit">{{ $t('activity.document') }}{{ index + 1 }}</div>
            <div class="download-btn"> {{ $t('activity.download_document') }}</div>
          </div>
        </div>
        <div class="btn-wrap flex-row-center-between" id="comment">
          <div class="btn" @click="apply" v-loading="loading" element-loading-spinner="el-icon-loading"
            v-if="item.has_join == 0">{{ $t('activity.apply') }}</div>
          <div class="btn" @click="apply(1)" v-loading="loading" element-loading-spinner="el-icon-loading"
            v-if="item.has_join == 0"> {{ $t('activity.apply2') }}</div>
          <div class="btn" @click="handleCollect(item.has_collect, item.id)" v-loading="loading"
            element-loading-spinner="el-icon-loading">{{ item.has_collect ?  $t('task.collected')  : $t('activity.collect_activity') }}</div>
        </div>
      </div>
      <div class="sec2">
        <div class="sec2-top flex-row-center-between">
          <img v-if="user && user.avatar && user.avatar.length" :src="user.avatar" />
          <img src="@/assets/icons/default.png" v-else />
          <el-input :placeholder="placeholderTxt" class="input" :class="{ 'animate': checked }" ref="inputRef"
            v-model="commentReq.content" @keyup.enter.native="handleSend" />
            <el-button type="primary" style="background: #02428C;border-color:#02428C ;" @click="handleSend">{{ $t('activity.send')  }}</el-button>
        </div>
        <div class="sec2-bot-wrap" v-if="list.length">
          <div class="sec2-bot" v-for='(itemt, index) in list' :key="index">
            <div class="sec2-bot-item flex-row">
              <img :src="itemt.user && itemt.user.avatar" v-if="itemt.user && itemt.user.avatar"
                @click="handleSkip(`/personal?id=${itemt.user.id}`)" />
              <img src="@/assets/icons/default.png" v-else @click="handleSkip(`/personal?id=${itemt.user.id}`)" />
              <div class="sec2-bot-info">
                <div class="nickName">{{ itemt.user && itemt.user.name }}</div>
                <div class="description" v-if="itemt.reply == ''">{{ itemt.content }}</div>
                <div class="description" v-else>
                  <span class="c333"> {{ $t('activity.reply')  }}</span>
                  <span class="color">{{ itemt.reply.user.name }}</span>
                  <span class="c333">：</span>
                  {{ itemt.content }}
                </div>
                <div class="operate flex-row-center-between">
                  <div class="time">{{ itemt.created_at }}</div>
                  <div class="operate-item flex-row-center">
                    <img @click="handlecommentZan(itemt.has_like, itemt.id, index)" :src="require(`@/assets/icons/zan${itemt.has_like == 1 ? 'ed' : ''}.png`)
                      " />
                    <div @click="handlecommentZan(itemt.has_like, itemt.id, index)" class="txt">{{ itemt.like_count ||
                      $t('activity.zan')
                    }}</div>
                    <a href="#comment" class="flex-row-center" @click="handleReply(item.id, itemt.id, itemt.user.name, itemt.id)">
                      <img src="@/assets/icons/comment.png"
                         />
                      <div class="txt" >{{ $t('activity.reply') }}</div>
                    </a>
                  </div>
                </div>
                <div class="sec2-bot-item flex-row" v-for="(v, i) in itemt.reply_list" :key="i">
                  <img :src="v.user && v.user.avatar" v-if="v.user && v.user.avatar"
                    @click="handleSkip(`/personal?id=${v.user.id}`)" />
                  <img src="@/assets/icons/default.png" v-else @click="handleSkip(`/personal?id=${v.user.id}`)" />
                  <div class="sec2-bot-info">
                    <div class="nickName">{{ v.user && v.user.name }}</div>
                    <div class="description" v-if="v.reply == ''">{{ v.content }}</div>
                    <div class="description" v-else>
                      <span class="c333">{{ $t('activity.reply') }}</span>
                      <span class="color">{{ v.reply.user.name }}</span>
                      <span class="c333">：</span>
                      {{ v.content }}
                    </div>
                    <div class="operate flex-row-center-between">
                      <div class="time">{{ v.created_at }}</div>
                      <div class="operate-item flex-row-center">
                        <img @click.stop="handlecommentZan(v.has_like, v.id, index, i)" :src="require(`@/assets/icons/zan${v.has_like == 1 ? 'ed' : ''}.png`)
                          " />
                        <div @click.stop="handlecommentZan(v.has_like, v.id, index, i)" class="txt">{{ v.like_count ||
                          $t('activity.zan')
                        }}</div>
                        <a  @click="handleReply(item.id, itemt.id, v.user.name, v.id)" href="#comment" class="flex-row-center"> <img src="@/assets/icons/comment.png"
                            />
                          <div class="txt" >{{ $t('activity.reply')  }}</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="look">查看更多评论</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="floor1">
        <div class="common">
         {{  $t('activity.member')  }}   ({{ total }})
        </div>
        <ul>
          <li class="flex-row-center-between" v-for="(item, index) in members" :key="index"
            @click="handlePage(`/personal?id=${item.id}`,item.id)">
            <img v-if="item.avatar" :src="item.avatar" />
            <img src="@/assets/icon/default.png" v-else />
            <div class="user">
              <div class="nickName">{{ item.name }}</div>
              <div class="score x-ell-1">{{ item.introduction }}</div>
            </div>
            <div class="timer">{{ item.created_at | getTimer }}   {{  $t('activity.apply3')  }}  </div>
          </li>
        </ul>
        <div class="more" @click="visible = true">{{ $t('task.lookAll') }}</div>
      </div>
    </div>
    <activityMember :id="id" :show="visible" @handleClose="visible = false" />
    <!-- 活动报名成功 -->
    <ApplySuccess :show="show" @handleClose="show = false" />
  </div>
</template>
<script>
import activityMember from '@/components/activityMember.vue'
import ApplySuccess from '@/components/ApplySuccess.vue'

export default {
  components: { ApplySuccess, activityMember },
  data() {
    return {
      item: {},//详情
      id: null,
      members: [],//活动成员
      total: 0,
      loading: false,//加载效果
      user: this.$storage.getData("userInfo"),
      listQuery: {
        page: 1,
        type: 'activity',
        type_id: ''
      },
      list: [],//评论列表
      lastPage: 0,
      commentReq: {
        check_code: '', //重复码
        type: 'activity',
        type_id: '',
        content: '', // 评论内容
        reply_id: '',
        index_reply_id: ''
      },
      placeholderTxt: this.$t('activity.tip'),
      show: false,
      visible: false,
      checked: false,
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.listQuery.type_id = this.id
      this.commentReq.type_id = this.id
      //获取评论列表
      this.getList()
      this.$api.get(`/api/activity/${this.id}`).then(res => {
        this.item = res.data
        if (this.item.label) {
          let arr = this.item.label.split('#')
          arr.splice(0, 1)
          this.$set(this.item, 'labelArr', arr)
        }
      })
      this.getMember()
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    // 监听如果页面发生滚动时
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
     //标签点击事件
     handleLabel(label) {
      const txt = label.replace(/#/g, ",");
      this.$router.push(`/search?search=${txt}&current=4&flag=1`)

    },
    //获取成员
    getMember() {
      this.$api.get(`/api/activity-user/${this.id}`).then(res => {
        this.members = res.data.data
        this.total = res.data.meta.total
      })
    },
    //报名
    apply(flag) {
      if(!this.user){
        this.$router.push('/login')
        return
      }
      this.loading = true
      let url = `/api/activity-join/${this.id}`
      if(flag==1){
       url = `/api/activity-join/${this.id}?anonymous=1`
      }
      this.$api.post(url).then(() => {
        this.loading = false
        this.show = true
        this.item.has_join = 1
        this.getMember()
      }).catch(() => {
        this.loading = false
        this.$message.error(this.$t('activity.apply_fail'))
      })
    },
    //下载
    download(url) {
      let a = document.createElement('a')
      a.href = url;
      a.click();
    },
    //收藏 取消收藏
    handleCollect(state, id) {
      if(!this.user){
        this.$router.push('/login')
        return
      }
      if (state == 0) {
        // 收藏
        this.item.has_collect = 1
        this.$api.post(`/api/collect/activity/${id}`)
      } else {
        //取消点赞
        this.item.has_collect = 0
        this.$api.delete(`/api/collect/activity/${id}`)
      }
    },
    //获取评论列表
    getList(page) {
      if (page) this.listQuery.page = page
      this.$api.get('/api/comment', { params: this.listQuery }).then((res) => {
        if (this.listQuery.page == 1) {
          this.list = res.data.data
        } else {
          this.list = [...this.list, ...res.data.data]
        }
        this.lastPage = res.data.meta.last_page

      })
    },
    //回复
    handleReply(type_id, index_reply_id, name, reply_id) {
      if(!this.user){
        this.$router.push('/login')
        return
      }
      this.commentReq.type_id = type_id
      this.commentReq.reply_id = reply_id
      this.commentReq.index_reply_id = index_reply_id
      this.placeholderTxt = `${this.user.name}${this.$t('activity.reply')}${name}:`
      this.$refs.inputRef.focus()
      this.checked = true
      setTimeout(() => {
        this.checked = false
      }, 2000)
    },
    //发送
    handleSend() {
      if(!this.user){
        this.$router.push('/login')
        return
      }
      this.commentReq.check_code = this.user.id + new Date().getTime()
      if (this.commentReq.content == null || this.commentReq.content.length < 2) {
        this.$message.warning(this.$t('activity.tip2'))
        return
      }
      this.$api.post('/api/comment', this.commentReq).then(res => {
        this.$message.success(this.$t('activity.publish_success'))
        this.commentReq.content = ''
        this.commentReq.reply_id = ''
        this.commentReq.index_reply_id = ''
        this.placeholderTxt = this.$t('activity.tip'),
        setTimeout(() => {
          this.getList(1)
        }, 1000)
      })
    },
    //评论点赞 取消点赞
    handlecommentZan(state, id, index1, index2) {
      if(!this.user){
        this.$router.push('/login')
        return
      }

      if (state == 0) {
        // 点赞
        if (index2 == undefined) {
          this.list[index1].has_like = 1
          this.list[index1].like_count += 1

        } else {

          this.list[index1].reply_list[index2].has_like = 1
          this.list[index1].reply_list[index2].like_count += 1
        }

        this.$api.post(`/api/like/comment/${id}`)
      } else {
        //取消点赞
        if (index2 == undefined) {
          this.list[index1].has_like = 0
          this.list[index1].like_count -= 1
        } else {

          this.list[index1].reply_list[index2].has_like = 0
          this.list[index1].reply_list[index2].like_count -= 1
        }

        this.$api.delete(`/api/like/comment/${id}`)
      }
    },
    handleSkip(url) {
      this.$router.push(url)
    },
    handlePage(url,id){
      if(id!=0){
        this.$router.push(url)
      }

    },
    //页面滑到底部调用的方法
    handleScroll() {
      //下面这部分兼容手机端和PC端
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeitht =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
        if (this.listQuery.page <= this.lastPage) {
          this.listQuery.page++
          this.getList()
        }
      }
    },
  }
};
</script>
<style scoped lang="scss">
.activity {
  width: 13.2rem;
  margin: 0.92rem auto;

  .left {
    width: 9.96rem;
  }

  .sec1 {
    width: 9.96rem;
    background: #FFFFFF;
    border-radius: 0.03rem;
    padding: 0.4rem 0.5rem;
    box-sizing: border-box;

    .tit {
      font-size: 0.3rem;
      color: #393736;

    }

    .desc {
      margin-top: 0.2rem;
      font-size: 0.16rem;
      color: #999999;

      .desc-left {
        margin-right: 0.48rem;
      }


    }

    .con {
      margin-top: 0.2rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      color: #666666;
      line-height: 0.35rem;

      img {
        width: 100%;
      }
    }

    .topic {
      line-height: 0.35rem;
      color: #02428C;
      font-size: 0.16ren;

      .topic-item {
        margin-right: 0.2rem;
        cursor: pointer;
      }

    }

    .block {
      color: #262423;
      font-size: 0.18rem;
      margin-top: 0.1rem;

      span {
        margin: 0 0.08rem;
      }
    }

    .link {
      margin-top: 0.15rem;
      line-height: 0.3rem;
      font-size: 0.16rem;

      .label {
        color: #393736;
      }

      .link-item {
        a {
          color: #457FF6;
          text-decoration: none;
        }

      }


    }

    .word-wrap {
      margin: 0.1rem auto 0;
      width: 5rem;
      height: 0.66rem;
      border-radius: 0.03rem;
      border: 0.01rem solid #DDDDDD;
      padding: 0 0.16rem;
      box-sizing: border-box;

      img {
        width: 0.2rem;
      }

      .word-tit {
        font-size: 0.18rem;
        color: #202020;
        margin: 0 0.16rem;
        flex: 1
      }

      .download-btn {
        width: 1.24rem;
        height: 0.36rem;
        border-radius: 0.04rem;
        border: 0.01rem solid #02428C;
        text-align: center;
        line-height: 0.36rem;
        cursor: pointer;
        font-size: 0.16rem;
        color: #02428C;
      }


    }

    .btn-wrap {
      width: 7.5rem;
      margin: 0.6rem auto 0;

      .btn {
        width: 2.35rem;
        height: 0.58rem;
        background: #02428C;
        border-radius: 0.04rem;
        text-align: center;
        line-height: 0.58rem;
        cursor: pointer;
        color: #ffffff;
        font-size: 0.16rem;
        border: 1px solid #02428C;
        cursor: pointer;

      }

      .btn:last-child {
        background-color: #ffffff;
        border: 1px solid #979797;
        color: #979797;
      }


    }

  }

  .sec2 {
    width: 9.96rem;
    background: #FFFFFF;
    padding: 0.49rem 0.3rem;
    box-sizing: border-box;
    margin-top: 0.2rem;

    .sec2-top {
      img {
        width: 0.6rem;
        height: 0.6rem;
        background-color: #efefef;
        border-radius: 50%;
        margin-right: 0.29rem;
        cursor: pointer;

      }

      .input {
        flex: 1;
        margin-right: 0.1rem;
      }
    }

    .sec2-bot-wrap {
      width: 100%;
      height: auto;
      border-radius: 0.04rem;
      border: 0.01rem solid #E9E9E9;
      padding: 0.3rem;
      box-sizing: border-box;
      margin-top: 0.1rem;

    }

    .sec2-bot {


      .more {
        width: 1.84rem;
        height: 0.5rem;
        background: #F8F8F8;
        border-radius: 0.03rem;
        text-align: center;
        line-height: 0.5rem;
        cursor: pointer;
        font-size: 0.16rem;
        color: #737373;
        margin-top: 0.2rem;

      }

      .look {
        width: 2.42rem;
        height: 0.58rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #5077D5;
        margin: 0.5rem auto;
        font-size: 0.16rem;
        cursor: pointer;
        color: #5077D5;
        text-align: center;
        line-height: 0.58rem;


      }

      .sec2-bot-item {
        margin-top: 0.2rem;
        width: 100%;

        >img {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          background-color: #efefef;
          margin-right: 0.2rem;
          cursor: pointer;


        }

        .sec2-bot-info {
          flex: 1;

          .nickName {
            color: #828282;
            font-size: 0.18rem;
          }

          .description {

            color: #202020;
            font-size: 0.18rem;
            margin-top: 0.08rem;
          }

          .operate {
            margin-top: 0.1rem;

            .time {
              color: #828282;
              font-size: 0.16rem;
            }

            .operate-item {
              cursor: pointer;

              img {
                width: 0.2rem;
                margin-right: 0.06rem;
              }

              .txt {
                color: #737373;
                font-size: 0.16rem;
                margin-right: 0.2rem;
              }

            }

          }
        }
      }

    }

  }

  .right {
    width: 3.56rem;
    margin-left: 0.13rem;

    .floor1 {
      width: 100%;
      padding: 0 0.2rem 0.3rem;
      box-sizing: border-box;
      background: #ffffff;
      margin-bottom: 0.17rem;

      ul {
        li {
          margin-bottom: 0.14rem;
          cursor: pointer;

          img {
            width: 0.55rem;
            height: 0.55rem;
            border-radius: 50%;
            background-color: #efefef;
          }

          .user {
            margin-left: 0.06rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            flex: 1;

            .nickName {
              width: 1.41rem;
              height: 0.29rem;
              font-size: 0.16rem;


              color: #202020;
              line-height: 0.29rem;
              overflow: hidden;

            }

            .score {
              font-size: 0.14rem;


              color: #909090;
              line-height: 0.24rem;


            }
          }

          .timer {
            font-size: 0.14rem;
            width: 1rem;

            color: #909090;
            line-height: 0.24rem;

          }

        }
      }

      .more {
        width: 2.07rem;
        height: 0.43rem;
        border-radius: 0.03rem;
        border: 1px solid #02428C;
        text-align: center;
        line-height: 0.43rem;
        font-size: 0.16rem;


        color: #02428C;
        cursor: pointer;
        margin: 0.3rem auto 0;
      }

    }
  }

  .common {
    width: 100%;
    height: 0.62rem;
    background: #ffffff;
    line-height: 0.62rem;
    // padding-left: 0.22rem;
    box-sizing: border-box;
    font-size: 0.2rem;


    color: #202020;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-size: 0.18rem;
    }

    img {
      width: 0.32rem;
      height: 0.32rem;
      background-color: #02428C;
      margin-right: 0.06rem;

    }

  }


}

.c333 {
  color: #333;
}

.color {
  color: #1296db;
  margin: 0 0.06rem;

}

@keyframes shake {
  10% {
    transform: rotate(5deg);
  }

  20% {
    transform: rotate(-2.5deg);
  }

  30% {
    transform: rotate(2.5deg);
  }

  40% {
    transform: rotate(-2.5deg);
  }

  50%,
  100% {
    transform: rotate(0deg);
  }
}
.cover{
  width: 100%;
  margin-top: 0.2rem;
}

.animate {
  animation: shake 2s infinite;

}</style>
