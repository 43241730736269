<!-- 我的收藏 -->
<template>
    <div class="box">
        <div class="main">
            <div class="title">{{ $t('personal.my_collect') }} </div>
            <ul class="nav flex-row-center">
                <li @click="changeNav(index)" :class="{ 'active': current == index }" v-for="(item, index) in navs"
                    class="flex-col-center">
                    <div class="name">{{ item.title }}</div>
                    <div class="line"></div>
                </li>
            </ul>
            <!-- 资源 -->
            <div v-if="current == 0">
                <SourceItem :list="resource"  @categoryChange="categoryChange"
                    @searchResource="searchResource" />
               
            </div>
            <!-- 动态 -->
            <div v-if="current == 1">
                <div v-if="post.length">
                    <div v-for="(item, i) in post" :key="i">
                        <FeedsCard :card="item" :isHome="true" size="medium" @updatePost="getList(1)" />
                    </div>
                </div>
                <view-empty v-else></view-empty>
            </div>
            <!-- 任务 -->
            <div v-if="current == 2">
                <TaskItem :list="task" v-if="task.length" />
                <view-empty v-else></view-empty>
            </div>
            <!-- 活动 -->
            <div v-if="current == 3">
                <ActivityItem :list="activity" v-if="activity.length" />
                <view-empty v-else></view-empty>
            </div>
        </div>
    </div>
</template>
<script>
import ActivityItem from './components/ActivityItem.vue'
import SourceItem from './components/SourceItem.vue'
import FeedsCard from "@/components/FeedsCard";
import TaskItem from "./components/TaskItem"
export default {
    name: "collect",
    components: { ActivityItem, SourceItem, FeedsCard, TaskItem },
    data() {
        return {
            navs: [
                { title: this.$t('circle.source'), value: 'resource' },
                { title: this.$t('index.feed'), value: 'post' },
                { title: this.$t('index.task'), value: 'task' },
                { title: this.$t('index.activity'), value: 'activity' },
            ],
            params: {
                page: 1,
                type: 'resource',

            },
            current: 0,//当前导航
            activity: [],//活动
            post: [],//动态
            task: [],//任务
            list: [],//列表
            resource: [],//资源
            lastPage: 0,//最后一页
            loading: false,//加载效果
        };
    },
    created() {
        this.getList()
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    mounted() {
        // 监听如果页面发生滚动时
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        //资源类型改变
        categoryChange(val) {
            this.$set(this.params, 'resource_type', val)
            this.getList(1)
        },
        //资源搜索
        searchResource(val) {
            this.$set(this.params, 'title', val)
            this.getList(1)
        },
        //导航点击事件
        changeNav(index) {
            this.current = index
            this.params = {
                type: this.navs[index].value,
                page: 1
            }
            this.lastPage = 0
            this.getList()
        },
        //获取列表
        getList(page) {
            if (page) this.params.page = page
            this.loading = true
            this.$api.get(`/api/collect/${this.params.type}`, { params: this.params }).then((res) => {
                if (this.params.page == 1) {
                    this[this.params.type] = res.data.data
                } else {
                    this[this.params.type] = [...this[this.params.type], ...res.data.data]
                }
                if (this.params.type == 'resource') {
                    this.resource.forEach(item => {
                        this.$set(item, 'checked', false)
                    })
                }
                this.lastPage = res.data.meta.last_page
                this.loading = false
            })
        },

        //页面滑到底部调用的方法
        handleScroll() {
            //下面这部分兼容手机端和PC端
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            var windowHeitht =
                document.documentElement.clientHeight || document.body.clientHeight;
            var scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;
            //是否滚动到底部的判断
            if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
                if (this.params.page <= this.lastPage) {
                    this.params.page++
                    this.getList()
                }
            }
        },

    }
};
</script>
<style scoped lang="scss">
.box {
    .main {
        margin: 1.09rem auto 0.5rem;
        width: 13.2rem;
        padding: 0.37rem 1rem;
        box-sizing: border-box;
        background-color: #ffffff;

        .title {
            font-size: 0.26rem;
            color: #262423;
            line-height: 0.4rem;

        }

        .nav {
            margin-top: 0.29rem;
            border-bottom: 1px solid #EAEAEA;

            li {
                margin-right: 0.92rem;
                cursor: pointer;

                .name {
                    font-size: 0.18rem;
                    color: #202020;


                }

                .line {
                    width: 0.39rem;
                    height: 0.03rem;
                    background: #ffffff;
                    margin-top: 0.1rem;
                    border-radius: 0.02rem;


                }

            }

            .active {
                .name {
                    font-size: 0.18rem;
                    color: #02428C;
                }

                .line {
                    background: #02428C;
                }

            }

        }

    }
}
</style>
  