<!-- 新增资源 -->
<template>
    <div class="post-feed">
        <el-dialog title="" :visible.sync="show" width="50%" :before-close="handleClose">
            <div class="floor1 flex-row-center">
                <img class="avatar" :src="user.avatar" v-if="user && user.avatar" />
                <img class="avatar" src="@/assets/icons/default.png" v-else />
                <div class="floor1-title">{{ $t('con.publish_resource') }}</div>
            </div>
            <div>
                <div class="floor5">
                    <div>
                        <div>
                            <el-form size="medium" ref="form" :rules="rules" :model="item" label-position="left"
                                label-width="85px">
                                <div class="flex-row-center-between">
                                    <div class="tabs flex-row-center">
                                        <div class="tab tab1" :class="{ 'active': item.type == 2 }" @click="changeTab(2)">
                                            {{ $t('circle.organ') }}</div>
                                        <div class="tab tab2" :class="{ 'active': item.type == 1 }" @click="changeTab(1)">
                                            {{ $t('circle.export') }}</div>
                                    </div>
                                </div>
                                <el-form-item :label="$t('con.resource_img')" prop="image">
                                    <el-upload class="uploadImg" action :limit="9" multiple accept="image/*"
                                        :on-change="(file) => onChange1(file)" :show-file-list="showImg"
                                        :fileList="item.fileList" ref="imageUpload" list-type="picture-card"
                                        :class="{ hide: item.hideUploadEdit }" :auto-upload="false"
                                        :on-remove="(file) => handleRemove1(file)">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item :label="$t('con.resource_tit')" prop="title">
                                    <el-input v-model="item.title" :placeholder="$t('con.placeholder3')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.orgname')" prop="organization" v-if="item.type == 2">
                                    <el-input v-model="item.organization" :placeholder="$t('con.placeholder4')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.main_character')" prop="main_character" v-if="item.type == 1">
                                    <el-input v-model="item.main_character" :placeholder="$t('con.placeholder5')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.other_character')" prop="other_character" v-if="item.type == 1">
                                    <el-input v-model="item.other_character" :placeholder="$t('con.placeholder6')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.country')">
                                    <el-col :span="6">
                                        <el-select filterable :placeholder="$t('con.placeholder7')" style="width: 100%" v-model="item.countryId"
                                            @change="(event) => changeCountry(event)">
                                            <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt, i) in country"
                                                :key="i"></el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-select filterable v-model="item.stateId" :placeholder="$t('con.placeholder8')" style="width: 100%"
                                            @change="(event) => changeProvince(event)">
                                            <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt, i) in province"
                                                :key="i"></el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col class="line" :span="1">-</el-col>
                                    <el-col :span="6">
                                        <el-select filterable v-model="item.cityId" :placeholder="$t('con.placeholder9')" style="width: 100%"
                                            @change="(event) => changeCity(event)">
                                            <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt, i) in city"
                                                :key="i"></el-option>
                                        </el-select>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :label="$t('con.other_city')" prop="other_city">
                                    <el-input v-model="item.other_city" :placeholder="$t('con.placeholder10')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.industry')">
                                    <el-col class="sec" :span="7" v-for="(v, i) in item.chooseIndustry" :key="i">
                                        <el-cascader style="width: 100%;"
                                            :props="{ label: 'name', value: 'id', children: 'childrens', checkStrictly: true }"
                                            :placeholder="$t('feed.placeholder28')" v-model="v.ids" :options="industry"></el-cascader>
                                        <div class="del" @click="handleDel(i, 1)">{{ $t('task.del') }}</div>
                                    </el-col>
                                    <el-col class="line add" :span="5">
                                        <div @click="handleAdd(1)">+{{ $t('con.continue_industry') }}</div>
                                    </el-col>
                                </el-form-item>
                                <el-form-item :label="$t('circle.category')" prop="category_id">
                                    <el-select v-model="item.category_id" :placeholder="$t('personal.placeholder18')" style="width: 100%">
                                        <el-option :label="itemt.name" :value="itemt.id" v-for="(itemt,index) in category"
                                            :key="index" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('circle.introduction')" prop="introduction">
                                    <el-input :rows="6" :placeholder="$t('con.placeholder11')" type="textarea"
                                        v-model="item.introduction" :maxLength="250"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.background')" prop="background">
                                    <el-input :rows="6" :placeholder="$t('con.placeholder12')" type="textarea"
                                        v-model="item.background"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.main_field')" prop="main_field">
                                    <el-input :rows="6" :placeholder="$t('con.placeholder13')" type="textarea"
                                        v-model="item.main_field"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('circle.achievement')" prop="achievement">
                                    <el-input :rows="6" :placeholder="$t('con.placeholder14')"  type="textarea"
                                        v-model="item.achievement"></el-input>
                                </el-form-item>
                                <el-form-item   prop="reliable_relationship">
                                    <template #label>
                                        <div>{{ $t('index.relation') }}</div>
                                        <div class="tips">*{{ $t('index.bgVisible') }}</div>
                                     </template>
                                    <el-input :rows="6" :placeholder="$t('index.reliable_relationship')"  type="textarea"
                                        v-model="item.reliable_relationship"></el-input>
                                </el-form-item>


                                <el-form-item :label="$t('circle.link')" prop="out_line">
                                    <div v-for="(itemt, index2) in item.out_line" :key="index2" class="item">
                                        <el-input v-model="itemt.name" :placeholder="$t('con.placeholder15')"></el-input>
                                        <el-input v-model="itemt.url" :placeholder="$t('con.placeholder16')"
                                            style="margin-top:15px"></el-input>
                                        <div class="del" @click="handleDel(index2, 2)">{{ $t('task.del') }}</div>
                                    </div>
                                    <div class="add" @click="handleAdd(2)">+{{ $t('con.add_resource_link') }}</div>
                                </el-form-item>
                                <el-form-item :label="$t('index.label')" prop="label">
                                    <el-input v-model="item.label" :placeholder="$t('index.placeholder')"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="submit-btn" @click="handleSubmit">{{ $t('con.publish') }}</div>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>
import uploadFile from "@/utils/upload-file";
export default {
    props: ["show","params"],
    data() {
        return {
            user: this.$storage.getData("userInfo"),
            item: {
                hideUploadEdit: false,//是否显示上传按钮
                title: '',//资源标题
                type: '',//资源类别   （机构/专家）
                image: [],//资源图片
                fileList: [],
                main_character: '',//主要角色（type 为专家时）
                other_character: '',//其他角色 （type 为专家时）
                organization: '',//     机构名称  （type 为机构时）
                country: '',//     所属国家
                countryId: '',
                state: '',
                stateId: '',
                city: '',//   总部所在城市
                cityId: '',
                other_city: '',//     其他城市   城市1,城市2
                category_id: '',//     资源类型id
                introduction: '',//    资源介绍
                background: '',//     资源背景
                main_field: '',//   主要领域
                achievement: '',//    关键成就
                out_line: [],//   外部链接   （跟活动一样传json格式）
                label: '',//   标签     ()
                industry_ids: '',
                fee_description: '',//费用说明
                chooseIndustry: [],//选择的行业
                reliable_relationship:''
            },
            rules: {
                title: [{ required: true, message: this.$t('con.placeholder3'), trigger: "blur" }],
                image: [{ required: true, message: this.$t('con.msg1'), trigger: "blur" }],
                category_id: [{ required: true, message: this.$t('personal.placeholder18'), trigger: "blur" }]
            },
            showImg: true,//显示图片
            loading: false,//加载效果
            country: [],//国家
            province: [],//省份
            city: [],//城市
            industry: [],//行业
            category: [],//资源类别
        };
    },
    watch:{
        show(){
            if(this.params){
              
                this.item = {
                    ...this.params,
                    hideUploadEdit: false,
                    fileList: this.params.image.map(item=>{return{url:item}}),
                    countryId: '',
                    stateId:'',
                    cityId:'',
                    chooseIndustry:this.params.industry.length?this.params.industry.map(item=>{return{ids:item.id}}):[],
                    industry_ids:this.params.industry.length?this.params.industry.map(item=>item.id).join(','):'',
                    category_id:this.params.category?this.params.category.id:''
                }
                this.$api
                .get("/api/country", { params:{search: this.item.country} })
                .then((res) => {
                   if(res.data&&res.data.length){
                    this.item.countryId = res.data[0].id
                    this.changeCountry(this.item.countryId)
                   }
                    
                });

                this.$api
                .get("/api/city", { params:{search: this.item.city} })
                .then((res) => {
                   if(res.data&&res.data.length){
                    this.item.cityId = Number(res.data[0].id)
                    
                    this.item.stateId = Number(res.data[0].pid)
                    this.changeProvince(this.item.stateId)
                   }
                    
                });





            }
        }

    },
    created() {
        this.fetchData()
    },
    methods: {
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        //发布
        handleSubmit() {
            if (this.item.chooseIndustry && this.item.chooseIndustry.length) {
                let arr = []
                this.item.chooseIndustry.forEach(item => {
                    arr.push(item.ids[item.ids.length - 1])
                })
                this.item.industry_ids = arr.join(',')
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$api.post('/api/resource', this.item).then((res) => {
                        this.$message.success(this.$t('activity.publish_success'))
                        this.$emit('handleClose')
                    }).catch(() => {
                        this.$message.error(this.$t('con.publish_fail'))
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        //获取数据
        fetchData() {
            this.$api
                .get("/api/industry", { paginate: 0 })
                .then((res) => {
                    this.industry = res.data
                });
            this.$api
                .get("/api/country", { paginate: 0 })
                .then((res) => {
                    this.country = res.data
                });
            this.$api
                .get("/api/resource-category", { paginate: 0 })
                .then((res) => {
                    this.category = res.data
                });
        },
        //选择国家改变
        changeCountry(id) {
            // this.item.state = ''
            // this.item.stateId = ''
            // this.item.city = ''
            // this.item.cityId = ''
            this.country.find(item => {
                if (item.id == id) {
                    this.item.country = item.name
                }
            })
            this.$api.get(`/api/state/${id}`).then(res => {
                this.province = res.data
            })
        },
        //选择城市改变
        changeCity(id) {
            this.city.find(item => {
                if (item.id == id) {
                    this.item.city = item.name
                }
            })
        },
        //选择省份改变
        changeProvince(id) {
            // this.item.city = ''
            // this.item.cityId = ''
            this.$api.get(`/api/city?pid=${id}`).then(res => {
                this.city = res.data
            })
        },
        //新增
        handleAdd(state) {
            switch (state) {
                //新增行业
                case 1:
                    this.item.chooseIndustry.push({ ids: [] })
                    break;
                //新增外链
                case 2:
                    this.item.out_line.push({ name: '', url: '' })
                    break;
            }
        },
        //删除
        handleDel(index2, state) {
            switch (state) {
                //删除行业
                case 1:
                    this.item.chooseIndustry.splice(index2, 1)
                    break;
                //新增外链
                case 2:
                    this.item.out_line.splice(index2, 1)
                    break;
            }
        },
        //上传图片改变
        async onChange1(file) {
            this.item.hideUploadEdit = false
            let { data } = await uploadFile(file);
            this.item.image.push(data.path)
            this.item.fileList.push({ url: data.path })
        },
        //移除图片
        handleRemove1(e) {
            this.item.image = this.item.image.filter(imgUrl => imgUrl != e.url)
            this.item.hideUploadEdit = this.item.image.length >= 9 ? true : false;
        },
        //改变资源类别
        changeTab(type) {
            this.item.type = type
        }
    }

};
</script>
<style scoped lang="scss">
.post-feed {
    .floor1 {
        img {
            width: 0.69rem;
            height: 0.69rem;
            border-radius: 50%;
            overflow: hidden;
            background-color: #efefef;
        }

        .floor1-title {
            margin-left: 0.14rem;
            font-size: 0.2rem;
            color: #2B2B2B;
        }
    }

    .floor2 {
        margin-top: 0.2rem;
    }

    .floor3 {
        width: 100%;
        height: 2.6rem;
        border-radius: 0.04rem;
        border: 0.01rem solid #E9E9E9;
        margin-top: 0.2rem;

        .floor3-head {
            width: 100%;
            height: 0.6rem;
            background: #F7F7F7;
            border-radius: 0.03rem 0.03rem 0rem 0rem;
            padding-left: 0.35rem;
            box-sizing: border-box;

            .floor3-item {
                margin-right: 1.11rem;
                cursor: pointer;

                span {
                    font-size: 0.16rem;
                    color: #828282;
                    margin-right: 0.04rem;
                }

                .pic {
                    width: 0.16rem;

                }

                .video {
                    width: 0.18rem;
                }

                .topic {
                    width: 0.14rem;
                }
            }

        }

        ::v-deep .el-textarea__inner {
            border: none;

        }

    }

    .floor4 {
        margin-top: 0.2rem;

        .floor4-item-bot {
            text-align: right;
            font-size: 0.15rem;
            color: #5077D5;
            cursor: pointer;

        }

        .floor4-item {
            .floor4-item-top {
                font-size: 0.15rem;

                .floor4-item-top-left {
                    color: #838383;
                }

                .floor4-item-top-right {
                    color: #5077D5;
                    cursor: pointer;
                }

            }

            .floor4-item-cen {
                margin-bottom: 0.1rem;
            }


            .floor4-btn {
                font-size: 0.16rem;
                color: #5077D5;
                cursor: pointer;
                width: 1rem;
                text-align: center;
            }
        }

    }

    .floor5 {
        margin-top: 0.2rem;

        .navs {
            flex: 1
        }

        .floor5-item {
            position: relative;
            height: 0.6rem;
            background: #FCFCFC;
            border-radius: 0.04rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            width: 100%;
            cursor: pointer;

            .item-left {

                .tit {
                    color: #02428C;
                }

                .mark {
                    width: 0.5rem;
                    height: 0.27rem;
                    background: #FF580F;
                    border-radius: 0.14rem;
                    font-size: 0.14rem;
                    text-align: center;
                    line-height: 0.27rem;
                    color: #FFFFFF;
                    margin: 0 0.06rem;
                }

                .con {

                    color: #262423;

                }
            }

            .item-right {
                width: 0;
                height: 0;
                border-left: 0.06rem solid transparent;
                border-right: 0.06rem solid transparent;
                border-top: 0.06rem solid #8B8B8B;

            }

        }

        .navs {
            margin-bottom: 0.2rem;

            ul {
                li {
                    margin-right: 0.3rem;
                    cursor: pointer;

                    .nav-tit {
                        width: 0.7rem;
                        height: 0.32rem;
                        font-size: 0.17rem;
                        font-weight: 400;
                        color: #828282;
                        text-align: center;
                        line-height: 0.32rem;
                    }

                    .nav-line {
                        width: 0.7rem;
                        height: 0.02rem;
                        background: #ffffff;
                        border-radius: 0.02rem;

                    }

                    .active-color {
                        color: #02428C;
                    }

                    .active-bg {
                        background: #02428C;
                    }

                }
            }

            .navs-left {
                font-size: 0.18rem;
                font-weight: 600;
                color: #2B2B2B;
                line-height: 0.32rem;

            }

            .navs-right {
                font-size: 0.15rem;
                color: #5077D5;
                cursor: pointer;

            }

        }

        .del-source {
            font-size: 0.15rem;
            color: #5077D5;
            cursor: pointer;
        }





    }

    .add-btn {
        width: 1.94rem;
        height: 0.43rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #5077D5;
        text-align: center;
        line-height: 0.43rem;
        color: #5077D5;
        font-size: 0.15rem;
        cursor: pointer;
        margin-top: 0.2rem;

    }

    .floor6 {
        margin-top: 0.4rem;

        .floor6-left {
            font-size: 0.16rem;
            color: #B0B0B0;
            margin: 0 0.14rem;
        }

        .floor6-right {
            font-size: 0.16rem;
            color: #02428C;
            cursor: pointer;
        }

    }

    .floor7 {
        height: auto;
        background: #FCFCFC;
        border-radius: 0.03rem;
        margin: 0.3rem;
        box-sizing: border-box;
        padding: 0.2rem 0.3rem;

        .floor7-tit {
            font-size: 0.18rem;
            font-weight: 500;
            color: #202020;

        }

        .floor7-top-right {
            .btn {
                color: #5077D5;
                font-size: 0.16rem;
                margin-left: 0.3rem;
                cursor: pointer;
            }
        }

        ul {
            li {
                width: 33%;
                color: #B0B0B0;
                margin-top: 0.2rem;
            }
        }


    }

    .submit-btn {
        margin: 0.5rem auto 0;
        width: 2rem;
        height: 0.5rem;
        text-align: center;
        line-height: 0.5rem;
        color: #ffffff;
        font-size: 0.16rem;
        cursor: pointer;
        background-color: #5077D5;
        border-radius: 0.06rem;
    }

    // 上传文件
    ::v-deep .el-upload--picture-card {
        width: 0.8rem;
        height: 0.8rem;
        line-height: 0.8rem;
        margin: 0.06rem;
    }

    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
        width: 0.8rem;
        height: 0.8rem;
        margin: 0.06rem;
    }

    ::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        width: 0.8rem;
        height: 0.8rem;

    }

    .add {
        font-size: 0.16rem;
        color: #02428C;
        line-height: 0.4rem;
        cursor: pointer;
        text-align: left;
    }



    .line {
        text-align: center;
    }

    .sec {
        margin-right: 0.1rem;
        margin-bottom: 0.3rem;
        position: relative;

        .del {
            font-size: 0.14rem;
            color: #587DD7;
            position: absolute;
            right: 0;
            top: -0.3rem;
            cursor: pointer;
        }

    }

    .item {
        margin-bottom: 0.3rem;
        position: relative;

        .del {
            font-size: 0.14rem;
            color: #587DD7;
            position: absolute;
            right: 0;
            top: -0.3rem;
            cursor: pointer;

        }
    }


    .tabs {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;

        .tab {
            width: 0.8rem;
            height: 0.36rem;
            border: 0.01rem solid #565656;
            text-align: center;
            line-height: 0.36rem;
            color: #828282;
            font-size: 0.16rem;
            cursor: pointer;
        }

        .tab1 {
            border-radius: 0.04rem 0rem 0rem 0.04rem;
        }

        .tab2 {
            border-radius: 0rem 0.04rem 0.04rem 0rem;
        }

        .active {
            background-color: #02428C;
            color: #ffffff;
            border-color: #02428C;
        }

        .add-btn {
            width: 1.94rem;
            height: 0.43rem;
            border-radius: 0.03rem;
            border: 0.01rem solid #5077D5;
            text-align: center;
            line-height: 0.43rem;
            cursor: pointer;
            font-size: 0.16rem;
            color: #5077D5;
        }

    }
}
::v-deep .el-upload-list__item.is-ready {
        display: none;
    }
    .tips{
    color:red;
    font-weight: bold;
    font-size: 0.14rem;
}
</style>
  
