<template>
    <div class="main">
        <div class="title">{{ title }}</div>
        <div class="con" v-html="content"></div>
        <!-- <div class="word-wrap flex-row-center-between">
            <img src="@/assets/icons/word.png" />
            <div class="word-tit">关于本次活动的现场注意事项.docx</div>
            <div class="download-btn">{{ $t('activity.download_document') }}</div>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: '',
            content: ''
        }
    },
    created() {
        let type = this.$route.query.type
        if (type == 'user_service_agreement') this.title =  this.$t('auth.tit1')
        if(type=='xikedao_service_agreement') this.title =  this.$t('auth.tit2')
        if(type=='about') this.title =  this.$t('con.tab1')
        if(type=='business_partner') this.title =  this.$t('con.tab2')
        if(type=='privacy_agreement') this.title =  this.$t('con.tab4')
        if(type=='case') this.title =  this.$t('con.tab5')
        if(type=='cooperate') this.title =  this.$t('con.tab6')
        if(type=='media_coverage') this.title =  this.$t('con.tab7')
        if(type=='advertising_cooperation') this.title =  this.$t('con.tab8')
        this.$api.get('/api/init/config').then(res => {
            this.content = res.data[type]
        })
    }
}
</script>
<style lang="scss" scoped>
.main {
    width: 13.2rem;
    height: auto;
    background: #FFFFFF;
    border-radius: 0.03rem;
    padding: 0.3rem 0.5rem;
    box-sizing: border-box;
    margin: 0.92rem auto 0;

    .title {
        font-size: 0.24rem;
        color: #202020;


    }

    .con {
        font-size: 0.16rem;
        line-height: 0.3rem;
        color: #202020;
        ;
        margin: 0.2rem 0;

    }

    .word-wrap {
        margin: 0.6rem auto;
        width: 5rem;
        height: 0.66rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #DDDDDD;
        padding: 0 0.16rem;
        box-sizing: border-box;

        img {
            width: 0.2rem;
        }

        .word-tit {
            font-size: 0.18rem;
            color: #202020;
            margin: 0 0.16rem;
            flex: 1
        }

        .download-btn {
            width: 1.24rem;
            height: 0.36rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #02428C;
            text-align: center;
            line-height: 0.36rem;
            cursor: pointer;
            font-size: 0.16rem;
            color: #02428C;
        }


    }
}
</style>