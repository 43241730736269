<!-- 创建圈子 -->
<template>
  <div class="release">
    <section class="main">
      <div class="title">
        {{ form.id ? $t("feed.edit") : $t("feed.add") }} {{ $t("feed.circle") }}
      </div>
      <div class="release-form">
        <el-form ref="form" :rules="rules" :model="form" label-position="top">
          <el-form-item :label="$t('feed.circle_name')" prop="name">
            <el-input
              v-model="form.name"
              :placeholder="$t('feed.placeholder14')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('circle.circle_type')" prop="category_id">
            <el-select
              v-model="form.category_id"
              :placeholder="$t('feed.placeholder15')"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in category"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('circle.label')" prop="label">
            <el-input
              v-model="form.label"
              :placeholder="$t('feed.placeholder16')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('feed.circle_avatar')" prop="icon">
            <!-- <el-upload
              class="avatar-uploader"
              ref="upload_img"
              action
              accept="image/jpeg, image/jpg, image/png"
              list-type="picture-card"
              :name="upload_name"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :limit="1"
              :file-list="file_list"
              :before-upload="beforeUpload"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus"></i>
            </el-upload> -->
            <div
              class="el-upload--picture-card flex-row-center-center"
              v-if="!(form.icon && form.icon.length)"
              @click="cropperModel = true"
            >
              <i class="el-icon-plus"></i>
            </div>
            <div
              class="img-self"
              v-if="form.icon"
              @mouseenter="coverDelete = true"
              @mouseleave="coverDelete = false"
              @click="cropperModel = true"
            >
              <el-avatar
                shape="square"
                :size="150"
                fit="cover"
                :src="form.icon"
              />

              <div class="delete-img" v-if="coverDelete">
                <i class="el-icon-delete" @click.stop="form.icon = ''"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('feed.circle_cover')" prop="cover">
            <el-upload
              class="avatar-uploader"
              ref="upload_img"
              action
              accept="image/jpeg, image/jpg, image/png"
              list-type="picture-card"
              :name="upload_name2"
              :on-remove="handleRemove2"
              :on-exceed="handleExceed2"
              :limit="1"
              :file-list="file_list2"
              :before-upload="beforeUpload2"
              :class="{ hide: hideUpload2 }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('feed.circle_desc')" prop="description">
            <el-input
              :rows="6"
              :placeholder="$t('feed.placeholder17')"
              type="textarea"
              v-model="form.description"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('feed.add_question')" prop="question">
            <div
              class="flex-row-center sec-wrap"
              v-for="(item, index) in question"
              :key="index"
            >
              <div class="sec">{{ $t("feed.question") }} {{ index + 1 }}</div>
              <el-input
                style="flex: 1; margin-left: 0.1rem"
                v-model="item.title"
                :placeholder="$t('feed.placeholder19')"
              ></el-input>
              <div class="del" @click="handleDel(index)">
                {{ $t("task.del") }}
              </div>
            </div>
          </el-form-item>
          <div class="add" @click="handleAdd">
            +{{ $t("feed.adder_question") }}
          </div>
          <div
            class="release-form-btn"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            @click="submit()"
          >
            {{ $t("feed.sure") }}
          </div>
        </el-form>
      </div>
    </section>
    <!-- 剪裁组件弹窗 -->
    <el-dialog
      title="编辑头像"
      :visible.sync="cropperModel"
      width="950px"
      center
    >
      <cropper-image
        :Name="cropperName"
        @uploadImgSuccess="handleUploadSuccess"
        ref="child"
        width="150"
        height="150"
      >
      </cropper-image>
    </el-dialog>
  </div>
</template>
<script>
import { showLoading, hideLoading } from "@/utils/loading";
import CropperImage from "@/components/cropperImage.vue";
import uploadFile from "@/utils/upload-file";
export default {
  components: { CropperImage },
  data() {
    return {
      coverDelete: false,
      cropperModel: false,
      cropperName: "",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: this.$t("feed.placeholder14"),
            trigger: "blur",
          },
        ],
        cover: [
          { required: true, message: this.$t("feed.msg7"), trigger: "blur" },
        ],
        icon: [
          { required: true, message: this.$t("feed.msg8"), trigger: "blur" },
        ],
        description: [
          { required: true, message: this.$t("feed.msg9"), trigger: "blur" },
        ],
        category_id: [
          { required: true, message: this.$t("feed.msg10"), trigger: "blur" },
        ],
        label: [
          { required: true, message: this.$t("feed.msg12"), trigger: "blur" },
        ],
      },
      question: [],
      category: [], //圈子分类
      file_list: [],
      upload_name: "file", //图片上传的后端接受图片文件的 key
      dialogImageUrl: "", //上传后的图片地址
      hideUpload2: false,
      hideUpload: false,
      file_list2: [],
      upload_name2: "file", //图片上传的后端接受图片文件的 key
      dialogImageUrl2: "", //上传后的图片地址
      loading: false,
    };
  },
  created() {
    if (this.$route.query.id) {
      showLoading(this.$t("feed.loading"));
      this.$api.get(`/api/group/${this.$route.query.id}`).then((res) => {
        this.form = res.data;
        this.$set(
          this.form,
          "category_id",
          this.form.category ? this.form.category.id : ""
        );
        // this.form.join_type = this.form.join_type ? this.form.join_type.toString() : ''
        this.file_list = [{ url: this.form.icon }];
        this.file_list2 = [{ url: this.form.cover }];
        if (this.form.question && this.form.question.length) {
          this.question = this.form.question.map((item) => {
            return {
              title: item,
            };
          });
        }
        hideLoading();
      });
    }
    //获取分类
    this.getCategory();
  },
  methods: {
    //获取分类
    getCategory() {
      this.$api
        .get("/api/group/category", { params: { paginate: 1, limit: 100 } })
        .then((res) => {
          this.category = res.data.data;
        });
    },
    //继续添加
    handleAdd() {
      this.question.push({ title: "" });
    },
    //删除问题
    handleDel(index) {
      this.question.splice(index, 1);
    },
    //上传图片
    handleExceed() {
      this.$alert(this.$t("feed.tip3"), {
        showConfirmButton: false,
      });
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.dialogImageUrl = "";
      this.form.icon = null;
      //打开上传按钮
      this.hideUpload = false;
    },
    async beforeUpload(file) {
      let { data } = await uploadFile(file);
      this.form.icon = data.path;
      this.file_list = [{ url: this.form.icon }];
      //隐藏上传按钮
      this.hideUpload = true;
    },
    //上传成功
    handleUploadSuccess(path) {
      this.form.icon = path;
      this.file_list = [{ url: this.form.icon }];
      this.cropperModel = false;
    },
    //上传图片
    handleExceed2() {
      this.$alert(this.$t("feed.tip3"), {
        showConfirmButton: false,
      });
    },
    // 文件列表移除文件时的钩子
    handleRemove2(file, fileList) {
      this.dialogImageUrl2 = "";
    },
    async beforeUpload2(file) {
      console.log(file);
      let { data } = await uploadFile(file);
      console.log(data);
      console.log("数据");
      this.form.cover = data.path;
      this.file_list2 = [{ url: this.form.cover }];
      //隐藏上传按钮
      this.hideUpload2 = true;
    },
    //圈子提交
    submit() {
      if (this.question.length) {
        this.form.question = this.question.map((item) => item.title);
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$api
            .post("/api/group", this.form)
            .then(() => {
              this.$message({
                message: this.$t("auth.operate_success"),
                type: "success",
              });
              this.$router.push("/circle");
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              this.$message({
                message: this.$t("auth.operate_fail"),
                type: "warning",
              });
            });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
//主体
.main {
  margin: 0.92rem auto;
  display: flex;
  flex-direction: column;
  width: 13.2rem;
  background-color: #ffffff;
  border-radius: 0.03rem;
  padding: 0.5rem 1rem 0.75rem;
  box-sizing: border-box;

  .title {
    font-size: 0.28rem;
    color: #262423;
  }

  .desc {
    font-size: 0.14rem;
    color: #828282;
    margin-top: 0.06rem;
  }
}

.release-form {
  height: auto;
  background: #ffffff;
  margin-top: 0.2rem;

  p {
    font-size: 0.17rem;

    color: #bcbcbc;
  }

  .tip {
    font-size: 0.14rem;

    color: #b0b0b0;
  }

  .add {
    font-size: 0.16rem;
    color: #02428c;
    line-height: 0.29rem;
    margin-top: 0.23rem;
    cursor: pointer;
  }

  .release-form-btn {
    cursor: pointer;
    width: 2.35rem;
    height: 0.58rem;
    background: #02428c;
    border-radius: 0.04rem;
    text-align: center;
    line-height: 0.58rem;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-size: 0.17rem;
    color: #ffffff;
    margin: 0 auto;
  }
}

.line {
  text-align: center;
}

.steps {
  margin: 0.5rem auto 0;

  li {
    .block {
      width: 1.5rem;

      img {
        width: 0.84rem;
        height: 0.84rem;
      }

      .tit {
        font-size: 0.16rem;
        color: #bcbcbc;
        margin-top: 0.2rem;
        text-align: center;
      }

      .time {
        font-size: 0.16rem;
        color: #bcbcbc;
        line-height: 0.24rem;
        text-align: center;
      }

      .active-color {
        color: #02428c;
      }
    }

    .line {
      width: 1.12rem;
      height: 0.12rem;
      background: #f7f7f7;
      margin-top: 0.36rem;
      margin-left: -0.3rem;
    }

    .active-line {
      background-color: #dbe3f6;
    }
  }

  li:nth-child(2),
  li:nth-child(3),
  li:nth-child(4),
  li:nth-child(5) {
    .block {
      margin-left: -0.35rem;
    }
  }
}

.sec-wrap {
  margin-top: 0.2rem;
  position: relative;

  .sec {
    color: #999;
  }
}

.del {
  font-size: 0.16rem;
  color: #587dd7;
  cursor: pointer;
  margin-left: 0.1rem;
}
::v-deep .el-upload-list__item.is-ready {
  display: none;
}
.img-self {
  margin-right: 4px;
  position: relative;
  .delete-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.646);
    text-align: center;
    line-height: 150px;
    font-size: 20px;
    color: #fff;
    border-radius: 4px;
  }
}
</style>
