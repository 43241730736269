<!-- 活动列表 -->
<template>
  <div class="activity-home">
    <div class="main">
      <div class="sec-head flex-row-center-between">
        <ul class="flex-row-center">
          <li :class="{ 'active': current == 0 }" @click="onClickItem(0)">{{ $t('task.hot') }}</li>
          <li :class="{ 'active': current == 1 }" @click="onClickItem(1)">{{ $t('task.new') }}</li>
        </ul>
        <div class="flex-row-center">
          <div class="search flex-row-center">
            <input :placeholder="$t('activity.search')" type="text" v-model="searchParams.search" @keyup.enter="getList(1)" />
            <img src="@/assets/icons/search.png" @click="getList(1)" />
          </div>
          <div class="publish" @click="goCreate">{{ $t('activity.publish') }}</div>
        </div>
      </div>
      <div class="cen">
        <ActivityList :list="list" />
      </div>
    </div>
  </div>
</template>
<script>
import ActivityList from "./components/ActivityList";

export default {
  components: { ActivityList },
  data() {
    return {
      current: 0,//当前导航
      list: [],//活动列表
      searchParams: {
        search:'',
        paginate: 1,
        page: 1,
        order_by: 'hot'
      },
      lastPage: 0,
      user: this.$storage.getData("userInfo"),
    };
  },
  created() {
    //获取活动列表
    this.getList()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //获取活动列表
    getList(page) {
      if (page) this.searchParams.page = page
      if (this.searchParams.page == 1) {
        this.showLoading()
      }
      this.$api.get('/api/activity', { params: this.searchParams }).then(res => {
        let result = res.data.data
        this.hideLoading()
        this.lastPage = res.data.meta.last_page
        if (this.searchParams.page == 1) {
          this.list = result
        } else {
          if (result.length) {
            this.list = [...this.list, ...result]
          }
        }
      })
    },
    handleScroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeitht =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
        if (this.searchParams.page <= this.lastPage) {
          this.searchParams.page++;
          this.getList()
        }
      }
    },
    //创建活动
    goCreate() {
      if(!this.user){
        this.$router.push('/login')
        return
      }
      this.$router.push("/activity/create");
    },
    //导航点击事件
    onClickItem(index) {
      this.current = index;
      this.searchParams.order_by = index == 0 ? 'hot' : 'new'
      this.searchParams.page = 1;
      this.getList()
    }

  },
};
</script>
<style scoped lang="scss">
.activity-home {
  padding: 0.92rem 0;

  .main {
    width: 13.2rem;
    margin: 0 auto;

    .sec-head {
      height: 0.89rem;
      background: #FFFFFF;
      border-radius: 0.03rem;
      padding: 0 0.3rem;
      box-sizing: border-box;

      ul {
        li {
          font-size: 0.18rem;
          cursor: pointer;
          color: #202020;
          margin-right: 0.65rem;
        }

        .active {
          color: #02428C;
        }
      }

      .search {
        width: 2.17rem;
        height: 0.46rem;
        border-radius: 0.06rem;
        border: 0.01rem solid #F5F5F5;
        padding-left: 0.09rem;
        padding-right: 0.09rem;
        box-sizing: border-box;
        background-color: #F5F5F5;

        input {
          flex: 1;
          height: 0.46rem;
          outline: none;
          border: none;
          background-color: #F5F5F5;
          box-sizing: border-box;
        }

        img {
          width: 0.2rem;
          height: 0.2rem;
          cursor: pointer;
        }

      }

      .publish {
        width: 1.84rem;
        height: 0.46rem;
        background: #02428C;
        border-radius: 0.04rem;
        cursor: pointer;
        text-align: center;
        line-height: 0.46rem;
        font-size: 0.17rem;

        color: #ffffff;
        cursor: pointer;
        margin-left: 0.95rem;
      }

    }
  }


}
</style>
