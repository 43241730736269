//申请接单
<template>
    <div class="apply">
        <el-dialog :title="$t('con.apply_order')" center :visible.sync="show" width="8rem" :before-close="handleClose">
            <div class="tip">{{ $t('con.tit7') }}</div>
            <div class="label">{{$t('con.tit8')}}：</div>
            <div class="wrap">
                <div v-for="(item, index) in list" :key="index" class="item flex-row-center-between">
                    <div class="item-left flex-row-center" v-if="item.type" @click="handshow(index)">
                        <div class="tit">{{ $t('circle.source') }}:</div>
                        <div class="mark" v-if="item.type == 2">{{ $t('circle.organ') }}</div>
                        <div class="mark" v-if="item.type == 1">{{ $t('circle.export') }}</div>
                        <div class="con">{{ item.title }}</div>
                    </div>
                    <div v-else class="item-left" style="width: 100%;" @click="handshow(index)">{{ $t('con.choose_resource') }}</div>
                    <div class="item-right"></div>
                </div>
               
            </div>
            <div class="add" @click="handleAdd">+{{ $t('con.add_service') }}</div>
            <div class="btn-wrap flex-row-center-between">
                <div class="btn" @click="sub">{{ $t('con.sub') }}</div>
                <div class="btn diff" @click="handleClose">{{ $t('con.cancel') }}</div>
            </div>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
        <ViewSelect :show="shows"  @handleChoose="(event) => handleChoose(event)" @handleClose="shows=false" />
    </div>
</template>
<script>
import ViewSelect from './ViewSelect.vue'
export default {
    components: { ViewSelect },
    props: ["show"],
    data() {
        return {
            flag: true,
            shows: false,
            list: [],
            index: -1,
        };
    },
    methods: {
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        sub() {
            let ids = this.list.map(item => item.id)
            this.$emit('sub', ids)
        },
        //选择已有资源
        handleChoose(item) {
            this.list[this.index] = { ...this.list[this.index], ...item }
            this.shows = false
        },
        //打开下拉框
        handshow(index) {
            this.shows = !this.shows
            this.index = index
        },
        //新增
        handleAdd(){
            this.list.push({})
        }
    }

};
</script>
<style scoped lang="scss">
.apply {
    .tip {
        font-size: 0.16rem;
        color: #828282;
        text-align: center;
    }

    .label {
        margin-top: 0.3rem;
        color: #262423;
        font-size: 0.18rem;
    }

    .wrap {
        width: 100%;
        height: auto;
        background: #FCFCFC;
        border-radius: 0.04rem;
        padding: 0 0.24rem;
        box-sizing: border-box;
        margin-top: 0.2rem;

        .item {
            position: relative;
            height: 0.6rem;
            background: #FCFCFC;
            border-radius: 0.04rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            width: 100%;
            cursor: pointer;

            .item-left {

                .tit {
                    color: #02428C;
                }

                .mark {
                    width: 0.5rem;
                    height: 0.27rem;
                    background: #FF580F;
                    border-radius: 0.14rem;
                    font-size: 0.14rem;
                    text-align: center;
                    line-height: 0.27rem;
                    color: #FFFFFF;
                    margin: 0 0.06rem;
                }

                .con {

                    color: #262423;

                }
            }

            .item-right {
                width: 0;
                height: 0;
                border-left: 0.06rem solid transparent;
                border-right: 0.06rem solid transparent;
                border-top: 0.06rem solid #8B8B8B;

            }

        }
    }

    .add {
        color: #02428C;
        font-size: 0.18rem;
        margin-top: 0.3rem;
        cursor: pointer;
    }

    .btn-wrap {
        width: 5.3rem;
        margin: 0.6rem auto 0.1rem;

        .btn {
            width: 2.1rem;
            height: 0.52rem;
            background: #02428C;
            border-radius: 0.04rem;
            text-align: center;
            line-height: 0.52rem;
            cursor: pointer;
            font-size: 0.16rem;
            color: #ffffff;
            border: 1px solid #02428C
        }

        .diff {
            border: 1px solid #979797;
            color: #848484;
            background-color: #ffffff;

        }


    }

}
</style>
  