import Login from '../views/auth/login.vue'//登录
import Forget from '../views/auth/forget.vue'//忘记密码
import ImproveInfo from '../views/auth/improveInfo.vue'
import Focus from '../views/auth/focus.vue'
import Agreement from '../views/auth/agreement.vue'
export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
    },
  },
  {
    path: '/forget',
    name: 'Forget',
    component: Forget,
    meta: {
      title: '忘记密码',
    },
  },

  {
    path: '/improveInfo',
    name: 'ImproveInfo',
    component: ImproveInfo,
    meta: {
      title: '新用户信息',
    },
  },
  {
    path: '/focus',
    name: 'Focus',
    component: Focus,
    meta: {
      title: '推荐关注',
    },
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement,
    meta: {
      title: '服务条款',
    },
  },



  
]
