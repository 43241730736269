
module.exports = {
    language: {
        zh: '中文',
        en: 'English'
    },
    //首页:
    index: {
        bgVisible:'Only visible in the background',
        relation:'Reliable relationship',
        reliable_relationship:'Please briefly discribe the reliable relationship between you and this social capital. Only visible in the background',
        biao:'Global Social Capital',
        home: 'Home',
        shareTip: 'Share and add value to your social capital',
        tip: "You haven't logged in yet and can't post updates",
        recommend: 'recommend',
        focus: 'follow',
        focused: 'Followed',
        share: 'share',
        tip2: 'You can only post comments after logging in',
        no_data: 'There is currently no content available',
        tit1: 'Associated Contents',
        feed: 'Moments',
        task: 'Task',
        activity: 'Events',
        user: 'user',
        search: 'industrial resources available upon your search',
        publish_task: '+Set Up Task',
        tip3: 'Please enter search content',
        login_reg: 'Login/Register',
        logout: 'Log out of login',
        my_member: 'My Social Capital',
        my_resource: 'My Capital',
        my_page: 'My Page',
        my_friends:'My Partners',
        friend:'partner',
        login:'log on',
        reg:'register',
        no_limit:'unlimited',
        label:'Resource tags',
        placeholder:'Please label the resources for easy search, with # starting at the interval',
        sec1:'Load more',
        sec2:'There is no more',
        sec3:'There is currently no data available',
        completed:'Completed',
        unCompleted:'incomplete',
        edit:'Edit',
        del:'Delete'

    },
    //任务模块
    task: {
        tasked:'Task status: Task completed',
        confirm_finish:'Confirm completion',
        search: 'Search Task',
        status0: 'Waiting for system review',
        status1: 'Waiting for order reception',
        status2: 'Order received',
        status3: 'Task in progress',
        status4: 'Revoked',
        status5: 'Rejected',
        status6: 'Approved',
        status7: 'Approval failed',
        content: 'Task content',
        deliverable: 'Main deliverables',
        category: 'Task type',
        budget: 'Budget',
        due_time: 'Delivery time',
        country: 'Target country',
        front: 'Before',
        city: 'Target city',
        industry: 'Target industry',
        label: 'Topic tags',
        end_time: 'Information validity time',
        task_user: 'Order applicants',
        apply: 'I want to take the order',
        apply2: 'I am willing to apply as a substitute',
        collect: 'Collect',
        collected: 'Collected',
        collect_task: 'Favorite task',
        my_task: 'My Task Management',
        look: 'View all tasks',
        lookAll: 'View all',
        collected_task: 'Favorite tasks',
        tip1: 'Unable to take order',
        hot: 'Popular',
        new: 'Latest',
        success: 'Successfully received the order',
        fail: 'Failed to receive order',
        publish: 'What I published',
        accept: 'What I took',
        tip2: 'Task status: Waiting for system review ',
        tip3: 'Task status:Application approved, waiting for matching order receiving users',
        task_status: 'Task status',
        taskno: 'Task number',
        publisher: 'Publisher',
        publishat: 'Published on',
        task_detail: 'Task details',
        cancel_apply: 'Cancel application',
        del: 'Delete',
        all: 'All',
        edit_task: 'Edit task',
        cancel_task: 'Cancel task',
        applyed: 'Applied',
        ing: 'In progress',
        completed: 'Task completed',
        cancel_success: 'Canceled successfully',
        cancel_fail: 'Cancel failed',
        del_success: 'Successfully deleted',
        del_fail: 'Delete failed',
        task_manage: 'Task management',
        tip4: 'The current task information has been successfully submitted',
        tip5: 'The platform will contact you in the near future to confirm the task details, please keep in touch',
        service_ing: 'Service in progress',
        task_info: 'Task information',
        tip6: 'The application has been raised up, waiting for confirmation from the platform and publisher',
        servicer_info: 'Service provider information',
        tip7: 'Waiting to applicants, no more information at the moment',
        tit1: 'Similar service providers',
        service_time: 'Service release time',
        view: 'View Home Page',
        contact: 'Contact the platform',
        step1: 'Set Up Task',
        step2: 'Waiting for platform review',
        step3: 'Matching services through review',
        step4: 'Matching successful, task under going',
        step5: 'Task payment fully settled',
        step6: 'Application received, waiting for platform review',
        step7: 'Successfully received order, service in progress',
        step8: 'The task has been confirmed complated by the owner',
        tip8:'Whether to confirm that the task is complete?',
        tit2:'Tips',
        cancel:'Cancel',
        confirm:'verify'
    },
    //活动模块
    activity: {
        search: 'Search Events',
        publish: 'Publish Event',
        date: 'Event time',
        address: 'Event location',
        link: 'Promotion link',
        to: 'to',
        document: 'Document',
        download_document: 'Download Document',
        apply: 'Apply',
        apply2: 'Anonymously join',
        collect_activity: 'Set favorite',
        send: 'Send',
        reply: 'Reply',
        reply_comment: 'Reply to comment',
        zan: 'Like it',
        member: 'Participants',
        apply3: 'Register',
        tip: 'Friendly comments are most welcome',
        apply_fail: 'Register failed',
        tip2: 'The comment is too short',
        publish_success: 'Successfully published',
        status0: 'Pending review',
        status2: 'Ended',
        num: 'Number of participants',
        activity_num: 'Number of participants',
        joined: 'Participated',
        apply_join: 'Apply to join',
        tip3: 'You have participated in this activity',
    },
    auth: {
        activity_success:'Successfully published the event',
        notice:'Application has been submitted, please wait',
        tit1: 'User Service Terms',
        tit2: 'ThinkDock Service Terms',
        tit3: 'Privacy Guidelines',
        tit4: 'Potential users for your interests',
        tip1: 'Like it, follow it',
        nullName: 'Currently no nickname',
        nulldesc: 'No introduction yet',
        tit5: 'Potential spaces for your interests',
        tip2: 'Connect out of your box',
        joined: 'Joined',
        join: 'Join directly',
        skip: 'Skip',
        next: 'Next',
        ok: 'Selected',
        findPwd: 'Retrieve password',
        tip3: 'The verification code will be sent to your registered phone number or email',
        phone: 'Phone number',
        email: 'Email',
        code: 'Verification code',
        tip4: 'The verification code has been sent to your registration',
        placeholder1: 'Please enter your mobile number',
        placrholder2: 'Please enter email',
        placrholder3: 'Please enter your registered mobile number or email address',
        modify: 'Modify',
        placeholder4: 'Please enter the 6-digit verification code',
        set_newpwd: 'Set new password',
        set_pwd: 'Successfully verified, please set your login password',
        confirm_pwd: 'Verify your new password',
        sure: 'Confirm',
        operate_success: 'Operation succeeded',
        operate_fail: 'Operation failed',
        tip5: 'Please enter your new password',
        tip6: 'Please verify your new password',
        send_success: 'Successfully sent',
        tip7: 'Please enter the verification code',
        tip8: 'Please enter your mobile/email',
        tip9: 'Please enter the correct mobile/email',
        second: 'seconds',
        get_code: 'Obtain verification code',
        nick_name: 'User Nickname',
        placeholder5: 'Give yourself a nice nickname',
        job: 'Occupation (optional)',
        placeholder6: 'Enter your profession',
        introduce: 'Introduction (optional)',
        placeholder7: 'Introduce yourself',
        submit: 'Submit',
        tip10: 'Nickname cannot be empty',
        tip11: 'Please upload your avatar',
        option1: 'Email verification code login',
        option2: 'Login with mobile verification code',
        option3: 'Password login',
        placeholder8: 'Please enter the password',
        forget_pwd: 'Forgot password',
        login_reg: 'Login/Register',
        remember: 'Remember me',
        tit12: 'Unregistered mobile phone/email will automatically login after verification , register means consent',
        tit6: 'Quick Login',
        way1: 'Weibo',
        way2: 'WeChat',
        way3: 'Google',
        way4: 'LinkedIn',
        tip13: 'Not configured yet',
        tip14: 'Incorrect email format',
        tip15: 'Incorrect phone number format',
        tip16: 'User name cannot be empty',
        tip17: 'Password cannot be empty',
        tip18: 'Verification code cannot be empty',
        login_success: 'Login successful',
        login_fail: 'Login failed',
        placeholder9:'Please enter your phone number/email address',
        tip19:'The phone number has been successfully verified and cannot be modified temporarily',
        tip20:'The information validity time should be less than the delivery time'
    },
    //圈子
    circle: {
        apply_join3:'join',
        feed_detail: 'Moments',
        source_detail: 'Resources',
        export: 'Expert',
        organ: 'Organisation',
        main_character: 'Main roles',
        other_character: 'Other roles',
        level: 'Resource level',
        apply_service: 'Request service',
        collect: 'Collect to Wish List',
        source_info: 'Resource Details',
        orgname: 'Organisation name',
        country: 'Country',
        city: 'The city where the headquarters is located',
        category: 'Resource type',
        other_city: 'Business Extension City',
        industry: 'Industry',
        introduction: 'Resource Introduction',
        background: 'Main areas',
        main_field: 'recent developments',
        achievement: 'historical achievements',
        label: 'Resource tags',
        link: 'Resource outer chain',
        link_feed: 'Related moments',
        look: 'View the original text',
        comment: 'Comment',
        look_more: 'View more',
        like_source: 'Similar resources',
        no_apply: 'Unable to apply for service',
        apply_fail: 'Application failed',
        nomore: 'No more',
        collect_success: 'Collection succeeded',
        cancel_collect: 'Successfully cancelled collection',
        focus_success: 'Successfully followed',
        cancel_success: 'Unfollowing succeeded',
        tit1: 'The space you joined',
        create_circle: 'Create a space',
        creater: 'Created by',
        member: 'Member',
        search: 'Search space',
        apply_join: 'Apply to join',
        circle_creater: 'Space creator',
        circle_type: 'Space type',
        label: 'Space tags',
        created_at: 'Creation time',
        edit_bg: 'Replace background',
        share: 'Share to invite ',
        edit_circle: 'Edit space',
        tip1: 'Resource? It is for sharing',
        circle_member: 'Space members',
        look_all: 'View all members',
        enter: 'Join',
        circle_score: 'Space Scoreboard',
        point: 'Points',
        my: 'My',
        del_circle: 'Quit the space',
        feed_list: 'Moments',
        source: 'Social capitals',
        tip2: 'There is currently no member in this space',
        tit2: 'Top Capitals',
        tit3: 'Management the top capitals',
        top: 'Top his/her resources',
        to_top: 'Top',
        cancel_top: 'Cancel topping',
        tip3:'Only the space owner has permission to top it'
    },
    //发布
    feed: {
        activity_placeholder:'Event type cannot be empty',
        edit_feed:'Edit moment',
        tit1: 'Please fill in the event information carefully to ensure its authenticity and validity',
        activity_name: 'Event Name',
        placeholder1: 'Please enter the event name',
        activity_category: 'Event type',
        placeholder2: 'Please select an event type',
        activity_cover: 'Event cover',
        activity_detail: 'Event details',
        placeholder3: 'Please provide a detailed description of the event you want to publish',
        placeholder4: 'Please enter the maximum number of people for your event',
        placeholder5: 'Select country',
        placeholder6: 'Select province',
        placeholder7: 'Select City',
        placeholder8: 'Please enter your detailed address for this event',
        placeholder9: 'Select the start time of the event',
        placeholder10: 'Select end time',
        activity_label: 'Event tags',
        placeholder11: 'Please tag your event for easy search, starting with #',
        activity_file: 'Event file',
        tip1: 'Please ensure that the text in the file is clear and visible, supports jpg png jpeg pdf, and the file size does not exceed 5MB',
        placeholder12: 'Please enter the promoting platform and theme',
        placeholder13: 'Please add the link of your promotion',
        add_link: 'Continue to add promotion links',
        confirm_publish: 'Confirm to publish',
        msg1: 'Please upload the event cover',
        msg2: 'Please enter event details',
        msg3: 'Please enter the limited number of participants to the event',
        msg4: 'Please enter the event location',
        msg5: 'Please select an event time',
        msg6: 'Please enter the event tags',
        tip2: 'The end time cannot be less than the event start time',
        tip3: 'Upload at most one image, please delete and then upload again!',
        tip4: 'Upload up to 9 images, please delete and then upload again!',
        edit: 'Edit',
        add: 'Create',
        circle_name: 'Space Name',
        placeholder14: 'Please enter the name of the space',
        placeholder15: 'Please select the industry type of the space',
        placeholder16: 'Please tag the circle to facilitate search, with # starting interval',
        circle_avatar: 'Space avatar',
        circle_cover: 'Space cover',
        circle_desc: 'Introduction to the space',
        placeholder17: 'Please describe the characteristics of your space',
        join_type: 'Joining method',
        placeholder18: 'Please select the joining method for the space',
        option1: 'All users',
        option2: 'Blue V authentication',
        option3: 'City Advisor',
        option4: 'City Partner',
        add_question: 'Add application question',
        question: 'Question',
        placeholder19: 'Please set the application question',
        adder_question: 'Continue adding application questions',
        sure: 'OK',
        msg7: 'Please upload the space cover',
        msg8: 'Please upload a space avatar',
        msg9: 'Please enter a description of the space',
        msg10: "Please select a space type",
        msg11: 'Please select the joining method',
        msg12: 'Please enter the space tag',
        loading: 'Loading',
        tip5: 'Upload up to 20 images, please delete and then upload again!',
        circle: 'Space',
        tit2: 'Task set up successfully',
        tip6: 'The task has been submitted to the platform for review. During this period, the platform may contact you. Please keep in touch',
        know: 'Got it',
        task_step: 'Task process',
        task_title: 'Task title',
        placeholder20: 'Please briefly describe your task',
        placeholder21: 'Please select a task type',
        placeholder22: 'Please provide a detailed description of your task requirements, as the more detailed it is, the easier it is to accurately match with the service provider.',
        budget: 'Task budget',
        placeholder23: 'Please enter your task budget, for example: 100k to 200k',
        currency: 'Please select a currency unit',
        placeholder24: 'Please select a task delivery time',
        end_time: 'Information validity time (need to be republished if expired)',
        placeholder25: 'Select information validity time',
        deliverable: 'Main deliverables (optional)',
        placeholder26: 'The clearer the description of the deliverables, the easier it is to get response',
        task_label: 'Task tags',
        placeholder27: 'Please tag the task for easy search, with # starting interval',
        country2: 'Target country',
        add_country2: 'Add more target countries',
        add_city: 'Add more target cities',
        confirm_publish: 'Confirm to publish',
        confirm_edit: 'Confirm modification',
        add_industry: 'Add more target industries',
        placeholder28: 'Select industry',
        msg13: 'Please enter the task title',
        msg14: 'Please enter task content',
        msg15: 'Please enter the task budget range',
        msg16: 'Please select a currency type',
        msg17: 'Please select a task delivery time',
        msg18: 'Task effective deadline',
        msg19: 'Please enter task tags'
    },
    //个人中心
    personal: {
        user_service_secret:'User Service Terms',
        my_joined:'I joined',
        my_create:'I created',
        msg_notice: 'Message notification',
        all_read: 'All read',
        leave_msg: 'Leave a message',
        agree: 'Agree',
        refuse: 'Refuse',
        no_msg: 'No message currently',
        placeholder1: 'Please enter the reason for rejection',
        all_msg: 'All messages',
        feed_msg: 'Moment Messages',
        group_msg: 'Space Messages',
        task_msg: 'Task Messages',
        activity_msg: 'Event messages',
        system_msg: 'System messages',
        tip1: 'Please enter the reason for rejection',
        my_point: 'My points',
        point_tip: 'Note for Points',
        point_balance: 'Point balance',
        tit22: 'Daily active tasks',
        desc1: 'Completing the corresponding tasks will earn points',
        point_detail: 'My points details',
        choose: 'Choose a dimension',
        type: 'Select dimension',
        deal: 'Deal',
        active: 'Active',
        promotion: 'Promotion',
        business: 'Lead',
        point_source: 'Point source',
        time: 'Time',
        ip: 'IP location',
        fen: 'Followers',
        edit_user: 'Edit profile',
        country: 'Advantageous Country',
        city: 'Advantageous City',
        industry: 'Advantageous industries',
        label: 'Advantageous tags',
        my_income: 'My income',
        look_detail: 'View income details',
        income: 'Direct income',
        fuxing_income: 'Fortune Star Income',
        bole_income: 'Bole income',
        rank: 'Points ladder list',
        tit1: 'The space he joined',
        tit2: 'People have joined',
        users: 'Social capitals',
        honor: 'Honor',
        tit3: 'Direct Income Details',
        tit4: 'Bole Income Details',
        tit5: 'Fortune Star Income Details',
        tit6: 'Direct income',
        tit7: 'Indirect income',
        income_resource: 'Project name of the income source',
        project_resource: 'Project resources',
        project_user: 'Agent',
        recommend_income: 'Referal income',
        bole: 'Bole',
        fuxing: 'Fortune Star',
        incomes: 'Income',
        project_customers: 'Project publisher',
        complete_time: 'Project completion time',
        look_task: 'View original task',
        income_detail: 'Income details',
        detail: 'Details',
        certificate: 'Real name authentication',
        modify_avatar: 'Modify avatar',
        introduction: 'Profile',
        placeholder2: 'Add your advantageous tags for easy search, with # starting interval',
        my_collect: 'My Collection',
        tip2: 'After submitting the materials, it will take approximately three working days for review. Please be patient and keep in touch.',
        apply_type1: 'Individual',
        apply_type2: 'Institution',
        certificate_type: 'Document type',
        placeholder3: 'Please select your ID type',
        certificate_type1: 'ID card',
        certificate_type2: 'Passport',
        certificate_type3: 'Driver s license',
        user_name: 'Legal Name',
        placeholder4: 'Please enter your legal name',
        certificate_number: 'ID number',
        placeholder5: 'Please enter your ID number',
        certificate_img: 'ID photo',
        tip3: 'Please ensure that the text on the image is clear and visible. The image supports jpg png jpeg and the file size does not exceed 5MB',
        placeholder6: 'Please enter the legal institution name',
        business_license: 'Institutional Business License',
        legal_person: "Legal representative's name",
        placeholder7: 'Please enter the legal name of the legal representative',
        legal_person_img: 'Corporate ID photo',
        tax_info: 'Invoice information',
        tax_number: 'Invoice tax number',
        placeholder8: 'Please enter the invoice tax number',
        tit8: 'Bank account information',
        tip4: 'For account payment settlement, please fill it out carefully',
        bank_account_holder: 'Full name of the account holder',
        placeholder9: 'Please enter the full name of the account holder',
        bank_account: 'Bank account number',
        placeholder10: 'Please enter your bank card account number',
        bank_name: 'Name of the Opening Bank',
        placeholder11: 'Please enter the name of the account bank',
        bank_account_proof: 'Bank account proof or statement',
        contact: 'Contact information',
        contact_name: 'Contact Person',
        placeholder12: 'Please enter the contact name',
        position: 'Contact position',
        placeholder13: 'Please enter the contact person title',
        phone_number: 'Contact number',
        placeholder14: 'Please enter the contact person s phone number',
        sms_code: 'Mobile verification code',
        confirm_vertify: 'Confirm verification',
        email: 'Email',
        mail_code: 'Email verification code',
        placeholder15: 'Please enter the contact email',
        zip_code: 'Post Code',
        email_address: 'Mailing address',
        placeholder16: 'Please enter the post code',
        placeholder17: 'Please enter detailed physical mailing address',
        readed: 'I have read and agree',
        submit: 'Submit for authentication',
        msg1: 'Please select an authentication type',
        msg2: 'Please select the required document type for application',
        msg3: 'Please enter your legal name',
        msg4: 'Please enter your ID number',
        msg5: 'Please upload ID photo',
        msg6: 'Please enter the institution name',
        msg7: 'Please upload the business license image',
        msg8: 'Please enter the name of legal representative',
        msg9: 'Please upload a photo of the legal representative s ID',
        msg10: 'Please enter the invoicing tax number',
        msg11: 'Please enter the name of bank account holder',
        msg12: 'Please enter a bank account number',
        msg13: 'Please enter the name of the opening bank',
        msg14: 'Bank account proof cannot be empty',
        msg15: 'Please enter the contact name',
        msg16: 'Please enter the contact title',
        msg17: 'Please enter your mobile phone number with country code',
        msg18: 'Please enter a post code',
        msg19: 'Please enter detailed physical mailing address',
        msg20: 'Please choose whether to agree to the users terms',
        msg21: 'Please agree to the users terms ',
        cancel_collect: 'Cancel Collection',
        status0: 'Waiting for review',
        status1: 'Passed',
        placeholder18: 'Please select a resource type',
        search_resource: 'Search for resources',
        view_detail: 'View details',
        checked_all: 'Select All',
        customer_service: 'Customer service contact information',
        sec: 'Display only selected',
        add_resource: 'Add new resource',
        tit9: 'Points Radar Map',
        your_point: 'Your points',
        average: 'User average points',
        certification_level: 'Certification level',
        current_level: 'Current level',
        current_level1: 'The current level is',
        current_level2: 'Not far from the next level',
        score: 'points',
        tit10: 'Exclusive Rights',
        tit11: 'Enjoy higher rates or shares in tasks',
        tit12: 'Priority for receiving orders',
        tit13: 'Priority for resource & service push',
        tit14: 'Rules for Certification Levels',
        placeholder19: 'Select country',
        my_rank: 'My ranking',
        has_date: 'Obtained time',
        lock: 'Not unlocked yet',
        focus_list: 'Follow list',
        cancel_focus: 'Cancel following',
        focus2: 'Mutual Follow',
        copy: 'Copy',
        scan: 'Scan to add official WeChat',
        copy_success: 'Successfully copied',
        bole_recommend: 'Swift Horse(Recommended as Bole)',
        fx_recommend: 'Lucky Horse(Recommended as Fortune Star)',
        my_join: 'I participated in',
        all: 'All',
        mark1: 'Authentication passed',
        mark0: 'Under review',
        mark2: 'Approval failed'
    },
    //组件
    con: {
        placeholder1: 'Search for existing social capitals',
        choose: 'Choose',
        operate: 'Select existing social capitals',
        placeholder2: 'Please select a city',
        hot_resource: 'Popular resources',
        no_tit: 'No title currently',
        tab1:'About ThinkDock',
        tab2:'Business Partners',
        tab3:'Service Terms',
        tab4:' Privacy Policy',
        tab5:'Cases',
        tab6:'Business',
        tab7:'Media',
        tab8:'Ads',
        tit1: 'You can share the QR code or invite new users to register and become his/her Bole to receive a share of social capital income. A Fortune Star, as a Bole to Bole, could also get a share after those users get project income!',
        tit2: 'Share invitation through link',
        tit3: 'Sharing: Running "Global Social Capital", ThinkDock can not only help you save and monetize social capital, but also serve you to connect with industry experts or institutional resources. I recommend joining ThinkDock to add value to your social capital! Please click on this link to register',
        tit4: 'Copy and share',
        tit5: 'Share through QR code',
        tit6: 'Scan the QR code directly on your phone to register on the website',
        know: 'Got it',
        hot_user: 'Popular users',
        activity_member: 'Event members',
        publish_resource: 'Publish resources',
        resource_img: 'Resource image',
        resource_tit: 'Resource Title',
        placeholder3: 'Please enter the resource title',
        placeholder4: 'Please enter the real institution name',
        placeholder5: 'Please enter the primary role',
        placeholder6: 'Please enter other roles',
        placeholder7: 'Please select the country where the institution belongs',
        placeholder8: 'Please select the HQ province',
        placeholder9: 'Please select the HQ city',
        other_city: 'Extended Cities',
        placeholder10: 'Please enter business extension cities, with spaces between individual cities',
        continue_industry: 'Continue to add your industry',
        placeholder11: 'Please briefly introduce this resource, limited to 250 words',
        placeholder12:'Please introduce the main areas and businesses that the institution is engaged in',
        placeholder13:'Please introduce the recent achievements of the organization',
        placeholder14:'Please enter detailed historical achievement information of the institution',
        placeholder15: 'Add external link theme',
        placeholder16: 'Add external link URL',
        add_resource_link: 'Add more resource info links',
        placeholder17: 'Please tag the resource for easy search, with # starting interval',
        publish: 'Publish',
        msg1: 'Please upload resource images',
        publish_fail: 'Publish failed',
        join_apply: 'Space application',
        optional: 'Optional',
        placeholder17: 'Tell me why you want to join',
        apply_order: 'Apply to take the order',
        tit7: 'Apply the order first, wait for reviews from the platform and the task owner',
        tit8: 'What resource services can you provide',
        choose_resource: 'Please select a resource',
        add_service: 'Continue adding resource & services',
        sub: 'Submit',
        cancel: 'Cancel',
        tit9: 'You have not yet become a certified blue V',
        tit10: 'In order to ensure the rights and interests of both parties, please first authenticate as a blue V before applying for services',
        to_certificate: 'Go for authentication',
        next: 'Next time',
        tit11: 'Successfully registered for the event, remember to attend on time',
        group_user: 'Space member',
        delete: 'Kick out',
        knows: 'Understood',
        guide_tit1: 'Connect out of the box',
        guide_desc1: 'If you have any industrial resource needs, you can click here to set up a task to seek them.',
        guide_tit2: 'Post moments',
        guide_desc2: 'You can share industrial resources to get your social capital gains.',
        guide_tit3: 'Collect Wish List',
        guide_desc3: 'If you have any favorite moments, tasks, resources, etc., you can collect them here first',
        guide_tit4: 'Create a new space',
        guide_desc4: 'Share social capitals with friends',
        copy_success_tip: 'Successfully copied, share it with your friends!'


    },
    post: {
        publish_feed: 'Post moments',
        placeholder1: 'Please enter a moment title',
        add_img: 'Add image',
        add_video: 'Add Video',
        add_label: 'Add tag',
        placeholder2: 'Please tell me something interesting that happened',
        urls: 'Link',
        url_name: 'Add Link Theme',
        url_web: 'Add link URL',
        add_url: 'Add more links',
        choose_resource: 'Select an existing resource',
        del_resource: 'Delete resource',
        add_resource: 'Add new resource',
        choose_resource2: 'Please select a resource',
        add_resource2: 'Add existing resources',
        tip1: 'Moment title cannot be empty',
        tip2: 'Moment content cannot be empty',
        way1: 'Self addition',
        way2: 'Let platform to add',
        sure: 'Confirm',
        manage_group: 'Manage synchronization space',
        async: 'Automatically publish moments to my space',
        tip7:'Please enter a 6-18 digit password containing letters'
    },
    reg:{
        phone_reg:'Mobile phone registration',
        mail_reg:'Email registration',
        placeholder1:'Please set your password',
        placeholder2:'Please repeat your password',
        placeholder3:'Please enter email',
        placeholder4:'Please enter the verification code',
        tip1:'Please enter your phone number',
        tip2:'Please enter your email address',
        tip3:'The two passwords are different',
        tip4:'The verification code is incorrect',
        tip5:'Registered, please login',
        tip6:'Failure in registration',
        tip7:'Please enter a 6-18 character password with letters'
    },
    authentication:{
      code_error:'Verification code error'
    }
}
