<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>
<script>
import echarts from 'echarts'
import resize from '@/components/Charts/mixins/resize'

export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        title: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '800px'
        },
        height: {
            type: String,
            default: '400px'
        },
        userArr: {
            type: Array,
            default: () => []

        },
        averageArr: {
            type: Array,
            default: () => []

        },
        indicator: {
            type: Array,
            default: () => []

        }
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        indicator: {
            handler(val) {
                this.initChart()
                this.$forceUpdate()
            },
            deep: true, // 深度监听
            immediate: true, // 初次监听即执行  
        },
    },
    mounted() {
        // this.initChart()
        // this.$forceUpdate()
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
       
            this.chart = echarts.init(document.getElementById(this.id))
            var option = {
                title: {
                    text: this.$t('personal.tit9'),
                    left: 'center',
                    bottom: 0,
                },
                legend: {
                    data: [this.$t('personal.your_point'), this.$t('personal.average')],
                    left: 'right'
                },
                radar: {
                    indicator: this.indicator && this.indicator.length ? this.indicator : [
                        { name: this.$t('personal.deal'), value: '0', average: '0',max:600 },
                        { name: this.$t('personal.business'), value: '0', average: '0',max:600 },
                        { name: this.$t('personal.promotion'), value: '0', average: '0',max:600 },
                        { name: this.$t('personal.active'), value: '0', average: '0',max:600 },
                        { name: this.$t('circle.source'), value: '0', average: '0',max:600 },
                    ],
                    name: {
                        color: '#333',
                        backgroundColor:'#e9e9e9',
                        borderRadius: 2,//指示器圆角
                        padding: [3, 3, 3, 3],
                        formatter: (val) => {
                            let value, average;
                            this.indicator.map((item) => {
                                if (item.name == val) {
                                    value = item.value;
                                    average = item.average

                                }
                            });
                            return `${val}` + '(' + `{valueStyle|${value}}`+','+`{averageStyle|${average}}`+')'                       
                        },
                        rich:{
                            valueStyle:{
                                color: '#B34038'
                            },
                            averageStyle:{
                                color: '#334452'
                            }
                        }
                    },
                   
                },
                series: [
                    {
                        name: 'Budget vs spending',
                        type: 'radar',
                        symbol:'none',
                        data: [
                            {
                                value: this.userArr,
                                name: this.$t('personal.your_point')
                            },
                            {
                                value: this.averageArr,
                                name: this.$t('personal.average')
                            }
                        ],


                    },
                ]
            };
            this.chart.setOption(option)
        }
    }
}
</script>
  