//申请服务 实名认证
<template>
   
        <el-dialog title="" center :visible.sync="show" width="7rem" :before-close="handleClose">
            <div class="main">
            <div class="flex-col-center-center">
                <div class="title">{{ $t('con.tit9') }},{{ tip }}</div>
                <div class="desc">{{ $t('con.tit10') }}</div>
                <div class="btn-wrap flex-row-center-between">
                    <div class="btn" @click="handleSkip('/authentication')">{{ $t('con.to_certificate') }}</div>
                    <div class="btn diff" @click="handleClose">{{ $t('con.next') }}</div>
                </div>
                <span slot="footer" class="dialog-footer"></span>
            </div>
        </div>
        </el-dialog>
    
</template>
<script>
export default {
    props: ["show",'tip'],
    data() {
        return {
        };
    },
    methods: {
        //关闭
        handleClose() {
            this.$emit('handleClose')
        },
        //跳转
        handleSkip(url){
            this.$router.push(url)
            this.$emit('handleClose')
        }
    }

};
</script>
<style scoped lang="scss">
.main {
    width: 100%;
    height: 2.8rem;


    .title {

        color: #363636;
        margin-top: 0.3rem;
        font-size: 0.22rem;

    }

    .desc {
        margin-top: 0.2rem;
        color: #363636;
        font-size: 0.16rem;
        width: 3.66rem;
        height: 0.58rem;
        text-align: center;
        line-height: 0.24rem;

    }

    .btn-wrap {
        margin: 0.4rem auto 0;
        width: 5rem;

        .btn {
            width: 2.2rem;
            height: 0.52rem;
            background: #5077D5;
            border-radius: 0.04rem;
            text-align: center;
            line-height: 0.52rem;
            cursor: pointer;
            color: #ffffff;
            font-size: 0.16rem;
            border: 1px solid #5077D5;
        }

        .diff {
            border: 1px solid #979797;
            background-color: #ffffff;
            color: #979797;

        }



    }



}
</style>
  