<template>
    <el-dialog title="选择资源" :visible.sync="show" width="50%" :before-close="handleClose">
   
    <div class="floor2" >
        <div class="search flex-row-center-between">
            <img src="@/assets/icons/search.png" />
            <input :placeholder="$t('con.placeholder1')"  v-model="params.search" @keyup.enter="getList(true)"/>
        </div>
        <div v-if="list&&list.length">
            <div class="item flex-row-center-between" v-for="(item,index) in list" :key="item">
            <div class="item-left flex-row-center">
                <div class="tit">{{$t('circle.source')}} {{ index + 1 }}:</div>
                <div class="mark">{{ item.type==2?   $t('circle.organ') :   $t('circle.export')  }}</div>
                <div class="con">{{ item.title }} </div>
            </div>
            <div class="item-right" @click="handleChoose(item)">{{ $t('con.choose') }}</div>
        </div>
        </div>
        <el-empty v-else/>
        <div class="choose" @click="handleClick">{{ $t('con.operate') }}</div>
    </div>
</el-dialog>
</template>
<script>
export default {
    props:['show'],
    data() {
        return {
            list:[],
            params:{
                search:'',
                has:0,
                user_id:this.$storage.getData("userInfo")?this.$storage.getData("userInfo").id:''
            },
          

        }
    },
    created(){
        this.getList()

    },
    methods:{
        //获取资源列表
        getList(flag){
            if(flag) this.params.has = 0
            this.$api.get('/api/resource',{params:this.params}).then((res)=>{
               this.list = res.data.data
            })
        },
        //选择已有资源
        handleClick(){
            this.params.has = 1
            this.getList()
        },
        //选择
        handleChoose(item){
            console.log(item)
            this.$emit('handleChoose',item)
        } ,
        handleClose(){
            this.$emit('handleClose')
        }       
    }
}
</script>
<style lang="scss" scoped>
.floor2 {
    // position: absolute;
    // left: 0;
    // top: 0.3rem;
    width: 100%;
    min-height:50%;
background: #FCFCFC;
    padding: 0rem 0.3rem;
    box-sizing: border-box;
    // z-index: 999;
    .item {
        width: 100%;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.16rem;
        border-bottom: 1px solid #EAEAEA;
    }

    .item-right {
        width: 0.69rem;
        height: 0.32rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #02428C;
        text-align: center;
        line-height: 0.32rem;
        cursor: pointer;
        font-size: 0.16rem;
        color: #02428C;

    }

    .tit {
        color: #02428C;
    }

    .mark {
        width: 0.5rem;
        height: 0.27rem;
        background: #FF580F;
        border-radius: 0.14rem;
        font-size: 0.14rem;
        text-align: center;
        line-height: 0.27rem;
        color: #FFFFFF;
        margin: 0 0.06rem;
    }

    .con {

        color: #262423;

    }

    .search {
        height: 0.5rem;
        background: #FFFFFF;
        border-radius: 0.04rem;
        border: 0.01rem solid #DCDCDC;
        cursor: pointer;
        padding: 0 0.2rem;

        img {
            width: 0.2rem;
            margin-right: 0.03rem;
        }

        input {
            flex: 1;
            outline: none;
            border: none;
        }

    }

    .choose {
        cursor: pointer;
        font-size: 0.16rem;
        color: #02428C;
        cursor: pointer;
        line-height: 0.6rem;
        text-align: center;
    }


}
</style>