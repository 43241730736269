//活动报名成功
<template>
    <div class="main">
        <el-dialog title="" center :visible.sync="show" width="7rem" :before-close="handleClose">
            <div class="flex-col-center-center">
                <img src="@/assets/icons/gou.png" />
            <div class="title">{{ $t('con.tit11') }}</div>
            <div class="btn" @click="handleClose">{{ $t('feed.know') }}
            </div>
            <span slot="footer" class="dialog-footer"></span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["show"],
    data() {
        return {
        };
    },
    methods: {
        //关闭
        handleClose() {
            this.$emit('handleClose')
        }
    }

};
</script>
<style scoped lang="scss">
.main {
    width: 100%;
    height: 4rem;


    img {
        width: 0.8rem;
    }

    .title {

        color: #363636;
        margin-top: 0.3rem;
        font-size: 0.22rem;

    }

    .desc {
        margin-top: 0.2rem;
        color: #363636;
        font-size: 0.16rem;

    }

    .btn {
        width: 2.3rem;
        height: 0.54rem;
        text-align: center;
        line-height: 0.54rem;
        margin-top: 0.6rem;
        cursor: pointer;
        background-color: #02428C;
        color: #ffffff;
        font-size: 0.16rem;
        border-radius: 0.06rem;


    }

}
</style>
  