<template>
  <div class="card">
    <div class="top-info flex-row-center-between">
      <Avatar :size="size" :showName="true" :user="card.user" width="7rem" />
      <div class="el-icon-edit icon" v-if="card.user && card.user.id == userId && !isHome" @click="handleEdit(card)">{{ $t('index.edit') }}</div>
      <div class="el-icon-delete icon" v-if="card.user && card.user.id == userId && !isHome" @click="handleDel(card.id)">{{ $t('index.del') }}</div>
    </div>
    <div class="content" :id="'site' + card.id">
      <div>
        <div @click="handleSkip(`/feedDetail?id=${card.id}`)" class="other">
          {{ card.title }}
        </div>
        <div class="feeds-content" ref="detailDom" @click="handleSkip(`/feedDetail?id=${card.id}`)">
            {{ card.content }}
          <span class="topic" v-for="(item, i) in card.label" :key="i"
            @click.stop="handleLabel(item.label, item.id)">
            {{ item.label }}
          </span>
        </div>
        <div class="link" v-if="card.urls && card.urls.length && card.urls[0].name">
          <a class="link-item flex-row-center" v-for="(item, index) in card.urls" :key="index" :href="item.url"
            target="_blank">
            <img src="@/assets/icons/www.png" />
            <div class="link-name">{{ item.name }}</div>
          </a>
        </div>
        <div v-if="card.image && card.image.length">
          <ImageContainer :images="card.image" />
        </div>
        <div class="video-wrap" v-if="card.video">
          <div class="cover">
            <el-image style="width: 6.36rem; height: 3.59rem" :src="card.video_cover"
              v-if="card.show" fit="cover"></el-image>
            <img :src="require(`@/assets/icon/play.png`)" alt="" class="play-wrap" @click="handlePlay(card.show)"
              v-if="card.show" />
            <video v-else style="width: 6.36rem; outline: none; height: 3.59rem" :controls="true" :poster="card.video_cover"
              :src="card.video" ref="videoRef"></video>
          </div>
        </div>
      </div>
    </div>
    <div class="bg" v-if="card.resource.length">
      <div class="source-tit flex-row-center">
        <img src="@/assets/icons/list.png" />
        <div class="title">{{ $t('circle.source') }}（{{ card.resource.length }}）</div>
      </div>
      <ul class="source-ul flex-row-wrap">
        <li class="flex-row-center" v-for="(item, index) in card.resource" :key="index"
          @click="handleSkip(`/sourceDetail?id=${item.id}`)">
          <img :src="item.image[0]" v-if="item.image && item.image.length" />
          <img v-else />
          <div class="info">
            <div class="x-ell-1 tit">{{ item.title }}</div>
            <div class="flexr-row-center-between">
              <img class="star" src="@/assets/icons/star.png" v-for="itmet in item.level"  :key="itmet" />
              <div class="mark" v-if="item.type == 1">{{ $t('circle.export') }}</div>
              <div class="mark diff" v-if="item.type == 2">{{ $t('circle.organ') }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="flex-row">
      <div class="time">{{ $t('task.publishat') }}{{ card.created_at }}</div>
    </div>
    <div class="bottom-info">
      <div class="flex-row">
        <div class="flex-row-center pointer mr" @click="handleZan(card.has_like, card.id)">
          <img :src="require(`@/assets/icons/zan${card.has_like == 1 ? 'ed' : ''}.png`)
            " />
          <span v-if="card.like_count">{{ card.like_count }}</span>
        </div>
        <div class="flex-row-center pointer mr" @click="handleSkip(`/feedDetail?id=${card.id}`)">
          <img :src="require(`@/assets/icons/comment.png`)" />
          <span v-if="card.comment_count">{{ card.comment_count }}</span>
        </div>
        <div class="flex-row-center pointer mr" @click="handleShare">
          <img :src="require(`@/assets/icons/fenxiang.png`)" />
          <span>{{ $t('index.share') }}</span>
        </div>
        <div class="flex-row-center pointer mr" @click="handleCollect(card.has_collect, card.id)">
          <img :src="require(`@/assets/icons/heart${card.has_collect ? 'ed' : ''}.png`)
            " />
          <span>{{ $t('task.collect') }}</span>
        </div>
      </div>
    </div>
    <!-- 分享弹窗 -->
    <SharePopup :show="visible" @handleClose="visible = false" />
    <!-- 编辑动态 -->
    <EditFeed :params="form" :show="show" @handleClose="handleClose" />
  </div>
</template>
<script>
import ImageContainer from "@/components/ImageContainer";
import SharePopup from '@/components/SharePopup'
import EditFeed from '@/components/EditFeed'
import {copyData} from '@/utils/copy.js'
import Avatar from "./Avatar";

export default {
  components: {
    ImageContainer,
    SharePopup,
    EditFeed,
    Avatar,
  },
  props: ["size", "card", 'isHome'],
  name: "FeedsCard",
  data() {
    return {
      userId: this.$storage.getData("userInfo") ? this.$storage.getData("userInfo").id : null,//当前用户id
      visible: false,
      show:false,
      form:{}

    };
  },
  created() {
    if (this.card) {
      this.$set(this.card, 'show', true)
      // if (this.card && this.card.content && this.card.content.length) {
      //   let arr = this.card.content.split('#')
      //   this.$set(this.card, 'con', arr[0])
      //   if (arr && arr.length && arr.length >= 2) {
      //     let newArr = []
      //     arr.forEach((item, index) => {
      //       if (index != 0) {
      //         newArr.push(item)
      //       }

      //     })
      //     this.$set(this.card, 'label', `#${newArr.join('#')}`)
      //   }

      // }
    }
  },
  methods: {
    //编辑动态
    handleEdit(card){
      this.form = card
      this.show  = true
    },
    //关闭弹窗
    handleClose(){
      this.show = false;
      this.$emit('updatePost',new Date().getTime())
    },
    //标签点击
    handleLabel(label, id) {
      const txt = label.replace(/#/g, "");
      this.$router.push(`/search?label_id=${id}&search=${txt}`)

    },
    //关注 取消关注
    handleFollow(state, id) {
      if (state == 0) {
        this.$api.post(`/api/follow/user/${id}`).then(() => {
          this.card.user.has_follow = 1
          this.$message.success(this.$t('circle.focus_success'))
        })
      } else {
        this.$api.delete(`/api/follow/user/${id}`).then(() => {
          this.card.user.has_follow = 0
          this.$message.success(this.$t('circle.cancel_success'))
        })
      }
    },
    //删除帖子
    handleDel(id) {
      this.$api.delete(`/api/post/${id}`).then(() => {
        this.$message.success(this.$t('task.del_success'))
        this.$emit('updatePost')
      })
    },
    //点赞 取消点赞
    handleZan(state, id) {
      if (state == 0) {
        // 点赞
        this.card.has_like = 1
        this.card.like_count++
        this.$api.post(`/api/like/post/${id}`)
      } else {
        //取消点赞
        this.card.has_like = 0
        this.card.like_count--
        this.$api.delete(`/api/like/post/${id}`)
      }
    },
    //收藏 取消收藏
    handleCollect(state, id) {
      if (state == 0) {
        // 收藏
        this.card.has_collect = 1
        this.$api.post(`/api/collect/post/${id}`)
      } else {
        //取消点赞
        this.card.has_collect = 0
        this.$emit('updatePost')
        this.$api.delete(`/api/collect/post/${id}`)

      }
    },
    //分享
    handleShare() {
      copyData(location.href)
      // this.visible = true
    },
    //跳转
    handleSkip(url) {
      this.$router.push(url)
    },
    //播放
    handlePlay() {
      this.card.show = false
      setTimeout(() => {
        this.$refs.videoRef.play()
      }, 200)
    }
  },
};
</script>
<style scoped lang="scss">
.card {
  width: 100%;
  border-bottom: 1px solid #EAEAEA;
  padding: 0.2rem 0rem 0.48rem 0rem;
  box-sizing: border-box;

  .top-info {
    display: flex;
    flex-direction: row;
    align-items: center;


    ul {
      display: flex;
      flex-direction: row;
      margin-left: 0.2rem;
      align-items: center;
      margin-top: -0.2rem;

      li {
        font-size: 0.15rem;


        color: #02428C;
        line-height: 0.24rem;
      }

    }

  }

  .content {
    cursor: pointer;


    .feeds-content {
      white-space: pre-line;
      width: 100%;
      height: auto;
      font-size: 0.16rem;
      color: #202020;
      line-height: 0.3rem;
      word-break: break-all;
      width: 100%;
      height: auto;
      font-size: 0.16rem;
      color: #202020;
      line-height: 0.3rem;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      white-space: pre-line;
    }

    .topic {
      color: 02428C;
      color: #02428C;
      font-size: 0.15rem;
      margin-left: 0.1rem;
    }
  }

  .bottom-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 0.4rem;
    color: #999;

    img {
      padding: 0 0.05rem;
      width: 0.24rem;
    }

    div {
      padding: 0 0.09rem;

      &:first-child {
        padding-left: 0;
      }
    }

    &>div:first-child {
      padding-left: 0;
    }

    .more {
      padding: 0 !important;
      margin: 0;

      .click-more {
        padding: 0.1rem 0 0.1rem 0.1rem;
      }
    }

    .el-dropdown {
      padding: 0.05rem;
    }
  }

  .other {

    color: #202020;
    line-height: 0.36rem;
    margin-top: 0.1rem;
    font-size: 0.2rem;


  }

  .circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.1rem;

    .circle-item {
      cursor: pointer;
      padding: 0.08rem 0.15rem;
      background-color: #519fe821;
      color: #519ee8;
      border-radius: 0.05rem;
      margin-left: 0.1rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:first-child {
        margin-left: 0;
      }

      .logo-img {
        width: 0.22rem;
        height: 0.22rem;
        border-radius: 10%;
        margin-right: 0.05rem;
        overflow: hidden;
      }
    }
  }
}

.categories-container {
  .categories-item {
    cursor: pointer;
    padding: 0.1rem 0.12rem;
    margin: 0.05rem;
    display: inline-block;
    border: 0.01rem solid #f3f3f3;
  }
}

.pointer {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;

}

.lookAll {
  color: #519ee8;
  cursor: pointer;
  margin-top: 0.1rem;
}

.video-wrap {
  position: relative;
  padding-bottom: 0.2rem;
  margin-top: 0.1rem;
  width: 6.36rem;
  height: 3.59rem;

  .play-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.8rem;
    height: 0.8rem;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    img {}
  }
}

video {}

.flex-row {
  display: flex;
  margin-top: 0.1rem;
  color: #999;

  .time {
    color: #999;
  }

  .adoption {
    margin-right: 0.2rem;
    padding: 0 0.15rem;
    height: 0.2rem;
    line-height: 0.2rem;
    font-size: 0.1rem;
    color: #fff;
    background-color: #519ee8;
    border-radius: 0.2rem;
    cursor: pointer;
  }
}

.source-tit {
  height: 0.56rem;
  line-height: 0.56rem;

  img {
    width: 0.18rem;
    margin-right: 0.05rem;
  }

  .title {
    font-size: 0.17rem;
    color: #02428C;

  }
}

.source-ul {
  li {
    width: 3.92rem;
    height: 0.66rem;
    border-radius: 0.03rem;
    border: 0.01rem solid #DDDDDD;
    padding: 0.07rem;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 0.14rem;
    margin-right: 0.14rem;
    cursor: pointer;

    img {
      width: 0.5rem;
      height: 0.5rem;
      // background: #D8D8D8;
      border-radius: 0.03rem;
      object-fit: cover;

    }

    .info {
      margin-left: 0.12rem;
      flex: 1;

      .tit {
        font-size: 0.17rem;
        color: #212121;
        line-height: 0.24rem;

      }

      .star {
        width: 0.16rem;
        height: auto;
        margin-right: 0.05rem;



      }

      .mark {
        min-width: 0.55rem;
        height: 0.26rem;
        background: #FEF0EA;
        border-radius: 0.03rem 0rem 0.03rem 0rem;
        text-align: center;
        line-height: 0.26rem;
        font-size: 0.14rem;
        color: #F17E4C;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .diff {
        color: #3B7DD2;
        background: #E3EFFE;

      }

    }


  }


}

.del-btn {
  width: 0.69rem;
  height: 0.32rem;
  border-radius: 0.03rem;
  border: 0.01rem solid #8A8A8A;
  text-align: center;
  line-height: 0.32rem;
  cursor: pointer;
  color: #8A8A8A;
  font-size: 0.16rem;

}

.mr {
  margin-right: 1rem;
}

.link {
  margin-top: 0.14rem;

  .link-item {
    margin-bottom: 0.14rem;
    height: auto;
    background: #FBFBFB;
    border-radius: 0.04rem;
    padding: 0.05rem 0.1rem;
    box-sizing: border-box;

    img {
      width: 0.5rem;
    }

    .link-name {
      font-size: 0.16rem;
      margin-left: 0.2rem;
      font-weight: 500;
      color: #202020;
      line-height: 0.29rem;

    }
  }

}
.icon{
  color: #02428C;
  font-size: 0.12rem;
  cursor: pointer;
}

</style>
