<!-- 圈子列表 -->
<template>
    <div class="circle">
        <!-- 圈子列表 -->
        <ul class="list" v-if="list.length">
            <li v-for="(item, index) in list" :key="index">
                <div class="cover" style="cursor: pointer" @click="toDetail(item.id)" :style="{
                    'background-image': 'url(' + item.cover + ')',
                }">
                </div>
                <div class="circle-logo" style="cursor: pointer" @click="toDetail(item.id)" :style="{
                    'background-image': 'url(' + item.icon + ')',
                }"></div>
                <div class="info">
                    <div class="title x-ell-1" @click="toDetail(item.id)" style="cursor: pointer">
                        {{ item.name }}
                    </div>
                    <div class="summary x-ell-2" @click="toDetail(item.id)" style="cursor: pointer">
                        {{ item.description }}
                    </div>
                    <div class="btn" @click="handleJoin(item.id)" style="cursor: pointer" v-if="item.has_join == 0">
                        <p> {{ $t('circle.apply_join') }}</p>
                    </div>
                    <div class="btn diff" style="cursor: pointer" v-else>
                        <p> {{ $t('auth.joined') }}</p>
                    </div>
                </div>
            </li>
        </ul>
        <!-- 加圈申请 -->
        <ApplyJoin :show="show" @handleClose="show = false" @handleConfirm="handleConfirm" />
    </div>
</template>
<script>
import ApplyJoin from '@/components/ApplyJoin.vue'
export default {
    components: { ApplyJoin },
    props: ['list'],
    data() {
        return {
            show: false,//控制弹窗的显示和隐藏
            form: {
                id: '',
                comment: ''
            }
        };
    },
    methods: {
        //跳转到圈子详情页面
        toDetail(id) {
            this.$router.push(`/circleDetail?id=${id}`)
        },
        //加入圈子
        handleJoin(id) {
            this.form.id = id;
            this.show = true
        },
        //确认加入圈子
        handleConfirm(comment) {
            this.form.comment = comment
            this.$api.post(`/api/join-group/${this.form.id}`, this.form).then(() => {
                this.$message.success(this.$t('auth.operate_success'))
                this.show = false
            }).catch(() => {
                this.$message.error(this.$t('auth.operate_fail'))
            })
        }
    },
};
</script>
<style scoped lang="scss">
.circle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}





//圈子列表
.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
        width: 3.69rem;
        height: 3.99rem;
        background: #FFFFFF;
        border-radius: 0.06rem;
        margin: 0.2rem 0.3rem 0 0;
        overflow: hidden;


        .cover {
            width: 3.69rem;
            height: 1.44rem;
            background-color: #efefef;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
            position: relative;

            img {
                position: absolute;
                display: inline-block;
                bottom: 0.05rem;
                right: 0.05rem;
            }
        }

        .circle-logo {
            width: 0.84rem;
            height: 0.84rem;
            border-radius: 0.06rem;
            border: 2px solid #ffffff;
            margin: -0.6rem 1.425rem 0;
            position: relative;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
            background-color: #efefef;
        }

        .info {
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                margin-top: 0.16rem;
                font-size: 0.2rem;


                color: #262423;
                line-height: 0.32rem;
                cursor: pointer;
            }

            .summary {
                margin-top: 0.1rem;
                font-size: 0.15rem;
                padding: 0 0.3rem;
                box-sizing: border-box;
                color: #838383;
                line-height: 0.29rem;
                cursor: pointer;
                height: 0.58rem;
            }

            .expense {
                font-size: 0.14rem;
                font-family: PingFang SC;

                color: #ff6200;
                padding-right: 0.05rem;
            }

            .btn {
                width: 2.07rem;
                height: 0.43rem;
                border-radius: 0.03rem;
                border: 1px solid #02428C;
                text-align: center;
                font-size: 0.17rem;
                color: #02428C;
                line-height: 0.43rem;
                margin-top: 0.36rem;


            }

            .diff {
                background: #F3F3F3;
                border: 0.01rem solid #F3F3F3;
                color: #939393;
            }

            .price-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                width: 100%;

                .price-btn {
                    cursor: pointer;
                    min-width: 1rem;
                    padding: 0 0.1rem;
                    height: 0.32rem;
                    border: 0.01rem solid #b3b3b3;
                    opacity: 1;
                    border-radius: 0.16rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    line-height: 0.3rem;
                    font-size: 0.1rem;
                    font-family: PingFang SC;

                    color: #519fe8be;
                }
            }

            .different {
                border: 1px solid #b3b3b3;
                color: #b3b3b3;
            }
        }
    }
}


.waiting {
    border-radius: 20px;
    background: #519ee8;
    color: #ffffff !important;
    width: 1rem;
    padding: 0 0.1rem;
    height: 0.3rem;
    text-align: center;
    line-height: 0.3rem;
    margin-top: 0.05rem;
}
</style>
  