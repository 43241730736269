import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css/normalize.css'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()
import 'swiper/dist/css/swiper.css'
//兼容
import 'babel-polyfill'
// 中英文切换
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import elementEn from 'element-ui/lib/locale/lang/en' // element-ui 英语语言包
import elementZh from 'element-ui/lib/locale/lang/zh-CN' // element-ui 中文语言包
import zh from './components/language/zh' // 自己的中文语言包
import en from './components/language/en'
//elementui
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//谷歌登录
// import GSignInButton from 'vue-google-signin-button'
// Vue.use(GSignInButton);
Vue.use(Element)
Vue.use(VueI18n)
import { showLoading, hideLoading } from "@/utils/loading";
Vue.prototype.showLoading = showLoading
Vue.prototype.hideLoading = hideLoading
//数据存储
import storage from './utils/store'
//封装的接口
import api from './api/api.js'
Vue.prototype.$storage = storage
Vue.prototype.$api = api
Vue.prototype.$baseUrl = 'http://xikedao.shellsns.com'
require('es6-promise').polyfill()
Es6Promise.polyfill()
export const eventBus = new Vue()
import * as filters from './filters.js'
Vue.prototype.$MessageBundle = filters.plusMessageFirst
for (const k in filters) {
  Vue.filter(k, filters[k])
}
// 定义一个过滤器 处理时间
Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  // 月份从0开始,使她变成字符串,不足两位时,前面补个0.
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

Vue.filter('getTimer', function (stringTime) {
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let week = day * 7;
  let month = day * 30;
  let year = month * 12;
  let time1 = new Date().getTime(); //当前的时间戳
  let time2 = Date.parse(new Date(stringTime)); //指定时间的时间戳
  let time = time1 - time2;
  let result = null;
  if (time < 0) {
    // 时间不能早于当前时间
  } else if (time / year >= 1) {
    result = parseInt(time / year) + "年前";
  } else if (time / month >= 1) {
    result = parseInt(time / month) + "月前";
  } else if (time / week >= 1) {
    result = parseInt(time / week) + "周前";
  } else if (time / day >= 1) {
    result = parseInt(time / day) + "天前";
  } else if (time / hour >= 1) {
    result = parseInt(time / hour) + "小时前";
  } else if (time / minute >= 1) {
    result = parseInt(time / minute) + "分钟前";
  } else {
    result = "刚刚发布";
  }
  return result;

})



//图片点击放大预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
})
Vue.config.productionTip = false
//路由发生变化修改页面title
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
//进入页面，页面回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
//注册全局组件空白页
import ViewEmpty from '@/components/ViewEmpty'
Vue.component('ViewEmpty', ViewEmpty)
const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'zh', // 从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    zh: {
      ...zh,
      ...elementZh
    },
    en: {
      ...en,
      ...elementEn
    }
  }
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
new Vue({
  router,
  i18n,   //把 i18n 挂载到 vue 根实例上
  render: h => h(App)
}).$mount('#app')
