<template>
    <div class="source">
        <div class="source-head">
            <div class="source-tit">
                <div class="title"> {{ $t('circle.tit2') }}</div>
            </div>
            <ul class="source-ul flex-row-wrap">
                <li class="flex-row-center" v-for="(item, index) in dataSorce" :key="index"
                    @click="handleSkip(`/sourceDetail?id=${item.id}`)">
                    <img :src="item.image && item.image[0]" />
                    <div class="info">
                        <div class="x-ell-1 tit">{{ item.title }}</div>
                        <div class="flexr-row-center-between">
                            <img class="star" src="@/assets/icons/star.png" v-for="(itemt) in item.level" :key="itemt" />
                            <div class="mark" v-if="item.type == 1">  {{ $t('circle.export') }}</div>
                            <div class="mark diff" v-else>  {{ $t('circle.organ') }}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="flex-row-center-center btn-wrap">
                <div class="btn m15" @click="handleOpen(dataSorce)">{{ $t('circle.tit3') }}</div>
                <div class="btn m15" @click="handleMore">{{ $t('circle.look_more')}}</div>
            </div>
        </div>
        <div class="source-item" v-for="(item, index) in list">
            <div class="source-item-head flex-row-center-between">
                <div class="flex-row-center">
                    <Avatar size="medium2" :showName="true" :user="item" />
                    <!-- <div class="focus"> {{ item.has_follow == 1 ?   $t('index.focused') :   $t('index.focus')  }}</div> -->
                </div>
                <div class="btn" @click.stop="handleOpen(item.resource)">{{ $t('circle.top')}}</div>
            </div>
            <div class="bg" v-if="item.resource && item.resource.length">
                <div class="source-tit flex-row-center">
                    <img src="@/assets/icons/list.png" />
                    <div class="title"> {{$t('circle.source')}} （{{ item.resource && item.resource.length }}）</div>
                </div>
                <ul class="source-ul flex-row-wrap">
                    <li class="flex-row-center" v-for="(itemt, index) in item.resource" :key="index"
                        @click="handleSkip(`/sourceDetail?id=${itemt.id}`)">
                        <img :src="itemt.image[0]" v-if="itemt.image && itemt.image.length" />
                        <img v-else />
                        <div class="info">
                            <div class="x-ell-1 tit">{{ itemt.title }}</div>
                            <div class="flexr-row-center-between">
                                <img class="star" src="@/assets/icons/star.png" v-for="v in itemt.level" :key="v" />
                                <div class="mark" v-if="itemt.type == 1">  {{ $t('circle.export') }}</div>
                                <div class="mark diff" v-if="itemt.type == 2">  {{ $t('circle.organ') }}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 置顶资源 -->
        <PopupTop :show="show2" :list="array" @handleClose="show2 = false" :id="id" @handleUntop="handleUntop" @handletop="handletop"/>
    </div>
</template>
<script>
import PopupTop from './PopupTop.vue'
import Avatar from '@/components/Avatar.vue'
export default {
    components: { Avatar, PopupTop },
    props: ['list', 'id','manageId'],
    data() {
        return {
            user: this.$storage.getData("userInfo"),
            show2: false,//控制置顶资源弹窗的显示和隐藏
            array: [],
            dataSorce: [],//圈子置顶资源列表
            listQuery: {
                group_id: this.id,
                page: 1
            },
            lastPage: 0
        };
    },
    created() {
        this.getList()
    },
    methods: {
        handleSkip(url) {
            this.$router.push(url)
        },
        //打开置顶资源弹窗
        handleOpen(array) {
            if(this.user&&this.user.id!= this.manageId){
                this.$message.warning(this.$t('circle.tip3'))
                return
            }




            this.array = array;
          
            this.show2 = true
        },
        //获取置顶资源列表
        getList(page) {
            if (page) this.listQuery.page = page
            this.$api.get('/api/top/group/resource', { params: this.listQuery }).then((res) => {
                if (this.listQuery.page == 1) {
                    this.dataSorce = res.data.data
                } else {
                    this.dataSorce = [...this.dataSorce, ...res.data.data]
                }
                this.lastPage = res.data.meta.last_page
            })
        },
        //查看更多
        handleMore(){
            if(this.listQuery.page<=this.lastPage){
                this.listQuery.page++
                this.getList()
            }else{
                this.$message.warning(this.$t('circle.nomore'))
            }
        },
        //取消置顶
        handleUntop(index){
            this.array.splice(index,1)
            this.getList(1)
        },
        //置顶
        handletop(index){
            this.array[index].is_to_top=1
            this.getList(1)
        }
    }
};
</script>
<style lang="scss" scoped>
.source {
    .source-head {
        padding-bottom: 0.29rem;

    }

    .source-tit {
        height: 0.56rem;
        line-height: 0.56rem;

        >img {
            width: 0.2rem;
            height: 0.2rem;
            // background-color: #efefef;
            margin-right: 0.05rem;
        }

        .title {
            font-size: 0.17rem;
            color: #02428C;

        }
    }

    .source-ul {
        li {
            width: 3.92rem;
            height: 0.66rem;
            border-radius: 0.03rem;
            border: 0.01rem solid #DDDDDD;
            padding: 0.07rem;
            box-sizing: border-box;
            position: relative;
            margin-bottom: 0.14rem;
            margin-right: 0.14rem;
            cursor: pointer;

            >img {
                width: 0.5rem;
                height: 0.5rem;
                background: #D8D8D8;
                border-radius: 0.03rem;

            }

            .info {
                margin-left: 0.12rem;
                flex: 1;

                .tit {
                    font-size: 0.17rem;
                    color: #212121;
                    line-height: 0.24rem;

                }

                .star {
                    width: 0.16rem;
                    height: auto;
                    background-color: none;
                    margin-right: 0.05rem;



                }

                .mark {
                    width: 0.55rem;
                    height: 0.26rem;
                    background: #FEF0EA;
                    border-radius: 0.03rem 0rem 0.03rem 0rem;
                    text-align: center;
                    line-height: 0.26rem;
                    font-size: 0.14rem;
                    color: #F17E4C;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                .diff {
                    color: #3B7DD2;
                    background: #E3EFFE;

                }

            }


        }


    }

    .btn-wrap {
        margin-top: 0.16rem;

    }

    .btn {
        width: 1.3rem;
        height: 0.33rem;
        border-radius: 0.03rem;
        border: 0.01rem solid #02428C;
        text-align: center;
        line-height: 0.33rem;
        cursor: pointer;
        font-size: 0.17rem;
        color: #02428C;
    }

    .m15 {
        margin: 0 0.15rem;
    }

    .source-item-head {
        .focus {

            font-size: 0.17rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02428C;
            line-height: 0.24rem;
        }
    }

    .source-item {
        border-top: 1px solid #EAEAEA;
        padding-top: 0.46rem;
        padding-bottom: 0.6rem;


    }

    .bg {
        background-color: #FCFCFC;
        padding: 0.17rem;
        box-sizing: border-box;
        margin-top: 0.24rem;
    }







}
</style>