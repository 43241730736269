<!-- 消息通知 -->
<template>
    <div class="msg">
        <div class="msg-tit-wrap flex-row-center-between">
            <div class="msg-tit">{{ $t('personal.msg_notice') }}</div>
            <div class="read flex-row-center" @click="handleRead">
                <img src="@/assets/icons/msg7.png" />
                <span>{{ $t('personal.all_read') }}</span>
            </div>
        </div>
        <div class="main flex-row">
            <ul>
                <li @click="handleNav(index)" :class="{ 'active': current == index }" class="flex-row-center"
                    v-for="(item, index) in navs" :key="index">
                    <img :src="current == index ? item.iconed : item.icon" />
                    <div class="tit">{{ item.title }}</div>
                    <div class="dot" v-if="item.num != 0"></div>
                </li>
            </ul>
            <div v-if="list.length">
                <!-- 全部消息 -->
                <div class="all" v-if="current == 0">
                    <div class="item flex-row" v-for="(item, index) in list" :key="index" @click="handlePage(item.target_type,item.target_id)">
                        <div class="item-left">
                            <img :src="item.from.avatar"
                                v-if="(item.target_type != 'system')&&item.from" />
                                <img src="@/assets/icons/default.png" v-else />
                            <img src="@/assets/icons/la.png" v-if="item.target_type == 'system'" />
                            <div class="dot" v-if="item.is_read == 0"></div>
                        </div>
                        <div class="item-right">
                            <div v-if="item.target_type == 'post'">
                                <div class="desc flex-row-center">
                                    <div class="desc-left">{{ item.from && item.from.name }}</div>
                                    <div class="desc-right">{{ item.action_description }}</div>
                                </div>
                                <div class="title" v-if="item.action_title">{{ $t('circle.comment') }} ：{{ item.action_title
                                }}</div>
                                <div class="topic flex-row-center">
                                    <div class="topic-left"></div>
                                    <div class="topic-right">{{ item.target_title }}</div>
                                </div>
                                <div class="sec flex-row-center">
                                    <div class="time">{{ item.created_at }}</div>
                                    <div class="flex-row-center comment" @click.stop="skip(item.target_id)">


                                        <img src="@/assets/icons/comment.png" />
                                        <div class="reply">{{ $t('activity.reply_comment') }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="item.target_type == 'group'">
                                <div class="desc flex-row-center">
                                    <div class="desc-left">{{ item.from && item.from.name }}</div>
                                    <div class="desc-right">{{ item.action_description }}</div>
                                </div>
                                <div class="title" v-if="item.action_title">
                                    {{ $t('personal.leave_msg') }} ：{{ item.action_title }}</div>
                                <div class="btn-wrap flex-row-center" v-if="item.need_operate == 1">
                                    <div class="btn" @click.stop="handleGroupAudit(1, item)">{{ $t('personal.agree') }}</div>
                                    <div class="btn diff" @click.stop="handleGroupAudit(0, item)">{{ $t('personal.refuse') }}
                                    </div>
                                </div>
                                <div class="sec">
                                    <div class="time">{{ item.created_at }}</div>
                                </div>
                            </div>
                            <div v-else>
                                <p class="tit">{{ item.action_description }}</p>
                                <div class="sec">
                                    <div class="time">{{ item.created_at }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 动态消息 -->
                <div class="all" v-if="current == 1">
                    <div class="item flex-row" v-for="(item, index) in list" :key="index" @click="handlePage(item.target_type,item.target_id)">
                        <div class="item-left">
                            <img v-if="item.from&&item.from.avatar" :src="item.from.avatar" />
                            <img src="@/assets/icons/default.png" v-else />
                            <div class="dot" v-if="item.is_read == 0"></div>
                        </div>
                        <div class="item-right">
                            <div>
                                <div class="desc flex-row-center">
                                    <div class="desc-left">{{ item.from && item.from.name }}</div>
                                    <div class="desc-right">{{ item.action_description }}</div>
                                </div>
                                <div class="title" v-if="item.action_title">{{ $t('circle.comment') }} ：{{ item.action_title
                                }}</div>
                                <div class="topic flex-row-center">
                                    <div class="topic-left"></div>
                                    <div class="topic-right">{{ item.target_title }}</div>
                                </div>
                                <div class="sec flex-row-center">
                                    <div class="time">{{ item.created_at }}</div>
                                    <div class="flex-row-center comment"
                                        @click.stop="handleSkip(`/feedDetail?id=${item.target_id}`)">
                                        <img src="@/assets/icons/comment.png" />
                                        <div class="reply">{{ $t('activity.reply_comment') }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 空间消息 -->
                <div class="all" v-if="current == 2">
                    <div class="item flex-row" v-for="(item, index) in list" :key="index" @click="handlePage(item.target_type,item.target_id)">
                        <div class="item-left">
                            <img v-if="item.from&&item.from.avatar" :src="item.from.avatar" />
                            <img src="@/assets/icons/default.png" v-else />
                            <div class="dot" v-if="item.is_read == 0"></div>
                        </div>
                        <div class="item-right">
                            <div>
                                <div class="desc flex-row-center">
                                    <div class="desc-left">{{ item.from && item.from.name }}</div>
                                    <div class="desc-right">{{ item.action_description }}</div>
                                </div>
                                <div class="title" v-if="item.action_title">{{ $t('personal.leave_msg') }}：{{
                                    item.action_title }}</div>
                                <div class="btn-wrap flex-row-center" v-if="item.need_operate == 1">
                                    <div class="btn" @click.stop="handleGroupAudit(1, item)">{{ $t('personal.agree') }}</div>
                                    <div class="btn diff" @click.stop="handleGroupAudit(-1, item)">{{ $t('personal.refuse') }}
                                    </div>
                                </div>
                                <div class="sec">
                                    <div class="time">{{ item.created_at }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 任务消息以及活动消息 -->
                <div class="all" v-if="current == 3 || current == 4 || current == 5">
                    <div class="item flex-row" v-for="(item, index) in list" :key="index" @click="handlePage(item.target_type,item.target_id)">
                        <div class="item-left">
                            <img v-if="item.from&&item.from.avatar" :src="item.from.avatar" />
                            <img src="@/assets/icons/default.png" v-else />
                            <div class="dot" v-if="item.is_read == 0"></div>
                        </div>
                        <div class="item-right">
                            <div>
                                <p class="tit">{{ item.action_description }}</p>
                                <div class="sec">
                                    <div class="time">{{ item.created_at }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 系统消息 -->
                <div class="all" v-if="current == 5">
                    <div class="item flex-row" v-for="(item, index) in list" :key="index" @click="handlePage(item.target_type,item.target_id)">
                        <div class="item-left">
                            <img src="@/assets/icons/la.png" />
                            <div class="dot" v-if="item.is_read == 0"></div>
                        </div>
                        <div class="item-right">
                            <div>
                                <p class="tit">{{ item.action_description }}</p>
                                <div class="sec">
                                    <div class="time">{{ item.created_at }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <view-empty v-else :title="$t('personal.no_msg')"></view-empty>
        </div>
        <!-- 同意/拒绝加入圈子 -->
        <el-dialog :title="$t('personal.refuse')" :visible.sync="show" width="8rem" :before-close="handleClose">
            <el-input type="textarea" rows="5" :placeholder="$t('personal.placeholder1')" v-model="group.reason" />
            <div class="btn-diff" @click="handleConfirm">{{ $t('auth.submit') }}</div>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
    </div>
</template>
<script>


export default {
    data() {
        return {
            navs: [
                {
                    icon: require('@/assets/icons/msg1.png'),
                    iconed: require('@/assets/icons/msged1.png'),
                    title: this.$t('personal.all_msg'),
                    value: '',
                    num: 0
                },
                {
                    icon: require('@/assets/icons/msg2.png'),
                    iconed: require('@/assets/icons/msged2.png'),
                    title: this.$t('personal.feed_msg'),
                    value: 'post',
                    num: 0
                },
                {
                    icon: require('@/assets/icons/msg3.png'),
                    iconed: require('@/assets/icons/msged3.png'),
                    title: this.$t('personal.group_msg'),
                    value: 'group',
                    num: 0
                },
                {
                    icon: require('@/assets/icons/msg4.png'),
                    iconed: require('@/assets/icons/msged4.png'),
                    title: this.$t('personal.task_msg'),
                    value: 'task',
                    num: 0
                },
                {
                    icon: require('@/assets/icons/msg5.png'),
                    iconed: require('@/assets/icons/msged5.png'),
                    title: this.$t('personal.activity_msg'),
                    value: 'activity',
                    num: 0
                },
                {
                    icon: require('@/assets/icons/msg6.png'),
                    iconed: require('@/assets/icons/msged6.png'),
                    title: this.$t('personal.system_msg'),
                    value: 'system',
                    num: 0
                }
            ],
            current: 0,//当前导航
            //圈子申请审核信息
            group: {
                id: '',
                status: '',//审核状态
                reason: '',
            },
            loading: false,
            list: [],
            listQuery: {
                page: 1,
                target: ''
            },
            lastPage: 0,//最后一页
            timer: null,
            show: false,
            ids: [],
            type: '',
            userId: this.$storage.getData("userInfo")
                ? this.$storage.getData("userInfo").id
                : null
        }
    },
    created() {
        //获取消息列表
        this.fetchData()
        //获取统计
        this.getsStatistics()
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
        // clearInterval(this.timer)
    },
    mounted() {
        // 监听如果页面发生滚动时
        window.addEventListener("scroll", this.handleScroll);
        // if (this.userId) {
        //     this.timer = setInterval(() => {
        //         this.getsStatistics()
        //     }, 100000)
        // }
    },
    methods: {
        //跳转
        handlePage(type,id){
            if(type=='task'){
                this.handleSkip(`/taskDetail?id=${id}`)
            }
            if(type=='post'){
                this.handleSkip(`/feedDetail?id=${id}`)
            }
            if(type=='group'){
                this.handleSkip(`/circleDetail?id=${id}`)
            }
            if(type=='activity'){
                this.handleSkip(`/activityDetail?id=${id}`)
            }



        },
        skip(id) {
            this.$api.get(`/api/post/`+id).then(() => {
                this.handleSkip(`/feedDetail?id=${id}`)
            }).catch(() => {
                this.$message.warning('原帖子已被删除')
            })
        },
        //关闭弹窗
        handleClose() {
            this.show = false
        },
        //全部已读
        handleRead() {
            let params = {}
            if (this.ids && this.ids.length && this.current != 0) {
                params = {
                    ids: this.ids,
                    type: this.type
                }
            }
            this.$api.get(`/api/notify/read`, { params }).then(res => {
                this.getsStatistics()
                this.fetchData(1)
            }).catch(() => {
                this.$message.warning(this.$t('auth.operate_fail'))
            })

        },
        //跳转
        handleSkip(url) {
            this.$router.push(url)
        },
        //获取统计
        getsStatistics() {
            this.$api.get('/api/notify/statistics').then(res => {
                let result = res.data
                this.navs[0].num = result.all
                this.navs[1].num = result.post
                this.navs[2].num = result.group
                this.navs[3].num = result.task
                this.navs[4].num = result.activity
                this.navs[5].num = result.system
            })
        },
        //导航切换
        handleNav(index) {
            this.current = index
            this.listQuery.target = this.navs[index].value
            this.type = this.navs[index].value
            this.fetchData(1)

        },
        //消息列表
        fetchData(page) {
            if (page) this.listQuery.page = page
            if (this.listQuery.page == 1) {
                this.loading = true
            }
            this.$api.get('/api/notify/list', { params: this.listQuery }).then((res) => {
                if (this.listQuery.page == 1) {
                    this.list = res.data.data
                } else {
                    this.list = [...this.list, ...res.data.data]
                }
                if (res.data.data && res.data.data.length) {
                    this.ids = res.data.data.map(item => item.id)
                }

                this.lastPage = res.data.meta.last_page
                this.loading = false
                // this.handleRead()

            })

        },
        //页面滑到底部调用的方法
        handleScroll() {
            //下面这部分兼容手机端和PC端
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            var windowHeitht =
                document.documentElement.clientHeight || document.body.clientHeight;
            var scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;
            //是否滚动到底部的判断
            if (Math.round(scrollTop) + windowHeitht + 2 > scrollHeight) {
                if (this.listQuery.page <= this.lastPage) {
                    this.listQuery.page++
                    this.fetchData(1)
                }
            }
        },
        //圈子申请审核
        handleGroupAudit(status, item) {
            this.group.status = status
            this.group.id = item.action_id
            if (status == 1) {
                this.$api.post('/api/group/audit', this.group).then(() => {
                    this.$message.success(this.$t('auth.operate_success'))
                    this.fetchData()
                    this.loading = false
                }).catch(() => {
                    this.$message.error(this.$t('auth.operate_fail'))
                })

            } else {
                this.show = true
            }
        },
        //拒绝加圈子
        handleConfirm() {
            if (this.group.reason.length == 0) {
                this.$message.warning(this.$t('personal.tip1'))
                return
            }
            this.$api.post('/api/group/audit', this.group).then(() => {
                this.$message.success(this.$t('auth.operate_success'))
                this.group.reason = ''
                this.fetchData(1)
                this.show = false

            }).catch(() => {
                this.$message.error(this.$t('auth.operate_fail'))
            })
        }
    }
};
</script>
<style lang="scss" scoped>
.msg {
    width: 13.2rem;
    height: auto;
    background: #FFFFFF;
    border-radius: 0.03rem;
    margin: 0.92rem auto;
    padding: 0 0.3rem 0.3rem;
    box-sizing: border-box;

    .msg-tit-wrap {
        height: 1rem;
        border-bottom: 1px solid #EAEAEA;

        .msg-tit {
            font-size: 0.22rem;
            color: #262423;
        }

        .read {
            width: 1.58rem;
            height: 0.4rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #02428C;
            padding: 0 0.3rem;
            box-sizing: border-box;
            cursor: pointer;

            img {
                width: 0.2rem;
            }

            span {
                font-size: 0.16rem;
                color: #02428C;
                margin-left: 0.06rem;

            }

        }

    }

    .main {
        margin-top: 0.3rem;
        width: 100%;

        ul {
            width: 2.3rem;
            height: auto;

            li {
                width: 100%;
                height: 0.63rem;
                padding: 0 0.1rem;
                box-sizing: border-box;
                cursor: pointer;

                img {
                    width: 0.2rem;
                    margin-right: 0.15rem;
                }

                .tit {
                    font-size: 0.18rem;
                    color: #848484;

                }

                .dot {
                    width: 0.09rem;
                    height: 0.09rem;
                    background: #FF1E1E;
                    border-radius: 50%;
                    margin-left: 0.12rem;

                }
            }

            .active {
                background-color: #02428C;

                .tit {
                    color: #ffffff;
                }
            }
        }

        .all {
            width: 10.3rem;
            background: #FBFBFB;
            border-radius: 0.03rem;
            padding: 0 0.5rem;
            box-sizing: border-box;

            .item {
                padding: 0.3rem 0;
                border-bottom: 1px solid #EAEAEA;
                cursor: pointer;
                .item-left {
                    width: 0.58rem;
                    height: 0.58rem;
                    position: relative;
                    margin-right: 0.15rem;

                    img {
                        width: 0.58rem;
                        height: 0.58rem;
                        border-radius: 50%;
                        background: #abcdef;
                    }

                    .dot {
                        position: absolute;
                        width: 0.09rem;
                        height: 0.09rem;
                        background: #FF1E1E;
                        border-radius: 50%;
                        right: 0.05rem;
                        top: 0;

                    }

                }

                .item-right {
                    flex:1;
                    .tit {
                        font-size: 0.18rem;
                        word-wrap: break-word;
                        width: 8.6rem;

                    }

                    .desc {
                        font-size: 0.16rem;

                        .desc-left {
                            margin-right: 0.14rem;
                            color: #848484;
                        }

                        .desc-right {
                            color: #898989;
                        }

                    }

                    .title {
                        color: #262423;
                        font-size: 0.18rem;
                        margin-top: 0.1rem;
                    }

                    .topic {
                        margin: 0.2rem 0;

                        .topic-left {
                            width: 0.03rem;
                            height: 0.2rem;
                            background: #D8D8D8;
                        }

                        .topic-right {
                            color: #898989;
                            font-size: 0.18rem;
                            margin-left: 0.14rem;
                        }
                    }

                    .sec {
                        margin-top: 0.06rem;

                        .time {
                            font-size: 0.16rem;
                            color: #9B9B9B;
                            margin-right: 0.26rem;
                        }

                        .comment {
                            cursor: pointer;

                            img {
                                width: 0.2rem;
                            }

                            .reply {

                                color: #262423;
                                font-size: 0.16rem;
                                margin-left: 0.06rem;
                            }

                        }
                    }

                    .btn-wrap {
                        margin: 0.15rem 0;

                        .btn {
                            width: 1.4rem;
                            height: 0.45rem;
                            background: #02428C;
                            border-radius: 0.04rem;
                            text-align: center;
                            line-height: 0.45rem;
                            color: #ffffff;
                            font-size: 0.16rem;
                            cursor: pointer;
                        }

                        .diff {
                            color: #ffffff;
                            background-color: #C6C6C6;
                            margin-left: 0.3rem;
                        }

                    }

                }

            }

            .item:last-child {
                border-bottom: 0;
            }

        }




    }
}

.btn-diff {
    width: 2.3rem;
    height: 0.52rem;
    background: #02428C;
    border-radius: 0.04rem;
    font-size: 0.16rem;
    cursor: pointer;
    text-align: center;
    line-height: 0.52rem;
    margin: 0.6rem auto 0;
    color: #ffffff;


}
</style>
