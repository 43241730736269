<!-- 推荐关注 -->
<template>
  <div class="focus">
    <div class="main flex-col-center-center">
      <div class="title">{{ $t('auth.tit4') }}</div>
      <div class="desc">{{ $t('auth.tip1') }}</div>
      <ul class="user flex-row-wrap">
        <li class="flex-col-center-center" v-for="(item, index) in users" :key="index">
          <img :src="item.avatar" mode="aspectFill" class="avatar" v-if="item.avatar&&item.avatar.length" />
          <img src="@/assets/icons/default.png" mode="aspectFill" class="avatar" v-else />
          <div class="name x-ell-1">{{ item.name || $t('auth.nullName') }}</div>
          <div class="x-ell-1 bio">{{ item.introduction || $t('auth.nulldesc') }}</div>
          <div class="focus-btn" :class="{ 'active': item.has_follow == 1 }"
            @click="handleFollow(item.has_follow, item.id, index)">{{ item.has_follow == 1 ?   $t('index.focused')  :    $t('index.focus')  }}</div>
        </li>
      </ul>
      <div class="title mt">{{ $t('auth.tit5') }}</div>
      <div class="desc">{{ $t('auth.tip2') }}</div>
      <ul class="quan flex-row-wrap">
        <li class="flex-col-center-center" v-for="(item, index) in list" :key="index">
          <el-image :src="item.icon" mode="aspectFill" class="cover" />
          <div class="tit x-ell-1">{{ item.name }}</div>
          <div class="join-btn" @click="joinGroup(item.id,index)" :class="{ 'active': item.has_join == 1 }"> {{ item.has_join == 1 ?  $t('auth.joined') :  $t('auth.join') }}</div>
        </li>
        
      </ul>
      <div class="operate-btn flex-row-center-between">
        <div class="common btn1" @click="handleSkip('/')">{{ $t('auth.ok') }}</div>
        <div class="common btn2" @click="handleSkip('/')">{{ $t('auth.skip') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      users: [],//感兴趣的用户
      list: [],//感兴趣的圈子
    };
  },
  created() {
    //查询数据
    this.fetchData()
  },
  methods: {
    //跳转
    handleSkip(url) {
      this.$router.push(url)
    },
    //确认加入圈子
    joinGroup(id, index) {
      this.$api.post(`/api/join-group/${id}`).then(() => {
        this.list[index].has_join = 1
      })
    },
    //关注 取消关注
    handleFollow(state, id, index) {
      if (state == 0) {
        this.$api.post(`/api/follow/user/${id}`).then(() => {
          this.users[index].has_follow = 1
        })
      } else {
        this.$api.delete(`/api/follow/user/${id}`).then(() => {
          this.users[index].has_follow = 0
        })
      }
    },
    //查询数据
    fetchData() {
      this.$api.get('/api/users', { params: { type: 'recommend', page: 1, limit: 8 } }).then(res => {
        let result = res.data.data
        if (result && result.length) {
          result = result.slice(0, 8)
          this.users = result
        }
      })
      this.$api.get('/api/group', { params: {type: 'recommend', page: 1, limit: 6 } }).then(res => {
        let arr = res.data.data
        if (arr && arr.length) {
          this.list = arr.slice(0, 6)
        }
      })
    },
  }

};
</script>
<style scoped lang="scss">
.focus {

  //主体
  .main {
    width: 9.3rem;
    height: auto;
    background: #FFFFFF;
    border-radius: 0.06rem;
    padding: 0.65rem 0.7rem 0.68rem;
    box-sizing: border-box;
    margin: 1.2rem auto;

    .title {
      font-size: 0.23rem;


      color: #202020;
      line-height: 0.32rem;
    }

    .desc {
      font-size: 0.17rem;


      color: #8B8B8B;
      line-height: 0.24rem;
      margin-top: 0.05rem;
    }

    .user {
      margin-top: 0.56rem;

      li {
       
        margin-bottom: 0.29rem;
        margin-left: 0.48rem;
        margin-right: 0.48rem;
        .avatar {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }

        .name {
          margin-top: 0.05rem;
          font-size: 0.18rem;
          width: 1rem;
          text-align: center;


          color: #252525;
          line-height: 0.32rem;

        }

        .bio {
          font-size: 0.15rem;
          width: 1rem;
          text-align: center;


          color: #939393;
          line-height: 0.24rem;
        }

        .focus-btn {
          width: 0.69rem;
          height: 0.3rem;
          border-radius: 0.03rem;
          border: 0.01rem solid #02428C;
          cursor: pointer;
          text-align: center;
          line-height: 0.3rem;
          font-size: 0.15rem;


          color: #02428C;
          margin-top: 0.05rem;


        }

        .active {
          background: #F3F3F3;
          border: 0.01rem solid #F3F3F3;
          color: #939393;
        }
      }
      // li:nth-child(2n),li:nth-child(3n),li:nth-child(4n){
      //   margin-left: 1.2rem;

      // }

      // li:nth-child(5n) {
      //   margin-left: 0;
      // }
    }

    .mt {
      margin-top: 0.37rem;
    }

    .quan {
      margin-top: 0.46rem;

      li {
        margin-bottom: 0.29rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .cover {
          width: 0.84rem;
          height: 0.84rem;
          border-radius: 0.06rem;

        }

        .tit {
          font-size: 0.18rem;
          width: 1.6rem;
          text-align: center;


          color: #262423;
          line-height: 0.32rem;
          margin-top: 0.05rem;

        }

        .join-btn {
          width: 1.6rem;
          height: 0.42rem;
          border-radius: 0.03rem;
          border: 0.01rem solid #02428C;
          text-align: center;
          line-height: 0.42rem;
          font-size: 0.15rem;


          color: #02428C;
          cursor: pointer;
          margin-top: 0.08rem;

        }

        .active {
          background: #F3F3F3;
          border: 0.01rem solid #F3F3F3;
          color: #939393;
        }

        .diff {
          width: 1.6rem;
          height: 0.42rem;
          background: #F3F3F3;
          border: 0.01rem solid #F3F3F3;
          border-radius: 0.03rem;
          font-size: 0.17rem;
          text-align: center;
          line-height: 0.42rem;


          color: #939393;


        }
      }

     



    }


    .operate-btn {
      width: 4.46rem;
      margin: 0.6rem auto 0;

      .common {
        width: 2.02rem;
        height: 0.56rem;
        background: #02428C;
        border-radius: 0.03rem;
        text-align: center;
        line-height: 0.56rem;
        cursor: pointer;
        font-size: 0.17rem;


        color: #FFFFFF;

      }

      .btn2 {
        color: #A5A5A5;
        background-color: #E9E9E9;
      }

    }

  }

}
</style>
