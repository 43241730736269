<template>
    <div>
        <ul class="users flex-row-wrap flex-row-center-between">
            <li v-for="(itemt, i) in list" class="flex-row-center" @click="handleSkip(`/personal?id=${itemt.id}`)">
                <Avatar size="medium3" :user="itemt" :showName="true" />
            </li>
        </ul>
    </div>
</template>
<script>
import Avatar from '@/components/Avatar.vue'
export default {
    props: ['list'],
    components: { Avatar },
    data() {
        return {
        };
    },
    methods: {
        //跳转
        handleSkip(url) {
            this.$router.push(url)
        }
    }
};
</script>
<style lang="scss" scoped>
.users {
    padding: 0.3rem;

    li {
        width: 48%;
        height: 0.98rem;
        background: #FAFAFA;
        border-radius: 0.09rem;
        padding: 0.13rem;
        box-sizing: border-box;
        margin-bottom: 0.2rem;


    }

}
</style>