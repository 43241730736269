//圈子模块
import Circle from '../views/circle/index.vue'
//圈子详情
import CircleDetail from '../views/circle/circleDetail.vue'
// 资源详情
import SourceDetail from '../views/circle/sourceDetail.vue'


export default [{
  path: '/circle',
  name: 'circle',
  component: Circle,
  meta: {
    title: '圈子',
  },
},
{
  path: '/circleDetail',
  name: 'circleDetail',
  component: CircleDetail,
  meta: {
    title: '圈子详情',
  },
},

{
  path: '/sourceDetail',
  name: 'SourceDetail',
  component: SourceDetail,
  meta: {
    title: '资源详情',
  },
},












]
