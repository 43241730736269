<template>
  <div id="app">
    <!-- 头部组件 -->
    <Head :toUrl="toUrl"></Head>
    <!-- 路由出口 -->
    <router-view v-cloak v-if="isRouterAlive" />
    <!-- 返回顶部组件 -->
    <scroll-to></scroll-to>
  </div>
</template>
<script>
import scrollTo from "@/components/scrollTo.vue"; //返回顶部组件
import Head from "@/components/head.vue"; //头部组件
export default {
  components: {
    Head,
    scrollTo,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true, //控制视图是否显示的变量
      toUrl: "/",
    };
  },
  watch: {
    toUrl(e) { },
  },
  created() {
    //首次进入页面
    if (window.performance.navigation.type != 1) {
      let url = window.location.href.split("#")[1];
      localStorage.setItem("toUrlData", url);
    }

    let _this = this;
    let data = localStorage.getItem("toUrlData");
    if (data) {
      this.toUrl = data;
    }
    this.$router.beforeEach((to, from, next) => {
      _this.toUrl = to.path;

      next();
    });
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  mounted() {
    function checkIE() {
      return (
        "-ms-scroll-limit" in document.documentElement.style &&
        "-ms-ime-align" in document.documentElement.style
      );
    }
    if (checkIE()) {
      window.addEventListener(
        "hashchange",
        () => {
          var currentPath = window.location.hash.slice(1);
          if (this.$route.path !== currentPath) {
            this.$router.push(currentPath);
          }
        },
        false
      );
    }
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

body {
  background: #f5f5f5;
}

#app {
  margin: 0 auto;
}

* {
  font-size: 14px;
  font-family: PingFang SC;
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333333;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-col-center-center {
  display: flex;
  flex-direction: column;
  ;
  align-items: center;
  justify-content: center;
}

.flex-row-center-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
  display: flex;
}

.bgfff {
  background-color: #ffffff;
}

@for $var from 1 to 10 {
  .x-ell-#{$var} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $var;
    -webkit-box-orient: vertical;
  }
}</style>
